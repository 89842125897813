// @flow

import React from 'react';
import styled from 'styled-components';

import {
  NewSlider,
} from 'components/UI';

const Container = styled.div`
  ${({ show }) => (show ? 'opacity: 1; visibility: visible;' : 'opacity: 0; visibility: hidden;')}
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);

  cursor: auto;

  width: 360px;
  background: #192933;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 12px 16px;
  transition: opacity 0.2s ease-in-out;

  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) => theme.darkTwo} transparent transparent transparent;
  }
  z-index: 1000;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  text-align: left;
  margin-bottom: 4px;
`;

const SliderContainer = styled.div`
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const Image = styled.div`
width: 24px;
height: 24px;
background: url(${({ icons }) => `/images/icons/ic-${icons}.svg`}) no-repeat;

margin-right: 12px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
`;

const X = styled.div`
  width: 14px;
  height: 14px;

  mask-image: url('/images/X.svg');
  mask-repeat: no-repeat;

  background-color: rgba(255,255,255,0.54);
`;

const QuitButton = styled.button`
  width: 24px;
  height: 24px;
  padding: 5px;
  border: 0;
  &:focus {
    outline: none;
  }

  background-color: transparent;
  border-radius: 4px;
  &:hover,
  &:active {
    ${X}{
      background-color: rgba(255,255,255,1) !important;
    }
  }
`;

type Props = {
  data: {
    x: number,
    y: number,
    blur: number,

    borderWidth: number,
    borderBlurShadow: number,
  },
  onChange: (any) => void,
}
// TODO: убрать привязку к title (checkBoxText) компонента. Вынести изменения связанные с border и shadow
export default class ModalTooltip extends React.Component<Props> {
  render() {
    const {
      data,
      onChange,
      show,
      title,
      quitButton,
    } = this.props;
    return (
      <Container show={show}>
        <Row>
          <Title>{`${title} settings`}</Title>
          <QuitButton onClick={quitButton.onClick}><X /></QuitButton>
        </Row>
        {title === 'Shadow' && (
        <Content>
          <SliderContainer>
            <Image icons="shift-horizontal" />
            <NewSlider
              value={data.x}
              min={-100}
              max={100}
              step={5}
              onChange={size => onChange({ x: Number(size) })}
              centerPoint
              magnitPoint={5}
              grey
            />
          </SliderContainer>
          <SliderContainer>
            <Image icons="shift-vertical" />
            <NewSlider
              value={data.y}
              min={-100}
              max={100}
              step={5}
              onChange={size => onChange({ y: Number(size) })}
              centerPoint
              magnitPoint={5}
              grey
            />
          </SliderContainer>
          <SliderContainer>
            <Image icons="blur" />
            <NewSlider
              value={data.blur}
              min={0}
              max={50}
              step={1}
              onChange={size => onChange({ blur: Number(size) })}
              grey
            />
          </SliderContainer>
        </Content>)}
        {title === 'Border' && (
          <Content>
            <SliderContainer>
              <Image icons="border-width" />
              <NewSlider
                value={data.borderWidth}
                min={0}
                max={50}
                step={1}
                onChange={size => onChange({ borderWidth: Number(size) })}
                grey
              />
            </SliderContainer>
            <SliderContainer>
              <Image icons="blur" />
              <NewSlider
                value={data.borderBlurShadow}
                min={0}
                max={50}
                step={1}
                onChange={size => onChange({ borderBlurShadow: Number(size) })}
                grey
              />
            </SliderContainer>
          </Content>)}
      </Container>
    );
  }
}
