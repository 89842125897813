import React, { Component } from 'react';

import {
  BackgroundUsesPackage,
  UsesPackage,
  LogoPackage,
  TextLogo,
  SpanOrange,
  OBS,
  XSplit,
  Wirecast,
} from './style';

export class UsesPackageContent extends Component {
  render() {
    return (
      <BackgroundUsesPackage>
        <UsesPackage>
          <LogoPackage>
            <OBS />
            <XSplit />
            <Wirecast />
          </LogoPackage>
          <TextLogo>
            {'Specifically designed for '}
            <SpanOrange>OBS</SpanOrange>
            {', '}
            <SpanOrange>XSplit</SpanOrange>
            {' and '}
            <SpanOrange>Wirecast</SpanOrange>
            <p>Use your usual streaming solution along with TipAlerts</p>
          </TextLogo>
        </UsesPackage>
      </BackgroundUsesPackage>
    );
  }
}
