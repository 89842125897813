import {
  takeLatest, call, put, select,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';


import {
  taCloudfunctions,
  API_HOST,
} from 'constants';

import {
  LOGS_GET_ITEMS,
  LOGS_FIND_MORE,
  LOGS_FIND_MORE_BY_DATE,
  LOGS_TOGGLE_FETCH_AUTO,
  LOGS_AUTH,
  LOGS_USER_GET_INFO,
  LOGS_USER_IMPERSONATE,
  // LOGS_ACTIVATE_PREMIUM,
  LOGS_ACTIVATE_TRIAL,
  LOGS_GET_SERVER_START_TIME,
  LOGS_RESTART_SERVER,
  LOGS_GET_ACTIVE_USERS,
  LOGS_KILL_PROCESS,
  LOGS_TEST_ALERT,
  logsAddItems,
  logsAuthPutToken,
  logsUserGetInfoSuccess,
  logsInputUid,
  logsGetServerStartTimeSuccess,
  logsGetActiveUsersSuccess,
  logsToggleFetchAuto,
  logsInputEmail,
  logsGetItems,
} from 'store/reducers/logs';
import { persistor } from 'store';
import { authLogs, firebaseSignWithToken } from 'utils/firebase';

// const s = URLSearchParams ? new URLSearchParams(window.location.search) : undefined;
// const port = s ? s.get('port') || 4333 : 4333;
const baseURL = `${API_HOST}/`;

const fetchJSON = (...args) => fetch(...args).then(r => r.json());
const makeQuery = data => Object.keys(data)
  .filter(i => typeof data[i] !== 'undefined')
  .map(i => `${i}=${data[i]}`)
  .join('&');

const getURL = (path, data = {}) => {
  let URL = baseURL + path;
  const query = makeQuery(data);
  if (query) {
    URL = `${URL}?${query}`;
  }
  return URL;
};

function* authRequest(path, options = {}) {
  const token = yield call(authLogs);
  const combinedOptions = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: token,
    },
  };
  return yield call(fetchJSON, path, combinedOptions);
}

function* getItems({ tags, services }) {
  try {
    const data = yield select(({ logs }) => ({ uid: logs.uid }));
    if (!data.uid) {
      yield put(logsAddItems([]));
      return;
    }
    if (tags && tags.length) data.tags = tags || [];
    if (services && services.length) data.services = services || [];
    const items = yield call(authRequest, getURL('logs', data));
    yield put(logsAddItems(items));
  } catch (e) {
    alert('Error!');
  }
}
/* eslint-disable */
const latestPlaces = {
  true: 'start',
  false: 'end',
};
function* findMore({ latest, tags, services }) {
  try {
    const logItems = yield select(({ logs }) => logs.items);
    const data = yield select(({ logs }) => ({ uid: logs.uid }));
    if (!data.uid) return;
    if (latest) {
      const firstLogItem = logItems[0] || {};
      data.dateAfter = firstLogItem.date;
    } else {
      const lastLogItem = logItems[logItems.length - 1] || {};
      data.dateBefore = lastLogItem.date;
    }
    if (tags && tags.length) data.tags = tags || [];
    if (services && services.length) data.services = services || [];
    const items = yield call(authRequest, getURL('logs', data));
    let storedItems = [];
    const storeLast10 = logItems.slice(0, 10);
    const maxCount = Math.min(items.length, 10);
    for (let i = 0; i < maxCount; i += 1) {
      const item = items[i];
      if (!storeLast10.find(
        ({ date, service, message }) => (
          `${date}+${service}+${message}` === `${item.date}+${item.service}+${item.message}`
        ))) {
        storedItems.push(items[i]);
      }
    }
    storedItems = storedItems.concat(items.slice(maxCount));
    yield put(logsAddItems(storedItems, latestPlaces[latest]));
  } catch (e) {
    const { fetchAuto } = yield select(({ logs }) => logs);
    if (fetchAuto) {
      yield put(logsToggleFetchAuto());
    }
    alert(`Error! ${e}`);
  }
}

/* поиск по дате */
function* findMoreByDate({ dateBefore, dateAfter }) {
  try {
    const data = yield select(({ logs }) => ({ uid: logs.uid }));
    if (!data.uid) return;

    data.dateAfter = dateAfter;
    data.dateBefore = dateBefore;

    const items = yield call(authRequest, getURL('logs', data));
    yield put(logsAddItems(items));
  } catch (e) {
    const { fetchAuto } = yield select(({ logs }) => logs);
    if (fetchAuto) {
      yield put(logsToggleFetchAuto());
    }
    alert(`Error! ${e}`);
  }
}

function* toggleFetchAuto() {
  const { fetchAuto } = yield select(({ logs }) => logs);
  if (fetchAuto) {
    yield delay(10000);
    yield findMore({ latest: true });
    yield toggleFetchAuto();
  }
}

function* auth() {
  // TODO: when user refresh page auth not completed, need wait.
  // Why? I DONT KNOW :( Fix this damn!!!
  yield delay(1000);
  const token = yield call(authLogs);
  yield put(logsAuthPutToken(token));
}

function* userGetInfo({ uid, service = 'email' }) {
  try {
    let emailSearch = uid;
    if (!emailSearch) {
      const { email } = yield select(({ logs }) => logs);
      emailSearch = email;
    }
    if (!emailSearch) return alert('Input email');
    const userInfo = yield call(authRequest, getURL('user', { email: encodeURIComponent(emailSearch), type: service }));
    if (userInfo) {
      if (userInfo.user) {
        if (service === 'email') {
          yield put(logsInputEmail(userInfo.user.email));
        }
        yield put(logsInputUid(userInfo.user.uid));
      }
      yield delay(200);
      yield put(logsGetItems());
    }
    return yield put(logsUserGetInfoSuccess(userInfo));
  } catch (e) {
    return alert(`Error! ${e}`);
  }
}

const confirmMessage = (...args) => (
  new Promise((resolve, reject) => {
    // eslint-disable-next-line
    if (confirm(...args)) {
      resolve();
    } else {
      reject();
    }
  })
);

function* userImpersonate() {
  try {
    yield call(confirmMessage, 'You really want impersonate?\nAfter this action you would need to logout and login again by your account.\nDONT MODIFY ANY USER INFORMATION WITHOUT CONFIRMATION FROM USER!');
    // eslint-disable-next-line
    const { token } = yield select(({ logs }) => logs.userInfo);
    yield call(persistor.purge);
    localStorage.clear();
    yield firebaseSignWithToken(token);
    yield delay(2000);
    // eslint-disable-next-line
    location.pathname = '/settings';
  } catch (e) {
    //
  }
}

// function* activatePremium() {
//   try {
//     const { email } = yield select(({ logs }) => logs);
//     if (!email) return;
//     yield call(authRequest, getURL('premium', { email }));
//     alert('Success!');
//   } catch (e) {
//     alert('Error!');
//   }
// }

function* activateTrial() {
  try {
    const { email } = yield select(({ logs }) => logs);
    if (!email) return;
    const res = yield call(fetchJSON, `${taCloudfunctions}/activateTrial?email=${email}`);
    if (res.result === 'error') throw new Error(res.error);
    alert('Success!');
  } catch (e) {
    alert(`Error: ${e.message}`);
  }
}

function* getSererStartTime() {
  try {
    const { time } = yield call(authRequest, getURL('start-time'));
    yield put(logsGetServerStartTimeSuccess(time));
  } catch (e) {
    console.log(e);
    alert('Error!');
  }
}

function* restartServer() {
  try {
    yield call(confirmMessage, 'You really want to restart server?');
  } catch (e) {
    return;
  }
  try {
    yield call(authRequest, getURL('restart'));
  } catch (e) {
    alert('Error!');
  }
}

function* getActiveUsers() {
  try {
    const users = yield call(authRequest, getURL('active'));
    yield put(logsGetActiveUsersSuccess(users));
  } catch (e) {
    // alert('Error!');
  }
}

function* killProcess() {
  try {
    yield call(confirmMessage, 'You really want to kill this process?');
  } catch (e) {
    return;
  }
  try {
    const { chaturbateId } = yield select(({ logs }) => logs);
    yield call(authRequest, getURL('kill', { chaturbateId }));
  } catch (e) {
    alert('Error!');
  }
}

function* sendTestAlertLog({ streamAccessToken, amount }) {
  yield call(fetch, `${API_HOST}/test?token=${streamAccessToken}&amount=${amount}`);
}

export function* logs() {
  yield takeLatest(LOGS_GET_ITEMS, getItems);
  yield takeLatest(LOGS_FIND_MORE, findMore);
  yield takeLatest(LOGS_FIND_MORE_BY_DATE, findMoreByDate);
  yield takeLatest(LOGS_TOGGLE_FETCH_AUTO, toggleFetchAuto);
  yield takeLatest(LOGS_AUTH, auth);
  yield takeLatest(LOGS_USER_GET_INFO, userGetInfo);
  yield takeLatest(LOGS_USER_IMPERSONATE, userImpersonate);
  // yield takeLatest(LOGS_ACTIVATE_PREMIUM, activatePremium);
  yield takeLatest(LOGS_ACTIVATE_TRIAL, activateTrial);
  yield takeLatest(LOGS_GET_SERVER_START_TIME, getSererStartTime);
  yield takeLatest(LOGS_RESTART_SERVER, restartServer);
  yield takeLatest(LOGS_GET_ACTIVE_USERS, getActiveUsers);
  yield takeLatest(LOGS_KILL_PROCESS, killProcess);
  yield takeLatest(LOGS_TEST_ALERT, sendTestAlertLog);
}
