import React from 'react';
import { Link } from 'react-router-dom';

import { Block, BlockTypes } from 'components/UI';

export const PremiumOnly = ({ widgetTitle }) => (
  <Block type={BlockTypes.info}>
    {widgetTitle}
    {' '}
    available only for premium users.
    <div>
      Go to
      {' '}
      <Link style={{ color: '#0062ff' }} to="/settings">
        Settings
      </Link>
      {' '}
      to upgrade your account.
    </div>
  </Block>
);
