import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Landing, Auth } from 'scenes';
import { setLanguage } from 'store/reducers/lang';
import {
  authSignOut,
  authDonationModalHide,
  authGetPaymentProvider,
  authUserGoToNewVersion,
} from 'store/reducers/auth';
import { Navbar } from '../index';


import './Container.css';

const pagesWithoutRequiredAuth = ['donations', 'contacts', 'refund', 'terms', 'auth', 'sorry-but-it-actually-important', 'signup'];


export const AuthContainer = withRouter(connect(
  store => ({
    supporter: store.auth.supporter,
    user: store.auth.user,
    hideDonationModal: store.auth.hideDonationModal,
    lang: store.lang.lang,
    t: store.lang.t,
  }),
  dispatch => bindActionCreators({
    authSignOut,
    authDonationModalHide,
    setLanguage,
    authGetPaymentProvider,
    authUserGoToNewVersion,
  }, dispatch),
)(class extends React.Component {
  componentDidMount() {
    const { authGetPaymentProvider, location: { pathname } } = this.props;
    if (pathname.search(/\/w\//gi) === -1) {
      authGetPaymentProvider();
    }
  }

  render() {
    const {
      children,
      user,
      supporter,
      authSignOut,
      authUserGoToNewVersion,
      location: { pathname },
      lang,
      setLanguage,
    } = this.props;
    let authNotRequired = false;

    // detect if user open widget render url (in OBS e.g.)
    // just return render
    if (pathname.search(/\/w\//gi) !== -1) {
      return children;
    }

    for (let i = 0; i < pagesWithoutRequiredAuth.length; i += 1) {
      const regExp = new RegExp(`/${pagesWithoutRequiredAuth[i]}`, 'gi');
      if (pathname.search(regExp) !== -1) {
        authNotRequired = true;
        break;
      }
    }

    const landing = pathname === '/new' ? <Auth /> : <Landing />;
    const pageContent = user || authNotRequired ? children : landing;

    return (
      <div className="Container">
        <Navbar
          authUserGoToNewVersion={authUserGoToNewVersion}
          authSignOut={authSignOut}
          user={user}
          supporter={supporter}
          lang={lang}
          setLanguage={setLanguage}
          pathname={pathname}
        />
        <div className="Container_Content">{pageContent}</div>
      </div>
    );
  }
}));
