// @flow

import React from 'react';
import styled from 'styled-components';

import { galleryImages, storageImagePath } from 'utils';

const Image = styled.div`
  width: 50px;
  height: 50px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 5px;
`;

type Props = {
  source: number | string,
};

export const UserImage = ({ source, style, ...otherProps }: Props) => (
  <Image
    {...otherProps}
    style={{
      backgroundImage: `url(${typeof source === 'number' ? galleryImages[source] : storageImagePath(source)})`,
      ...style,
    }}
  />
);
