import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border-radius: 4px;
  background-color: #2E3F4C;
  height: 36px;
  border: none;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  width: 140px;

  display: flex;
  align-items: center;

  margin-right: 6px;

  border: 1px solid transparent;
  outline: none;

  &:hover {
    border-color: ${({ theme }) => theme.orangeButtonHover};
  }
  &:focus-within {
    outline: none;
    border-color: ${({ theme }) => theme.orangeLanding};
  }
  &:active  {
    border-color:  ${({ theme }) => theme.orangeButtonActive};
  }
`;

const Input = styled.input`
  background-color: #2E3F4C;
  height: 36px;
  width: 100%;

  color: white;
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button { 
    -webkit-appearance: none;
  }
  padding: 0px 6px;

  border: none;
  outline: none;
  
`;

const Label = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin: 0px 0px 0px 8px;
  color:  ${({ theme }) => theme.white54};
`;

export class InputWithText extends React.Component {
  static defaultProps = {
    style: {},
  };

  render() {
    const {
      value,
      style,
      onChange,
      width,
      textLabel,
      onBlur,
      onFocus,
    } = this.props;
    return (
      <Container style={style}>
        <Label>{textLabel}</Label>
        <Input
          ref={this.input}
          type="text"
          onChange={onChange}
          value={value}
          width={width}
          onkeypress="if(this.value.length&gt;6) return false;"
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </Container>
    );
  }
}
