import React, { Component } from 'react';

import { UsesPackageContent } from './UsesPackage';
import { HeaderContent } from './Header';
import { WebcamServices } from './WebcamServices';
import { AlertsContainer } from './AlertsContent';
import { GoalsContainer } from './GoalsContent';
import { KeyFeaturesContainer } from './KeyFeatures';
import { InstallContainer } from './Install';
import { PriceContainer } from './Price';
import { SupportContainer } from './Support';
import { FooterContainer } from './Footer';

import { Frame } from './style';

export class Landing extends Component {
  render() {
    document.title = 'TipAlerts';
    return (
      <Frame>
        <HeaderContent />
        <WebcamServices />
        <UsesPackageContent />
        <AlertsContainer />
        <GoalsContainer />
        <KeyFeaturesContainer />
        <InstallContainer />
        <PriceContainer />
        <SupportContainer />
        <FooterContainer />
      </Frame>
    );
  }
}
