import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const Content = styled.div`
  width: 100%;
  background: #7DA8BF;
  border-radius: 6px;

  display: flex;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  margin: 4px 0px;

  transition: height 0.375s ease-in-out;
  height: ${({ disabledRule }) => (disabledRule ? '60px' : '294px')};
`;

export const Text = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #061822;

  margin-bottom: ${({ marginBottom }) => marginBottom && '6px'};
  >span{
    font-style: italic;
  }
`;

export const TextContainer = styled.div`
  padding: 52px 0px 24px 24px;
  width: 668px;
  position: absolute;
  left: 0;
  transition: opacity 0.375s ease-in-out;
  opacity: ${({ disabledRule }) => (disabledRule ? 0 : 1)};
`;

export const ImageContainer = styled.div`
`;

export const AboutGameImage = styled.div`
  width: 470px;
  height: 294px;

  background-image: url('/images/about_game_img_2x.png');
  background-repeat: no-repeat;

  background-size: cover;

  transition: opacity 0.375s ease-in-out;
  opacity: ${({ disabledRule }) => (disabledRule ? 0 : 1)};
`;

export const ButtonTitle = styled.button`
  position: absolute;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;


  padding: 12px 8px 12px 24px;
  background-color: transparent;
  border: none;
  outline: none;

  z-index: 3;
`;

export const Title = styled.div`
  color: #061822;
  ${({ theme }) => theme.font.header};
`;

export const ContainerImageArrowDown = styled.div`
  width: 36px;
  height: 36px;
`;

export const ImageArrowDown = styled.div`
  transition: transform 0.375s;
  transform: ${({ disabledRule }) => (disabledRule ? '' : 'rotate(-90deg)')};

  width: 100%;
  height: 100%;

  mask-image: url('/images/icons/ic-arrow-down.svg');
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: #061822;
`;

export const ImageSnow = styled.div`
  position: absolute;
  top: -8px;
  left: -4px;
  z-index: 4;
  width: 1148px;
  height: 16px;
  background-image: url('/images/snow@2x.png');
  background-repeat: no-repeat;
  background-size: cover;
`;
