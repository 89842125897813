import styled from 'styled-components';

export const InputArea = styled.textarea`
  height: 72px;
  border-radius: 4px;
  background-color: #263845;
  border: solid 1px ${({ theme }) => theme.darkGreyBlue};
  resize: none;
  display: flex;
  width: 100%;
  margin-top: 5px;
  &:focus {
    outline: none;
    border: 1px solid #FFB65A;
  }

  padding: 6px 12px;


  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 6px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background: ${({ theme }) => theme.white05};

    padding: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.white17};
    border-radius: 3px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.white54};
  }
`;

export const Description = styled.div`
  margin-top: 6px;
`;

export const ExtraWord = styled.span`
  color: ${({ theme }) => theme.orangeLanding};
`;
