import React from 'react';

import {
  Container,
  Item,
  ItemDiscount,
} from './style';

export const SegmentedControl = ({ onSelect, activeItemId, items = [] }) => (
  <Container>
    {items.map(item => (
      <Item
        key={item.id}
        isActive={item.id === activeItemId}
        onClick={() => onSelect && onSelect(item.id)}
      >
        {item.title}
        {item.discount && (
          <ItemDiscount isActive={item.id === activeItemId}>
            (Save {item.discount}%)
          </ItemDiscount>
        )}
      </Item>
    ))}
  </Container>
);
