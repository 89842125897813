// @flow
/* eslint-disable */
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import isEqual from 'react-fast-compare';

import {
  animationDuration,
  sounds,
  storageImagePath,
  storageSoundPath,
  getColor,
  getAnimation,
  getAnimationWidgets,
  randInt,
} from 'utils';
import { TipAlertDataDefault, widgetFonts } from 'constants';
import type { TipAlertData } from 'types/Widget';
import './RenderTips.css';
import './animate.css';
import './animatedElements.css';

const verticalAlign = ['alignBottom', 'alignMiddle', 'alignTop'];

const horizontalAlign = ['alignLeft', 'alignCenter', 'alignRight'];

const breakWord = word => word && `${word}`.split().map((char, key) => <span key={key}>{char}</span>);

const getFont = fontIndex => widgetFonts[fontIndex];

const getTextDecoration = (lineThrough, underline) => {
  let str = '';
  if (lineThrough) {
    str += 'line-through';
    if (underline) {
      str += ' ';
    }
  }
  if (underline) {
    str += 'underline';
  }
  return str;
};

const calculateMessageWidthAndAlign = (messageRef, containerRef) => {
  const messageNode = findDOMNode(messageRef);
  const containerNode = findDOMNode(containerRef);
  if (messageNode && containerNode) {
    const messageWidth = messageNode.offsetWidth;
    const containerWidth = containerNode.offsetWidth;
    const halfMessageWidth = messageWidth / 2;
    const halfContainerWidth = containerWidth / 2;
    messageNode.style.left = `-${halfMessageWidth - halfContainerWidth}px`;
  }
};


type Props = {
  data: TipAlertData,
  paused: boolean,
  isPreview: boolean,
  supporter: {
    active: boolean,
  },
};

export class RenderTips extends Component<Props> {
  static defaultProps = {
    data: {},
    isPreview: false,
    supporter: {
      active: false,
    },
  };

  refSound = React.createRef();

  componentDidMount() {
    const {
      data,
      isPreview,
      supporter,
    } = this.props;
    setTimeout(() => calculateMessageWidthAndAlign(this.messageRef, this.alertContainerRef), 500);
    if (!isPreview && supporter.active && typeof data.sound !== 'undefined' && this.refSound.current) {
      this.playAudio(this.refSound.current, data.sound.volume);
    };
  }

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(nextProps, this.props);
  }

  componentDidUpdate({ data: oldData }: Props) {
    const {
      customMessage: oldCustomMessage,
      animation: oldAnimationEnabled,
      color: oldColor,
      fontFamily: oldFontFamily,
      fontSize: oldFontSize,
    } = (oldData || {}).message || {};
    const {
      data,
      isPreview,
    } = this.props;
    const {
      customMessage,
      animationEnabled,
      color,
      fontFamily,
      fontSize,
    } = (data || {}).message || {};
    if (
      oldCustomMessage !== customMessage
      || animationEnabled !== oldAnimationEnabled
      || color !== oldColor
      || fontFamily !== oldFontFamily
      || fontSize !== oldFontSize
    ) {
      calculateMessageWidthAndAlign(this.messageRef, this.alertContainerRef);
    }
  }

  playAudio(ref, dataVolume) {
    const _volume = ( dataVolume !== undefined ? Number((dataVolume / 100).toFixed(2)) : 1 );
    ref.volume=_volume;
    // ref.currentTime = 0;
    // ref.play()
    /*
    const playPromise = ref.play();
    if (playPromise !== undefined) {
      playPromise.then(_ => {
        console.log('Play!', playPromise);
      })
      .catch(error => {
        console.log(error);
      });
    }
    */
  }


  replaceTipperNameAndAmount(text) {
    const { data: { tips: { from, amount } } } = this.props;
    return text.replace(/\$NICK/gi, from).replace(/\$AMOUNT/gi, amount);
  }

  soundSrc = (data) => {
    const {
      isPreview,
      supporter,
    } = this.props;
    const sound = data.sound;
    const noSound = !isPreview && supporter.active && typeof sound !== 'undefined';
    if (noSound && (!data.enabled || (sound === null || sound < 0))) return '';
    if (Number.isFinite(sound)) {
      return (
      sounds[sound].path
      );
    }
    return storageSoundPath(sound);
  };

  render() {
    const {
      data: tipsData, paused, tipperName, supporter, isPreview,
    } = this.props;

    const data = {
      ...tipsData,
      amount: {
        ...TipAlertDataDefault.amount,
        ...(tipsData.amount || {}),
      },
      tipper: {
        ...TipAlertDataDefault.tipper,
        ...(tipsData.tipper || {}),
      },
      message: {
        ...TipAlertDataDefault.message,
        ...(tipsData.message || {}),
      },
      image: {
        ...TipAlertDataDefault.image,
        ...(tipsData.image || {}),
      },
      sound: {
        ...TipAlertDataDefault.sound,
        ...(tipsData.sound || {}),
      },
      preset: {
        ...TipAlertDataDefault.preset,
        ...(tipsData.preset || {}),
      },
    };


    if ((!data.preset.enabled && !isPreview) || !data.tips || !data.tips.from || !data.tips.amount) {
      return null;
    }

    const stylePresetDisabled = (!data.preset.enabled && isPreview)
    && {
      opacity: 0.54,
      filter: 'grayscale(100%)',
    };

    const shadow = shadowData => `${shadowData.textShadowX}px ${shadowData.textShadowY}px ${shadowData.textShadowBlur}px ${getColor(shadowData.textShadowColor)}`;

    const basic = supporter && supporter.basic && !(supporter.special === 'trial') && !supporter.active;
    const displayCustomMessage = (basic && isPreview) || supporter.active;
    const textCustomMessage = (basic && isPreview) ? `${data.message.customMessage} \n(Preview only)` : data.message.customMessage;

    return (
      <div
        className="RenderTips"
        style={{
          transform: `scale(${data.preset.transformScale})`,
          ...stylePresetDisabled,
        }}
      >
        <div
          className={`RenderTips_Tip${
            paused ? ' RenderTips_Tip--pause' : ''
          }${` RenderTips_Tip--animation_${getAnimationWidgets(data.preset.animation)}`}`}
          style={{
            animationDuration: `${animationDuration({ ...data.preset, amount: data.tips.amount })}s`,
            transform: data.preset.splitAlertsByOneToken ? `translate(${randInt(-100, 200)}px, ${randInt(0, 100)}px)` : undefined,
          }}
          ref={r => (r ? (this.alertContainerRef = r) : null)}
        >
          {data.image.enabled && (!data.image.onPoolRandom && data.image.image === 0) && (
          <div
            className={`RenderTips_TipImage${
              data.image.animationEnabled ? ' RenderTips_TipImage--animated' : ''
            }`}
          >
            <video className="RenderTips_NY2019" autoPlay loop muted>
              <source type="video/webm" src="https://m.tipalerts.com/images/widgets/5000_15.webm" />
            </video>
          </div>)}
          {data.image.enabled && (!data.image.onPoolRandom && data.image.image === 1) && (
          <div
            className={`RenderTips_TipImage${
              data.image.animationEnabled ? ' RenderTips_TipImage--animated' : ''
            }`}
          >
            <video className="RenderTips_NY2019" autoPlay loop muted>
              <source type="video/webm" src="https://m.tipalerts.com/images/widgets/5000_4.webm" />
            </video>
          </div>)}
          {data.image.enabled && (!data.image.onPoolRandom && data.image.image !== 0 && data.image.image !== 1) && (
          <div
            className={`RenderTips_TipImage${
              data.image.animationEnabled ? ` RenderTips_TipImage--animated_${getAnimation(data.image.animation)}` : ''
            }`}
            style={{
              animationDuration: `${data.image.animationDuration}s`,
              opacity: `${data.image.opacity}`,
              backgroundImage: `url(${
                storageImagePath(data.image.image)
              })`,
            }}
          />)}
          {data.image.enabled && data.image.onPoolRandom && (
          <div
            className={`RenderTips_TipImage${
              data.image.animationEnabled ? ` RenderTips_TipImage--animated_${getAnimation(data.image.animation)}` : ''
            }`}
            style={{
              animationDuration: `${data.image.animationDuration}s`,
              opacity: `${data.image.opacity}`,
              backgroundImage: `url(${
                storageImagePath(data.image.image, data.image.onPoolRandom, data.image.poolRandom)
              })`,
            }}
          />)}
          {data.amount.enabled && (
            <div
              className={`RenderTips_Amount
                ${verticalAlign[data.amount.verticalPosition]}
                ${horizontalAlign[data.amount.horizontalPosition]}
              `}
            >
              <div
                style={{
                  transform: `translate(${data.amount.transformHorizontally}px, ${data.amount.transformVertically}px) rotate(${data.amount.transformRotate}deg)`,
                }}
              >
                <div
                  className={`RenderTips_Background
                  ${data.amount.animationEnabled
                    ? `RenderTips_Amount--animated_${getAnimation(data.amount.animation)}` : ''
                  }`}
                  style={{
                    animationDuration: `${data.amount.animationDuration}s`,
                    color: getColor(data.amount.color),
                    fontSize: `${data.amount.fontSize}px`,
                    fontFamily: getFont(data.amount.fontFamily),
                    textShadow: data.amount.textShadowEnabled && shadow(data.amount),
                    backgroundColor: data.amount.backgroundEnabled && getColor(data.amount.backgroundColor),
                    fontWeight: data.amount.fontWeightBold ? 'bold' : data.amount.fontWeight,
                    fontStyle: data.amount.fontStyle,
                    textDecoration: getTextDecoration(data.amount.textDecorationLineThrough, data.amount.textDecorationUnderline),
                    textTransform: data.amount.textTransform,
                  }}
                >
                  {breakWord(data.tips.amount || '300')}
                </div>
              </div>
            </div>
          )}
          {data.tipper.enabled && (
            <div
              className={`RenderTips_Tipper
                ${verticalAlign[data.tipper.verticalPosition]}
                ${horizontalAlign[data.tipper.horizontalPosition]}
              `}
            >
              <div
                style={{
                  transform: `translate(${data.tipper.transformHorizontally}px, ${data.tipper.transformVertically}px) rotate(${data.tipper.transformRotate}deg)`,
                }}
              >
                <div
                  className={`RenderTips_Background
                  ${data.tipper.animationEnabled
                    ? `RenderTips_Tipper--animated_${getAnimation(data.tipper.animation)}` : ''
                  }`}
                  style={{
                    animationDuration: `${data.tipper.animationDuration}s`,
                    color: getColor(data.tipper.color),
                    fontSize: `${data.tipper.fontSize}px`,
                    fontFamily: getFont(data.tipper.fontFamily),
                    textShadow: data.tipper.textShadowEnabled && shadow(data.tipper),
                    backgroundColor: data.tipper.backgroundEnabled && getColor(data.tipper.backgroundColor),
                    fontWeight: data.tipper.fontWeightBold ? 'bold' : data.tipper.fontWeight,
                    fontStyle: data.tipper.fontStyle,
                    textDecoration: getTextDecoration(data.tipper.textDecorationLineThrough, data.tipper.textDecorationUnderline),
                    textTransform: data.tipper.textTransform,
                  }}
                >
                  {breakWord(tipperName || data.tips.from)}
                </div>
              </div>
            </div>
          )}
          {(displayCustomMessage && data.message.customMessage !== '') && (
            <div
              className={`RenderTips_Message
                ${verticalAlign[data.message.verticalPosition]}
                ${horizontalAlign[data.message.horizontalPosition]}
              `}
            >
              <div
                style={{
                  transform: `translate(${data.message.transformHorizontally}px, ${data.message.transformVertically}px) rotate(${data.message.transformRotate}deg)`,
                }}
              >
                <div
                  className={`RenderTips_Background
                  ${data.message.animationEnabled
                    ? `RenderTips_Message--animated_${getAnimation(data.message.animation)}` : ''
                  }`}
                  style={{
                    whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word',
                    animationDuration: `${data.message.animationDuration}s`,
                    color: getColor(data.message.color),
                    fontSize: `${data.message.fontSize}px`,
                    fontFamily: getFont(data.message.fontFamily),
                    textShadow: data.message.textShadowEnabled && shadow(data.message),
                    backgroundColor: data.message.backgroundEnabled && getColor(data.message.backgroundColor),
                    fontWeight: data.message.fontWeightBold ? 'bold' : data.message.fontWeight,
                    fontStyle: data.message.fontStyle,
                    textDecoration: getTextDecoration(data.message.textDecorationLineThrough, data.message.textDecorationUnderline),
                    textTransform: data.message.textTransform,
                    lineHeight: `${data.message.lineHeight}%`,
                    textAlign: `${data.message.textAlign}`,
                  }}
                  ref={r => (r ? (this.messageRef = r) : null)}
                >
                  {this.replaceTipperNameAndAmount(textCustomMessage)}
                </div>
              </div>
            </div>
          )}
          {!isPreview && supporter.active && data.sound && <audio ref={this.refSound} src={this.soundSrc(data.sound)} autoPlay />}
        </div>
      </div>
    );
  }
}
