import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const button = css`
  margin: 0px 4px;

  outline: none;
  border: none;

  padding: 12px 16px;
  border-radius: 6px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Cat = styled.div`
  border-radius: 50%;
  height: 110px;
  width: 110px;


  margin-top: 126px;

  background-image: url('/images/cat.png');
`;

export const Title = styled.span`
  margin-top: 25px;
  margin-bottom: 13px;

  font-weight: bold;
  font-size: 48px;
  line-height: 95%;
`;

export const AboutTitle = styled.span`
  width: 700px;

  font-size: 18px;
  line-height: 26px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  min-height: 50px;

  margin-top: 38px;
  margin-bottom: 140px;
`;

export const ButtonRenew = styled.button`
  ${button}
  background: #FFB65A;
  color: #333333;
`;

export const ButtonPricing = styled(Link)`
  ${button}
  background: rgba(255, 182, 90, 0.07);
  color: #FFB65A;
  :hover {
    color: #FFB65A;
    border: none;
  }
  :focus {
    color: #FFB65A;
    outline: none;
    border: none;
  }
`;
