import React, { Component } from 'react';
import {
  Table, Grid, Row, Col, Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { RenderTips } from 'components';

import './Home.css';

const previewData = {
  amountVariations: {
    '20-99': {
      from: 20,
      sound: 1,
      to: 99,
    },
  },
  customMessage: 'Thank you $NICK!',
  customMessageAnimated: true,
  customMessageVerticalAlign: 0,
  hideTipper: true,
  image: '6cb6277b-c93d-4a78-8b25-caa0594e7c19',
  sound: 2,
  duration: 3,
  customMessageFont: 9,
  amount: 100,
  animation: 4,
  tipperAnimated: false,
  amountAnimated: false,
  imageAnimated: false,
};

const images = ['6cb6277b-c93d-4a78-8b25-caa0594e7c19', 1, 2, 3, 4, 5, 6, 7, 8, 9, 27, 26, 24, 21, 16, 20];
const fonts = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const arrRand = arr => arr[Math.floor(Math.random() * arr.length)];
const makeRandomImage = () => arrRand(images);
const makeRandomFont = () => arrRand(fonts);

const alertsCountStart = 839648;
const startTime = 1523727247625;
const dTime = ((new Date()).getTime() - startTime) / 1000;
const alertsCountNow = Math.floor(alertsCountStart + (dTime * 1.2));

const countAlerts = countNow => countNow + Math.floor(Math.random() * 10);

const makeRandomState = state => ({
  image: makeRandomImage(),
  customMessageFont: makeRandomFont(),
  amountFont: makeRandomFont(),
  duration: 4,
  imageAnimated: Math.random() > 0.5,
  countAlerts: countAlerts(state ? state.countAlerts : alertsCountNow),
  locale: state ? state.locale : 'EN',
  usdRate: state ? state.usdRate : 62,
});

const formatNumber = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

const descriptionRU = (
  <span>
    TipAlerts позволяет отображать уведомления о донатах поверх стрима с помощью плагина BrowserSource для OBS.
    Уведомления поддерживают гибкую настройку - в частности вы можете загрузить собственное изображение или звук.
    <br />
    Основной функционал сервиса доступен бесплатно, однако если вам необходимы дополнительные настройки
    вы можете приобрести премиум аккаунт.
    <br />
    Список отличий базового аккаунта от премиум вы можете найти в таблице ниже.
    <br />
    Цена приведена с учетом текущего курса доллара США, курс предоставлен Центральным банком РФ.
  </span>
);

class DummyHome extends Component {
  state = makeRandomState();

  componentDidMount() {
    fetch('https://www.cbr-xml-daily.ru/daily_json.js')
      .then(r => r.json())
      .then((r) => {
        this.setState({ usdRate: r.Valute.USD.Value });
      });
    fetch('https://us-central1-tipalerts-94db4.cloudfunctions.net/getIPInfo')
      .then(r => r.json())
      .then((r) => {
        if (r.countryCode === 'RU') {
          this.setState({ locale: 'RU' });
        }
      });
    this.interval = setInterval(() => {
      this.setState(makeRandomState);
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  interval = null;

  render() {
    const { locale, usdRate, countAlerts } = this.state;
    const { t } = this.props;
    return (
      <div className="Home">
        <h3>{t.getAlerts}</h3>
        <div style={{ margin: '20px 0' }}>
          <div style={{ textAlign: 'center' }}>{t.alertsSend}</div>
          <div style={{ fontSize: '24px', textAlign: 'center' }}>
            {formatNumber(countAlerts)}
          </div>
        </div>
        <Link to="/auth"><Button bsStyle="success">{t.startNow}</Button></Link>
        <Grid fluid style={{ margin: '40px 0' }}>
          <Row>
            <Col md={6}>
              <div style={{ position: 'relative', width: '250px', height: '150px' }}>
                <RenderTips
                  data={previewData}
                  tipperName="Alex"
                  supporter={{ active: true }}
                  isPreview
                  paused={false}
                />
              </div>
            </Col>
            <Col
              md={6}
              style={{
                height: '150px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              {t.displayAlerts}
            </Col>
          </Row>
          <Row style={{ marginTop: '100px' }}>
            <Col
              md={6}
              style={{
                height: '150px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              {t.layout}
            </Col>
            <Col md={6}>
              <div style={{ position: 'relative', width: '250px', height: '150px' }}>
                <RenderTips
                  data={{ ...previewData, ...this.state }}
                  tipperName="Alex"
                  supporter={{ active: true }}
                  isPreview
                  paused={false}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '100px' }}>
            <Col md={6}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <img src="/images/Open_Broadcaster_Software_Logo.png" style={{ width: '60px' }} />
                <img src="/images/XSplit-logo.png" style={{ width: '60px' }} />
                <img src="/images/wirecast-logo.png" style={{ width: '60px' }} />
              </div>
            </Col>
            <Col
              md={6}
              style={{
                height: '70px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              {t.supportedSoftware}
            </Col>
          </Row>
        </Grid>
        <h3>{t.pricing}</h3>
        <span style={{ fontSize: '12px', marginBottom: '6px' }}>
          <span style={locale === 'RU' ? { color: 'white' } : { textDecoration: 'underline', color: '#c5c5c5', cursor: 'pointer' }} onClick={() => this.setState({ locale: 'RU' })}>Russia</span>
          &nbsp;|&nbsp;
          <span style={locale === 'EN' ? { color: 'white' } : { textDecoration: 'underline', color: '#c5c5c5', cursor: 'pointer' }} onClick={() => this.setState({ locale: 'EN' })}>other countries</span>
        </span>
        <div style={{ textAlign: 'center', margin: '20px 0', width: '60%' }}>
          {locale === 'RU' ? descriptionRU : ''}
        </div>
        <Table bordered condensed style={{ width: '60%' }}>
          <thead>
            <tr>
              <td>{t.feature}</td>
              <td>{t.basic}</td>
              <td>{t.premium}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t.fontCustomizations}</td>
              <td colSpan="2">+</td>
            </tr>
            <tr>
              <td>{t.variablesAnimations}</td>
              <td colSpan="2">+</td>
            </tr>
            <tr>
              <td>{t.customImage}</td>
              <td colSpan="2">+</td>
            </tr>
            <tr>
              <td>{t.soundAlert}</td>
              <td>-</td>
              <td>+</td>
            </tr>
            <tr>
              <td>{t.customMessageText}</td>
              <td>-</td>
              <td>+</td>
            </tr>
            <tr>
              <td>{t.alertOptions}</td>
              <td>-</td>
              <td>+</td>
            </tr>
            <tr>
              <td>{t.prioritySupport}</td>
              <td>-</td>
              <td>+</td>
            </tr>
            <tr>
              <td>{t.setup}</td>
              <td>-</td>
              <td>+</td>
            </tr>
            <tr>
              <td>{t.price}</td>
              <td>{t.free}</td>
              <td>
                {locale === 'EN' ? '$5' : `${Math.round(5 * usdRate)} руб.`}
                /
                {t.month}
              </td>
            </tr>
          </tbody>
        </Table>
        <Link to="/auth"><Button bsStyle="success">Start now!</Button></Link>
        <div style={{ width: '40%', textAlign: 'center', marginTop: '20px' }}>
          {t.paymentsInfo}
        </div>
        <div style={{ marginTop: '10px' }}>
          <img src="/images/visa_ms_mir.svg" />
        </div>
      </div>
    );
  }
}

export const Home = connect(({ lang: { t } }) => ({ t }))(DummyHome);
