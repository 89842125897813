import React from 'react';
import { connect } from 'react-redux';
// import { Link, Redirect } from 'react-router-dom';

import {
  Container,
} from './style';

import { Header } from './Header';
import { PaymentsInfo } from './PaymentsInfo';
import { SubscriptionsComparison } from './SubscriptionsComparison';
import { FAQ } from './FAQ';
import { HelpCenter } from './HelpCenter';
import { Footer } from './Footer';
import { SubPatreon } from './SubPatreon';

export const Price = connect(state => ({
  provider: state.auth.provider,
}))(
  class extends React.Component {
    render() {
      const { provider } = this.props;
      document.title = 'Pricing — TipAlerts';
      return (
        <Container>
          <Header />
          {provider === 'mandarin' && <PaymentsInfo color="#1B2D3A" />}
          {provider === 'patreon' && <SubPatreon />}
          <SubscriptionsComparison />
          {false && <FAQ />}
          <HelpCenter />
          <Footer />
        </Container>
      );
    }
  },
);
