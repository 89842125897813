/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getActionWidgetsComponentsUpdate } from 'store/reducers/widgets';

import {
  TextAppearance,
  AnimationBlock,
  BarAppearance,
  TransformGoalText,
} from 'components/UIkit';

import {
  Section,
  FormItem,
  CheckBox,
} from 'components/UI';
import { widgets } from 'constants';

const Col3 = styled.div`
  flex: 3;
  margin-right: ${({ marginRight }) => marginRight}px;
`;

const Row = styled.div`
  display: flex;
  align-items: ${({ baseline }) => (baseline ? 'baseline' : 'center')};
`;

class ProgressBarTab extends React.Component {
  onTextAppearanceChange = ({ backgroundEnabled, backgroundColor, ...rest }) => {
    const { widgetsComponentsUpdate } = this.props;
    widgetsComponentsUpdate({ textBackgroundEnabled: backgroundEnabled, textBackgroundColor: backgroundColor, ...rest });
  };

  onTransformGoalTextChange = ({ transformRotate }) => {
    const { widgetsComponentsUpdate } = this.props;
    widgetsComponentsUpdate({ textTransformRotate: transformRotate });
  };

  render() {
    const {
      data,
      widgetsComponentsUpdate,
    } = this.props;
    const textAppearanceData = {
      ...data,
      backgroundEnabled: data.textBackgroundEnabled,
      backgroundColor: data.textBackgroundColor,
    };
    const transformGoalTextData = {
      transformRotate: data.textTransformRotate,
    };
    return (
      <div>
        <Section>
          <FormItem label="Display" themeFont="header" header />
          <Row>
            <Col3>
              <CheckBox
                checked={!data.hideFill}
                onChange={() => widgetsComponentsUpdate({ hideFill: !data.hideFill })}
                themeFont="headerCheckbox"
              >
                Progress fill
              </CheckBox>
            </Col3>
            <Col3>
              <CheckBox
                checked={!data.hideValueTokens}
                onChange={() => widgetsComponentsUpdate({ hideValueTokens: !data.hideValueTokens })}
                themeFont="headerCheckbox"
              >
                Goal value
              </CheckBox>
            </Col3>
            <Col3>
              <CheckBox
                checked={!data.hideReceivedToken}
                onChange={() => widgetsComponentsUpdate({ hideReceivedToken: !data.hideReceivedToken })}
                themeFont="headerCheckbox"
              >
                Received
              </CheckBox>
            </Col3>
            <Col3>
              <CheckBox
                checked={!data.hideCurrentPercent}
                onChange={() => widgetsComponentsUpdate({ hideCurrentPercent: !data.hideCurrentPercent })}
                themeFont="headerCheckbox"
              >
                Progress %
              </CheckBox>
            </Col3>
          </Row>
        </Section>

        <TextAppearance
          data={textAppearanceData}
          widgetUpdate={this.onTextAppearanceChange}
          mainLabel="Progress bar"
          borderTop
        />

        <BarAppearance
          data={data}
          widgetUpdate={widgetsComponentsUpdate}
          borderTop
        />

        <TransformGoalText
          data={transformGoalTextData}
          widgetUpdate={this.onTransformGoalTextChange}
          borderTop
        />
      </div>
    );
  };
}

ProgressBarTab.title = 'Progress bar';

export default connect(
  null,
  (dispatch, ownProps) =>
    bindActionCreators({
    widgetsComponentsUpdate: getActionWidgetsComponentsUpdate(widgets.goals.id, ownProps.selectedPreset, 'progressBar'),
  }, dispatch)
)(ProgressBarTab);
