import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { widgetsActiveSubscribe } from '../../store/reducers/widgets';

import './Stats.css';

const stateToProps = store => ({ activeWidgets: store.widgets.activeWidgets, user: store.auth.user });
const actionCreators = dispatch => bindActionCreators({ widgetsActiveSubscribe }, dispatch);

export const Stats = connect(stateToProps, actionCreators)(class extends React.Component {
  state = { time: new Date() };

  updateInterval = null;

  componentDidMount() {
    const { user, widgetsActiveSubscribe } = this.props;
    if (user.level === 1) {
      widgetsActiveSubscribe();
      this.updateInterval = setInterval(() => this.setState({ time: new Date() }), 5000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval);
  }

  renderActiveWidgetsItem = (itemKey) => {
    const { activeWidgets } = this.props;
    const item = activeWidgets[itemKey];
    const lastActiveDate = new Date(item.lastActivity.date).getTime();
    const isStreamerActive = lastActiveDate + 60000 > (new Date()).getTime();
    return (
      <tr key={itemKey} style={isStreamerActive ? { backgroundColor: 'green' } : null}>
        <td>{itemKey}</td>
        <td>
          <a href={`https://chaturbate.com/${item.chaturbateId}`} target="_blank" rel="noopener noreferrer">{item.chaturbateId}</a>
        </td>
        <td>{item.lastActivity.date}</td>
      </tr>
    );
  };

  render() {
    const { activeWidgets, user } = this.props;
    if (user.level !== 1) {
      return (
        <div>Not found</div>
      );
    }
    const activeWidgetsKeys = activeWidgets ? Object.keys(activeWidgets) : null;
    return (
      <table className="Stats__Table">
        <thead>
          <tr>
            <td>UserID</td>
            <td>ChaturbateID</td>
            <td>Last activity</td>
          </tr>
        </thead>
        <tbody>
          {
            activeWidgetsKeys && activeWidgetsKeys.map(this.renderActiveWidgetsItem)
          }
        </tbody>
      </table>
    );
  }
});
