import React, { PureComponent } from 'react';

import { TestAlert } from 'components';

import { ContainerTestAlerts } from './UI';

export class SendTestAlert extends PureComponent {
  render() {
    return (
      <ContainerTestAlerts>
        <TestAlert onSend={this.props.sendLogsTestAlert} />
      </ContainerTestAlerts>
    );
  }
}
