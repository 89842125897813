import React, { Component } from 'react';

import './Donations.css';

export class Donations extends Component {
  render() {
    return (
      <div>
        <div className="DonateBlock">
          <a href="https://www.patreon.com/bePatron?c=1515250">
            <img src="https://c5.patreon.com/external/logo/become_a_patron_button.png" />
          </a>
        </div>
        <div className="DonateBlock">
          <div>
            <img
              src="images/1024px-Bitcoin_logo.svg.png"
              style={{
                width: '169px',
                backgroundColor: 'white',
                padding: '10px',
              }}
            />
            {' '}
            14oXHK1F6JQh3RuvWNfuHEo3Dbc3T6gKrk
          </div>
          <div>
            <img
              src="images/ethereum-logo.png"
              style={{
                width: '144px',
                backgroundColor: '#32434e',
                padding: '10px',
              }}
            />
            {' '}
            0xe0f03D949AfC3369E18d9278EF551097c138d504
          </div>
          <div>
            <img
              src="images/Ripple-Logo.png"
              style={{
                width: '188px',
              }}
            />
            {' '}
            ravEJAGcWiKsLgpyHpL2t8dgNKg9mh3Xo4
          </div>
        </div>
        <div className="Donations">
          <div className="Donations__LetterBody">
            <h1>Hello.</h1>
            <p>
              My name is Vladimir Kudryavcev. I am the founder and CEO of the TipAlerts. I am going
              to present some future plans of the service development. Thank you for taking the time
              to read this letter further. I will try and make it less time consuming.
            </p>
            <p>
              Active users of TipAlerts will probably be happy to know that we are going to create
              an amazing tool for live streaming together, despite the fact that it has been a long
              time since we published big updates.
            </p>
            <p>
              I am happy to note that a lot of streamers have become TipAlerts users for the last 6
              months. Such a popularity comes as surprise because initially I created this tool for
              my friends. Growing audience leads to financial difficulties because of the costs on
              the hardware maintenance. TipAlerts is not adding third-party advertising and survives
              only through the contribution of the developers team. Unfortunately I need to admit
              that it is only enough to keep service working but does not afford us to implement new
              features and continue development of the project. That is the reason why I had to
              pause the work on the TipAlerts and write this letter.
            </p>
            <p>
              Of course I received a lot of offers from the potential investors, who assume complete
              legalization of the service and acquiring of the controlling interest in TipAlerts as
              the result. In my opinion it would not allow us to develop the project in a way we
              plan. TipAlerts development would be driven by business motives, which mean an
              aggressive pricing policy and new features agreement according not to the interests of
              the streamers community but to the marketing rules for future income. In my view this
              is a wrong development model and I do not want to contribute to it.
            </p>
            <p>
              I am deeply convinced that TipAlerts users are those who should determine its way of
              development. I will ask you if you agree to support the project as much as you can. I
              wish that all user’s support will be as open and clear as possible. That is the reason
              why we have decided to postpone the paid features implementation at this stage.
            </p>
            <p>
              Patreon is one of my favourite platforms, as it allows to help directly those services
              or people who do something really cool and important. That is the reason why I have
              chosen this exact platform.
              <br />
              Our Patreon page:
              {' '}
              <a href="https://patreon.com/tipalerts">https://patreon.com/tipalerts</a>
            </p>
            <p>
              The second open way of support is cryptocurrency, in my opinion this is one of the
              best payment methods appeared in the last ten years. If this method suits you best we
              will highly appreciate any help provided this way.
            </p>
            <p>
              Our wallets:
              <br />
              Bitcoin: 14oXHK1F6JQh3RuvWNfuHEo3Dbc3T6gKrk
              <br />
              Ethereum: 0xe0f03D949AfC3369E18d9278EF551097c138d504
              <br />
              Ripple: ravEJAGcWiKsLgpyHpL2t8dgNKg9mh3Xo4
              <br />
            </p>
            <p>
              TipAlerts needs very small sum of money like a price of cup of coffee. If everyone who
              reads this letter will be able to help, our team can keep the service live and develop
              it and only our users will decide which functions should be released first.
            </p>
            <p>
              I guarantee, in turn, the maximum transparency of financial support and reporting on
              all money raised. Moreover, every person who provides assistance will get an
              appropriate reward within the service, prompt support and opportunity to influence
              decisions concerning the future project development.
            </p>
            <p>
              There are plans to compose soon the list of additions and improvements which should be
              developed first. We can make it all come true together with your support.
              <br />
              You can also email me using this address -
              {' '}
              <a href="mailto:ceo@tipalerts.com">ceo@tipalerts.com</a>
              . I will be happy to answer any questions.
            </p>
            <p>Thank you for your time.</p>
          </div>
        </div>
      </div>
    );
  }
}
