import React from 'react';
import styled from 'styled-components';

import {
  Section,
  FormItem,
  NewSlider,
} from 'components/UI';

import {
  RowSlider,
  Image,
} from './style';

const Container = styled.div``;

export default class TransformBlock extends React.Component {
  render() {
    const {
      data,
      widgetUpdate,
    } = this.props;

    return (
      <Container>
        <Section borderTop>
          <FormItem label="Transform" themeFont="header" header />
          <RowSlider>
            <Image icon="shift-horizontal" />
            <NewSlider
              value={data.transformHorizontally}
              min={-200}
              max={200}
              step={1}
              onChange={size => widgetUpdate({ transformHorizontally: Number(size) })}
              centerPoint
              magnitPoint={5}
            />
          </RowSlider>
          <RowSlider>
            <Image icon="shift-vertical" />
            <NewSlider
              value={data.transformVertically}
              min={-200}
              max={200}
              step={1}
              onChange={size => widgetUpdate({ transformVertically: Number(size) })}
              centerPoint
              magnitPoint={5}
            />
          </RowSlider>
          <RowSlider>
            <Image icon="rotate" />
            <NewSlider
              value={data.transformRotate}
              min={-180}
              max={180}
              step={1}
              onChange={angle => widgetUpdate({ transformRotate: Number(angle) })}
              centerPoint
              magnitPoint={5}
            />
          </RowSlider>
        </Section>
      </Container>
    );
  }
}
