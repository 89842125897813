import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import { Button, Block } from 'components/UI';

import {
  authSetFieldValue, authSignUp, authSignIn, authResetPassword,
} from '../../store/reducers/auth';
import * as views from './index';

import './Auth.css';


const renderAuthError = errorText => (
  <Block type="warning">
    <h5>Error</h5>
    {errorText}
  </Block>
);

export const Auth = connect(store => ({
  auth: store.auth,
  t: store.lang.t,
}), dispatch => bindActionCreators({
  authSetFieldValue,
  authSignUp,
  authSignIn,
  authResetPassword,
}, dispatch))(class extends React.Component {
  constructor(props) {
    super(props);

    this.state = { view: 'SignIn' };
  }

  changeView = view => () => this.setState({ view });

  render() {
    const {
      auth,
      authSetFieldValue,
      authSignUp,
      authSignIn,
      authResetPassword,
      t,
    } = this.props;
    const { view } = this.state;
    const ViewComponent = views[view];
    document.title = 'Authorization - TipAlerts';
    return (
      <div className="Auth">
        {auth.user && <Redirect to="/" />}
        <div className="pt-callout pt-intent-warning pt-icon-info-sign Auth__Item">
          <h5>{t.authRequired}</h5>
          { `${t.toContinueYouNeed} ${view === 'SignIn' ? t.signIn : t.signUp} ${t.or} ` }
          <Button
            onClick={this.changeView(view === 'SignIn' ? 'SignUp' : 'SignIn')}
          >
            { `${view === 'SignIn' ? t.signUp : t.signIn}` }
          </Button>
        </div>
        {auth.authError ? renderAuthError(auth.authError) : null}
        <ViewComponent
          auth={auth}
          authSetFieldValue={authSetFieldValue}
          authSignUp={authSignUp}
          authSignIn={authSignIn}
          authResetPassword={authResetPassword}
          isIntegrated
          t={t}
        />
        {view !== 'ResetPassword' && (
          <div>
            <Button onClick={this.changeView('ResetPassword')}>{t.resetPassword}</Button>
          </div>)
        }
      </div>
    );
  }
});
