import React from 'react';
import {
  Form, FormGroup, ControlLabel, FormControl, Button,
} from 'react-bootstrap';

import './ResetPassword.css';

export const ResetPassword = ({
  auth,
  authResetPassword,
  authSetFieldValue,
  t,
}) => (
  <div className="ResetPassword">
    {auth.resetPasswordSuccess
      ? <div>{t.restPasswordSend}</div>
      : (
        <Form inline>
          <FormGroup controlId="email">
            <ControlLabel>{t.eMail}</ControlLabel>
            {' '}
            <FormControl
              type="email"
              placeholder="jane.doe@example.com"
              onChange={({ target: { value } }) => authSetFieldValue('email', value)}
              value={auth.fields.email}
            />
          </FormGroup>
          {' '}
          <Button onClick={authResetPassword}>
            {t.resetPassword}
          </Button>
        </Form>)
    }

  </div>
);
