/* eslint-disable */
import React, { Component } from 'react';
import styled, { css } from 'styled-components';


const Container = styled.div`
  position: relative;
  margin-right: ${({ marginRight}) => marginRight};
  display: flex;
  height: 36px;
  width: 36px;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;

  border-radius: 4px;
  outline: none;
  border: none;

  /*
  background-color: ${({ active }) => active ? 'hsla(206, 29%, 41%, 1)' : 'hsla(206, 29%, 36%, 1)'};
  &:hover {
    background-color: hsla(206, 29%, 46%, 1);
  }

  &:active {
    background-color: hsla(206, 29%, 41%, 1);
  }
  */
`;


const Image = styled.div`
  width: 24px;
  height: 24px;

  mask-image:  url('/images/icons/ic-${({ icon }) => icon}.svg');
  mask-repeat: no-repeat;
  mask-position: center;

  background-color: ${({ active, theme }) => active ? theme.white54 : theme.white};

  &:hover {
    background-color: ${({ theme }) =>theme.white40 };
  }

  &:active {
    background-color: ${({ theme }) =>theme.white50 };
  }
`;

export default class ButtonIcon extends Component {
  render() {
    const {
      icon,
      onClick,
      children,
      active,
      marginRight,
    } = this.props;
    /*
    Button.addEventListener('click', function (event) {
      alert('События вызвано функцией!');
    }); */

    return (
      <Container marginRight={marginRight}>
        <Button
          icon={icon}
          onClick={onClick}
          active={active}
        >
          <Image icon={icon} active={active}/>
        </Button>
        {children}
      </Container>
    );
  }
};
