import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { theme } from 'constants/theme';
// import Constants from '../const/const';
import {
  Gradient,
  fadeOutUp,
  fadeOutUp2,
  fadeOutUp3,
  pulse,
  rubberBand,
  swing,
  pulseSimple,
} from './animation';

export const BackgroundHeader = styled.div`
  background: radial-gradient(
      358.63px at 50% 100%,
      rgba(131, 154, 213, 0.45) 0%,
      rgba(31, 43, 54, 0) 100%
    ),
    rgba(50, 65, 76, 0.2);
  display: flex;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
`;

export const TextColumn = styled.div`
  text-align: center;
  width: 754px;
`;

export const TextHeader = styled.div`
  font-weight: 800;
  line-height: 68px;
  font-size: 56px;
  margin-top: 48px;
  > p {
    margin-top: 12px;
    font-weight: 500;
    line-height: 36px;
    font-size: 24px;
    font-style: normal;
    color: rgba(255, 255, 255, 0.7);
  }
`;

export const SpanOrange = styled.span`
  color: ${theme.main.orangeLanding};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 43px;
  margin-bottom: 79px;
  justify-content: center;
`;

export const ButtonConnectFree = styled.button`
  padding: 16px 38px;

  font-weight: bold;
  font-size: 16px;
  color: #32414c;

  border-radius: 100px;
  border: none;

  background-color: ${theme.main.orangeLanding};

  &:focus {
    outline: none;
  }

  &:hover {
    outline: none;
    background-color: ${theme.main.orangeButtonHover};
  }

  &:active {
    outline: none;
    background-color: ${theme.main.orangeButtonActive};
  }
`;

export const LogoChaturbate = styled.div`
  width: 200px;
  height: 59px;
  background-image: url('/images/Chaturbate-logo.svg');

  margin-left: 20px;
  padding-top: 58px;

  text-align: center;
  line-height: 20px;
  font-size: 12px;
`;

export const RegLink = styled(Link)`
  border-bottom: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    border-bottom: none;
  }
`;

export const ImageAnimationColumn = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
`;

export const RowGoalMeter = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  z-index: 2;
`;

export const GoalContainer = styled.div`
  flex: 3;

  margin: 50px 16px;
  padding: 0px 10px;

  background: linear-gradient(to right, #c94dff 50%, #ff58b2 50.5%);
  background-size: 200% 100%;

  animation: ${Gradient} 10s ease infinite;

  transform: rotate(-5.46deg);

  border-radius: 4px;
`;

export const TextGoal = styled.div`
  padding-right: 20px;
  font-weight: 600;
  line-height: 20px;
  font-size: 16px;
  text-align: right;

  animation: ${pulseSimple} 10s linear infinite;
`;

export const MeterContainer = styled.div`
  flex: 2;

  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const TextMeter = styled.div`
  font-weight: normal;
  line-height: 24px;
  font-size: 14px;
  text-align: center;

  letter-spacing: 0.02em;
`;

export const MeterAlert = styled.div`
  padding: 10px 16px;
  margin: 0px 10px;

  background: #32414c;
  box-sizing: border-box;
  border-radius: 4px;

  font-weight: 600;
  line-height: 24px;
  font-size: 28px;
  text-align: center;
`;

export const ImageContainer = styled.div`
  z-index: 1;
  flex: 4;
  display: flex;
  align-items: flex-end;
`;

const WidgetSimple = styled.div`
  width: 150px;
  height: 150px;
  background-size: cover;
`;

export const Widget1 = styled(WidgetSimple)`
  animation: ${fadeOutUp} 4s ease infinite;
  background-image: url('/images/widgets/img20.gif');
`;

export const TextWidgets1 = styled.div`
  animation: ${rubberBand} 3s linear infinite;
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  font-size: 28px;
  text-align: center;

  color: #fff730;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  > p {
    padding-top: 110px;

    font-weight: 900;

    font-size: 32px;
    text-transform: uppercase;

    color: #30ffa8;
  }
`;

export const Widget2 = styled(WidgetSimple)`
  animation: ${fadeOutUp2} 15s ease-in-out infinite;
  background-image: url('/images/widgets/img4.gif');
`;

export const TextWidgets2 = styled.div`
  animation: ${pulse} 3s linear infinite;
  font-family: Lobster;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 32px;
  text-align: center;

  color: #ff58b2;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  > p {
    padding-top: 110px;
    color: #ffb65a;
  }
`;

export const Widget3 = styled(WidgetSimple)`
  animation: ${fadeOutUp} 10s ease infinite;
  width: 200px;
  background-image: url('/images/widgets/img3.gif');
`;

export const TextWidgets3 = styled.div`
  animation: ${swing} 5s linear infinite;
  font-family: Pacifico;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 48px;
  text-align: center;

  color: #ff5ab3;

  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  > p {
    padding-top: 100px;
    color: #fcff5a;
  }
`;

export const Widget4 = styled(WidgetSimple)`
  animation: ${fadeOutUp3} 7s ease infinite;
  background-image: url('/images/widgets/img21.gif');
`;

export const TextWidgets4 = styled.div`
  animation: ${rubberBand} 3s linear infinite;
  font-family: Yanone Kaffeesatz;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  font-size: 48px;
  text-align: center;

  color: #ffb65a;

  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  > p {
    padding-top: 100px;
    font-size: 32px;
    color: #c94dff;
  }
`;

export const YouTubePromoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  display: none;
`;
