import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';

const runnerSize = 12;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const RangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  height: ${runnerSize}px;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
  
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
`;

const Track = styled.div`
  height: 2px;
  width: 100%;
  background: #ffffff26;
  border-radius: 2px;
  z-index: 0;
  position: relative;
  pointer-events: none;
  /* overflow: hidden; */
`;

const Progress = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #FFB65A;
    transition: width 50ms ease-in-out;
    z-index: 2;

    left: ${({ centerPoint }) => (centerPoint ? 50 : 0)}%;

    width: ${({ progress, centerPoint }) => {
    let widthBar;
    if (centerPoint) {
      if (progress > 50) {
        widthBar = progress - 50;
      } else { widthBar = 50 - progress; }
    } else widthBar = progress;
    return widthBar;
  }}%;

    ${({ progress, centerPoint }) => centerPoint && (progress < 50 && css`
        transform-origin: 0;
        transform: scale(-1, 1);`)};
`;

const PreProgress = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #ffffff80;
    z-index: 1;

    left: ${({ centerPoint }) => (centerPoint ? 50 : 0)}%;

    width: ${({ progress, centerPoint }) => {
    let widthBar;
    if (centerPoint) {
      if (progress > 50 || progress === 0) {
        widthBar = progress - 50;
      } else { widthBar = 50 - progress; }
    } else widthBar = progress;
    return widthBar;
  }}%;

    ${({ progress, centerPoint }) => centerPoint && (progress < 50 && css`
        transform-origin: 0;
        transform: scale(-1, 1);`)};
`;

const HackTooltip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: max-content;
  max-width: 200px;
  visibility: visible;
  opacity: 1;
  position: absolute;
  bottom: 160%;
  transform: translateX(-50%);
  
  min-height: 24px;
  
  border-radius: 2px;
  padding: 2px 8px;

  color: #fff;
  background: #1d272e;
  
  cursor: auto;
  pointer-events: none;
  
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  z-index: 30;
    
  transition: left 50ms ease-in-out;
  
  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #1d272e transparent transparent transparent;
  }
`;

const Tooltip = styled(HackTooltip)`
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  ${RangeContainer}:hover & {
    visibility: ${({ disabled }) => (disabled ? 'hidden' : 'visible')};
    opacity: 1;
  }
`;

const RunnerContainer = styled.div`
  position: absolute;
  left: ${runnerSize / 2}px;
  top: 0;
  bottom: 0;
  right: ${runnerSize / 2}px;
  background: transparent;
`;

const Runner = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${runnerSize}px;
  height: ${runnerSize}px;
  border-radius: 50%;
  
  background: #fff;
  z-index: 3;
  
  transition: left 50ms ease-in-out;
  
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
`;

const Value = styled.input`
  width: 34px;
  height: 24px;
  background-color: #2E3F4C;
  border: none;
  border-radius: 4px;

  font-size: 14px;
  line-height: 24px;
  text-align: left;
  color: #FFFFFF;
  
  padding: 0 6px;
  
  &:focus {
    outline: none;
  }
`;

export default class NewSlider extends React.Component {
  static defaultProps = {
    min: 0,
    max: 999,
    step: 1,
    style: {},
  };

  state = {
    inputValue: '',
    preWidth: 0, // in percent (0 - 100)
    preValue: 0,
    isDragging: false,
  };

  percentForDot = ((this.props.max - this.props.min) / 100).toFixed(2);

  range = React.createRef();

  rangeRect = {};

  focus = false; // input

  componentDidMount() {
    this.calculateRange();
  }

  calculateRange = () => {
    this.rangeRect = ReactDOM.findDOMNode(this.range.current).getBoundingClientRect();
  };

  /** input handlers */
  onChange = (e) => {
    const number = Number(e.target.value.replace(/[,+]/g, ''));
    if (Number.isNaN(number)) return;
    this.setState({ inputValue: number });
  };

  onBlur = () => {
    const { min, max, onChange } = this.props;
    const { inputValue } = this.state;
    this.focus = false;
    this.setState({ inputValue: '' }); // shows value from props in time when state is empty (focus = false), so value change is smoothly
    if (Number.isNaN(inputValue) || inputValue < min) return onChange(`${min}`);
    if (inputValue > max) return onChange(`${max}`);
    return onChange(`${inputValue}`);
  };

  onFocus = (e) => {
    this.focus = true;
    this.setState({ inputValue: e.target.value });
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  /** track value handlers */
  handleRunner = (e, accuracy) => {
    const {
      onChange,
      min,
      max,
      value,
      step,
      decPlace,
    } = this.props;
    const { left, width } = this.rangeRect;
    const offset = (e.pageX - left) / width * 100; // percent
    /* const { left, right, width } = this.rangeRect; */
    /* const rightDiff = right - e.pageX; // pixels */


    if (offset < 0) return value !== min && onChange(min);
    if (offset > 100) return value !== max && onChange(max);/* right < 0 */

    // magic here
    const closeToDot = offset * this.percentForDot / step;
    const closestValue = (closeToDot).toFixed(decPlace || 0);
    const rest = Math.abs(closeToDot - closestValue);
    /* < accuracy - stick accuracy, if remove condition it will be on the border between two steps (equal < 0.5) */
    if (rest < accuracy) {
      const actualValue = min + closestValue * step;
      if (value !== (actualValue)) onChange(actualValue);
    }
    return null;
  };

  onMouseDown = () => {
    this.setState({ isDragging: true });
    // range size can be dynamic
    this.calculateRange();
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
  };

  onMouseUp = () => {
    const { value, min } = this.props;
    const progress = (value - min) / this.percentForDot;
    // set values for tooltip
    this.setState({ isDragging: false, preWidth: progress, preValue: value });
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  };

  onMouseMove = e => this.handleRunner(e, 0.1);

  onTrackClick = (e) => {
    if (this.state.isDragging || this.focus) return;
    this.setState({ preWidth: 0, preValue: this.props.min });

    this.handleRunner(e, 0.5);

    this.onMouseDown();
  };

  /** track prevalue handlers */
  calculatePreValue = (offsetPercent) => {
    const { min, step } = this.props;
    return ((offsetPercent * this.percentForDot / step) * step + min);
  };

  onPreMouseEnter = (e) => {
    e.currentTarget.addEventListener('mousemove', this.onPreMouseMove);
  };

  onPreMouseLeave = (e) => {
    const { min } = this.props;
    e.currentTarget.removeEventListener('mousemove', this.onPreMouseMove);
    this.setState({ preWidth: 0, preValue: min });
  };

  onPreMouseMove = (e) => {
    if (this.state.isDragging) return;

    const { left, width } = this.rangeRect;
    const offsetX = e.pageX - left;
    const preWidth = (offsetX / width) * 100;
    const preValue = this.calculatePreValue(preWidth);
    if (preValue !== this.state.preValue) this.setState({ preWidth, preValue });
  };

  render() {
    const {
      min,
      max,
      value: _value,
      centerPoint,
      decPlace,
    } = this.props;
    const {
      inputValue,
      preWidth,
      preValue,
      isDragging,
    } = this.state;
    let value = _value;
    if (value < min) value = min;
    else if (value > max) value = max;
    const progress = (value - min) / this.percentForDot;
    const numberVisible = _value => Number(_value).toFixed(decPlace || 0);
    return (
      <Container>
        <RangeContainer
          ref={this.range}
          onMouseDown={this.onTrackClick}
          onMouseEnter={this.onPreMouseEnter}
          onMouseLeave={this.onPreMouseLeave}
        >
          <Track>
            <Progress progress={progress} centerPoint={centerPoint} />
            <PreProgress progress={preWidth} centerPoint={centerPoint} />
          </Track>
          <RunnerContainer>
            <Runner style={{ left: `${progress}%` }} />
            {isDragging && (
              <HackTooltip style={{ left: `${progress}%` }}>
                {numberVisible(value)}
              </HackTooltip>)}
          </RunnerContainer>
          {!isDragging && (
            <Tooltip
              // disabled={isDragging}
              style={{ left: `${preWidth}%` }}
            >
              {numberVisible(preValue)}
            </Tooltip>)}
        </RangeContainer>
        <Value
          type="text" // https://github.com/facebook/react/issues/9402
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          value={this.focus ? inputValue : value}
          onKeyDown={this.handleKeyDown}
        />
      </Container>
    );
  }
}
