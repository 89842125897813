import styled from 'styled-components';

export const TabCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  width: 144px;
  height: 144px;

  background: url('/images/position-poni.png') no-repeat;
  background-position: center;
  background-size: contain;
`;

export const TabRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Edge = styled.div`
  width: 40px;
  height: 1px;
  border-bottom: 1px dashed ${({ theme }) => theme.white35};
  margin: 8px 4px 7px 4px;
`;

export const Edge90 = styled.div`
  height: 40px;
  width: 1px;
  border-right: 1px dashed ${({ theme }) => theme.white35};
  margin: 4px 7px 4px 8px;

  transform: rotate(${({ rotateDeg }) => rotateDeg}deg);
  transform-origin: center;
`;
