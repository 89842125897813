import {
  takeLatest, select, put, call, fork, take, cancel,
} from 'redux-saga/effects';

import { delay } from 'redux-saga';
import { push, replace } from 'connected-react-router';
import { REHYDRATE } from 'redux-persist/es/constants';
import moment from 'moment';

import {
  AUTH_SIGNUP,
  AUTH_SIGNIN,
  AUTH_SEND_SERVICE_ID,
  AUTH_DISABLE_NEW_INTEGRATION,
  AUTH_DISABLE_NEW_EDITOR,
  AUTH_DELETE_SERVICE_ID,
  AUTH_SIGNOUT,
  AUTH_GENERATE_STREAMACCESSTOKEN,
  AUTH_SET_PASSWORD,
  AUTH_RESET_PASSWORD,
  AUTH_GET_PAYMENT_LINK,
  AUTH_GET_PAYMENT_PROVIDER,
  AUTH_SET_RENEW_SUBSCRIPTION,
  AUTH_TRIAL_PROGRESS_TO_CANCEL,
  AUTH_PAYMENT_FS_DISABLE_SUBSCRIPTION,
  AUTH_PAYMENT_FS_MODAL_SHOW,
  AUTH_PAYMENT_CONTACT_SAVE,
  authSignUpFail,
  authSignUpSuccess,
  authSignInFail,
  authSignInSuccess,
  authResetPasswordError,
  authResetPasswordSuccess,
  authPaymentPatreon,
  authTrialProgressClear,
  authTrialDelayIsOver,
  authSetPaymentProvider,
  authPaymentProgressClear,
  authSetServiceIdError,
  authSetServiceIdLoading,
  authPaymentFSModalShow,
  authPaymentContactModalShow,
  authSubscriptionExpire,
  WIDGETS_DELETE_IMAGE,
  WIDGETS_DELETE_FILE,
  AUTH_USER_SETTINGS_UPDATE,
  AUTH_PAYMENT_ENABLE_SUBSCRIPTION,
  AUTH_SET_DONATION_STATUS,
  authSkipCheckOnSignUp,
  authSendInviteFail,
  authSendInviteSuccess,
  AUTH_SEND_INVITE,
  authSetInviteCode,
  authSendInvite,
  AUTH_SET_USER_FIELDS,
  AUTH_FIREBASE_SIGNIN,
  AUTH_USER_GO_TO_NEW_VERSION,
  AUTH_CHANGE_TOKENS_RATE,
} from 'store/reducers/auth';

import {
  taCloudfunctions,
  widgets,
  API_HOST,
  web2Link,
} from 'constants';

import store from 'store';


import {
  firebaseSignUp,
  firebaseSignIn,
  firebaseGenerateStreamAccessToken,
  firebaseSignOut,
  firebaseSetPassword,
  firebaseResetPassword,
  firebaseSetRenewSubscription,
  firebaseUpdateCurrentProfile,
  firebaseDeleteUserFile,
  firebaseUserSettingsUpdate,
  firebaseSendEmailVerification,
  removeUndefinedFields,
  // firebaseDeleteServiceId,
} from 'utils';
import { fbGetIdToken, firebaseChangeTokensRate } from 'utils/firebase';

function* generateStreamAccessToken(action = {}) {
  try {
    yield firebaseGenerateStreamAccessToken(null, action.user);
  } catch (e) {
    // TODO: handle error on generateStreamAccessToken
  }
}

function* signUp() {
  const { email, password } = yield select(({ auth: { fields } }) => fields);
  let data;
  let user;
  yield put(authSkipCheckOnSignUp());
  try {
    data = yield firebaseSignUp(email, password);
    user = { uid: data.user.uid, email: data.user.email };
    yield generateStreamAccessToken({ user });
    yield firebaseSendEmailVerification();
  } catch (e) {
    yield put(authSkipCheckOnSignUp(false));
    return yield put(authSignUpFail(e));
  }
  yield put(replace('/verify'));
  yield put(authSignUpSuccess(user));
  return yield put(authSkipCheckOnSignUp(false));
}

function* signIn() {
  const { email, password } = yield select(({ auth: { fields } }) => fields);
  let user;
  try {
    user = yield firebaseSignIn(email, password);
    yield put(authSignInSuccess(user));
  } catch (e) {
    yield put(authSignInFail(e));
  }
}

function* signOut() {
  yield firebaseSignOut();
}

function* setDisableNewIntegration({ disableNewIntegration }) {
  try {
    yield firebaseUpdateCurrentProfile({ disableNewIntegration });
  } catch (e) {
    // TODO: handle error on setChaturbateId
  }
}

function* setDisableNewEditor({ disableNewEditor }) {
  try {
    yield firebaseUpdateCurrentProfile({ disableNewEditor });
  } catch (e) {
    // TODO: handle error on setChaturbateId
  }
}

function* setDeleteServiceId({ service }) {
  try {
    yield put(authSetServiceIdLoading(true, service));
    const token = yield call(fbGetIdToken);
    const res = yield call(fetch, `${API_HOST}/service-id`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ service }),
    });
    if (res.status >= 400) return yield put(authSetServiceIdError(res.statusText, service));
    yield put(authSetServiceIdLoading(false, service));
  } catch (e) {
    let msg = e.message;
    if (msg === 'Failed to fetch') msg = 'Connection error. Please try again later.';
    yield put(authSetServiceIdError(msg, service));
  }
  return null;
}

function* goToNewVersion() {
  try {
    const token = yield call(fbGetIdToken);
    const url = `${web2Link}/versions-auth?token=${token}`;
    window.open(url, '_self');
  } catch (e) {
    console.log(e);
  }
}

function* sendServiceId({ id, service }) {
  try {
    yield put(authSetServiceIdLoading(true, service));
    const { currentProfile, profiles } = yield select(({ auth }) => auth.user);
    const profile = currentProfile
      ? profiles[currentProfile]
      : Object.values(profiles)[0];
    if (profile[`${service}Id`] !== id) {
      const token = yield call(fbGetIdToken);
      const res = yield call(fetch, `${API_HOST}/service-id`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ id, service }),
      });
      if (res.status >= 400) return yield put(authSetServiceIdError(res.statusText, service));
    }
    yield put(authSetServiceIdLoading(false, service));
    yield put(push(`/connect-service/${service}/success`));
    // const { chaturbateId, bongacamsId, myfreecamsId } = yield res.json();
  } catch (e) {
    let msg = e.message;
    if (msg === 'Failed to fetch') msg = 'Connection error. Please try again later.';
    yield put(authSetServiceIdError(msg, service));
  }
  return null;
}

function* setPassword({ oldPassword, password }) {
  const { email } = yield select(state => state.auth.user);
  try {
    yield firebaseSetPassword({ email, oldPassword, password });
  } catch (e) {
    alert(e);
  }
}

function* resetPassword() {
  const { email } = yield select(({ auth: { fields } }) => fields);
  try {
    yield firebaseResetPassword(email);
  } catch (e) {
    return yield put(authResetPasswordError(e));
  }
  return yield put(authResetPasswordSuccess());
}

const getLink = (uid, pp, accountType, firstName = '', lastName = '', duration = '1m') => (
  fetch(`${taCloudfunctions}/paymentLink?userId=${uid}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}${typeof pp !== 'undefined' ? `&pp=${pp}` : ''}${typeof accountType !== 'undefined' ? `&plan=${accountType}` : ''}${typeof duration !== 'undefined' ? `&duration=${duration}` : ''}`)
    .then(r => r.json())
);

const disableSubscription = (uid, pp) => fetch(`${taCloudfunctions}/disableSubscription?userId=${uid}&pp=${pp}`).then(r => r.json());

function* navigateToTipsWithDelay() {
  yield delay(2000);
  yield put(push(`/widget/${widgets.tips.id}`));
}
function* setTrial(email) {
  yield delay(5000);
  yield put(authTrialDelayIsOver());
  try {
    const r = yield fetch(`${taCloudfunctions}/activateTrial?email=${encodeURIComponent(email)}`);
    const res = yield r.json();
    if (res.verifyEmailError) yield firebaseSendEmailVerification();
    if (res.result === 'error') throw new Error(res.error);
    yield fork(navigateToTipsWithDelay);
  } catch (e) {
    if (e.code === 'auth/too-many-requests') {
      window.alert('We already send verification e-mail to you. Please check inbox or spam folder, if you cannot find e-mail try activate trial again in a few minutes.');
    } else {
      window.alert(`Error: ${e.message}`);
    }
  } finally {
    yield put(authTrialProgressClear());
  }
}

function* getPaymentLink({ accountType, goStraight, duration = 1 }) {
  // eslint-disable
  const {
    uid,
    pp,
    email,
    firstName,
    lastName,
  } = yield select(({ auth: { user } }) => user);
  let pcFirstName = firstName;
  let pcLastName = lastName;
  const { provider } = yield select(({ auth: authState }) => authState);
  if (accountType === 'trial') {
    const task = yield fork(setTrial, email);
    yield take(AUTH_TRIAL_PROGRESS_TO_CANCEL);
    yield cancel(task);
  } else {
    if ((provider === 'fastspring' || pp === 6) && (!pcFirstName || !pcLastName)) {
      yield put(authPaymentContactModalShow(true));
      const { firstName: fN, lastName: lN } = yield take(AUTH_PAYMENT_CONTACT_SAVE);
      pcFirstName = fN;
      pcLastName = lN;
    }
    const {
      isPatreon,
      url,
      isFS,
      session,
    } = yield call(getLink, uid, pp, accountType, pcFirstName, pcLastName, duration);
    yield put(authPaymentContactModalShow(false));
    if (isPatreon && !goStraight) {
      yield put(authPaymentPatreon(url));
    } else if (isFS) {
      yield put(authPaymentProgressClear());
      if (typeof window.fastspring !== 'undefined') {
        window.fastspring.builder.checkout(session.id);
        window.fsPopupClosed = (order) => {
          store.dispatch(authPaymentFSModalShow(true, !order));
        };
      }
    } else if (url) {
      ga('send', 'event', 'Payment', 'redirect', 'Premium', { 'ta-userId': uid });//eslint-disable-line
      window.location = url;
    } else {
      alert('Something went wrong. Please contact support by support@tipalerts.com');
    }
  }
}

function* getPaymentProvider() {
  let provider = '';
  try {
    const res = yield fetch(`${taCloudfunctions}/getPaymentProvider`);
    provider = (yield res.json()).provider || '';
  } catch (e) {
    console.log('Error: ', e);
  }
  yield put(authSetPaymentProvider(provider));
}

function* setRenewSubscription({ renewSubscription }) {
  const { uid } = yield select(({ auth: { user } }) => user);
  yield firebaseSetRenewSubscription(uid, renewSubscription);
}

function* deleteUserFile({ fileId, file, fileType }) {
  const { uid } = yield select(({ auth }) => auth.user);
  yield firebaseDeleteUserFile(uid, fileId, file, fileType);
}

function* userSettingsUpdate({ widgetId, payload }) {
  const { uid } = yield select(({ auth }) => auth.user);
  yield firebaseUserSettingsUpdate(uid, widgetId, removeUndefinedFields(payload));
}

function* authDisableSubscription({ pp }) {
  const { uid } = yield select(({ auth }) => auth.user);
  const { info } = yield call(disableSubscription, uid, pp);
  const infoError = info && info.subscriptions && info.subscriptions[0] && info.subscriptions[0].error;
  if (infoError) {
    alert(`${infoError.subscription}! Please contact support`);
  } else {
    alert('Success! Your subscription will be disabled in few minutes');
  }
}

// looks like with FastSpring popup modal bootstrap modal cannot correctly remove overflow from body
function* onFSModalHide({ show }) { // eslint-disable-line
  if (!show) {
    setTimeout(() => {
      document.body.style.overflow = 'auto';
    }, 1000);
  }
}

function* enableSubscription() {
  const { uid } = yield select(({ auth: { user } }) => user);
  yield fetch(`${taCloudfunctions}/enableSubscription?userId=${uid}`);
}

let resubOpened = false;

function* onSetSubscription(action) {
  if (action.status) {
    if (action.status.basic !== true && action.status.active !== true) {
      yield put(authSubscriptionExpire());
      if (!resubOpened) {
        resubOpened = true;
        yield put(push('/resub'));
      }
    } else if (action.status.basic === true || action.status.active === true) {
      yield put(authSubscriptionExpire(false));
    }
  }
}

function* sendInvite({ code }) {
  const token = yield call(fbGetIdToken);
  const res = yield call(fetch, `${API_HOST}/invite`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({ code }),
  });
  if (res.status !== 201) return yield put(authSendInviteFail());
  return yield put(authSendInviteSuccess());
}

function* onRehydrate() {
  if (window.location.search) {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('i')) {
      yield put(authSetInviteCode(searchParams.get('i')));
    }
  }
}

function* onFirebaseSignIn() {
  yield take(AUTH_SET_USER_FIELDS);
  const { savedInviteCode, user } = yield select(state => state.auth);
  if (savedInviteCode && user && !user.invite) {
    if (moment(user.creationTime).add(1, 'day').isAfter(moment())) {
      yield put(authSendInvite(savedInviteCode));
      yield put(authSetInviteCode(''));
    }
  }
}

function* changeTokensRate({ service, rate }) {
  const { user: { uid } } = yield select(state => state.auth);
  yield call(firebaseChangeTokensRate, uid, service, rate);
}

export function* auth() {
  yield takeLatest(REHYDRATE, onRehydrate);
  yield takeLatest(AUTH_SIGNUP, signUp);
  yield takeLatest(AUTH_GENERATE_STREAMACCESSTOKEN, generateStreamAccessToken);
  yield takeLatest(AUTH_SIGNIN, signIn);
  yield takeLatest(AUTH_FIREBASE_SIGNIN, onFirebaseSignIn);
  yield takeLatest(AUTH_DISABLE_NEW_INTEGRATION, setDisableNewIntegration);
  yield takeLatest(AUTH_DISABLE_NEW_EDITOR, setDisableNewEditor);
  yield takeLatest(AUTH_DELETE_SERVICE_ID, setDeleteServiceId);
  yield takeLatest(AUTH_SEND_SERVICE_ID, sendServiceId);
  yield takeLatest(AUTH_SIGNOUT, signOut);
  yield takeLatest(AUTH_SET_PASSWORD, setPassword);
  yield takeLatest(AUTH_RESET_PASSWORD, resetPassword);
  yield takeLatest(AUTH_GET_PAYMENT_LINK, getPaymentLink);
  yield takeLatest(AUTH_GET_PAYMENT_PROVIDER, getPaymentProvider);
  yield takeLatest(AUTH_SET_RENEW_SUBSCRIPTION, setRenewSubscription);
  yield takeLatest(WIDGETS_DELETE_IMAGE, deleteUserFile);
  yield takeLatest(WIDGETS_DELETE_FILE, deleteUserFile);
  yield takeLatest(AUTH_USER_SETTINGS_UPDATE, userSettingsUpdate);
  yield takeLatest(AUTH_PAYMENT_FS_DISABLE_SUBSCRIPTION, authDisableSubscription);
  yield takeLatest(AUTH_PAYMENT_FS_MODAL_SHOW, onFSModalHide);
  yield takeLatest(AUTH_PAYMENT_ENABLE_SUBSCRIPTION, enableSubscription);
  yield takeLatest(AUTH_SET_DONATION_STATUS, onSetSubscription);
  yield takeLatest(AUTH_SEND_INVITE, sendInvite);
  yield takeLatest(AUTH_USER_GO_TO_NEW_VERSION, goToNewVersion);
  yield takeLatest(AUTH_CHANGE_TOKENS_RATE, changeTokensRate);
}
