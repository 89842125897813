import styled from 'styled-components';

// import { theme } from 'constants/theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const Item = styled.button`
  flex: 1;
  border: 1px solid #FFB65A;
  background: ${({ isActive }) => isActive ? '#FFB65A' : 'none'};
  color: ${({ isActive }) => isActive ? '#304859' : '#FFB65A'};
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: none;
  margin: none;
  cursor: pointer;
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`;

export const ItemDiscount = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;

  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: ${({ isActive }) => isActive ? '#304859' : '#CCFF8A'}

  margin-left: 8px;
`;
