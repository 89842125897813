import styled from 'styled-components';

import { theme } from 'constants/theme';
import Constants from '../const/const';

export const GoalsContentBackground = styled.div`
  display: flex;
  justify-content: center;

  background: radial-gradient(
    200px at calc(50% + (${Constants.WidthContent} / 2 - 200px)) 100%,
    rgba(131, 154, 213, 0.45) 0%,
    rgba(31, 43, 54, 0) 100%
  );

  
`;

export const GoalsContent = styled.div`
  width: ${Constants.WidthContent};
  display: flex;
  flex-flow: row; 
  
`;

export const EmptyContainer = styled.div`
  flex: 1;
  
  display: flex;
  justify-content: center;
  align-items: center;   

  
`;

export const ImageGoals = styled.div`
  width: 792px;
  height: 314px;
  background-image: url('/images/goals-img.png');
  
  position: absolute;  
`;

export const TextContainer = styled.div`
  flex: 1;

  padding-top: 108px;
  padding-bottom: 162px;

  font-weight: 900;
  line-height: 48px;
  font-size: 36px;

  > p {
    font-weight: 500;
    line-height: 28px;
    font-size: 20px;
  }

  > .superIndex {
    vertical-align: super;
    font-weight: 600;
    font-size: 14px;

    color: ${theme.main.orangeLanding};
  }
`;
