import React from 'react';
import styled from 'styled-components';
import {
  TextAppearance,
  AnimationBlock,
} from 'components/UIkit';
import {
  Section,
  FormItem,
} from 'components/UI';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActionWidgetsComponentsUpdate } from 'store/reducers/widgets';
import { widgets } from 'constants';

const InputArea = styled.textarea`
height: 72px;
border-radius: 4px;
background-color: #263845;
border: solid 1px ${({ theme }) => theme.darkGreyBlue};
resize: none;
display: flex;
width: 100%;
margin-top: 5px;
&:focus {
  outline: none;
  border: 1px solid #FFB65A;
}

padding: 6px 12px;


overflow-y: auto;
overflow-x: hidden;
::-webkit-scrollbar {
  width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 3px;
  background: ${({ theme }) => theme.white05};

  padding: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: ${({ theme }) => theme.white17};
  border-radius: 3px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: ${({ theme }) => theme.white54};
}
`;

const TitleTab = ({
  data,
  widgetsComponentsUpdate,
}) => (
  <div>
    <Section>
      <FormItem label="Title text" themeFont="header" header />
      <InputArea
        value={data.customMessage}
        onChange={({ target: { value } }) => widgetsComponentsUpdate({ customMessage: value })}
      />
    </Section>
    <TextAppearance
      data={data}
      widgetUpdate={widgetsComponentsUpdate}
      mainLabel="Title"
      customMessageOptions
      borderTop
    />
    <AnimationBlock
      data={data}
      widgetUpdate={widgetsComponentsUpdate}
      borderTop
    />
  </div>
);

TitleTab.title = 'Title';

export default connect(
  null,
  (dispatch, ownProps) => bindActionCreators({
    widgetsComponentsUpdate: getActionWidgetsComponentsUpdate(widgets.top.id, ownProps.selectedPreset, 'title'),
  }, dispatch),
)(TitleTab);
