import React from 'react';
import styled from 'styled-components';

const Label = styled.div`
  display: flex;
  ${({ theme, themeFont }) => theme.font[themeFont]};
  margin-bottom: 4px;

  opacity: 0.85;
`;

const Children = styled.div`
  display: flex;
  width: ${({ flexEnd }) => (flexEnd ? '' : '100%')};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60px;

  align-items: flex-start;
`;

export const FormItemCol = ({
  label,
  children,
  themeFont,
}) => (
  <Container>
    <Label themeFont={themeFont}>
      {label}
    </Label>
    <Children>
      {children}
    </Children>
  </Container>
);
