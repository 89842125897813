// @flow
/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

import { CheckBox, Section, FormItem, NewSlider } from 'components/UI';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActionWidgetsComponentsUpdate } from 'store/reducers/widgets';
import { widgets } from 'constants';

const Container = styled.div`

`;

const Row = styled.div`
  display: flex;
  margin-bottom: 12px;
`;
const Label = styled.div`
  ${({ theme }) => theme.font.normal}
  margin-right: 12px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.white54};

  margin-left: 22px;
`;

class SoundsTab extends React.Component {
  render() {
    const {
      data,
      widgetsComponentsUpdate,
    } = this.props;
    return (
      <Container>
        <Section>
          <FormItem label="Sounds playback" themeFont="header" header />
          <Row>
            <Label>Volume</Label>
            <NewSlider
              value={data.volume}
              min={1}
              max={100}
              step={1}
              onChange={value => widgetsComponentsUpdate({ volume: value })}
            />
          </Row>
          <Col>
            <CheckBox
              checked={data.soundAnnounce}
              onChange={() => widgetsComponentsUpdate({ soundAnnounce: !data.soundAnnounce })}
            >
              Tip announce sound
            </CheckBox>
            <Text>Plays when game tip is made</Text>
          </Col>
          <Col>
            <CheckBox
              checked={data.soundRoll}
              onChange={() => widgetsComponentsUpdate({ soundRoll: !data.soundRoll })}
            >
              Roll sound
            </CheckBox>
            <Text>Plays when rolling starts</Text>
          </Col>
          <Col>
            <CheckBox
              checked={data.soundPrize}
              onChange={() => widgetsComponentsUpdate({ soundPrize: !data.soundPrize })}
            >
              Prize sound
            </CheckBox>
            <Text>Plays when prize notification appears</Text>
          </Col>
        </Section>
      </Container>
    );
  }
}

SoundsTab.title = 'Sounds';

export default connect(
  null,
  (dispatch, ownProps) => bindActionCreators({
    widgetsComponentsUpdate: getActionWidgetsComponentsUpdate(widgets.dice.id, ownProps.selectedPreset, 'sounds'),
  }, dispatch),
)(SoundsTab);
