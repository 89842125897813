
export const LOGS_ADD_ITEMS = 'LOGS_ADD_ITEMS';
export const LOGS_GET_ITEMS = 'LOGS_GET_ITEMS';
export const LOGS_CLEAR = 'LOGS_CLEAR';
export const LOGS_FIND_MORE = 'LOGS_FIND_MORE';
export const LOGS_FIND_MORE_BY_DATE = 'LOGS_FIND_MORE_BY_DATE';
export const LOGS_TOGGLE_FETCH_AUTO = 'LOGS_TOGGLE_FETCH_AUTO';
export const LOGS_INPUT_UID = 'LOGS_INPUT_UID';
export const LOGS_AUTH = 'LOGS_AUTH';
export const LOGS_AUTH_PUT_TOKEN = 'LOGS_AUTH_PUT_TOKEN';
export const LOGS_INPUT_EMAIL = 'LOGS_INPUT_EMAIL';
export const LOGS_USER_GET_INFO = 'LOGS_USER_GET_INFO';
export const LOGS_USER_GET_INFO_SUCCESS = 'LOGS_USER_GET_INFO_SUCCESS';
export const LOGS_USER_IMPERSONATE = 'LOGS_USER_IMPERSONATE';
export const LOGS_ACTIVATE_PREMIUM = 'LOGS_ACTIVATE_PREMIUM';
export const LOGS_ACTIVATE_TRIAL = 'LOGS_ACTIVATE_TRIAL';
export const LOGS_GET_SERVER_START_TIME = 'LOGS_GET_SERVER_START_TIME';
export const LOGS_GET_SERVER_START_TIME_SUCCESS = 'LOGS_GET_SERVER_START_TIME_SUCCESS';
export const LOGS_RESTART_SERVER = 'LOGS_RESTART_SERVER';
export const LOGS_GET_ACTIVE_USERS = 'LOGS_GET_ACTIVE_USERS';
export const LOGS_GET_ACTIVE_USERS_SUCCESS = 'LOGS_GET_ACTIVE_USERS_SUCCESS';
export const LOGS_KILL_PROCESS = 'LOGS_KILL_PROCESS';
export const LOGS_TEST_ALERT = 'LOGS_TEST_ALERT';

// place: 'start' | 'end'; another - replace
export const logsAddItems = (items, place) => ({
  type: LOGS_ADD_ITEMS,
  payload: items,
  place,
});
export const logsGetItems = (tags, services) => ({
  type: LOGS_GET_ITEMS,
  tags,
  services,
});
export const logsClear = () => ({ type: LOGS_CLEAR });
export const logsFindMore = ({ latest = true, tags, services }) => ({
  type: LOGS_FIND_MORE,
  latest,
  tags,
  services,
});
export const logsFindMoreByDate = ({ dateBefore, dateAfter }) => ({
  type: LOGS_FIND_MORE_BY_DATE,
  dateBefore,
  dateAfter,
});
export const logsToggleFetchAuto = () => ({ type: LOGS_TOGGLE_FETCH_AUTO });
export const logsInputUid = uid => ({ type: LOGS_INPUT_UID, payload: uid });
export const logsAuth = () => ({ type: LOGS_AUTH });
export const logsAuthPutToken = token => ({ type: LOGS_AUTH_PUT_TOKEN, token });
export const logsInputEmail = email => ({ type: LOGS_INPUT_EMAIL, email });
export const logsUserGetInfo = (uid, service = 'email') => ({ type: LOGS_USER_GET_INFO, uid, service });
export const logsUserGetInfoSuccess = userInfo => ({ type: LOGS_USER_GET_INFO_SUCCESS, userInfo });
export const logsUserImpersonate = () => ({ type: LOGS_USER_IMPERSONATE });
export const logsActivatePremium = () => ({ type: LOGS_ACTIVATE_PREMIUM });
export const logsActivateTrial = () => ({ type: LOGS_ACTIVATE_TRIAL });
export const logsGetServerStartTime = () => ({ type: LOGS_GET_SERVER_START_TIME });
export const logsGetServerStartTimeSuccess = time => ({ type: LOGS_GET_SERVER_START_TIME_SUCCESS, time });
export const logsRestartServer = () => ({ type: LOGS_RESTART_SERVER });
export const logsGetActiveUsers = () => ({ type: LOGS_GET_ACTIVE_USERS });
export const logsGetActiveUsersSuccess = users => ({ type: LOGS_GET_ACTIVE_USERS_SUCCESS, users });
export const logsKillProcess = () => ({ type: LOGS_KILL_PROCESS });
export const logsTestAlert = (streamAccessToken, amount) => ({ type: LOGS_TEST_ALERT, streamAccessToken, amount });

const initialState = {
  items: [],
  fetchAuto: false,
  uid: '',
  authToken: '',
  userInfo: null,
  email: '',
  serverStartTime: '',
  activeUsers: [],
  status: '',
};

export const logs = (state = { ...initialState }, action) => {
  switch (action.type) {
    case LOGS_GET_ITEMS: {
      return {
        ...state,
        status: 'loading',
      };
    }
    case LOGS_FIND_MORE: {
      return {
        ...state,
        status: 'loading',
      };
    }
    case LOGS_ADD_ITEMS: {
      const newState = {
        ...state,
        status: '',
      };
      switch (action.place) {
        case 'start':
          newState.items = [
            ...action.payload,
            ...state.items,
          ];
          break;
        case 'end':
          newState.items = [
            ...state.items,
            ...action.payload,
          ];
          break;
        default:
          newState.items = [
            ...action.payload,
          ];
      }
      return newState;
    }
    case LOGS_CLEAR:
      return {
        ...state,
        items: [],
        userInfo: null,
        fetchAuto: false,
        status: '',
      };
    case LOGS_TOGGLE_FETCH_AUTO:
      return {
        ...state,
        fetchAuto: !state.fetchAuto,
      };
    case LOGS_INPUT_UID:
      return {
        ...state,
        uid: action.payload,
      };
    case LOGS_AUTH_PUT_TOKEN:
      return {
        ...state,
        authToken: action.token,
      };
    case LOGS_INPUT_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case LOGS_USER_GET_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case LOGS_GET_SERVER_START_TIME_SUCCESS:
      return {
        ...state,
        serverStartTime: action.time,
      };
    case LOGS_GET_ACTIVE_USERS_SUCCESS:
      return {
        ...state,
        activeUsers: action.users,
      };
    default:
      return state;
  }
};
