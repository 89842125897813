import React, { PureComponent } from 'react';
import md5 from 'md5';

import { CheckBox } from 'components/UI';
import { Service_ID, servicesURLs, servicesCodes } from 'constants/service';

const fbURL = 'https://console.firebase.google.com/u/0/project/tipalerts-94db4/database/tipalerts-94db4/data/';

const services = Object.values(Service_ID);

export class FBLinks extends PureComponent {
  renderFBLink = (to, title, cleanURL = false, key = undefined) => <a key={key} style={{ marginRight: '6px' }} href={`${!cleanURL ? fbURL : ''}${to}`} target="_blank" rel="noopener noreferrer">{title}</a>;

  render() {
    const { user, hideUserInfo, onChangeHideUserInfo } = this.props;
    return (
      <span style={{ display: 'flex' }}>
        {(user && user.email) && this.renderFBLink(`payments/${md5(user.email)}`, 'Payments')}
        {(user && user.uid) && this.renderFBLink(`users/${user.uid}`, 'Profile')}
        {(user && user.profiles && user.profiles[0] && user.profiles[0].streamAccessToken) && this.renderFBLink(`widgets/${user.profiles[0].streamAccessToken}`, 'Widgets')}
        {(user && user.email) && <a style={{ marginRight: '6px' }} href={`https://www.patreon.com/members?query=${user.email}`} target="_blank" rel="noopener noreferrer">Patreon</a>}
        {(user && user.email) && <a style={{ marginRight: '6px' }} href={`https://app.fastspring.com/order/search.xml?query=${user.email}`} target="_blank" rel="noopener noreferrer">FastSpring</a>}
        {services.map((serviceId) => {
          const idKey = `${serviceId}Id`;
          if (!user || !user.profiles || !user.profiles[0] || !user.profiles[0][idKey]) {
            return null;
          }
          let url = `${servicesURLs[serviceId]}/${user.profiles[0][idKey]}`;
          if (serviceId === Service_ID.myfreecams) {
            url = `${servicesURLs[serviceId]}/#${user.profiles[0][idKey]}`;
          }
          return this.renderFBLink(url, servicesCodes[serviceId], true, url);
        })}
        <CheckBox
          checked={hideUserInfo}
          onChange={onChangeHideUserInfo}
        >
          Hide user info
        </CheckBox>
      </span>
    );
  }
}
