// @flow
/* eslint-disable */
import React, { Component } from 'react';

import { connect } from 'react-redux';

import { TabsContainer } from 'components/UI';
import { DiceDataDefault } from 'constants';

import type { DiceData } from 'types/Widget';

import {
  WidgetTab,
  PrizeListTab,
  NotificationsTab,
  SoundsTab,
} from './Tabs';

type Props = {
  diceData: DiceData,
};

export default connect(
  state => ({
    diceData: state.widgets.dice || {},
  })
)(class Options extends Component<Props> {
  static defaultProps = {
    diceData: {},
  };

  render() {
    const {
      diceData: data,
    } = this.props;

    const diceData = data.default || {};
    const tabHOC = (Tab, props) => <Tab {...props} selectedPreset="default" />;
    return (
      <TabsContainer
        content={[
          tabHOC(WidgetTab, {
            data: {
              ...DiceDataDefault.widget,
              ...(diceData.widget || {}),
            }
          }),
          tabHOC(PrizeListTab, {
            data: {
              ...DiceDataDefault.prizeList,
              ...(diceData.prizeList || {}),
            }
          }),
          tabHOC(NotificationsTab, {
            data: {
              ...DiceDataDefault.notifications,
              ...(diceData.notifications || {}),
            }
          }),
          tabHOC(SoundsTab, {
            data: {
              ...DiceDataDefault.sounds,
              ...(diceData.sounds || {}),
            }
          }),
        ]}
      />
    );
  }
});
