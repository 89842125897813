import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';


export const Container = styled.div`
  width: 100%;
  background-color: #0E1C26;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContainerContent = styled.div`
  width: 1140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 17px 0px;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MainLink = styled.span`
  font-weight: 900;
  font-size: 22px;
  line-height: 24px;

  color: white !important;
  outline: none;

  margin-right: 40px;
`;

const link = css`
  font-weight: 500;
  color: white;

  font-size: 16px;
  line-height: 24px;
  margin: 0px 8px;
  
  border: none;
  outline: none;
  
  &:hover,
  &:active,
  &:focus {
   border: none;
   color: #FFB65A;
   outline: none;
  }
  `;

export const InnerLink = styled(Link)`${link}`;

export const OuterLink = styled.a`${link}`;

export const OuterContainerLink = styled.a`
  ${link}
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
    margin: 4px;
`;

export const TextContainer = styled.span`
  font-size: 14px;
  line-height: 20px;
  margin: 44px 0px;
  color: #ffffff80;
`;
