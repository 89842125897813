import styled from 'styled-components';

import { theme } from 'constants/theme';
import Constants from '../const/const';

export const BackgroundContainer = styled.div`
  background: ${theme.main.slateThree};
  display: flex;
  justify-content: center;
`;

export const KeyFeatures = styled.div`
  width: ${Constants.WidthContent};
  display: flex;
  flex-flow: column wrap;
`;

export const Title = styled.div`
  padding-top: 60px;
  padding-bottom: 42px;

  font-weight: 900;
  line-height: 48px;
  font-size: 36px;
  text-align: center;
`;

export const GroupContentRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;
`;

export const GroupContentColumn = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column wrap;
  align-items: flex-start;
  align-content: flex-start;

  margin: 0px 15px;
`;

export const TextContent = styled.div`
  padding-top: 8px;
  padding-bottom: 70px;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  color: ${theme.main.orangeLanding};
  > p {
    font-weight: normal;
    color: ${theme.main.white};
  }
`;

export const LogoPaint = styled.div`
  width: 36px;
  height: 36px;
  background-image: url('/images/paint-palette-and-brush.svg');
`;

export const LogoDiag = styled(LogoPaint)`
  background-image: url('/images/diagramma.svg');
`;

export const LogoDialog = styled(LogoPaint)`
  background-image: url('/images/dialog.svg');
`;

export const LogoVolume = styled(LogoPaint)`
  background-image: url('/images/volume.svg');
`;
