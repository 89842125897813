import styled from 'styled-components';

import { theme } from 'constants/theme';
import Constants from '../const/const';

export const BackgroundContainer = styled.div`
  display: flex;
  justify-content: center;
  display: none;
`;

export const Install = styled.div`
  width: ${Constants.WidthContent};

  display: flex;
  flex-flow: column wrap;

  box-shadow: inset 0px -1px 0px #405260;
`;

export const Title = styled.div`
  padding-top: 60px;
  padding-bottom: 22px;

  font-weight: 900;
  line-height: 48px;
  font-size: 36px;
  text-align: center;
  > p {
    padding-top: 2px;

    font-weight: normal;
    line-height: 24px;
    font-size: 16px;
  }
`;

export const Youtube = styled.div`
  display: flex;
  width: 100%;
  /* padding-bottom: 56.25%; /* 16 x 9 */

  justify-content: center;
`;

export const TextInstall = styled(Title)`
  padding-bottom: 58px;
`;

export const OutLink = styled.a`
  color: ${theme.main.orangeLanding} !important;
  border-bottom: 1px solid ${theme.main.orangeLanding05};
  &:hover {
    border-bottom: 1px solid ${theme.main.orangeLanding};
  }
`;
