import React, { Component } from 'react';

import {
  BackgroundContainer, Install, Title, Youtube, TextInstall, OutLink,
} from './style';

export class InstallContainer extends Component {
  render() {
    return (
      <BackgroundContainer>
        <Install>
          <Title>
            How to install
            <p>Here’s a step-by-step guide that will help to use/setup TipAlerts with OBS</p>
          </Title>
          <Youtube>
            <iframe
              title="Video TipAlerts channel"
              width="1140"
              height="712"
              src="https://www.youtube.com/embed/x5BiaavEzAg"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Youtube>
          <TextInstall>
            <p>
              <OutLink href="https://help.tipalerts.com/">
                Read instructions
              </OutLink>
              {' '}
            </p>
            <p>
              {'Watch other video instructions on our '}
              <OutLink href="https://www.youtube.com/tipalerts">
                Youtube channel.
              </OutLink>
            </p>
          </TextInstall>
        </Install>
      </BackgroundContainer>
    );
  }
}
