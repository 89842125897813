export const images = {
  arrow: '/images/alignment/top.png',
  circle: '/images/alignment/center.png',
};

export const scheme = [
  {
    VerticalAlign: 2,
    HorizontalAlign: 0,
    transform: 'rotate(-45deg)',
  },
  {
    VerticalAlign: 2,
    HorizontalAlign: 1,
  },
  {
    VerticalAlign: 2,
    HorizontalAlign: 2,
    transform: 'rotate(45deg)',
  },
  {
    VerticalAlign: 1,
    HorizontalAlign: 0,
    transform: 'rotate(-90deg)',
  },
  {
    VerticalAlign: 1,
    HorizontalAlign: 1,
  },
  {
    VerticalAlign: 1,
    HorizontalAlign: 2,
    transform: 'rotate(90deg)',
  },
  {
    VerticalAlign: 0,
    HorizontalAlign: 0,
    transform: 'rotate(-135deg)',
  },
  {
    VerticalAlign: 0,
    HorizontalAlign: 1,
    transform: 'rotate(180deg)',
  },
  {
    VerticalAlign: 0,
    HorizontalAlign: 2,
    transform: 'rotate(135deg)',
  },
];
