import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { Block, BlockTypes, Button } from 'components/UI';

const StyledBlock = styled(Block)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & ${Button} {
    color: white;
    font-weight: 600;
    font-size: 14px;
  }
`;

export default ({ title, lastActivity, onHelpClick }) => (
  <StyledBlock type={BlockTypes.warning}>
    <h4 style={{ margin: 0 }}>
      {title}
    </h4>
    {lastActivity
    && (
      <div
        style={{
          fontWeight: 'normal', fontSize: '13px', color: 'black', textAlign: 'center',
        }}
      >
        Last check was:&nbsp;
        {moment(lastActivity).format('HH:mm (MMMM DD)')}
        <div>If your widget is active &mdash; next check will be in 2 minutes.</div>
      </div>
    )}
    <Button onClick={onHelpClick}>Help me</Button>
  </StyledBlock>
);
