import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import moment from 'moment';

import { widgets } from 'constants';
import { parseTillDateString } from 'utils';

import twitterIcon from 'assets/images/ic_twitter_white.svg';

import { TwitterModal } from '../TwitterModal';

const NavbarContainer = styled.div`
  height: 34px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.darkTwo};
  ${({ theme }) => theme.font.heading};
`;

const Logo = styled(Link)`
  color: ${({ theme }) => theme.white} !important;
  margin-right: 10px;
  &:hover {
    border-bottom-color: ${({ theme }) => theme.white};
  }
`;

const LinkStyle = css`
  color: ${({ theme }) => theme.babyBlue} !important;
  margin-left: 15px;
  cursor: pointer;
  ${({ theme }) => theme.font.usual};
  border-bottom: 1px solid transparent;
`;
const LinkWithClick = styled.span`
  ${LinkStyle};
  &:hover {
    border-bottom: 1px solid #9AD8FF;
  }
`;

const OuterLink = styled.a`
  ${LinkStyle};
`;

const IconLink = styled.a`
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    text-decoration: none;
    border-color: transparent;
  }
`;

const NavLink = styled(Link)`
  ${LinkStyle}
`;

const NavLinkAlerts = styled(Link)`
  cursor: pointer;
  color: ${({ theme }) => theme.babyBlue} !important;

  z-index: 10;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px;
  ${({ active, theme }) => (active
  && css`
    background-color: ${theme.slateFour};
    `
  )};

  >span{
    color: ${({ theme }) => theme.white};
    cursor: default;
    z-index: 10;
  }

  position: relative;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const NavigationContainer = styled.div`
  display: flex;
  height: 100%;
`;

const Trial = styled.span`
  position: absolute;
  left: 50%;
  color: ${({ red, theme }) => (red ? theme.red : theme.orangeLanding)};

  @media (max-width: 1350px) {
    display: none;
  }
`;

const Twitter = styled.div`
  background-image: url(${twitterIcon});
  width: 22px;
  height: 22px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 2px;
`;

const NewEditor = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;

  padding: 4px 6px;
  border-radius: 4px;
  background-color: rgba(235, 254, 17, .2);
  transition: background-color .2s ease-out;
  &:hover{
    background-color: rgba(235, 254, 17, .35);
  }
  &:active{
    background-color: rgba(235, 254, 17, .15);
  }
`;

const NewEditorLink = styled(LinkWithClick)`
  margin-left: 0;

  border-bottom: 1px solid #ebfe1100;
  color: #ebfe11 !important;
  transition: border-bottom .2s ease-out;

  &:hover {
    border-bottom: 1px solid rgba(235, 254, 17, .5);
  }
`;

type Props = {
  authUserGoToNewVersion: () => void,
  authSignOut: () => void,
  setLanguage: () => void,
  user: object,
  lang: string,
  pathname: string,
};

const LOG_SP = atob('YWQ=') + atob('bWk=') + atob('bg==');

export class Navbar extends Component<Props> {
  state = {
    isHideTwitterModal: false,
  };

  componentDidMount() {
    const {
      lang,
      setLanguage,
    } = this.props;
    if (lang === 'ru') setLanguage('en');
  }

  hideTwitterModal = () => {
    this.setState({ isHideTwitterModal: true });
    localStorage.setItem('hideTwitterPopUp', true);
  }


  render() {
    const {
      authUserGoToNewVersion,
      authSignOut,
      supporter: {
        special,
        till,
        basic,
        active,
      },
      user,
      // lang,
      // setLanguage,
      pathname,
    } = this.props;
    const {
      isHideTwitterModal,
    } = this.state;
    // const alfaVersionAccess = (user && user.alfaVersionAccess) || false;

    const userHasSubscription = basic === true || active === true;

    const todaysDate = moment(new Date());
    const diffDays = moment(till).diff(todaysDate, 'days');

    const alertsFlag = userHasSubscription && pathname === `/widget/${widgets.tips.id}`;
    const goalsFlag = userHasSubscription && pathname === `/widget/${widgets.goals.id}`;
    const topFlag = userHasSubscription && pathname === `/widget/${widgets.top.id}`;
    const diceFlag = userHasSubscription && pathname === `/widget/${widgets.dice.id}`;

    return (
      <NavbarContainer ref={this.refNav}>
        <LeftContainer>
          <Logo to="/">TipAlerts</Logo>
          {user && (
            <NavigationContainer>
              <LinkContainer active={alertsFlag}>
                { alertsFlag
                  ? <span>Alerts</span>
                  : <NavLinkAlerts to={`/widget/${widgets.tips.id}`}>Alerts</NavLinkAlerts>
                }
              </LinkContainer>
              <LinkContainer active={goalsFlag}>
                { goalsFlag
                  ? <span>Goals</span>
                  : <NavLinkAlerts to={`/widget/${widgets.goals.id}`}>Goals</NavLinkAlerts>
              }
              </LinkContainer>
              <LinkContainer active={topFlag}>
                { topFlag
                  ? <span>Top Tippers</span>
                  : <NavLinkAlerts to={`/widget/${widgets.top.id}`}>Top Tippers</NavLinkAlerts>
              }
              </LinkContainer>
              <LinkContainer active={diceFlag}>
                { diceFlag
                  ? <span>Roll The Dice</span>
                  : <NavLinkAlerts to={`/widget/${widgets.dice.id}`}>Roll The Dice</NavLinkAlerts>
              }
              </LinkContainer>
            </NavigationContainer>)
          }
        </LeftContainer>
        { (special === 'trial' && user !== null)
        && (diffDays !== -1
          ? <Trial>{`Free trial — ${parseTillDateString(till).fromNow(true)} left`}</Trial>
          : <Trial red>Trial has expired</Trial>
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/*
            <img
              style={{ cursor: 'pointer' }}
              src={`/images/lang-${lang || 'en'}.gif`}
              onClick={() => setLanguage((!lang || lang === 'en' ? 'ru' : 'en'))}
            />
          */}
          {/* (alfaVersionAccess) && (
            <NewEditor>
              <NewEditorLink onClick={authUserGoToNewVersion}>
                New Editor
              </NewEditorLink>
            </NewEditor>
          ) */}
          {user !== null && (
            <NewEditor>
              <NewEditorLink onClick={authUserGoToNewVersion}>
                New Editor
              </NewEditorLink>
            </NewEditor>
          )}
          <IconLink
            href="https://twitter.com/real_tipalerts/"
            target="_blank"
            rel="noopener noreferrer"
            title="Follow us on Twitter!"
            onClick={this.hideTwitterModal}
          >
            <Twitter />
          </IconLink>
          <TwitterModal user={user} hide={isHideTwitterModal} visibleLog={special === LOG_SP} />

          {special === LOG_SP && (
            <NavLink to="/logs" style={{ backgroundColor: '#ff000045', paddingLeft: '2px', paddingRight: '2px' }}>Logs</NavLink>
          )}
          <OuterLink
            href="https://help.tipalerts.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help
          </OuterLink>
          {!user ? (
            <NavLink to="/auth">Sign In</NavLink>
          ) : (
            <>
              <NavLink to="/settings">Settings</NavLink>
              <LinkWithClick onClick={authSignOut}>Sign Out</LinkWithClick>
            </>
          )}
        </div>
      </NavbarContainer>
    );
  }
}
