// @flow
/* eslint-disable */
import React, { Component } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  FormItem,
  RadioButton,
  Button,
  InputWithText,
} from 'components/UI';

const Container = styled.div`
  width: 100%;

  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const ButtonCancel = styled(Button)`
  margin-right: 0px;
  font-weight: 600;
  font-size: 16px;
  height: 36px;

  width: 100%;

  background-color: hsla(206, 29%, 36%, 1);
  &:hover {
    background-color: hsla(206, 29%, 46%, 1);
  }
  &:active {
    background-color: hsla(206, 29%, 36%, 1);
  }
`

const ButtonSave = styled.button`
  height: 36px;
  padding: 7px 12px 10px 12px;
  border-radius: 4px;
  margin-right: 4px;

  border: none;
  outline: none;

  color: black;
  font-weight: 600;
  font-size: 16px;

  background-color: ${({ theme }) => theme.orangeLanding};
  &:hover {
    background-color: ${({ theme }) => theme.orangeButtonHover};
  }
  &:active {
    background-color: ${({ theme }) => theme.orangeButtonActive};
  }
  &:focus {
    outline: none;
  }
`;

const ButtonEdit = styled.button`
  background-color: hsla(206, 29%, 36%, 1);

  height: 36px;
  width: 36px;

  border: none;
  outline: none;

  border-radius: 4px;
`;

const ImageEdit = styled.div`
  background-color: ${({ theme }) => theme.white54};

  width: 100%;
  height: 100%;

  mask-image:  url('/images/icons/ic-edit.svg');
  mask-repeat: no-repeat;
  mask-position: center;

  ${ButtonEdit}:hover & {
    background-color: ${({ theme }) => theme.white};
  }
`;

const Col1 = styled.div`
  flex: 1;
  padding-right: 16px;
`;

const Error = styled.span`
  flex: 3;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #FF8888;
`;

const Row = styled.div`
  display: flex;
`;

const RowButton = styled(Row)`
  width: 100%;
`;


const supporterTooltip = t => (
  <Tooltip id="tooltip">
    {t.premiumTooltip}
  </Tooltip>
);

const initialState = {
  from: 0,
  to: 0,
  presetMode: 0,
  edited: false,
  error: '',
};

export class AmountVariation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      from: props.from,
      to: props.to,
      presetMode: props.from !== props.to ? 0 : 1,
    }
  }

  static defaultProps = {
    from: 0,
    to: 0,
  };

  onAmountValueChange = (field: string) => (e: SyntheticEvent) => {
    const value = Number(e.target.value);
    if (Number.isNaN(value)) return;
    this.setState({ [field]: value, error: '' });
  };

  setError = (error: string) => this.setState({ error });

  onSaveChanges = () => {
    const {
      from,
      to,
    } = this.state;
    const {
      from: prevFrom,
      to: prevTo,
      onSave,
    } = this.props;
    this.setState({ edited: false });
    if (from > to) {
      return this.setState({ error: '"to" value should be more than "from" value' });
    }
    onSave({ from, to, prevFrom, prevTo }, this.setError);
  };

  onDeleteVariation = () => {
    const { to, from } = this.props;
    this.setState({
      ...initialState,
      from,
      to,
    });
  };

  render() {
    // if (false) {
    //   return (
    //     <Form inline>
    //       <FormGroup>
    //         <OverlayTrigger placement="bottom" overlay={supporterTooltip(t)}>
    //           <Label bsStyle="warning" style={{ marginLeft: '4px', fontSize: '12px', fontWeight: '200' }}>
    //             {t.amountVariationPremium1}
    //             <Link to="/settings">{t.premiumAccount}</Link>
    //           </Label>
    //         </OverlayTrigger>
    //       </FormGroup>
    //     </Form>
    //   );
    // }

    const {
      from: prevFrom,
      to: prevTo,
    } = this.props;
    const {
      from,
      to,
      error,
      presetMode,
      edited,
    } = this.state;
    const isChanged = edited ||
      prevFrom !== from ||
      prevTo !== to ||
      prevFrom === prevTo && presetMode === 0 ||
      prevFrom !== prevTo && presetMode === 1;
    return (
      <div>
        <FormItem label="Tokens range" themeFont="normal" style={{ marginBottom: '2px' }}>
          <Container>
              <Row>
                <InputWithText
                  value={from}
                  onChange={this.onAmountValueChange('from')}
                  textLabel="From"
                  onBlur={this.onSaveChanges}
                  onFocus={() => this.setState({ edited: true })}
                />
                <InputWithText
                  value={to}
                  onChange={this.onAmountValueChange('to')}
                  textLabel="To"
                  onBlur={this.onSaveChanges}
                  onFocus={() => this.setState({ edited: true })}
                />
              </Row>
            {!isChanged ? (
              <ButtonEdit onClick={() => this.setState({ edited: true })}>
                <ImageEdit />
              </ButtonEdit>
            ) : (
              <RowButton>
                <ButtonSave onClick={this.onSaveChanges}>
                  Save
                </ButtonSave>
                <ButtonCancel
                  onClick={this.onDeleteVariation}
                >
                  Cancel
                </ButtonCancel>
              </RowButton>)}
          </Container>
        </FormItem>
        {error && (
          <Row>
            <Col1 />
            <Error>{error}</Error>
          </Row>)}
      </div>
    );
  }
}
