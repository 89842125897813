import React from 'react';
import { connect } from 'react-redux';

import { CardConnect } from 'components';
import { Service_ID } from 'constants';

import {
  Container,
  ContainerCard,
  Content,
  SetupLink,
  Title,
  QuitButton,
  QuitButtonContainer,
} from './style';

export const ConnectService = connect(state => ({
  currentProfile: state.auth.user.currentProfile,
  profiles: state.auth.user.profiles,
}))(
  class extends React.Component {
    render() {
      const {
        currentProfile,
        profiles,
        history,
      } = this.props;
      const profile = profiles && currentProfile ? profiles[currentProfile] : {};
      const idChaturbate = profile.chaturbateId;
      const idBongacams = profile.bongacamsId;
      const idMyfreecams = profile.myfreecamsId;
      const idStripchat = profile.stripchatId;
      const idCam4 = profile.cam4Id;
      const idCamsoda = profile.camsodaId;
      document.title = 'Service — TipAlerts';
      return (
        <Container>
          <Content>
            <SetupLink>
              Stream setup
            </SetupLink>
            <Title>
              Which services you would like to connect to TipAlerts?
            </Title>
            <ContainerCard>
              <CardConnect
                service={Service_ID.chaturbate}
                id={idChaturbate}
                history={history}
                type="connectService"
              />
              <CardConnect
                service={Service_ID.bongacams}
                id={idBongacams}
                history={history}
                type="connectService"
              />
              <CardConnect
                service={Service_ID.myfreecams}
                id={idMyfreecams}
                history={history}
                type="connectService"
              />
              <CardConnect
                service={Service_ID.stripchat}
                id={idStripchat}
                history={history}
                type="connectService"
              />
              <CardConnect
                service={Service_ID.cam4}
                id={idCam4}
                history={history}
                type="connectService"
              />
              <CardConnect
                service={Service_ID.camsoda}
                id={idCamsoda}
                history={history}
                type="connectService"
              />
              <CardConnect
                service={Service_ID.amateur}
                id={profile.amateurId}
                history={history}
                type="connectService"
              />
              <CardConnect
                service={Service_ID.cams}
                id={profile.camsId}
                history={history}
                type="connectService"
              />
              <CardConnect
                service={Service_ID.manyvids}
                id={profile.manyvidsId}
                history={history}
                type="connectService"
              />
            </ContainerCard>
            <QuitButtonContainer>
              <QuitButton onClick={() => history.push('/widget/tips')}>Quit Setup</QuitButton>
            </QuitButtonContainer>
          </Content>
        </Container>
      );
    }
  },
);
