import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  authGetPaymentLink,
  authPaymentPatreonClear,
  authPaymentFSModalShow,
} from 'store/reducers/auth';
import { Redirect } from 'react-router-dom';

import { PatreonModal, FastSpringModal } from 'components';

import {
  Container,
  Title,
  AboutTitle,
  ButtonContainer,
  ButtonPricing,
  Cat,
} from './style';

import { PaymentsInfo } from '../Price/PaymentsInfo';
import { Footer } from '../Price/Footer';

class ReSubComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waitLink: false,
    };
  }

  onClickPurchase = () => {
    const { uid, authGetPaymentLink } = this.props;
    ga('send', 'event', 'Resub', 'click', 'Premium', { dimension1: uid });//eslint-disable-line
    this.setState({ waitLink: true });
    authGetPaymentLink('premium');
  };

  onClickPayPatreon = () => {
    this.setState({ waitLink: false });
    // required because when user clicked on link
    // it's destroyed by rerender and not opened
    setTimeout(() => {
      const { authPaymentPatreonClear } = this.props;
      authPaymentPatreonClear();
    });
  };

  render() {
    const { waitLink } = this.state;
    const {
      email,
      paymentPatreonURL,
      paymentShowFSModal,
      paymentFSFailed,
      authPaymentFSModalShow,
      t,
    } = this.props;
    document.title = 'Your subscription has expired — TipAlerts';
    return (
      <Container>
        <Cat />
        <Title>Your subscription has expired</Title>
        <AboutTitle>
          To continue using TipAlerts you need to renew your
          subscription or change it. Learn more about subscriptions
          at our Pricing page.
        </AboutTitle>

        <ButtonContainer>
          {!waitLink && <ButtonPricing to="/price">View Pricing</ButtonPricing>}
        </ButtonContainer>
        <PaymentsInfo />
        <Footer />

        <PatreonModal
          t={t}
          paymentPatreonURL={paymentPatreonURL}
          email={email}
          onPayClick={this.onClickPayPatreon}
        />
        <FastSpringModal
          show={paymentShowFSModal}
          failed={paymentFSFailed}
          onHide={() => authPaymentFSModalShow(false)}
        />
      </Container>
    );
  }
}

export const ReSub = connect(({ auth, lang: { t } }) => ({
  email: auth.user.email,
  uid: auth.user.uid,
  supporter: auth.supporter,
  paymentPatreonURL: auth.paymentPatreonURL,
  paymentShowFSModal: auth.paymentShowFSModal,
  paymentFSFailed: auth.paymentFSFailed,
  t,
}), d => bindActionCreators({
  authGetPaymentLink,
  authPaymentPatreonClear,
  authPaymentFSModalShow,
}, d))(props => ((props.supporter && !props.supporter.firstTime) ? <ReSubComponent {...props} /> : <Redirect to="widget" />));
