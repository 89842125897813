// @flow
/* eslint-disable */
import React, { Component } from 'react';

import { connect } from 'react-redux';

import { TabsContainer } from 'components/UI';
import { TopDataDefault } from 'constants';

import type { TopData } from 'types/Widget';

import {
  AppearanceTab,
  TitleTab,
} from './Tabs';

type Props = {
  topData: TopData,
};

export default connect(
  state => ({
    topData: state.widgets.top || {},
  })
)(class Options extends Component<Props, State> {
  static defaultProps = {
    topData: {},
  };

  render() {
    const {
      topData,
    } = this.props;
    const tabHOC = (Tab, props) => <Tab {...props} selectedPreset="default" />;
    const defaultVariation = topData.default || {};
    return (
      <TabsContainer
        content={[
          tabHOC(AppearanceTab, {
            data: {
              ...TopDataDefault.appearance,
              ...(defaultVariation.appearance || {}),
            },
          }),
          tabHOC(TitleTab, {
            data: {
              ...TopDataDefault.title,
              ...(defaultVariation.title || {}),
            },
          }),
        ]}
      />
    );
  }
});
