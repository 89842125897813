import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Spinner from 'react-spinkit';


import {
  CustomButton,
  CustomTextInput,
  ContainerDate,
  ButtonLogContainer,
} from './UI';

export class LogButtons extends PureComponent {
  byDateClick = () => {
    const localDateHistory = moment.utc(this.props.startDate).format('YYYY-MM-DD HH:mm:ss');
    const dateHistory = moment.parseZone(`${localDateHistory}-02:00`).utc().format('YYYY-MM-DD HH:mm:ss');
    this.props.onFindByDateClick(dateHistory);
  };

  render() {
    const {
      uid,
      onChangeUID,
      logsClear,
      status,
      onFindClick,
      onFindClickUp,
      onFindClickDown,
      onDateChange,
    } = this.props;
    return (
      <ButtonLogContainer>
        <CustomTextInput value={uid} onChange={onChangeUID} placeholder="User's UID" />
        <CustomButton onClick={onFindClick}>Загрузить</CustomButton>
        <CustomButton onClick={onFindClickUp}>Сверху</CustomButton>
        <CustomButton onClick={onFindClickDown}>Снизу</CustomButton>
        <CustomButton onClick={logsClear}>Очистить</CustomButton>
        <ContainerDate>
          <DatePicker
            selected={uid && this.props.startDate}
            onChange={onDateChange}
            showTimeSelect
            maxDate={new Date()}
            timeFormat="HH:mm"
            dateFormat="HH:mm:ss dd.MM.yyyy"
            disabled={!uid}
            isClearable="true"
          />
          <CustomButton onClick={this.byDateClick}>По дате</CustomButton>
        </ContainerDate>
        {status === 'loading' && <Spinner name="wave" color="white" style={{ marginTop: '3px', marginLeft: '7px' }} />}
      </ButtonLogContainer>
    );
  }
}
