import styled from 'styled-components';

export const Tooltip = styled.div`
display: flex;
align-items: center;
justify-content: center;

width: max-content;
max-width: 280px;
visibility: hidden;
opacity: 0;
position: absolute;
bottom: calc(100% + 7px);
left: 50%;
transform: translateX(-50%);

min-height: 24px;

border-radius: 4px;
padding: 2px 8px;

color: #FFFFFF;
background: #11171B;

transition: opacity 0.3s ease-in-out;

cursor: auto;
pointer-events: none;

  font-size: 14px;
  text-align: left;
  line-height: 20px;

&:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #11171B transparent transparent transparent;
}

button:hover & {
  visibility: visible;
  opacity: 1;
}

z-index: 2;
`;
