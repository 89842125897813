import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export const FastSpringModal = ({ show, onHide, failed }) => (
  <Modal
    show={show}
    onHide={onHide}
  >
    <Modal.Header closeButton>
      <Modal.Title>
        {failed ? 'Payment' : 'Thank you for payment!'}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {failed && (
        <p>
          Your payment not finished.
          <br />
          Have any issues during purchase?
          <br />
          Please contact us by e-mail&nbsp;
          <a href="mailto:support@tipalerts.com">support@tipalerts.com</a>
          &nbsp;
          or support chat
        </p>)
        }
      {failed && (
        <p>
          Also you can purchase subscription via Patreon:&nbsp;
          <a href="https://patreon.com/join/tipalerts" target="_blank" rel="noopener noreferrer">patreon.com/tipalerts</a>
        </p>
      )}
      {!failed && <p>Your payment will be processed in a few minutes and then your account will be activated.</p>}
      {!failed && (
      <div>
        If you have any troubles please contact our support&nbsp;
        <a href="mailto:support@tipalerts.com">support@tipalerts.com</a>
      </div>)}
    </Modal.Body>
    <Modal.Footer>
      <Button bsStyle="primary" onClick={onHide}>Got it</Button>
    </Modal.Footer>
  </Modal>
);
