import { randInt } from 'utils';

export const widgetColors = [
  'random',
  '#ffffff',
  '#000000',
  '#ff8171',
  '#ff4023',
  '#ff62bb',
  '#ff35ab',
  '#ff90b7',
  '#cf57ff',
  '#bd3eff',
  '#ff5342',
  '#f292ff',
  '#FBA728',
  '#cfff81',
  '#6eff42',
  '#50ffba',
  '#3fafff',
  '#5c26ff',
];

function ColorRGBA(color, number) {
  const ind = number / 4;
  this.r = parseInt(color.slice(0, ind), 16);
  this.g = parseInt(color.slice(ind, ind * 2), 16);
  this.b = parseInt(color.slice(ind * 2, ind * 3), 16);
  this.a = parseInt(color.slice(ind * 3), 16) / 255;
  this.color = `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
}

ColorRGBA.prototype.toString = function () {
  return this.color;
};

export const hexaToRgba = (_color) => {
  if (typeof _color !== 'string' || _color[0] !== '#' || (_color.length - 1 !== 4 && _color.length - 1 !== 8)) return _color;
  const color = _color.slice(1);
  return new ColorRGBA(color, color.length);
};

export const getColor = (color) => {
  if (color === 'random' || color === 0 || typeof color === 'undefined') {
    return widgetColors[randInt(0, widgetColors.length + 1)];
  }
  if (typeof color === 'number') {
    return widgetColors[color];
  }
  if (typeof color === 'object' && color.hexa) {
    return hexaToRgba(color.hexa);
  }
  return hexaToRgba(color);
};
