import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  padding: 0 10px;
  background: #2E3F4C;
  border-radius: 4px;
  border: 1px solid transparent;
  outline: none;
  height: 36px;

  font-size: 14px;
  line-height: 20px;
  color: #FFF;

  &:placeholder {
    color: rgba(255, 255, 255, 0.34);
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.orangeLanding};;
  }

  &:hover {
    border-color: ${({ theme }) => theme.orangeButtonHover};
  }
  &:active {
    border-color:  ${({ theme }) => theme.orangeButtonActive};
  }
`;

export const TextInput2 = ({ innerRef, ...props }) => <Input type="text" {...props} ref={innerRef} />;
