import React from 'react';
import { connect } from 'react-redux';

import './Terms.css';

export const Terms = connect(({ lang: { t } }) => ({ t }))(({ t }) => (
  <div className="Terms">
    <h3>{t.termsOfUse}</h3>
    <div><a href="/Terms.pdf" target="_blank">{t.termsRu}</a></div>
    <div><a href="/Terms_en.pdf" target="_blank">{t.termsEn}</a></div>
    <div><a href="/Privacy_policy.pdf" target="_blank">Privacy policy</a></div>
  </div>
));
