import React from 'react';
import styled from 'styled-components';


import {
  Section,
  FormItem,
} from 'components/UI';

import {
  InputArea,
  Description,
  ExtraWord,
} from './style';


const Container = styled.div`

`;

export default class InputAreaBlock extends React.Component {
  render() {
    const {
      data,
      widgetUpdate,
      previewOnly,
    } = this.props;
    const messageText = previewOnly ? 'Message text (preview only)' : 'Message text';
    return (
      <Container>
        <Section>
          <FormItem label={messageText} themeFont="header" header />
          <InputArea value={data.customMessage} onChange={({ target: { value } }) => widgetUpdate({ customMessage: value })} />
          <Description>
            Use
            <ExtraWord>&nbsp;$NICK&nbsp;</ExtraWord>
            and
            <ExtraWord>&nbsp;$AMOUNT&nbsp;</ExtraWord>
            $keywords to insert tipper name and tip amount into the
            message.
          </Description>
        </Section>
      </Container>
    );
  }
}
