import React, { Component } from 'react';
import patreonImg from 'assets/images/Patreon.svg';
import youtubeImg from 'assets/images/Youtube.svg';

import {
  Container,
  ContainerContent,
  MainLink,
  InnerLink,
  OuterLink,
  TextContainer,
  OuterContainerLink,
  Logo,
  Content,
} from './style';

export class Footer extends Component {
  render() {
    return (
      <Container>
        <ContainerContent>
          <Content>
            <MainLink>TipAlerts</MainLink>
            <OuterLink href="http://help.tipalerts.com">Help</OuterLink>
            <InnerLink to="/settings">Settings</InnerLink>
          </Content>
          <Content>
            <OuterLink href="mailto:support@tipalerts.com">support@tipalerts.com</OuterLink>
            <OuterContainerLink
              href="https://www.youtube.com/tipalerts"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Logo
                src={youtubeImg}
                alt="youtube.svg"
              />
              YouTube
            </OuterContainerLink>
            <OuterContainerLink
              href="https://www.patreon.com/tipalerts"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Logo
                src={patreonImg}
                alt="patreon.svg"
              />
              Patreon
            </OuterContainerLink>
          </Content>
        </ContainerContent>
        <TextContainer>
          Copyright ©
          &nbsp;
          {new Date().getFullYear()}
          &nbsp;
          TipAlerts
        </TextContainer>
      </Container>
    );
  }
}
