import styled, { css } from 'styled-components';

export const BlockTypes = {
  default: 'default',
  warning: 'warning',
  info: 'info',
};

const Container = styled.div`
  ${({ theme, type = BlockTypes.default }) => type === BlockTypes.default && css`
      /* box-shadow: 0 1px 2px 0 ${theme.black05};
      border: solid 1px ${theme.dark}; */
  `}
  padding: 15px;
  border-radius: 4px;
  margin: 20px 0;
  ${({ theme, type = BlockTypes.default }) => {
    const backgroundColor = {
      [BlockTypes.warning]: theme.pastelOrange,
      [BlockTypes.default]: theme.slate,
      [BlockTypes.info]: theme.lightPeach,
    };
    const color = {
      [BlockTypes.warning]: theme.black,
      [BlockTypes.info]: theme.black,
      [BlockTypes.default]: theme.white,
    };
    return css`
    background-color: ${backgroundColor[type]};
    color: ${color[type]};
  `;
  }
}
${({ theme, type = BlockTypes.default }) => {
    const font = {
      [BlockTypes.warning]: theme.font.accent,
      [BlockTypes.default]: theme.font.usual,
    };
    return font[type];
  }
}`;

export const Block = Container;
