import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.slateTwo};
  /* border: 1px solid ${({ theme }) => theme.darkGreyBlue}; */
  border: none;
  padding: 13px 10px;
  height: 34px;
  ${({ theme }) => theme.font.usual};
  font-weight: normal;
  &:focus {
    outline-color: #03a8fd;
    outline-width: 1px;
  }
`;

export const TextInput = ({ innerRef, ...props }) => <Input type="text" {...props} ref={innerRef} />;
