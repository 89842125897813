// @flow
// import { defaultFont } from './fonts';

export type RaceDefaultPreset = {
  basePrice: number;
  tokensAtRace: number;
  playersNumber: number;
  startLevel: number;
  timeToDestroy: number; // s
};

export type RaceDefaultTrack = {
  skin: string;
};

export type RaceDefaultActive = {
  [name: string]: {
    amount: number;
    amountAtLevel: number;
    levelPrice: number;
    level: number;
    name: string;
    startedAt: number; // ms
    expireAt: number; // ms
  };
};

export type RaceVariation = {
  active: RaceDefaultActive | null;
  preset: RaceDefaultPreset;
  appearance: RaceDefaultAppearance;
}

/** this is backend default data
 *  (front and back need the same 'active' and 'preset' data) */
// active: {},
// preset: {
//   basePrice: 100,
//   tokensAtRace: 500,
//   playersNumber: 3,
//   startLevel: 1,
//   maxGrowLevel: 35,
//   timeToDestroy: 300, // s
//   showFPS: false,
//   startOverBasePrice: false,
// }

export const raceDataDefault: RaceVariation = {
  preset: {
    basePrice: 100,
    tokensAtRace: 500,
    playersNumber: 3,
    startLevel: 1,
    maxGrowLevel: 35,
    timeToDestroy: 300, // s
    showFPS: false,
    startOverBasePrice: false,
    showTimer: false,
  },
  appearance: {
    color: 1,
    fontFamily: 1,
    fontWeight: 400,
    fontWeightBold: true,
    fontStyle: 'normal', // normal | italic
    textDecorationLineThrough: false,
    textDecorationUnderline: false,
    textTransform: 'none', // none | capitalize | uppercase | lowercase | full-width | full-size-kana | inherit | initial | unset
    // textAlign: 'left',

    // lineHeight: 100, // percentage

    backgroundColor: '#158672ff',
    backgroundEnabled: true,

    textShadowEnabled: true,
    textShadowX: 1,
    textShadowY: 1,
    textShadowBlur: 4,
    textShadowColor: 1,
  },
  active: {},
  inactive: {},
};
