// @flow
/* eslint-disable */
import React from 'react';
import styled, { css } from 'styled-components';
import uuid from 'uuid/v4';

import {
  Button,
  DropDown,
} from 'components/UI';
import ReactDOM from 'react-dom';
import { widgetFontWeightsScheme } from 'constants';

const Container = styled.div`
  width: 160px;
  height: 36px;
  position: relative;
`;

const Tooltip = styled.div`
  ${({ show }) => (show ? 'opacity: 1; visibility: visible;' : 'opacity: 0; visibility: hidden;')}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  bottom: 125%;
  left: 40%;
  transform: translateX(-50%);

  cursor: auto;

  width: 360px;
  height: 300px;
  background: #192933;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 20px;
  transition: opacity 0.2s ease-in-out;

  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) => theme.darkTwo} transparent transparent transparent;
  }
  z-index: 1000;
`;

const AddAction = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0px 16px 0px 10px;
  margin: 0px;
  background-color: #3B586D;
`;

const AddActionTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4px;
`;

const AddActionText = styled.div`
  width: auto;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-right: 15px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ active, theme }) => active && theme.orangeButtonActive || '#FFB65A'};
  border-bottom: ${({ active, theme }) => active && theme.orangeButtonActive || '#FFB65A'} dashed 1px;

  &:hover{
    color: ${({ theme }) => theme.orangeButtonHover};
    border-bottom-color: ${({ theme }) => theme.orangeButtonHover};
  }
  &:active{
    color: ${({ theme }) => theme.orangeButtonActive};
    border-bottom-color: ${({ theme }) => theme.orangeButtonActive};
  }
`;

const AddActionCross = styled.div`
  position: relative;
  
  width: 14px;
  height: 14px;
  opacity: 0.34;
  
  cursor: pointer;
  
  transition: opacity 0.2s ease-in-out;
  
  &:before, &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: '';
    height: 20px;
    width: 2px;
    background-color: #fff;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  
  &:hover {
    opacity: 0.6;
  }
  
  &:active {
    opacity: 0.2;
  }
 
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end
  width: 100%;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  text-align: left;
  margin-bottom: 4px;
`;


const TooltipButton = styled.button`
  outline: none;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  height: 36px;

  font-weight: 600;
  font-size: 16px;
  line-height: 36px;

  margin-left: 6px;
  padding: 0 16px;
  border-radius: 4px;
  
  cursor: ${({ isLoading, disabled }) => (isLoading || disabled ? 'auto' : 'pointer')};

  ${({ orange, isLoading, theme }) => (orange
    ? css`
      background-color:  ${theme.orangeLanding};
      color: ${theme.black};
      &:hover{
        background-color:  ${isLoading ? theme.orangeLanding : theme.orangeButtonHover};
      }
      &:active{
        background-color:  ${isLoading ? theme.orangeLanding : theme.orangeButtonActive};
      }`
    : css`
      background-color: ${theme.white10};
      color: ${theme.white};
      &:hover {
        background-color: ${isLoading ? theme.white10 : theme.white10};
      }
      &:active {
        background-color:  ${isLoading ? theme.black05 : theme.black05};
      }`
  )};
  
  
  ${({ disabled, theme }) => (disabled
    ? `background-color: ${theme.disabled};
      &:hover {
          background-color: ${theme.disabled};
      }
      &:active {
        background-color:  ${theme.disabled};
      }`
    : ''
  )}
`;


const RadioRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  margin: 8px 0;
`;

const RadioMain = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ checked }) => (checked ? 'auto' : 'pointer')};
`;

const RadioPicker = styled.div`
  width: 240px;
  margin-left: 16px;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 8px;

  width: 12px;
  height: 12px;
  border-radius: 50%;

  background-color: ${({ checked, theme }) => (checked ? theme.orangeLanding : '#0D1215')};

  transition: 0.3s ease-in-out;
  transition-property: opacity, background-color;

  &:after {
    content: '';
    opacity: ${({ checked }) => (checked ? 1 : 0)};

    width: 6px;
    height: 6px;
    border-radius: 50%;

    background-color: #111;
  }
`;

const Label = styled.div`
  ${({ theme }) => theme.font.normal};
  transition: color 0.3s ease-in-out;
  color: ${({ checked, theme }) => (checked ? theme.orangeLanding : theme.white)};
  white-space: no-wrap;
`;

type Props = {
  data: {
    x: number,
    y: number,
    blur: number,

    borderWidth: number,
    borderBlurShadow: number,
  },
  onChange: (any) => void,
}

const actionVariations = [
  {
    title: 'Reset',
    id: 'resetGoal',
  },
  {
    title: 'Hide',
    id: 'hideGoal',
  },
  {
    title: 'Show',
    id: 'showGoal',
  },
];

const actionsTexts = {
  resetGoal: 'Reset ',
  hideGoal: 'Hide ',
  showGoal: 'Show ',
};

// type Action = {
//   action: 'hideGoal' | 'showGoal' | 'resetGoal',
//   event: 'goalReached',
//   id: string,
//   target: string,
// };

export default class Add extends React.Component<Props> {
  constructor(props) {
    super(props);

    const slicedPresets = { ...props.presets };
    delete slicedPresets[props.selectedPreset];
    const slicedPresetsArr = Object.keys(slicedPresets).sort((a, b) => Number(props.presets[a].goalValue) - Number(props.presets[b].goalValue));

    const { action } = props;
    let chosenAction = 0;
    const chosenActionToState = {};
    if (action) {
      chosenAction = actionVariations.findIndex(({ id }) => id === action.action);
      const { id } = actionVariations[chosenAction];
      chosenActionToState[id] = action.target;
    }
    this.state = {
      isModalOpen: false,
      chosenAction: chosenAction >= 0 ? chosenAction : 0,
      resetGoal: props.selectedPreset, // goal key
      hideGoal: props.selectedPreset, // goal key
      showGoal: slicedPresetsArr[0], // goal key
      ...chosenActionToState,
    };
  }

  modal = React.createRef();

  showModal = () => {
    if (!this.state.isModalOpen) {
      this.setState({ isModalOpen: true }, () => document.addEventListener('click', this.onClick));
    }
  };

  onClick = (e) => {
    const modalNode = ReactDOM.findDOMNode(this.modal.current);
    const isOutClick = !(modalNode && modalNode.contains(e.target));
    if (isOutClick) this.hideModal(e);
  };

  hideModal = (e) => {
    if (this.state.isModalOpen) {
      this.setState({ isModalOpen: false }, () => document.removeEventListener('click', this.onClick));
    }
  };

  onCheck = (index) => {
    const { chosenAction } = this.state;
    if (chosenAction !== index) this.setState({ chosenAction: index });
  };

  onSelect = (id, selected) => {
    this.setState({ [id]: selected });
  };

  onSubmit = (e) => {
    const { onSubmit, action } = this.props;
    const { chosenAction } = this.state;
    const { id } = actionVariations[chosenAction] || {};
    onSubmit({ action: id, event: 'goalReached', id: (action && action.id) || uuid(), target: this.state[id] });
    this.hideModal(e);
  };

  removeAction = () => {
    const { action, onRemove } = this.props;
    onRemove(action.id);
  };

  render() {
    const {
      presets,
      selectedPreset,
      action,
    } = this.props;

    const slicedPresets = { ...presets };
    delete slicedPresets[selectedPreset];
    const slicedPresetsArr = Object.keys(slicedPresets).sort((a, b) => Number(presets[a].goalValue) - Number(presets[b].goalValue));

    const presetsArr = [selectedPreset, ...slicedPresetsArr];
    const { isModalOpen, chosenAction } = this.state;

    /* условие на название goal в actions 'this goal' */

    /* const actionDefault = action || {}; */
    let nameGoal;
    if (action && selectedPreset === action.target) {
      nameGoal = 'this goal';
    } else if (action && selectedPreset !== action.target) {
      nameGoal = `goal ${presets[action.target] && presets[action.target].goalValue}`
    }

    return (
      <Container>
        {action ? (
          <AddActionTextContainer>
            <AddActionText active={isModalOpen} onClick={this.showModal}>{`${actionsTexts[action.action]} ${nameGoal}`}</AddActionText>
            <AddActionCross onClick={this.removeAction} />
          </AddActionTextContainer>
        ) : <AddAction active={isModalOpen} onClick={this.showModal}>Add Action...</AddAction>}
        <Tooltip show={isModalOpen} ref={this.modal}>
          <Title>Add action upon reaching the goal</Title>
          <Content>
            {actionVariations.map(({ title, id }, i) => {
              const isChecked = chosenAction === i;
              if (id === 'showGoal' && !slicedPresetsArr.length) return null;
              return (
                <RadioRow key={id}>
                  <RadioMain checked={isChecked} onClick={() => this.onCheck(i)}>
                    <Circle checked={isChecked} />
                    <Label>{title}</Label>
                  </RadioMain>
                  <RadioPicker>
                    <DropDown
                      backgroundColor="#27353F"
                      width="100%"
                      items={id === 'showGoal' ? slicedPresetsArr : presetsArr}
                      itemsScheme={{ ...presets, [selectedPreset]: { goalValue: 'This goal' }}}
                      titleKey="goalValue"
                      value={this.state[id]}
                      onSelect={selected => this.onSelect(id, selected)}
                    />
                  </RadioPicker>
                </RadioRow>
              );
            })}
          </Content>
          <ButtonsContainer>
            <TooltipButton onClick={this.hideModal}>Cancel</TooltipButton>
            <TooltipButton onClick={this.onSubmit} orange>{action ? 'Done' : 'Add'}</TooltipButton>
          </ButtonsContainer>
        </Tooltip>
      </Container>
    );
  }
}
