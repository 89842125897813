import React from 'react';
import styled from 'styled-components';
import { getAnimation, getColor, durationLowToHigh } from 'utils';
import { TopDataDefault, widgetFonts } from 'constants';

import '../animatedElements.css';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transform-origin: top;
  width: ${({ width }) => width}px;
  margin-top: ${({ preview }) => (preview ? 16 : 0)}px;
`;

const getTextDecoration = (lineThrough, underline) => {
  let str = '';
  if (lineThrough) {
    str += 'line-through';
    if (underline) {
      str += ' ';
    }
  }
  if (underline) {
    str += 'underline';
  }
  return str;
};

const shadow = data => `${data.textShadowX}px ${data.textShadowY}px ${data.textShadowBlur}px ${getColor(data.textShadowColor)}`;

const setStyle = ({ customStyle }) => (
  `
    margin-bottom: ${customStyle.marginBottom}px;
    color: ${getColor(customStyle.color)};
    font-family: ${widgetFonts[customStyle.fontFamily]};
    font-size: ${customStyle.fontSize}px;
    background-color: ${customStyle.backgroundEnabled ? getColor(customStyle.backgroundColor) : 'transparent'};
    line-height: ${customStyle.lineHeight}px;
    min-height: ${customStyle.lineHeight}px;
    border-radius: ${customStyle.borderRadius}px;
    text-align: ${customStyle.textAlign};
    text-shadow: ${customStyle.textShadowEnabled && shadow(customStyle)};
    font-weight: ${customStyle.fontWeightBold ? 'bold' : customStyle.fontWeight};
    font-style: ${customStyle.fontStyle};
    text-decoration: ${getTextDecoration(customStyle.textDecorationLineThrough, customStyle.textDecorationUnderline)};
    text-transform: ${customStyle.textTransform};
    `
);

const Title = styled.div`
  white-space: pre-wrap;
  width: content;
  overflow-wrap: break-word;
  ${setStyle}
  line-height: 1.35em;
  padding: 0.1em 0.216em;
  border-radius: 0.216em;
  height: content;
`;

const Tipper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  ${setStyle}
`;

const Name = styled.div`
  padding: 0.1em 0.432em;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  align-self: center;
`;

const Amount = styled.div`
  padding: 0.1em 0.432em 0.1em 0.216em;
  text-align: right;
`;

const dummyList = [
  { name: 'first tipper', amount: 1000 },
  { name: 'second tipper', amount: 670 },
  { name: 'third tipper', amount: 356 },
  { name: 'fourth tipper', amount: 234 },
  { name: 'fifth tipper', amount: 100 },
  { name: 'sixth tipper', amount: 99 },
];

let lastAmount = 99;

const defaultList = new Array(99)
  .fill(null)
  .map((_, i) => dummyList[i] || { name: `${i + 1}th tipper`, amount: lastAmount -= 1 });

export class RenderTopTippers extends React.Component {
  static defaultProps = {
    topList: [],
    config: {},
  };

  render() {
    const {
      config,
      topList,
      isPreview,
      premium,
    } = this.props;
    if (!isPreview && !premium) return null;

    const { appearance, title } = {
      appearance: {
        ...TopDataDefault.appearance,
        ...(config.appearance || {}),
      },
      title: {
        ...TopDataDefault.title,
        ...(config.title || {}),
      },
    };
    const tipperLH = Math.round(appearance.fontSize * 1.35);
    const titleLH = title.fontSize * (title.lineHeight / 100);

    const titleCustomStyle = {
      ...title,
      marginBottom: appearance.marginBottom,
      borderRadius: Math.round(titleLH * 0.16),
      lineHeight: titleLH,
    };
    const tipperCustomStyle = {
      ...appearance,
      lineHeight: tipperLH,
      borderRadius: Math.round(tipperLH * 0.16),
    };

    let limited = topList || [];
    if (isPreview && !limited.length) limited = defaultList;
    limited = limited.slice(0, appearance.limit);

    return (
      <Container>
        <Content preview={isPreview} width={appearance.widthList}>
          {(title.customMessage !== '') && (
            <Title
              className={titleCustomStyle.animationEnabled ? `RenderTips_Message--animated_${getAnimation(title.animation)}` : ''}
              style={{ animationDuration: `${durationLowToHigh(titleCustomStyle.animationDuration)}s` }}
              customStyle={titleCustomStyle}
            >
              {`${title.customMessage}`}
            </Title>
          )}
          {limited.map(({ name, amount }, i) => (
            <Tipper
              customStyle={tipperCustomStyle}
              offsetPreview={isPreview && i === 0}
              key={name}
            >
              <Name>{decodeURIComponent(name)}</Name>
              <Amount>{amount}</Amount>
            </Tipper>
          ))}
        </Content>
      </Container>
    );
  }
}
