import React, { Component } from 'react';
import styled from 'styled-components';

import { TableStroke } from './TableStroke';

const Container = styled.div`
    border-radius: 6px;
    overflow: hidden;
    margin: 35px 0px;
`;

export class Table extends Component<Props> {
  render() {
    return (
      <Container>
        <TableStroke headline feature="Feature" basic="Basic" premium="Premium" />
        <TableStroke
          colorStroke
          title="Alerts widget"
          feature="Show tiper’s name, tokens amount, image when someone tips  tokens to you."
        />
        <TableStroke
          title="Alert presets"
          feature="Customize alerts appearance for different tip amounts."
          basic="No"
          premium="Unlimited"
        />
        <TableStroke
          colorStroke
          title="Upload your images"
          feature="Upload custom images for alerts and goals. JPEG, GIF, PNG formats are supported."
        />
        <TableStroke
          title="Alerts sounds"
          feature="Play sound when aleart appears. You can upload your own sounds or choose from TipAlerts library."
          basic="No"
        />
        <TableStroke
          colorStroke
          title="Ability to add personalized message to alerts"
          basic="No"
        />
        <TableStroke
          title="Ability to hide tipper’s name and the amount of tokens"
          basic="No"
        />
        <TableStroke
          colorStroke
          title="Goals widget"
          feature="Show progress bar that fills as viewers tip tokens to you."
          basic="No"
        />
        <TableStroke
          title="Top tippers widget"
          feature="Show nicknames and amount of tokens for the best tippers"
          basic="No"
        />
        <TableStroke
          title="Roll the Dice widget"
          feature="Add Roll the Dice game for your viewers and fans"
          basic="No"
        />
        <TableStroke
          colorStroke
          title="Priority in support"
          basic="No"
        />
      </Container>
    );
  }
}
