// @flow

import type { GoalConfiguration } from 'types/Widget';
import { defaultFont } from 'constants/fonts';

// import { defaultFont } from './fonts';

// type Action = {
//   action: 'hideGoal' | 'showGoal' | 'resetGoal',
//   event: 'goalReached',
//   id: string,
//   target: string,
// };

export const GoalsDefaultData: GoalConfiguration = {
  preset: {
    enabled: true,
    goalValue: 100,
    value: 0,
    transformScale: 1,
    transformVerticalPosition: 0, // percentage
    transformRotate: 0,
    actions: {},
  },
  title: {
    customMessage: '',

    color: 0,
    fontFamily: defaultFont,
    fontSize: 16,
    fontWeight: 600,
    fontWeightBold: false,
    fontStyle: 'normal', // normal | italic
    textDecorationLineThrough: false,
    textDecorationUnderline: false,
    textTransform: 'none', // none | capitalize | uppercase | lowercase | full-width | full-size-kana | inherit | initial | unset

    lineHeight: 100, // percentage
    textAlign: 'center',

    animationEnabled: false,
    animation: 0,
    animationDuration: 2,

    backgroundColor: 1,
    backgroundEnabled: false,

    transformRotate: 0,

    textShadowEnabled: true,
    textShadowX: 0,
    textShadowY: 0,
    textShadowBlur: 0,
    textShadowColor: 0,
  },
  progressBar: {
    hideFill: false,
    hideValueTokens: false,
    hideReceivedToken: false,
    hideCurrentPercent: false,

    color: 0,
    fontFamily: defaultFont,
    fontSize: 16,
    fontWeight: 600,
    fontWeightBold: false,
    fontStyle: 'normal', // normal | italic
    textDecorationLineThrough: false,
    textDecorationUnderline: false,
    textTransform: 'none', // none | capitalize | uppercase | lowercase | full-width | full-size-kana | inherit | initial | unset

    textBackgroundColor: 1,
    textBackgroundEnabled: false,

    textShadowEnabled: true,
    textShadowX: 0,
    textShadowY: 0,
    textShadowBlur: 0,
    textShadowColor: 0,

    textTransformRotate: 0,


    barBorderRadius: 0,
    barBorderWidth: 0,
    barBorderBlur: 0,
    barBorderColor: 3,

    barBackgroundColor: 1,
    barForegroundColor: 2,
  },
  image: {
    enabled: false, /* 0 - 9999: true, -1: false */
    opacity: 1,
    image: 0,

    animationEnabled: false,
    animation: 0,
    animationDuration: 2,

    imagePosition: 0,
  },
};
