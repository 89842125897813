import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import { theme } from 'constants/theme';
import { Circle } from '../PriceComponent';

const ContainerLine = styled.div`
  display: flex;
  flex-direction: row;
  background: ${({ colorStroke }) => (colorStroke ? '#2B4152' : '#283E4E')};
  width: 100%;
`;

const TitleTextStyle = css`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding: 7px 24px ;
  color: white;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  padding: 3px 0px;
  color: white;
`;

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  width: 555px;
  text-align: left;
  padding: 14px 0px 14px 24px;
  color: ${theme.main.white60};
  ${({ headline }) => (headline && TitleTextStyle)};
`;

const Basic = styled.div`
  display: flex;
  width: 275px;
  justify-content: center;
  align-items: center;
  color: ${({ headline }) => (headline ? '#5AFAF0' : '#ffffff80')};
  ${({ headline }) => (headline && TitleTextStyle)};

`;

const Premium = styled(Basic)`
  color: ${({ headline }) => (headline ? '#FFAD5B' : '#ffffff')};
`;

type Props = {
    title: string,
    feature: string,
    basic: string,
    premium: string,
    headline: boolean,
    colorStroke: boolean,
  };

export class TableStroke extends Component<Props> {
  render() {
    const {
      title,
      feature,
      basic,
      premium,
      headline = false,
      colorStroke = false,
    } = this.props;
    return (
      <ContainerLine colorStroke={colorStroke}>
        <Feature headline={headline}>
          {title && (<Title>{title}</Title>)}
          {feature}
        </Feature>
        <Basic headline={headline}>
          {typeof basic === 'string'
            ? basic
            : <Circle colorСircle="#B6FF1C" colorCheck="#2B4152" />}
        </Basic>
        <Premium headline={headline}>
          {typeof premium === 'string'
            ? premium
            : <Circle colorСircle="#B6FF1C" colorCheck="#2B4152" />}
        </Premium>
      </ContainerLine>
    );
  }
}
