import styled from 'styled-components';

/* import { theme } from 'constants/theme'; */
import Constants from '../const/const';

export const AlertsContentBackground = styled.div`
  display: flex;
  justify-content: center;

  background: radial-gradient(
    200px at calc(50% - (${Constants.WidthContent} / 2 - 200px)) 100%,
    rgba(131, 154, 213, 0.45) 0%,
    rgba(31, 43, 54, 0) 100%
  );
`;

export const AlertsContent = styled.div`
  width: ${Constants.WidthContent};
  display: flex;
  flex-flow: row wrap;

  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.06);
`;

export const ImageContainer = styled.div`
  flex: 1;
  
  display: flex;
  justify-content: center;
  align-items: flex-end;  
`;

export const ImageAlerts = styled.div`
  width: 523.58px;
  height: 351.93px;

  background-image: url('/images/alerts-img.png');
`;

export const TextContainer = styled.div`
  flex: 1;

  padding-top: 108px;
  padding-bottom: 162px;

  font-weight: 900;
  line-height: 48px;
  font-size: 36px;
  > p {
    font-weight: 500;
    line-height: 28px;
    font-size: 20px;
  }
`;
