/* eslint-disable */
import React, { Component } from 'react';
import styled, { css } from 'styled-components';


const Container = styled.div`
  position: relative;
  margin-right: ${({ marginRight}) => marginRight};
  display: flex;
  height: 100%;
`

const Button = styled.button`
  display: flex;
  align-items: center;

background-color: ${({ active }) => active ? 'hsla(206, 29%, 41%, 1)' : 'hsla(206, 29%, 36%, 1)'};
  &:hover {
    background-color: hsla(206, 29%, 46%, 1);
  }

  &:active {
    background-color: hsla(206, 29%, 41%, 1);
  }

  border-radius: 4px;
  padding: ${({ icon }) => icon ? '6px 12px 6px 10px': '6px 12px'};

  outline: none;
  border: none;

  width: 100%;
`;


const Image = styled.div`
  background-color: ${({ theme }) => theme.white};

  width: 24px;
  height: 24px;

  mask-image:  url('/images/icons/ic-${({ icon }) => icon}.svg');
  mask-repeat: no-repeat;
  mask-position: center;

  margin-right: 6px;
`;

const Text = styled.span`
  ${({ theme }) => theme.font.accent};

  width: 100%;
  text-align: center;
`;

export default class ButtonIconText extends Component {
  render() {
    const {
      icon,
      text,
      onClick,
      children,
      active,
      marginRight,
      style,
      width,
    } = this.props;
    /*
    Button.addEventListener('click', function (event) {
      alert('События вызвано функцией!');
    }); */

    return (
      <Container style={style} marginRight={marginRight}>
        <Button
          icon={icon}
          onClick={onClick}
          active={active}
          width={width}
        >
          {icon && <Image icon={icon} />}
          <Text>{text}</Text>
        </Button>
        {children}
      </Container>
    );
  }
};
