import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { theme } from 'constants/theme';
import { TextInput } from 'components/UI';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 4px;

  padding-top: 20px;
`;

export const Title = styled.span`
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  white-space: nowrap;
`;

export const Line = styled.div`
  border-top: 1px solid #273C4C;
  width: 100%;
  margin-left: 12px;
  margin-top: 2px;
`;

export const ContainerItemLine = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px 0px;
`;

export const fontFs16Lh24 = css`
  font-size: 16px;
  line-height: 24px;
`;

export const TitleItemLine = styled.span`
  font-weight: 500;
  ${fontFs16Lh24};
  width: 143px;
`;

export const DescriptionItemLine = styled.span`
  ${fontFs16Lh24};
  margin-left: 34px;
`;

export const ContainerPurchaseSub = styled.div`
  background: #3A5265;
  border-radius: 4px;

  margin-top: 8px;

  display: flex;
  flex-direction: column;
  padding: 16px 16px 24px 16px;

  min-height: 150px;

`;

export const TitleBlock = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`;

export const AboutTitleBlock = styled.span`
  ${fontFs16Lh24};
  margin-bottom: 20px;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const HelpContainer = styled.div`
    display: flex;
    flex-direction: column;

    margin: 16px 0px;
`;

export const TextHelp = styled.span`
    margin-top: 4px;
    ${fontFs16Lh24};
`;

export const ButtonPurchase = styled.button`
  padding: 12px 16px;
  margin-right: 8px;

  background-color: ${theme.main.orangeLanding};

  &:focus {
    outline: none;
  }

  &:hover {
    outline: none;
    background-color: ${theme.main.orangeButtonHover};
  }

  &:active {
    outline: none;
    background-color: ${theme.main.orangeButtonActive};
  }
  border-radius: 6px;
  outline: none;
  border: none;

  color: black;
  font-weight: 600;
  ${fontFs16Lh24};
`;

const CssButtonChoseSubcription = css`
  display: flex;

padding: 12px 16px;
margin-right: 8px;

background-color: ${theme.main.orangeLanding};

&:focus {
  outline: none;
  border: none;
  color: black;
}

&:hover {
  outline: none;
  border: none;
  background-color: ${theme.main.orangeButtonHover};
  color: black;
}

&:active {
  outline: none;
  border: none;
  color: black;
  background-color: ${theme.main.orangeButtonActive};
}
border-radius: 6px;
outline: none;
border: none;

color: black;
font-weight: 600;
${fontFs16Lh24};
`;

export const ButtonChoseSubcription = styled(Link)`
  ${CssButtonChoseSubcription}
`;

export const ButtonChoseSubcriptionHash = styled(HashLink)`
  ${CssButtonChoseSubcription}
`;

export const ButtonComparison = styled(HashLink)`
  position: absolute;
  right: 0;

  padding: 12px 16px;

  background-color: rgba(255, 182, 90, 0.07);

  &:focus {
    outline: none;
    border: none;
    color: ${theme.main.orangeLanding};
  }

  &:hover {
    outline: none;
    border: none;
    color: ${theme.main.orangeLanding};
    background-color: rgba(255,194,116,0.1);
  }

  &:active {
    outline: none;
    border: none;
    color: ${theme.main.orangeLanding};
    background-color: rgba(255,171,65,0.1);
  }

  border-radius: 6px;
  outline: none;
  border: none;
  cursor: pointer;

  color: ${theme.main.orangeLanding};
  font-weight: 600;
  ${fontFs16Lh24};
`;

export const HelpLink = styled.a`
  font-size: 18px;
  line-height: 22px;
  color: ${theme.main.orangeLanding};

  border: none;
  outline: none;

  &:hover,
  &:active,
  &:focus {
   border: none;
   outline: none;
  }

  &:visited{
    color: ${theme.main.orangeLanding};
  }

  &:hover{
    color: ${theme.main.linkHover};
  }

  &:active{
    color: ${theme.main.linkActive};
  }
`;

export const PricingPageLink = styled(HashLink)`

  font-size: 18px;
  line-height: 22px;
  color: ${theme.main.orangeLanding};

  border: none;
  outline: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
   border: none;
   outline: none;
  }

  &:visited{
    color: ${theme.main.orangeLanding};
  }

  &:hover{
    color: ${theme.main.linkHover};
  }
  &:active{
    color: ${theme.main.linkActive};
  }
`;

export const RenewLink = styled(Link)`
  ${fontFs16Lh24};
  color: ${theme.main.orangeLanding};
  border-bottom: 1px solid ${theme.main.orangeLanding};

  &:visited{
    color: ${theme.main.orangeLanding};
    border-color: ${theme.main.orangeLanding};
  }

  &:hover{
    color: ${theme.main.linkHover};
    border-color: ${theme.main.linkHover};
  }
  &:active{
    color: ${theme.main.linkActive};
    border-color: ${theme.main.linkActive};
  }

`;

export const ExpiredText = styled.span`
  ${fontFs16Lh24};
  color: red;

`;

export const LinkWithLine = styled(HashLink)`
  ${fontFs16Lh24};
  color: ${theme.main.orangeLanding};
  border-bottom: 1px solid ${theme.main.orangeLanding};
  margin-bottom: -3px;

  &:visited{
    color: ${theme.main.orangeLanding};
    border-color: ${theme.main.orangeLanding};
  }

  &:hover{
    color: ${theme.main.linkHover};
    border-color: ${theme.main.linkHover};
  }
  &:active{
    color: ${theme.main.linkActive};
    border-color: ${theme.main.linkActive};
  }
`;

export const ContainerExpiredLink = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 4px;
`;

export const ContainerLink = styled.div`
  margin-left: 4px;
`;

export const ButtonSpinerContainer = styled.div`
  display: flex;
`;

export const SpinerContainer = styled(ButtonPurchase)`
  width: 242.28px;
  height: 48px;

  display: flex;
  justify-content: center;
`;

export const SpinerContainer2 = styled(SpinerContainer)`
  width: 201.86px;
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: calc(100% + 16px);
  margin: 10px 0 0 -8px;
`;

export const TitleMain = styled.div`
  margin: 22px 0px 29px;

  >span{
    font-weight: 900;
    font-size: 56px;
    line-height: 68px;
  };
`;

export const PurchaseSubscriptionAt = styled.span`
  font-size: 18px;
  line-height: 22px;
`;

export const InvitationContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 50px;
`;

export const InvitationCodeInput = styled.input`
  height: 34px;
  border-radius: 4px;
  border: none;
  color: black;
  margin-right: 10px;
`;

export const InvitationCodeError = styled.span`
  color: red;
  margin-left: 10px;
  transition-duration: 0.3s;
`;

export const TokensRateLink = styled.span`
  font-size: 16px;
  color: #9AD8FF;
  border-bottom: 1px dashed #9AD8FF;
  cursor: pointer;
`;

export const TokensRateItem = styled.div`
  width: 400px;
  display: flex;
  margin: 4px 0;
`;

export const TokensRateLabel = styled.div`
  width: 140px;
  display: flex;
  align-items: center;
  font-size: 16px;
`;

export const TokensRateInput = styled(TextInput)`
  width: 80px;
  background-color: white;
  color: black;
`;

export const TokensRateItemsContainer = styled.div`
  margin-left: 20px;
`;
