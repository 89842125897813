import styled, { css } from 'styled-components';

export const Col = styled.div`
  margin-right: 16px;
`;

export const Row = styled.div`
display: flex;
align-items: ${({ baseline }) => (baseline ? 'baseline' : 'center')};
`;

export const RowFont = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 24px;
`;

export const Col7 = styled.div`
  flex: 7;
  margin-right: 16px;
`;

export const Col5 = styled.div`
  flex: 5;
  display: flex;
  flex-direction: row;
`;

export const Col3 = styled.div`
  flex: 3;
  margin-right: 16px;
`;

export const Col2 = styled.div`
  flex: 2;
  justify-content: flex-end;
`;

export const ColFont = styled.div`
display: flex;
flex: 1;
margin-right: ${({ marginRight }) => marginRight}px;
${({ center }) => center && css`margin: 0px 6px 0px 6px;`};
${({ flexEnd }) => flexEnd && css`
    justify-content: flex-end;
  `};
`;

export const RowSlider = styled(Row)`
height: 36px;
margin-bottom: ${({ customMessageOptions }) => (customMessageOptions ? '0px' : '24px')};
`;

export const ImageTT = styled.div`
width: 24px;
height: 24px;
background: url('/images/icons/font-size.svg') no-repeat;

margin-right: 12px;
`;

export const ImageLS = styled.div`
  width: 24px;
  height: 24px;
  background: url('/images/icons/ic-line-spacing.svg') no-repeat;

  margin-right: 12px;
`;
