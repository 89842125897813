import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 160px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  box-shadow: 0px 1px 2px rgb(0, 0, 0, 0.15);

  display: flex;

  margin-top: 12px;

  background: #545454;
  border-radius: 10px;
  overflow: hidden;

  padding-right: 32px;
`;

const GoToNewVersion = styled.button`
  display: flex;
  align-items: center;

  height: 36px;
  margin-top: 12px;
  padding: 0px 12px;

  border: none;
  outline: none;

  color: #272E39;

  background-color: #EBFE11;
  &:hover {
    background-color: hsl(65, 99%, 63%);
  }
  &:active {
    background-color: hsl(65, 99%, 43%);
    transform: scale(0.99);
  }

  transition-property: background-color, border-color, color;
  transition: 0.2s ease-in-out;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.13);

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
`;

const ContainerImage = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  flex: 5;

  margin-top: 11px;

  margin-left: 32px;
`;

const Title = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Inter:400,600,700&display=swap');
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;

  >span {
    color: #EBFE11;
  }

  margin-bottom: 4px;
`;

const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
`;

const Image = styled.img.attrs({
  src: '/images/promo.png',
})`
  width: 275px;
  height: 156px;
`;

const Icon = styled.img.attrs({
  src: '/images/ic-arrow.svg',
})`
  width: 24px;
  height: 24px;
  margin-left: 6px;
`;

export const NewVersion = ({
  authUserGoToNewVersion,
}) => (
  <Container>
    <ContainerImage>
      <Image />
    </ContainerImage>
    <Content>
      <Title>
        New version of TipAlerts is here! Join closed test now.
      </Title>
      <Text>
        New Editor with Drag&#39;n&#39;Drop, unified URL for all of your widgets,
        built-in Test Mode, redesigned animations for Alerts and much more!
        No need to log in again.
      </Text>
      <GoToNewVersion onClick={() => authUserGoToNewVersion()}>
        Open New Editor
        <Icon />
      </GoToNewVersion>
    </Content>
  </Container>
);
