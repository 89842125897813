import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  RenderTips,
  RenderTopTippers,
  RenderRollTheDice,
} from 'components';
import {
  renderInit,
  renderShiftDiceData,
  renderDiceData,
} from 'store/reducers/render';
import { widgets } from 'constants';

import { GoalView } from 'components/WidgetsRender/RenderGoals/GoalView';
import styled from 'styled-components';

import './Render.css';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const connectionErrors = {
  'no email': (
    <p>
      Error: Invalid URL! Try replacing it with the correct one from&nbsp;
      <a href="https://tipalerts.com" style={{ color: 'red', textDecoration: 'underline rgba(255, 0, 0, 0.7)' }} target="_blank" rel="noopener noreferrer">tipalerts.com</a>
      &nbsp;or reach out for support.
    </p>
  ),
};

const widgetsIds = Object.keys(widgets).map(key => widgets[key].id);

export const Render = connect(
  ({ render, auth }) => ({
    items: render.items,
    data: render.data,
    supporter: auth.supporter,
    topUsers: render.topUsers,
    diceData: render.diceData,
    connectionError: render.connectionError,
  }),
  d => bindActionCreators({
    renderInit,
    renderShiftDiceData,
    renderDiceData,
  }, d),
)(class extends React.Component {
    static defaultProps = {
      supporter: {
        active: false,
      },
    };


    componentDidMount() {
      const { renderInit, match: { params: { accessToken, widgetId } } } = this.props;
      if (widgetsIds.indexOf(widgetId) >= 0) {
        renderInit({ streamAccessToken: accessToken, widgetId });
      } else if (widgetId === 'tree') {
        renderInit({ streamAccessToken: accessToken, widgetId: 'race' });
      }
    }

  forceReloadPlanned = false;

  componentDidUpdate(oldProps) {
    const { supporter } = this.props;
    if (supporter && oldProps.supporter) {
      if (supporter.forceReloadBrowser === true && oldProps.supporter.forceReloadBrowser === false) {
        if (!this.forceReloadPlanned) {
          window.location.reload();
        }
        this.forceReloadPlanned = true;
      }
    }
  }

  render() {
    const {
      items,
      data,
      topUsers,
      diceData,
      supporter,
      match: { params: { widgetId } },
      renderShiftDiceData,
      connectionError,
    } = this.props;
    if (connectionError || widgetsIds.indexOf(widgetId === 'tree' ? 'race' : widgetId) < 0 || widgetId === 'race') {
      return (
        <div
          style={{
            width: '100%',
            marginTop: '5%',
            fontSize: '30px',
            color: 'red',
            textAlign: 'center',
          }}
        >
          {connectionErrors['no email']}
        </div>
      );
    }
    if (!supporter || (!supporter.active && !supporter.basic)) {
      return (
        <div
          style={{
            width: '100%',
            marginTop: '5%',
            fontSize: '30px',
            color: 'red',
            textAlign: 'center',
          }}
        >
          Your account has issue. Please check configuration in the dashboard.
        </div>
      );
    }
    if (widgetId === widgets.tips.id) {
      return (
        <div className="Render">
          {(supporter && supporter.debug) && <div style={{ color: 'red', fontSize: '30px' }}>{navigator.userAgent}</div>}
          {Object.keys(items || {})
            .filter(id => Boolean(items[id]))
            .map((id) => {
              const { amount } = items[id];
              let dataForAmount = data.default;
              if (supporter.active) {
                for (const key in data) {
                  if (data.hasOwnProperty(key) && data[key].preset && (data[key].preset.enabled || data[key].preset.enabled === undefined)) {
                    const variation = data[key];
                    if (amount >= Number(variation.preset.from) && amount <= Number(variation.preset.to)) {
                      dataForAmount = variation;
                      break;
                    }
                  }
                }
              }
              return (
                <RenderTips
                  key={id}
                  data={{ ...dataForAmount, tips: { ...items[id] } }}
                  supporter={supporter}
                />
              );
            })}
        </div>
      );
    }
    if (widgetId === widgets.goals.id) {
      if (!supporter.active) return null;
      return (
        <Container>
          {Object.keys(data || {}).map((key) => {
            const userData = data[key];
            if (!userData.preset || !userData.preset.goalValue) {
              return null;
            }
            if (typeof userData.preset.enabled !== 'undefined' && userData.preset.enabled === false) {
              return null;
            }
            return (
              <GoalView key={key} data={userData} />
            );
          })}
        </Container>
      );
    }
    if (widgetId === widgets.dice.id) {
      const configDefault = (data && data.default) || {};
      return (
        <RenderRollTheDice
          config={configDefault}
          data={diceData || []}
          premium={supporter.active}
          renderShiftDiceData={renderShiftDiceData}
        />);
    }
    if (widgetId === widgets.top.id) {
      const configDefault = (data && data.default) || {};
      const topList = Object.keys(topUsers || {})
        .map(name => ({ name, amount: topUsers[name] })).sort((a, b) => b.amount - a.amount);
      return (<RenderTopTippers config={configDefault} topList={topList} premium={supporter.active} />);
    }
    return null;
  }
});
