// @flow

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Prism from 'prismjs';

const LogItemView = styled.div`
  margin: 10px 2px;
  padding: 2px;
  background-color: rgba(0, 0, 0, 0.1);
`;

const LogItemMessage = styled.div`
  font-family: monospace;
  font-size: 16px;
  letter-spacing: 0.1px;
  font-weight: 500;
  border-radius: 6px;
  background-color: rgb(35, 31, 27, 0.5) !important;
  color: #c5c5c5 !important;
  padding: 4px;
  margin: 4px;
  /* word-wrap:break-all; */
  border-width: 0 !important;
  box-shadow: none !important;
  white-space: pre-wrap;
  word-wrap: break-word;
  & > span {
    word-break: break-all;
    word-wrap: break-word;
  }
`;

const Badge = styled.span`
  border-radius: 3px;
  padding: 2px 6px;
  margin-left: 10px;
  word-break: break-all;
`;

const BadgeRoomMessage = styled(Badge)`
  background-color: ${({ theme }) => theme.white10};
  opacity: 0.8;
`;

BadgeRoomMessage.defaultProps = {
  title: 'Chat message from user',
};

const BadgeTipAlert = styled(Badge)`
  background-color: #ff91c8b3;
`;

BadgeTipAlert.defaultProps = {
  title: 'Tip alert',
};

const BadgeEvent = styled(Badge)`
  background-color: #ff8f0082;
`;

const BandgeGoodEvent = styled(Badge)`
background-color: #c7ea4680;
`;

BadgeEvent.defaultProps = {
  title: 'System event',
};

const BadgeEventError = styled(Badge)`
  background-color: #ff490082;
`;

BadgeEventError.defaultProps = {
  title: 'Error system event',
};

const ServiceBadge = styled.span`
  padding: 0 6px;
  margin: 0 4px;
  border-radius: 4px;
  background-color: #808080;
  color: #fff;
  font-style: italic;
  opacity: 0.5;
`;

interface Props {
  date: string;
  message: string;
  service: string;
}

const LOG_EVENTS = {
  OFFLINE: 'OFFLINE',
  INITIALLY_CONNECTED: 'INITIALLY_CONNECTED',
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
  AUTH_REQUIRED: 'AUTH_REQUIRED',
  USER_ONLINE: 'USER_ONLINE',
  CHAT_CONNECTED: 'CHAT_CONNECTED',
  STREAM_ONLINE: 'STREAM_ONLINE',
  CHECK_STREAM: 'CHECK_STREAM',
  CONNECTION_SUCCESSED: 'CONNECTION_SUCCESSED',
  PROCESS_CLOSED: 'PROCESS_CLOSED',
  SOCKET_CHAT_RECONNECT: 'SOCKET_CHAT_RECONNECT',
  TIP: 'TIP',
};

const parseMessage = (message) => {
  if (message[0] === '{') {
    let msgToParse;
    try {
      msgToParse = JSON.parse(message);
    } catch (e) {
      return null;
    }
    return { json: msgToParse, row: message };
  }
  if (message[0] === 'a') { // event from chaturbate
    let msgToParse = message.substr((message.indexOf('a') + 2), message.length - (message.indexOf('a') + 3));
    msgToParse = msgToParse.replace(/\\"/g, '"');
    msgToParse = msgToParse.replace(/\\\\"/g, '\\"');
    msgToParse = msgToParse.replace(/\\\\n\\\\t|\\\\n/g, '');
    msgToParse = msgToParse.replace(/\\n\\t|\\n/g, '');
    msgToParse = msgToParse.replace(/\\/g, '');
    msgToParse = msgToParse.replace(/"{/g, '{');
    msgToParse = msgToParse.replace(/}"/g, '}');
    msgToParse = msgToParse.replace(/!/g, '');
    msgToParse = msgToParse.replace(/,"/g, ', "');
    try {
      msgToParse = JSON.parse(msgToParse);
    } catch (e) {
      return null;
    }
    if (msgToParse.args) {
      /*
      let innerParseIndex = 0;
      if (msgToParse.args[1]) {
        innerParseIndex = 1;
      }
      /*
      try {
        msgToParse.args[innerParseIndex] = JSON.parse(msgToParse.args[innerParseIndex]);
      } catch (e) {
        console.log('e', e);
        return null;
      } */
      return { json: msgToParse, row: message };
    }
  } else if (message.indexOf('Some connection param not found') !== -1) {
    return { event: LOG_EVENTS.OFFLINE, row: message };
  } else if (message.indexOf('Socket connected') !== -1) {
    return { event: LOG_EVENTS.INITIALLY_CONNECTED, row: message };
  } else if (message.indexOf('Streamer page not found') !== -1) {
    return { event: LOG_EVENTS.PAGE_NOT_FOUND, row: message };
  } else if (message.indexOf('Authorization required') !== -1) {
    return { event: LOG_EVENTS.AUTH_REQUIRED, row: message };
  } else if (message.indexOf('Connect to room') !== -1) {
    return { event: LOG_EVENTS.USER_ONLINE, row: message }; /** work?  */
  } else if (message.indexOf('CHAT-CONNECTED') !== -1) {
    return { event: LOG_EVENTS.CHAT_CONNECTED, row: message };
  } else if (message.indexOf('Stream online, continue work') !== -1) {
    return { event: LOG_EVENTS.STREAM_ONLINE, row: message };
  } else if (message.indexOf('Check if stream gone offline...') !== -1) {
    return { event: LOG_EVENTS.CHECK_STREAM, row: message };
  } else if (message.indexOf('connection successed') !== -1) {
    return { event: LOG_EVENTS.CONNECTION_SUCCESSED, row: message };
  } else if (message.indexOf('EXIT: 0') !== -1) {
    return { event: LOG_EVENTS.PROCESS_CLOSED, row: message }; /* PROCESS_CLOSED */
  } else if (message.indexOf('Socket CHAT_RECONNECT') !== -1) {
    return { event: LOG_EVENTS.SOCKET_CHAT_RECONNECT, row: message };
  } else if (message.indexOf('amount') !== -1) {
    return { event: LOG_EVENTS.TIP, row: message };
  }
  return null;
};

export class LogItem extends PureComponent<Props> {
  renderParsedMessage = (parsedMessage: any) => {
    const { json, event } = parsedMessage;
    if (json) {
      if (
        json && json.type === 'tip_alert'
      ) {
        const data = json;
        return (
          <BadgeTipAlert>
            {data.from_username}
            {' : '}
            {data.amount}
          </BadgeTipAlert>
        );
      }
      if (
        json.method === 'onRoomMsg'
        && json.args[1]
        && json.args[1].m
      ) {
        const data = json.args[1];
        return (
          <BadgeRoomMessage>
            {data.user}
            {' : '}
            {data.m}
          </BadgeRoomMessage>
        );
      }
    }
    switch (event) {
      case LOG_EVENTS.OFFLINE:
        return <BadgeEventError>Stream offline</BadgeEventError>;
      case LOG_EVENTS.INITIALLY_CONNECTED:
        return <BadgeEvent>User initially connected</BadgeEvent>;
      case LOG_EVENTS.PAGE_NOT_FOUND:
        return <BadgeEventError>Stream page on chaturbate not found (offten user make typo)</BadgeEventError>;
      case LOG_EVENTS.AUTH_REQUIRED:
        return <BadgeEventError>Authorization required to view stream</BadgeEventError>;
      case LOG_EVENTS.USER_ONLINE:
        return <BadgeEvent>Stream online, we trying to connect</BadgeEvent>;
      case LOG_EVENTS.CHAT_CONNECTED:
        return <BadgeEvent>Connected to chat</BadgeEvent>;
      case LOG_EVENTS.STREAM_ONLINE:
        return <BandgeGoodEvent>Stream online</BandgeGoodEvent>;
      case LOG_EVENTS.CHECK_STREAM:
        return <BadgeEvent>Check stream</BadgeEvent>;
      case LOG_EVENTS.CONNECTION_SUCCESSED:
        return <BandgeGoodEvent>Connection successed</BandgeGoodEvent>;
      case LOG_EVENTS.PROCESS_CLOSED:
        return <BadgeEventError>Process closed</BadgeEventError>;
      case LOG_EVENTS.SOCKET_CHAT_RECONNECT:
        return <BandgeGoodEvent>Socket chat recconect</BandgeGoodEvent>;
      case LOG_EVENTS.TIP:
        return <BadgeTipAlert>Tip</BadgeTipAlert>;
      default:
        return null;
    }
  }

  render() {
    const { date, message, service } = this.props;
    // const dateMomentLocal = moment.parseZone(moment(date).isDST() ? `${date}+02:00` : `${date}+02:00`).local();
    const dateMomentLocal = moment.utc(date).local();
    const parsed = parseMessage(message);
    let renderContent;
    if (parsed && parsed.json) {
      const hlCode = Prism.highlight(JSON.stringify(parsed.json || {}, null, 2), Prism.languages.json, 'json');
      renderContent = <LogItemMessage style={{ backgroundColor: 'rgb(35, 31, 27, 0.5)' }} dangerouslySetInnerHTML={{ __html: hlCode }} />;
    } else {
      renderContent = <LogItemMessage style={{ backgroundColor: 'rgb(35, 31, 27, 0.5)' }}><span>{message}</span></LogItemMessage>;
    }
    return (
      <LogItemView>
        <span>
          {dateMomentLocal.fromNow()}
          (
          {dateMomentLocal.format('HH:mm:ss DD.MM.YY')}
          )
          {service && <ServiceBadge>{service}</ServiceBadge>}
        </span>
        {parsed && this.renderParsedMessage(parsed)}
        {renderContent}
      </LogItemView>
    );
  }
}
