/* eslint-disable */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import {
  Section,
  FormItem,
  CheckBox,
  NumberCountInput,
  NewSlider,
  Button,
  ButtonIconText,
  ModalTooltip,
} from 'components/UI';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActionWidgetsComponentsUpdate, widgetUpdate } from 'store/reducers/widgets';
import { widgetsOptionsSelectPreset } from 'store/reducers/widgetsOptions';
import { widgets } from 'constants';

import AddActionButton from './AddActionButton';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;


const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  min-width: 166px;
  color: ${({ theme }) => theme.white54};;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;


const RowEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DeletePreset = styled(Button)`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0px 16px 0px 10px;
  margin: 0px;
  position: relative;

  background-color: hsla(206, 29%, 36%, 1);
  &:hover {
    background-color: hsla(206, 29%, 46%, 1);
  }
  &:active {
    background-color: hsla(206, 29%, 36%, 1);
  }
`;

const ImageThrash = styled.div`
  background-color: ${({ theme }) => theme.white};

  width: 24px;
  height: 24px;

  mask-image:  url('/images/icons/ic-trash.svg');
  mask-repeat: no-repeat;
  mask-position: center;
`;


class PresetTab extends Component {
  state = {
    resetActive: false,
    deleteActive: false,
  };

  modal = React.createRef();

  showdeleteModal = () => {
    this.setState({ deleteActive: true }, () => document.addEventListener('click', this.onClick));
  };

  onClick = (e) => {
    const modalNode = ReactDOM.findDOMNode(this.modal.current);
    const isOutClick = !(modalNode && modalNode.contains(e.target));
    if (isOutClick) this.hidedeleteModal(e);
  };

  hidedeleteModal = (e) => {
    e.stopPropagation();
    this.setState({ deleteActive: false }, () => document.removeEventListener('click', this.onClick));
  };

  onDeleteVariation = (e) => {
    e.stopPropagation();
    const { selectedPreset, widgetUpdate } = this.props;

    this.setState({ deleteActive: false }, () => {
      document.removeEventListener('click', this.onClick);
      widgetUpdate(widgets.goals.id, { [selectedPreset]: null });
    });
  };


  render() {
    const {
      data,
      presets,
      selectedPreset,
      widgetsComponentsUpdate,
    } = this.props;

    const { deleteActive } = this.state;

    const actions = (data.actions || {});
    const actionsArr = Object.keys(actions);

    return (
      <div>
        <Section>
          <FormItem label="Goal settings" themeFont="header" header>
            <CheckBox
              checked={data.enabled}
              onChange={() => widgetsComponentsUpdate({ enabled: !data.enabled })}
              themeFont="headerCheckbox"
            >
              Enable this preset
            </CheckBox>
          </FormItem>
          <Content style={{ marginBottom: '12px' }}>
            <Row>
              <Label>
                Goal value
              </Label>
              <NumberCountInput
                value={data.goalValue}
                onChange={ goalValue => widgetsComponentsUpdate({ goalValue: goalValue })}
                min={0}
                max={9999999}
                width="72px"
              />
            </Row>
          </Content>
          <Content style={{ marginBottom: '12px' }}>
            <Row>
              <Label>
                Current value
              </Label>
              <NumberCountInput
                value={data.value}
                onChange={ value => widgetsComponentsUpdate({ value: value })}
                min={0}
                max={9999999}
                width="72px"
              />
            </Row>
          </Content>

          <Content>
            <Row>
              <Label>
                If goal is reached
              </Label>
              <Col>
                {actionsArr.length ?
                  actionsArr.map((actionId) => (
                    <AddActionButton
                      key={actionId}
                      action={actions[actionId]}
                      presets={presets}
                      selectedPreset={selectedPreset}
                      onSubmit={(action) => widgetsComponentsUpdate({ actions: { ...actions, [action.id]: action } })}
                      onRemove={(actionId) => widgetsComponentsUpdate({ actions: { ...actions, [actionId]: null } })}
                    />
                  )) :
                  <Label style={{color: 'white'}}>
                    Do nothing
                  </Label>
                }
                <AddActionButton
                  presets={presets}
                  selectedPreset={selectedPreset}
                  onSubmit={(action) => widgetsComponentsUpdate({ actions: { ...actions, [action.id]: action } })}
                />
              </Col>
            </Row>
          </Content>

        </Section>
        <Section borderTop>
          <FormItem label="Transform" themeFont="header" header borderTop />
          <FormItem label="Scale" themeFont="normal">
            <NewSlider
              value={data.transformScale}
              min={0}
              max={5}
              step={0.1}
              decPlace={1}
              onChange={size => widgetsComponentsUpdate({ transformScale: size })}
            />
          </FormItem>
          <FormItem label="Vertical position" themeFont="normal">
            <NewSlider
              value={data.transformVerticalPosition}
              min={0}
              max={100}
              step={1}
              onChange={size => widgetsComponentsUpdate({ transformVerticalPosition: size })}
            />
          </FormItem>
          <FormItem label="Rotate" themeFont="normal" style={{marginBottom: '0px'}}>
          <NewSlider
                value={data.transformRotate}
                min={-180}
                max={180}
                step={1}
                onChange={angle => widgetsComponentsUpdate({ transformRotate: Number(angle) })}
                centerPoint
              />
          </FormItem>
        </Section>

        <RowEnd>
          <ButtonIconText
            icon="trash"
            text="Delete Preset"
            active={deleteActive}
            onClick={this.showdeleteModal}
          >
            <ModalTooltip
              ref={this.modal}
              show={deleteActive}
              title="Delete preset?"
              text="This action cannot be undone."
              leftButton={{
                label: 'Cancel',
                onClick: this.hidedeleteModal,
              }}
              rightButton={{
                label: 'Yes, delete',
                onClick: this.onDeleteVariation,
              }}
            />
          </ButtonIconText>
        </RowEnd>
      </div>
    );
  }
}


PresetTab.title = 'Preset';

export default connect(
  null,
  (dispatch, ownProps) => bindActionCreators({
    widgetsComponentsUpdate: getActionWidgetsComponentsUpdate(widgets.goals.id, ownProps.selectedPreset, 'preset'),
    widgetsOptionsSelectPreset,
    widgetUpdate,
  }, dispatch),
)(PresetTab);
