import React, { Component } from 'react';
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  ControlLabel,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Spinner from 'react-spinkit';

import { authPaymentContactSave, authPaymentContactModalShow } from 'store/reducers/auth';

const mapDispatchToProps = d => bindActionCreators({
  authPaymentContactSave,
  authPaymentContactModalShow,
}, d);

const mapStateToProps = ({ auth }) => ({
  paymentContactModal: auth.paymentContactModal,
  paymentContactModalProgress: auth.paymentContactModalProgress,
});

class PaymentContactModalBase extends Component {
  state = {
    firstName: '',
    lastName: '',
  };

  render() {
    const {
      paymentContactModal,
      authPaymentContactSave,
      authPaymentContactModalShow,
      paymentContactModalProgress,
    } = this.props;
    const { firstName, lastName } = this.state;
    const continueDisabled = (firstName.length < 2 || lastName.length < 2) || paymentContactModalProgress;
    return (
      <Modal
        show={paymentContactModal}
        onHide={() => authPaymentContactModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Payment contact details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please provide payment contact info:</p>
          <div style={{ width: '250px' }}>
            <FormGroup controlId="pcFirstName">
              <ControlLabel>First name:</ControlLabel>
              <FormControl type="text" value={firstName} onChange={e => this.setState({ firstName: e.target.value })} />
            </FormGroup>
            <FormGroup controlId="pcLastName">
              <ControlLabel>Last name:</ControlLabel>
              <FormControl type="text" value={lastName} onChange={e => this.setState({ lastName: e.target.value })} />
            </FormGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={continueDisabled}
            bsStyle="success"
            style={{ width: '100px', height: '44px' }}
            onClick={() => authPaymentContactSave(firstName, lastName)}
          >
            {paymentContactModalProgress ? <Spinner style={{ display: 'inline-block' }} name="wave" /> : 'Continue'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export const PaymentContactModal = connect(mapStateToProps, mapDispatchToProps)(PaymentContactModalBase);
