import React, { Component } from 'react';
import styled from 'styled-components';

import { Circle } from './index';

const StrokeContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  > span {
    font-size: 18px;
    line-height: 22px;
    margin-left: 10px;
  }
`;

const colorCheck = 'white';

type Props = {
    text: string,
  };

export class CheckMarkStroke extends Component<Props> {
  render() {
    const {
      text,
      colorСircle,
    } = this.props;
    return (
      <StrokeContent>
        <Circle colorCheck={colorCheck} colorСircle={colorСircle} />
        <span>{text}</span>
      </StrokeContent>
    );
  }
}
