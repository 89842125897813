import styled, { css, keyframes } from 'styled-components';
/* import { Link } from 'react-router-dom'; */
// import { theme } from 'constants/theme';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

const Text = css`
  font-size: 16px;
  line-height: 140%;
`;

export const MainText = styled.span`
  ${Text};
  margin: 12px 0 27px 0;
`;

export const InputText = styled.span`
  ${Text};
  margin-bottom: 9px;
  >span{
    font-weight: 600;
  }
`;

export const IdInput = styled.input`
  width: 100%;
  padding: 0 10px;
  background: #2E3F4C;
  border-radius: 4px;
  border: 1px solid transparent;
  outline: none;
  height: 36px;

  font-size: 14px;
  line-height: 20px;
  color: #FFF;

  /* тут спросить */
  &:placeholder {
    color: rgba(255, 255, 255, 0.34);
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.orangeLanding};
  }

  &:hover, &:active {
    border-color: ${({ theme }) => theme.orangeButtonHover};
  }
`;

export const ExampleContainer = styled.div`
  margin-top: 8px;

  width: 100%;

  display: flex;
  justify-content: space-between;
`;

export const ExampleText = styled.span`
  font-size: 14px;
  line-height: 115%;
  color: #C0D1DE;
  >span{
    font-weight: 600;
  }
`;

const link = css`
  color: ${({ theme }) => theme.orangeLanding};

  font-size: 16px;
  line-height: 115%;
  font-weight: 500;

  border: none;
  outline: none;

  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
   border: none;
   color: ${({ theme }) => theme.linkActive};
   outline: none;
  }
  `;

export const OuterLink = styled.a`${link}`;

export const Warning = styled.span`
  ${Text};
  color: #FF9B9B;
`;

export const WarningContainer = styled.div`
  padding-top: 30px;
  height: 120px;
`;

export const ContainerBetaTest = styled.div`
  width: 100%;
  height: 92px;
  position: relative;
`;

export const ButtonContainer = styled.div`
  margin-top: 18px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 12px;

  display: flex;
  justify-content: flex-end;
`;

export const ButtonDeleteContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

export const Button = styled.button`
  outline: none;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 102px;
  height: 36px;

  font-weight: 600;
  font-size: 16px;
  line-height: 36px;

  margin-left: 6px;
  padding: 0 16px;
  border-radius: 4px;
  
  cursor: ${({ isLoading, disabled }) => (isLoading || disabled ? 'auto' : 'pointer')};

  ${({ orange, isLoading, theme }) => (orange
    ? css`
      background-color:  ${theme.orangeLanding};
      color: ${theme.black};
      &:hover{
        background-color:  ${isLoading ? theme.orangeLanding : theme.orangeButtonHover};
      }
      &:active{
        background-color:  ${isLoading ? theme.orangeLanding : theme.orangeButtonActive};
      }`
    : css`
      background-color: #44657E;
      color: ${theme.white};
      &:hover {
        background-color: ${isLoading ? theme.white10 : theme.white10};
      }
      &:active {
        background-color:  ${isLoading ? theme.black05 : theme.black05};
      }`
  )};
  
  
  ${({ disabled, theme }) => (
    disabled
      ? `background-color: ${theme.disabled};
      &:hover {
          background-color: ${theme.disabled};
      }
      &:active {
        background-color:  ${theme.disabled};
      }`
      : ''
  )}
`;

export const ConnectedAS = styled.span`
  ${Text};
  text-align: center;
  >p{
    font-weight: 600;
    font-size: 20px;
  };

  margin-bottom: 180px;
`;

export const CircleContainer = styled.div`
  margin-bottom: 12px;
`;

const rotate = keyframes`
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
`;

export const Spinner = styled.img`
  animation: ${rotate} 2s linear infinite;
`;


export const ResetLink = styled.div`
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: rgba(255, 255, 255, 0.54);

width: fit-content;
border-bottom: 1px dashed ${({ active }) => (active ? 'rgba(255, 255, 255, 0.54)' : 'transparent')};
transition: border-bottom-color 0.2s ease-in-out;
cursor: pointer;

position: relative;

&:hover {
  border-bottom-color: rgba(255, 255, 255, 0.54);
  outline: none;
}
`;
