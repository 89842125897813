/* eslint-disable react/no-multi-comp */

import React, { Suspense } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Grid,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button as BSButton,
} from 'react-bootstrap';
import moment from 'moment';
import styled from 'styled-components';
import queryString from 'querystring';
import Spinner from 'react-spinkit';
import { Link } from 'react-router-dom';
import deepEqual from 'deep-equal';

import { parseChaturbateId } from 'utils';
import {
  authSetPassword,
  authGetPaymentLink,
  authSetRenewSubscription,
  authPaymentPatreonClear,
  authPaymentFSModalShow,
  authPaymentDisableSubscription,
  authPaymentEnableSubscription,
  authSendInvite,
  authUserGoToNewVersion,
  authChangeTokensRate,
} from 'store/reducers/auth';
import {
  PaymentsHistory,
  PatreonModal,
  FastSpringModal,
  CardConnect,
} from 'components';
import { Button } from 'components/UI/Button';
import { Service_ID, REQUEST_STATUS, servicesNames } from 'constants';

import './Settings.css';
import { NewVersion } from './NewVersion';
import {
  Container,
  TitleContainer,
  Title,
  Line,
  HelpContainer,
  TitleBlock,
  TextHelp,
  ContainerPurchaseSub,
  AboutTitleBlock,
  ContainerButton,
  ButtonPurchase,
  ButtonComparison,
  ContainerItemLine,
  TitleItemLine,
  DescriptionItemLine,
  HelpLink,
  PricingPageLink,
  RenewLink,
  ExpiredText,
  ButtonChoseSubcription,
  LinkWithLine,
  ContainerExpiredLink,
  ContainerLink,
  ButtonSpinerContainer,
  SpinerContainer,
  ButtonChoseSubcriptionHash,
  ContainerCard,
  TitleMain,
  PurchaseSubscriptionAt,
  InvitationContainer,
  InvitationCodeInput,
  InvitationCodeError,
  TokensRateLink,
  TokensRateItem,
  TokensRateLabel,
  TokensRateInput,
  TokensRateItemsContainer,
} from './style';

const Referral = React.lazy(() => import('./Referral'));

const mapDispatchToProps = dispatch => bindActionCreators({
  authSetPassword,
  authGetPaymentLink,
  authSetRenewSubscription,
  authPaymentPatreonClear,
  authUserGoToNewVersion,
  authPaymentFSModalShow,
  authPaymentDisableSubscription,
  authPaymentEnableSubscription,
  authSendInvite,
  authChangeTokensRate,
},
dispatch);

const FormItem = ({
  id, type, title, description, value, onChange, onHelpClick, noTitle,
}) => (
  <FormGroup controlId={id}>
    {!noTitle && (
      <Col componentClass={ControlLabel} style={{ textAlign: 'left' }} sm={2}>
        {title}
      </Col>)}
    <Col sm={6} style={!type ? { marginTop: '7px' } : {}}>
      {type && (
        <FormControl
          type={type}
          value={value}
          onChange={({ target: { value: changedValue } }) => onChange(changedValue)}
        />
      )}
      {description}
      { id === 'textLink'
      && <LinkStyled onClick={onHelpClick}>Contact Support</LinkStyled>
      }
    </Col>
  </FormGroup>
);

const ItemLine = ({
  title,
  description,
  expired,
  till,
  special,
  provider,
}) => {
  const todaysDate = moment(new Date());
  const diffDays = moment(till).diff(todaysDate, 'days');
  const Expired = (diffDays === 0 && '(Expired)');
  return (
    <ContainerItemLine>
      <TitleItemLine>{title}</TitleItemLine>
      <DescriptionItemLine>{description}</DescriptionItemLine>
      { (diffDays === 0 && expired) && (
        <ContainerExpiredLink>
          <ExpiredText>{`${Expired} `}</ExpiredText>
          {special !== 'trial' && (
            <ContainerLink>
              {(provider === 'patreon')
                ? <LinkWithLine to="/price#subpatreon">How to renew subscription</LinkWithLine>
                : <RenewLink to="/resub">Renew</RenewLink>}
            </ContainerLink>
          )}
        </ContainerExpiredLink>
      )}
    </ContainerItemLine>
  );
};

const ChangeService = ({
  currentProfile,
  profiles,
  history,
  serviceRate,
  showTokensRate,
  onChangeServiceRate,
  onTokesnRateToggle,
}) => {
  const profile = profiles && currentProfile ? profiles[currentProfile] : {};
  const idChaturbate = profile.chaturbateId;
  const idBongacams = profile.bongacamsId;
  const idMyfreecams = profile.myfreecamsId;
  const idStripchat = profile.stripchatId;
  const idCam4 = profile.cam4Id;
  const idCamsoda = profile.camsodaId;
  return (
    <Container>
      <TitleContainer>
        <Title>Streams</Title>
        <Line />
      </TitleContainer>
      <ContainerCard>
        <CardConnect
          service={Service_ID.chaturbate}
          id={idChaturbate}
          history={history}
        />
        <CardConnect
          service={Service_ID.bongacams}
          id={idBongacams}
          history={history}
        />
        <CardConnect
          service={Service_ID.myfreecams}
          id={idMyfreecams}
          history={history}
        />
        <CardConnect
          service={Service_ID.stripchat}
          id={idStripchat}
          history={history}
        />
        <CardConnect
          service={Service_ID.cam4}
          id={idCam4}
          history={history}
        />
        <CardConnect
          service={Service_ID.camsoda}
          id={idCamsoda}
          history={history}
        />
        <CardConnect
          service={Service_ID.amateur}
          id={profile.amateurId}
          history={history}
        />
        <CardConnect
          service={Service_ID.cams}
          id={profile.camsId}
          history={history}
        />
        <CardConnect
          service={Service_ID.manyvids}
          id={profile.manyvidsId}
          history={history}
        />
      </ContainerCard>
      <div>
        <TokensRateLink onClick={onTokesnRateToggle}>Modify tokens conversion rate</TokensRateLink>
        {showTokensRate && (
        <TokensRateItemsContainer>
          <span>You can set custom conversion rate for each service.</span>
          <HelpLink
            href="https://help.tipalerts.com/article/tokens-conversion-rate/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: '14px', marginLeft: '4px' }}
          >
            How it work?
          </HelpLink>
          {Object.keys(servicesNames).map(id => (
            <TokensRateItem key={id}>
              <TokensRateLabel>{servicesNames[id]}</TokensRateLabel>
              <TokensRateInput value={serviceRate[id] || ''} onChange={e => onChangeServiceRate(id, e.target.value)} />
            </TokensRateItem>
          ))}
        </TokensRateItemsContainer>
        )}
      </div>
    </Container>
  );
};

const PurchaseSub = ({
  onClickPurchase,
  waitLink,
  loadingType,
  special,
  basic,
  active,
  provider,
}) => {
  let title;
  let aboutTitle;
  if (special === 'trial' || (basic === undefined && special !== 'trial' && (active === false || active === undefined)) || (basic === false && active === false && special !== 'trial')) {
    title = 'Purchase subscription';
    aboutTitle = (provider === 'patreon')
      ? 'Upgrade your subscription to Basic or Premium. At the moment TipAlerts can accept payments only via Patreon for your country. Learn how to purchase subscription at Pricing Page.'
      : 'Upgrade your subscription to Basic or Premium for maximum creative abilities. Pay with VISA or Mastercard.';
  } else {
    title = 'Upgrade to Premium';
    aboutTitle = (provider === 'patreon')
      ? 'Upgrade your subscription to Premium to get maximum creative abilities. At the moment TipAlerts can accept payments only via Patreon for your country.'
      : 'Upgrade your subscription to Premium for maximum creative abilities. Paid days left in this month will be taken into account.';
  }
  return (
    <ContainerPurchaseSub>
      <TitleBlock>{title}</TitleBlock>
      <AboutTitleBlock>{aboutTitle}</AboutTitleBlock>
      <ContainerButton>
        {(((basic === undefined || basic === false) && special !== 'trial' && (active === false || active === undefined))
        || (special === 'trial')) && (provider !== 'patreon')
        && (
        <ContainerButton>
          <PurchaseSubscriptionAt>
            Purchase subscription at
          </PurchaseSubscriptionAt>
          &nbsp;
          <PricingPageLink
            to="/price#choose"
          >
            Pricing Page
          </PricingPageLink>
        </ContainerButton>
        )}
        {(((basic === undefined || active === false) && special !== 'trial' && (active === false || active === undefined))
        || (special === 'trial')) && (provider === 'patreon')
        && (
        <ButtonSpinerContainer>
          <ButtonChoseSubcription to="/price">Choose subscription</ButtonChoseSubcription>
        </ButtonSpinerContainer>
        )}
        {((basic === true && special !== 'trial' && (active === false || active === undefined))
        && (provider !== 'patreon')) && (
        <div>
          {!waitLink && (
            <ButtonPurchase onClick={onClickPurchase('upgrade')}>
              Upgrade to premium
            </ButtonPurchase>)}
          {loadingType === 'upgrade' && <SpinerContainer style={{ width: '267.08px' }}><Spinner name="wave" color="black" /></SpinerContainer>}
        </div>
        )}
        {((basic === true && (active === false || active === undefined) && special !== 'trial')
        && (provider === 'patreon')) && (
        <div>
          <ButtonChoseSubcriptionHash to="/price#subpatreon">Learn how to upgrade subscription </ButtonChoseSubcriptionHash>
        </div>
        )}
        <ButtonComparison to="/price#subscriptions_comparison">Subscriptions comparison</ButtonComparison>
      </ContainerButton>
    </ContainerPurchaseSub>
  );
};

const Subscription = ({
  onClickPurchase,
  onDisableSubscription,
  onEnableSubscription,
  waitLink,
  loadingType,
  provider,
  supporter: {
    special,
    basic,
    active,
    till,
    fsSubscription,
    mdSubscription,
    mdSubscriptionInactive,
  },
}) => {
  let price;
  let plan;
  if (special === 'trial') {
    price = 'Free';
    plan = 'Trial';
  } else if (active === true) {
    price = '$10 per month';
    plan = 'Premium';
  } else if (basic === true) {
    price = '$5 per month';
    plan = 'Basic';
  } else {
    price = 'none';
    plan = 'none';
  }
  return (
    <Container>
      <TitleContainer>
        <Title>Subscription</Title>
        <Line />
      </TitleContainer>
      {!((basic === undefined || basic === false) && special !== 'trial' && (active === false || active === undefined))
      && (
      <div>
        <ItemLine expired title="Subscription" description={plan} till={till} special={special} provider={provider} />
        <ItemLine title="Price" description={price} />
        <ItemLine title="Active till" description={`${moment(till).format('dddd, MMMM Do YYYY')} (${moment(till).fromNow(true)})`} />
      </div>)}
      <div>
        {fsSubscription && (
          <div>
            You have active subscription that will be charged at the end of purchased period.
            <div>If you want to disable automatic payments please use &laquo;Disable subscription&raquo; button below.</div>
            <div>After disabling subscription, and when purchased period is ended, you will need to again making purchase and input payment information manually.</div>
            <div>Please note that we don&apos;t store any information about your card.</div>
          </div>
        )}
        {fsSubscription && <Button type="button" onClick={() => onDisableSubscription(6)}>Disable subscription</Button>}
        {mdSubscription && <Button type="button" onClick={() => onDisableSubscription(2)}>Disable subscription</Button>}
        {mdSubscriptionInactive && <Button type="button" onClick={() => onEnableSubscription()}>Enable autopayment</Button>}
      </div>
      {!(special !== 'trial' && active === true) && (
      <PurchaseSub
        onClickPurchase={onClickPurchase}
        waitLink={waitLink}
        loadingType={loadingType}
        basic={basic}
        active={active}
        special={special}
        till={till}
        provider={provider}
      />)}
      <HelpContainer>
        <TitleBlock>Help</TitleBlock>
        <TextHelp>
          Read about payments, refunds, subscription prolongation and cancelation at&nbsp;
          <HelpLink
            href="https://help.tipalerts.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help Center
          </HelpLink>
        </TextHelp>
        <TextHelp>
          Learn more about Basic and Premium subscriptions at&nbsp;
          <PricingPageLink
            to="/price#choose"
          >
            Pricing Page
          </PricingPageLink>
        </TextHelp>
        <TextHelp>
          If you have any questions or issues don&apos;t hesitate to contact us with e-mail&nbsp;
          <a href="mailto:support@tipalerts.com">support@tipalerts.com</a> or by <TokensRateLink onClick={() => window.$crisp.push(['do', 'chat:open'])}>support chat</TokensRateLink>
        </TextHelp>
      </HelpContainer>
    </Container>
  );
};

// TODO: TRIAL NOW WORK WITH DELAY (in saga)!
const LinkStyled = styled.a`
cursor: pointer;
&:visited,
&:hover,
&:active {
  color: #9AD8FF;
}
`;
export const Settings = connect(
  ({ auth, lang: { t, lang } }) => ({
    user: auth.user,
    sendInviteStatus: auth.sendInviteStatus,
    supporter: auth.supporter,
    provider: auth.provider,
    paymentPatreonURL: auth.paymentPatreonURL,
    paymentShowFSModal: auth.paymentShowFSModal,
    paymentFSFailed: auth.paymentFSFailed,
    t,
    lang,
  }),
  mapDispatchToProps,
)(class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      password: '',
      password2: '',
      chaturbateId: props.user.currentProfile && props.user.profiles ? props.user.profiles[props.user.currentProfile].chaturbateId : '',
      urlQuery: {},
      waitLink: false,
      loadingType: '',
      usdRate: 77,
      inviteCode: '',
      serviceRate: props.user.serviceRate,
      showTokensRate: false,
    };
  }

  componentWillMount() {
    const { location: { search }, user: { uid } } = this.props;
    if (search) {
      this.setState({ urlQuery: queryString.parse(search.replace('?', '')) }, () => {
        const { urlQuery } = this.state;
        if (urlQuery.payment) {
          ga('set', 'ta-userId', uid);//eslint-disable-line
          ga('send', 'event', 'Payment', `status:${this.state.urlQuery.payment}`, 'Premium', { 'ta-userId': uid });//eslint-disable-line
        }
      });
    }
    fetch('https://www.cbr-xml-daily.ru/daily_json.js')
      .then(r => r.json())
      .then((r) => {
        this.setState({ usdRate: r.Valute.USD.Value });
      });
  }

  componentWillReceiveProps(nextProps) {
    const nextProfile = nextProps.user.currentProfile && nextProps.user.profiles ? nextProps.user.profiles[nextProps.user.currentProfile] : {};
    const { user } = this.props;
    const profile = user.currentProfile && user.profiles ? user.profiles[user.currentProfile] : {};
    if (nextProfile.chaturbateId !== profile.chaturbateId) {
      this.setState({ chaturbateId: nextProfile.chaturbateId });
    }
    if (!deepEqual(nextProps.user.serviceRate, this.state.serviceRate)) {
      this.setState({ serviceRate: nextProps.user.serviceRate });
    }
  }

  onClickSave = () => {
    const {
      oldPassword, password, password2,
    } = this.state;
    const {
      authSetPassword,
    } = this.props;
    if (password) {
      if (password.length < 6) {
        return alert('Password length should be more than 6');
      }
      if (password !== password2) {
        return alert('Passwords should be equal');
      }
      authSetPassword(oldPassword, password);
    }
    return null;
  };

  onChaturbateIdChange = value => this.setState({ chaturbateId: parseChaturbateId(value) });

  onClickPurchase = accountType => () => {
    const { user: { uid }, authGetPaymentLink } = this.props;
    ga('send', 'event', 'Payment', 'click', 'Premium', { dimension1: uid });//eslint-disable-line
    this.setState({
      waitLink: true,
    });
    if (accountType !== 'trial') this.setState({ loadingType: accountType });
    authGetPaymentLink(accountType);
    setTimeout(() => this.setState({ waitLink: false, loadingType: '' }), 5000);
  };

  onClickPayPatreon = () => {
    this.setState({ waitLink: false });
    // required because when user clicked on link
    // it's destroyed by rerender and not opened
    setTimeout(() => {
      const { authPaymentPatreonClear } = this.props;
      authPaymentPatreonClear();
    });
  };

  onHelpClick = () => {
    window.$crisp.push(['do', 'chat:open']);
  };

  onInviteCodeChange = e => this.setState({ inviteCode: e.target.value.replace(/\s/ig, '').toUpperCase() });

  changeTokensRateDebounce = null;

  onChangeTokensRate = (service, rate) => {
    clearTimeout(this.changeTokensRateDebounce);
    const parsedRate = rate.replace(/[^0-9.]/g, '');
    const rateNumber = Number(parsedRate);
    if (rateNumber > 100) return;
    this.setState(({ serviceRate }) => (
      { serviceRate: { ...serviceRate, [service]: parsedRate } }
    ));
    if ((parsedRate === rateNumber.toString() && rateNumber > 0.00001) || rate === '') {
      this.changeTokensRateDebounce = setTimeout(
        () => this.props.authChangeTokensRate(service, rate === '' ? null : rateNumber),
        500,
      );
    }
  };

  onTokesnRateToggle = () => this.setState(({ showTokensRate }) => ({ showTokensRate: !showTokensRate }));

  render() {
    const {
      oldPassword,
      password,
      password2,
      waitLink,
      loadingType,
      inviteCode,
      serviceRate,
      showTokensRate,
    } = this.state;
    const {
      supporter,
      provider,
      user: {
        uid,
        email,
        payments,
        level,
        profiles,
        currentProfile,
        creationTime,
        invite,
        // alfaVersionAccess,
        emailVerified,
      },
      sendInviteStatus,
      history,
      paymentPatreonURL,
      paymentShowFSModal,
      paymentFSFailed,
      authPaymentFSModalShow,
      authPaymentDisableSubscription,
      authPaymentEnableSubscription,
      authSendInvite,
      t,
      authUserGoToNewVersion,
    } = this.props;
    document.title = 'Settings - TipAlerts';
    let showInvitationContainer = false;
    if (!invite && moment(creationTime).add(1, 'day').isAfter(moment())) {
      showInvitationContainer = true;
    }
    const emailView = emailVerified ? email : (
      <span>
        {email} <Link to="/verify?send">Verify</Link>
      </span>
    );
    return (
      <Grid>
        {/* {alfaVersionAccess && <NewVersion authUserGoToNewVersion={authUserGoToNewVersion} />} */}
        <NewVersion authUserGoToNewVersion={authUserGoToNewVersion} />
        <PatreonModal
          t={t}
          paymentPatreonURL={paymentPatreonURL}
          email={email}
          onPayClick={this.onClickPayPatreon}
        />
        <FastSpringModal
          show={paymentShowFSModal}
          failed={Boolean(paymentFSFailed)}
          onHide={() => authPaymentFSModalShow(false)}
        />
        <TitleMain>
          <span>{t.settings}</span>
        </TitleMain>
        <TitleContainer>
          <Title>Account</Title>
          <Line />
        </TitleContainer>
        <Form horizontal>
          {level === 1 && (
            <FormItem
              id="uid"
              title="TipAlerts user id"
              description={uid}
            />)
          }
          <FormItem
            id="email"
            title={t.eMail}
            description={emailView}
          />
          <TitleContainer>
            <Title>Password</Title>
            <Line />
          </TitleContainer>
          <FormItem
            id="passwordOld"
            type="password"
            title={t.oldPassword}
            value={oldPassword}
            onChange={oldPassword => this.setState({ oldPassword })}
          />
          <FormItem
            id="password"
            type="password"
            title={t.newPassword}
            value={password}
            onChange={password => this.setState({ password })}
          />
          <FormItem
            id="password2"
            type="password"
            title={t.repeatNewPassword}
            value={password2}
            onChange={password2 => this.setState({ password2 })}
          />
          <FormItem
            id="submit"
            description={(
              <Col className="pull-right">
                <BSButton bsStyle="success" onClick={this.onClickSave}>
                  {t.save}
                </BSButton>
              </Col>)
            }
          />
          <ChangeService
            profiles={profiles}
            currentProfile={currentProfile}
            history={history}
            serviceRate={serviceRate || {}}
            showTokensRate={showTokensRate}
            onChangeServiceRate={this.onChangeTokensRate}
            onTokesnRateToggle={this.onTokesnRateToggle}
          />
          <Subscription
            supporter={supporter}
            onClickPurchase={this.onClickPurchase}
            onDisableSubscription={(pp) => { if (window.confirm('You really want to disable subscription?')) authPaymentDisableSubscription(pp); }}
            onEnableSubscription={authPaymentEnableSubscription}
            waitLink={waitLink}
            loadingType={loadingType}
            provider={provider}
          />
          {payments && (
          <div>
            <TitleContainer>
              <Title>Payments history</Title>
              <Line />
            </TitleContainer>
            <PaymentsHistory payments={payments} t={t} />
          </div>
          )}
          {showInvitationContainer && (
          <InvitationContainer>
            <TitleContainer>
              <Title>Invitation</Title>
              <Line />
            </TitleContainer>
            <InvitationCodeInput placeholder="Invitation code" value={inviteCode} onChange={this.onInviteCodeChange} />
            <Button type="button" onClick={() => authSendInvite(inviteCode)}>Send</Button>
            {sendInviteStatus === REQUEST_STATUS.FAIL && (
              <InvitationCodeError>Something went wrong</InvitationCodeError>
            )}
          </InvitationContainer>
          )}
          <Suspense fallback={<div>Cannot load component</div>}>
            <Referral />
          </Suspense>
        </Form>
      </Grid>
    );
  }
});
