import React, { Component } from 'react';

import { SupportText } from './style';

export class SupportContainer extends Component {
  render() {
    return (
      <SupportText>
        Support
        <p>
          If you faced any difficulties in installation or setup you can easily reach out to us via
          chat (blue button at right)
        </p>
      </SupportText>
    );
  }
}
