// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActionWidgetsComponentsUpdate, widgetReset } from 'store/reducers/widgets';

import {
  NumberCountInput,
  Section,
  FormItem,
  NewSlider,
} from 'components/UI';

import { widgets } from 'constants';

import {
  TextAppearance,
} from 'components/UIkit';


import styled from 'styled-components';


import 'components/Common/SelectColor/SelectColor.css';

import ModalTooltip from './ModalTooltip';

const Container = styled.div`

`;

const ResetLink = styled.div`
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: rgba(255, 255, 255, 0.54);
margin-top: 28px;
width: fit-content;
border-bottom: 1px dashed ${({ active }) => (active ? 'rgba(255, 255, 255, 0.54)' : 'transparent')};
transition: border-bottom-color 0.2s ease-in-out;
cursor: pointer;

position: relative;

&:hover {
  border-bottom-color: rgba(255, 255, 255, 0.54);
  outline: none;
}
`;


class AppearanceTab extends React.Component {
  state = {
    resetActive: false,
  };

  modal = React.createRef();

  showResetModal = () => {
    this.setState({ resetActive: true }, () => document.addEventListener('click', this.onClick));
  };

  onClick = (e) => {
    const modalNode = ReactDOM.findDOMNode(this.modal.current);
    const isOutClick = !(modalNode && modalNode.contains(e.target));
    if (isOutClick) this.hideResetModal(e);
  };

  hideResetModal = (e) => {
    e.stopPropagation();
    this.setState({ resetActive: false }, () => document.removeEventListener('click', this.onClick));
  };

  resetList = (e) => {
    this.props.widgetReset('top-users');
    this.hideResetModal(e);
  };

  render() {
    const {
      data,
      widgetsComponentsUpdate,
    } = this.props;
    const { resetActive } = this.state;
    return (
      <Container>

        <Section>
          <FormItem label="List appearance" themeFont="header" header />
          <FormItem label="Tippers amount" themeFont="normal">
            <NumberCountInput
              value={data.limit}
              onChange={limit => widgetsComponentsUpdate({ limit })}
              min={0}
              max={99}
              style={{ margin: '0 6px 0 6px' }}
              width="87px"
            />
          </FormItem>
          <FormItem label="Vertical spacing" themeFont="normal">
            <NewSlider
              value={data.marginBottom}
              min={1}
              max={200}
              step={1}
              onChange={spacing => widgetsComponentsUpdate({ marginBottom: spacing })}
            />
          </FormItem>
          <FormItem label="Width" themeFont="normal">
            <NewSlider
              value={data.widthList}
              min={100}
              max={1000}
              step={1}
              onChange={width => widgetsComponentsUpdate({ widthList: width })}
            />
          </FormItem>
        </Section>

        <TextAppearance
          data={data}
          widgetUpdate={widgetsComponentsUpdate}
          mainLabel="List"
          borderTop
        />

        <ResetLink active={resetActive} onClick={this.showResetModal}>
          Reset widget stats
          <ModalTooltip
            ref={this.modal}
            show={resetActive}
            title="Reset statistics?"
            text="Current tippers and tokens data will be erased. This action cannot be undone."
            leftButton={{
              label: 'Cancel',
              onClick: this.hideResetModal,
            }}
            rightButton={{
              label: 'Yes, reset',
              onClick: this.resetList,
            }}
          />
        </ResetLink>
      </Container>
    );
  }
}

AppearanceTab.title = 'List';

export default connect(
  null,
  (dispatch, ownProps) => bindActionCreators({
    widgetsComponentsUpdate: getActionWidgetsComponentsUpdate(widgets.top.id, ownProps.selectedPreset, 'appearance'),
    widgetReset,
  }, dispatch),
)(AppearanceTab);
