import React, { Component } from 'react';
// import Spinner from 'react-spinkit';
import styled from 'styled-components';


const Container = styled.div`
  background: #112737;
  border-radius: 12px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(39, 59, 73, 0.5);
  padding: 30px 135px 10px 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
`;

const Button = styled.button`
  flex-grow: 0;
  cursor: ${({ loading, notClickable }) => ((loading || notClickable) ? 'auto' : 'pointer')};
  background: #FFB65A;
  border-radius: 365px;
  transition: all 0.3s ease-in-out;
  ${({ loading, notClickable }) => ((loading || notClickable) ? '' : '&:hover { opacity: 0.85; }')};
  padding: 8px 60px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  margin-top: 30px;
  margin-bottom: 10px;

  text-align: center;

  color: #112737;
`;

const Cancel = styled.a`
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #FFB65A;
  cursor: pointer;
  border-bottom: 1px solid;
  border-color: rgba(255, 182, 90, 0.34);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  ${({ show }) => !show && `
    opacity: 0;
    pointer-events: none;
  `};

  &:hover { border-color: transparent; color: white; }
`;

const HeadTitle = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;

  text-align: center;

  color: #FFFFFF;
`;

type Props = {
};

export default class ButtonTrial extends Component<Props> {
  onCancelClick = (e) => {
    e.stopPropagation();
    const { onCancel } = this.props;
    onCancel();
  };

  onClick = (e) => {
    e.stopPropagation();
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const {
      title,
      onCancel,
      notClickable,
      loading,
    } = this.props;
    return (
      <Container>
        <HeadTitle>
          Not sure? Try 7 days for free.
        </HeadTitle>
        <Button
          loading={loading}
          notClickable={notClickable}
          onClick={(loading || notClickable) ? () => {} : this.onClick}
        >
          <span>{title}</span>
        </Button>
        <Cancel show={loading && onCancel} onClick={this.onCancelClick}>Cancel</Cancel>
      </Container>
    );
  }
}
