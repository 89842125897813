import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { theme } from 'constants/theme';
import Constants from '../const/const';

export const BackgroundContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Price = styled.div`
  width: calc(${Constants.WidthContent} - 260px);

  display: flex;
  flex-flow: column;

  padding-bottom: 64px;

  box-shadow: inset 0px -1px 0px #405260;
`;

export const Title = styled.div`
  padding-top: 60px;
  padding-bottom: 22px;

  font-weight: 900;
  line-height: 48px;
  font-size: 36px;
  text-align: center;
  > p {
    padding-top: 2px;

    font-weight: normal;
    line-height: 24px;
    font-size: 16px;
  }
`;
export const TablePrice = styled.div`
  display: flex;
  flex-flow: column;
`;

export const TableRow = styled.div`
  display: flex;
  flex-flow: row;
  align-items: baseline;

  background-color: ${props => (props.moreGrey ? '#364755' : '#394B59')};
`;

export const TableColumn1 = styled.div`
  flex: 3;
`;

export const TableColumn23 = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const TextContent = styled.div`
  padding: 8px 30px;

  font-weight: 500;
  line-height: 24px;
  font-size: 16px;

  > p {
    font-weight: normal;
    line-height: 20px;
    font-size: 14px;

    color: ${theme.main.white50};

    text-align: ${props => props.TextAlign || 'left'};
  }
`;

export const TextHeader = styled.div`
  padding: 12px;

  font-weight: 600;
  line-height: 36px;
  font-size: 20px;
  text-align: center;
`;

export const TextPrice = styled.div`
  padding-top: 27px;
  padding-bottom: 34px;

  font-weight: 500;
  line-height: 48px;
  font-size: 48px;
  letter-spacing: -0.03em;

  text-align: center;
  > p {
    line-height: 20px;
    font-size: 14px;
  }
  > span {
    font-size: 24px;
  }
`;

export const TextUSD = styled.div`
  font-weight: 500;
  line-height: 5px;
  font-size: 14px;
  letter-spacing: -0.03em;

  text-align: center;
`;

export const SpanOrange = styled.span`
  color: ${theme.main.orangeLanding};
`;

export const CheckMark = styled.div`
  width: 14px;
  height: 11px;

  background-image: url('/images/check-mark.svg');
`;

export const CheckMarkOrange = styled(CheckMark)`
  background-image: url('/images/check-mark-orange.svg');
`;

export const ButLogTextContainer = styled(TablePrice)`
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px;
`;

export const ButtonStart = styled.button`
  padding: 16px 38px;

  font-weight: bold;
  font-size: 16px;
  color: #32414c;

  border-radius: 100px;
  border: none;

  background-color: ${theme.main.orangeLanding};

  &:focus {
    outline: none;
  }

  &:hover {
    outline: none;
    background-color: ${theme.main.orangeButtonHover};
  }

  &:active {
    outline: none;
    background-color: ${theme.main.orangeButtonActive};
  }
`;

export const LogoContainer = styled.div`
  width: 104px;
  height: 22px;
  background-image: url('/images/visa_ms.svg');
`;

export const RegLink = styled(Link)`
  border-bottom: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    border-bottom: none;
  }
`;
