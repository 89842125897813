import { REHYDRATE } from 'redux-persist/es/constants';

import { locales } from '../../locales';

const SET_LANGUAGE = 'SET_LANGUAGE';

export const setLanguage = lang => ({ type: SET_LANGUAGE, lang });

const DEFAULT_LOCALE = 'en';

const initialState = {
  lang: '',
  t: { ...locales[DEFAULT_LOCALE] },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE: {
      const persistState = action.payload ? action.payload.lang || initialState : initialState;
      return ({
        ...initialState,
        ...state,
        lang: persistState.lang || '',
        t: {
          ...(locales[persistState.lang || state.lang || DEFAULT_LOCALE]),
        },
      });
    }
    case SET_LANGUAGE:
      return ({
        lang: action.lang,
        t: { ...locales[action.lang] },
      });
    default:
      return state;
  }
};
