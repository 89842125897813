// @flow

import type { TopData } from 'types/Widget';
import { defaultFont } from './fonts';

// TODO поправить согласно документации
export const TopDataDefault: TopData = {
  appearance: {
    limit: 3,

    marginBottom: 5,
    widthList: 200,

    color: '#F25AFF',
    fontFamily: defaultFont,
    fontSize: 16,
    fontWeight: 400,
    fontWeightBold: false,
    fontStyle: 'normal', // normal | italic
    textDecorationLineThrough: false,
    textDecorationUnderline: false,
    textTransform: 'none', // none | capitalize | uppercase | lowercase | full-width | full-size-kana | inherit | initial | unset
    textAlign: 'left',

    lineHeight: 100, // percentage

    backgroundColor: 1,
    backgroundEnabled: true,

    textShadowEnabled: true,
    textShadowX: 0,
    textShadowY: 0,
    textShadowBlur: 0,
    textShadowColor: 0,
  },
  title: {
    customMessage: '',

    color: 0,
    fontFamily: defaultFont,
    fontSize: 16,
    fontWeight: 600,
    fontWeightBold: false,
    fontStyle: 'normal', // normal | italic
    textDecorationLineThrough: false,
    textDecorationUnderline: false,
    textTransform: 'none', // none | capitalize | uppercase | lowercase | full-width | full-size-kana | inherit | initial | unset
    textAlign: 'center',

    animationEnabled: false,
    animation: 0,
    animationDuration: 2,

    lineHeight: 100, // percentage

    backgroundColor: 1,
    backgroundEnabled: true,

    textShadowEnabled: true,
    textShadowX: 2,
    textShadowY: 2,
    textShadowBlur: 5,
    textShadowColor: 0,
  },
};
