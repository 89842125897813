import { combineReducers } from 'redux';

import { widgets } from 'constants';

export const WIDGETS_OPTIONS_SELECT_PRESET = 'WIDGETS_OPTIONS_SELECT_PRESET';
export const WIDGETS_OPTIONS_SELECT_TEST_PREVIEW = 'WIDGETS_OPTIONS_SELECT_TEST_PREVIEW';

export const WIDGETS_OPTIONS_RACE_SET_TIME = 'WIDGETS_OPTIONS_RACE_SET_TIME';

export const widgetsOptionsSelectPreset = (widgetId: string, selectedPreset: string) => ({ type: WIDGETS_OPTIONS_SELECT_PRESET, selectedPreset, widgetId });
export const widgetsOptionsSelectTestPreview = () => ({ type: WIDGETS_OPTIONS_SELECT_TEST_PREVIEW });

export const widgetsOptionsRaceSetTime = (payload: { [name: string]: number }) => ({ type: WIDGETS_OPTIONS_RACE_SET_TIME, payload });

const tipsInitialState = {
  selectedPreset: 'default',
  selectedTestPreview: false,
};

const tips = (state = { ...tipsInitialState }, action) => {
  switch (action.type) {
    case WIDGETS_OPTIONS_SELECT_PRESET: {
      if (action.widgetId !== widgets.tips.id) return state;
      return {
        ...state,
        selectedPreset: action.selectedPreset,
      };
    }
    case WIDGETS_OPTIONS_SELECT_TEST_PREVIEW:
      return {
        ...state,
        selectedTestPreview: true,
      };
    default:
      return state;
  }
};

const goalsInitialState = {
  selectedPreset: '',
};

const goals = (state = { ...goalsInitialState }, action) => {
  switch (action.type) {
    case WIDGETS_OPTIONS_SELECT_PRESET: {
      if (action.widgetId !== widgets.goals.id) return state;
      return {
        ...state,
        selectedPreset: action.selectedPreset,
      };
    }
    default:
      return state;
  }
};

// const topInitialState = {
//   selectedPreset: 'default',
// };
//
// const top = (state = { ...topInitialState }, action) => {
//   switch (action.type) {
//     case WIDGETS_OPTIONS_SELECT_PRESET: {
//       if (action.widgetId !== widgets.top.id) return state;
//       return {
//         ...state,
//         selectedPreset: action.selectedPreset,
//       };
//     }
//     default:
//       return state;
//   }
// };
//
// const diceInitialState = {
//   selectedPreset: 'default',
// };
//
// const dice = (state = { ...diceInitialState }, action) => {
//   switch (action.type) {
//     case WIDGETS_OPTIONS_SELECT_PRESET: {
//       if (action.widgetId !== widgets.dice.id) return state;
//       return {
//         ...state,
//         selectedPreset: action.selectedPreset,
//       };
//     }
//     default:
//       return state;
//   }
// };

const raceInitialState = {};

const race = (state = { ...raceInitialState }, action) => {
  switch (action.type) {
    case WIDGETS_OPTIONS_RACE_SET_TIME: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  tips,
  goals,
  // top,
  // dice,
  race,
});
