import styled, { css } from 'styled-components';

export const Col = styled.div`
  margin-right: 16px;
`;

export const Row = styled.div`
  display: flex;
  align-items: ${({ baseline }) => (baseline ? 'baseline' : 'center')};
  ${({ disabled }) => disabled && css`
    opacity: 0.54;
    pointer-events: none;
  `};
`;

export const Col1 = styled.div`
  flex: 1;
  margin-right: ${({ marginRight }) => marginRight}px;
  ${({ flexEnd }) => flexEnd && css`
    justify-content: flex-end;
    display: flex;
  `};
`;

export const Label = styled.span`
  margin-right: 12px;
`;
