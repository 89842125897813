import React, { PureComponent } from 'react';
import Prism from 'prismjs';

export class UserInfo extends PureComponent {
  render() {
    const { info } = this.props;
    const hlCode = Prism.highlight(JSON.stringify(info || {}, null, 2), Prism.languages.json, 'json');
    return <pre style={{ backgroundColor: '#0000003d' }}><code style={{ fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: hlCode }} /></pre>;
  }
}
