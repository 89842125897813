import React from 'react';
import { connect } from 'react-redux';

import './Refund.css';

export const Refund = connect(({ lang: { t } }) => ({ t }))(({ t }) => (
  <div className="Refund">
    <h3>{t.refund}</h3>
    <div>
      {t.refundIfYouWant}
      <a href="mailto:refund@tipalerts.com">refund@tipalerts.com</a>
    </div>
    <div>
      {t.refundLimitations}
    </div>
    <div style={{ marginTop: '40px' }}>
      <h3>{t.payments}</h3>
      <div>
        {t.paymentsInfo}
      </div>
      <div style={{ marginTop: '10px' }}>
        <img src="/images/visa_ms_mir.svg" />
      </div>
    </div>
    <div style={{ marginTop: '40px' }}>
      <h3>{t.securityLine1}</h3>
      <p>{t.securityLine2}</p>
      <p>{t.securityLine3}</p>
      <p>{t.securityLine4}</p>
      <p>{t.securityLine5}</p>
    </div>
  </div>
));
