// @flow
/* eslint-disable */
import React from 'react';
import deepEqual from 'deep-equal';

import styled from 'styled-components';

import type { GoalConfiguration, GoalData } from 'types/Widget';

import { UserImage } from 'components';
import { GoalsDefaultData, widgetFonts } from 'constants';
import {
  getColor,
  getAnimation,
} from 'utils';

import '../../animatedElements.css';

const Container = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const TextContainer = styled.div`
  text-align: center;
`;

const Text = styled.span`
  color: black;
  margin: 5px;
  display: inline-block;

  padding: 0.108em 0.216em;
  border-radius: 0.216em;
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GoalBarContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 1.4em;
`;
const GoalBarValueContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  height: 1.4em;
  align-items: flex-start;
`;
const GoalBarPercentContainer = styled(GoalBarValueContainer)`
  align-items: center;
`;
const GoalBarReceivedContainer = styled(GoalBarValueContainer)`
  align-items: flex-end;
`;
const GoalBar = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  border-style: solid;
`;
const GoalBarFill = styled.div`
  height: 100%;
  transition: width 0.5s;
`;
const GoalValue = styled.span`
  padding: 0em 0.216em;
  border-radius: 0.216em;
  height: 1.4em;
  font-size: 1em;
`;

const getTextDecoration = (lineThrough, underline) => {
  let str = '';
  if (lineThrough) {
    str += 'line-through';
    if (underline) {
      str += ' ';
    }
  }
  if (underline) {
    str += 'underline';
  }
  return str;
};

type Props = GoalData & GoalConfiguration;

const shadow = shadowData => `${shadowData.textShadowX}px ${shadowData.textShadowY}px ${shadowData.textShadowBlur}px ${getColor(shadowData.textShadowColor)}`;

class GoalTitle extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { titleStyle } = this.props;
    return !deepEqual(titleStyle, nextProps.titleStyle);
  }

  render() {
    const { titleStyle } = this.props;
    const title = {
      ...GoalsDefaultData.title,
      ...(titleStyle || {}),
    };
    if (!title.customMessage) return null;
    return (
      <TextContainer
        className={`${
          title.animationEnabled
            ? `RenderTips_Message--animated_${getAnimation(title.animation)}` : ''
        }`}
        style={{ animationDuration: `${title.animationDuration}s` }}
      >
        <Text
          style={{
            transform: `rotate(${title.transformRotate}deg)`,
            fontFamily: widgetFonts[title.fontFamily],
            color: getColor(title.color),
            fontSize: `${title.fontSize}px`,
            textShadow: title.textShadowEnabled && shadow(title),
            backgroundColor: title.backgroundEnabled && getColor(title.backgroundColor),
            fontWeight: title.fontWeightBold ? 'bold' : title.fontWeight,
            fontStyle: title.fontStyle,
            textDecoration: getTextDecoration(
              title.textDecorationLineThrough,
              title.textDecorationUnderline,
            ),
            textTransform: title.textTransform,
          }}
        >
          {title.customMessage}
        </Text>
      </TextContainer>
    );
  }
}

class GoalImage extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { imageStyle } = this.props;
    return !deepEqual(imageStyle, nextProps.imageStyle);
  }

  render() {
    const { imageStyle } = this.props;
    const image = {
      ...GoalsDefaultData.image,
      ...(imageStyle || {}),
    };
    if ((image.image || image.image === 0) && image.enabled) {
      return (
        <UserImage
          className={image.animationEnabled ? `RenderTips_TipImage--animated_${getAnimation(image.animation)}` : ''}
          style={{
            animationDuration: `${image.animationDuration}s`,
            opacity: `${image.opacity}`,
          }}
          source={image.image}
        />
      );
    }
    return null;
  }
}

class GoalProgressBar extends React.Component {
  shouldComponentUpdate(nextProps) {
    const { progressBarStyle, value, goalValue } = this.props;
    return !deepEqual(progressBarStyle, nextProps.progressBarStyle) || value !== nextProps.value || goalValue !== nextProps.goalValue;
  }

  render() {
    const { progressBarStyle, value, goalValue } = this.props;
    const progressBar = {
      ...GoalsDefaultData.progressBar,
      ...(progressBarStyle || {}),
    };
    const borderColor = getColor(progressBar.barBorderColor);
    const progress = Math.round(Number(value) / Number(goalValue) * 100);
    const textStyle = {
      color: getColor(progressBar.color),
      fontFamily: widgetFonts[progressBar.fontFamily],
      transform: `rotate(${progressBar.textTransformRotate}deg)`,
      textShadow: progressBar.textShadowEnabled && shadow(progressBar),
      backgroundColor: progressBar.textBackgroundEnabled && getColor(progressBar.textBackgroundColor),
      fontWeight: progressBar.fontWeightBold ? 'bold' : progressBar.fontWeight,
      fontStyle: progressBar.fontStyle,
      textDecoration: getTextDecoration(
        progressBar.textDecorationLineThrough,
        progressBar.textDecorationUnderline,
      ),
      textTransform: progressBar.textTransform,
    };
    const goalValueContainerStyle = { paddingLeft: +progressBar.barBorderWidth + 10, paddingRight: +progressBar.barBorderWidth + 10 };
    return (
      <GoalBarContainer style={{ fontSize: progressBar.fontSize, width: progressBar.hideFill ? 'auto' : '100%' }}>
        <GoalBar
          style={{
            borderRadius: Number(progressBar.barBorderRadius),
            backgroundColor: getColor(progressBar.barBackgroundColor),
            boxShadow: `0px 0px ${progressBar.barBorderBlur}px ${borderColor}`,
            borderWidth: progressBar.barBorderWidth,
            borderColor: getColor(progressBar.barBorderColor),
          }}
        >
          {progressBar.hideFill ? null : <GoalBarFill
            style={{
              width: `${progress}%`,
              backgroundColor: getColor(progressBar.barForegroundColor),
            }}
          />}
        </GoalBar>
        {progressBar.hideFill && progressBar.hideReceivedToken ? null : <GoalBarValueContainer style={goalValueContainerStyle}>
          {!progressBar.hideReceivedToken && (
            <GoalValue style={textStyle}>
              {value}
            </GoalValue>
          )}
        </GoalBarValueContainer>}
        {progressBar.hideFill && progressBar.hideCurrentPercent ? null : <GoalBarPercentContainer style={goalValueContainerStyle}>
          {!progressBar.hideCurrentPercent && (
            <GoalValue style={textStyle}>
              {`${progress}\xA0%`}
            </GoalValue>
          )}
        </GoalBarPercentContainer>}
        {progressBar.hideFill && progressBar.hideValueTokens ? null : <GoalBarReceivedContainer style={goalValueContainerStyle}>
          {!progressBar.hideValueTokens && (
            <GoalValue style={textStyle}>
              {goalValue}
            </GoalValue>
          )}
        </GoalBarReceivedContainer>}
      </GoalBarContainer>
    );
  }
}

export class GoalView extends React.Component<Props> {
  shouldComponentUpdate(nextProps) {
    const { data } = this.props;
    return !deepEqual(data, nextProps.data);
  }

  render() {
    const { data = {} } = this.props;
    const {
      preset,
    } = {
      preset: {
        ...GoalsDefaultData.preset,
        ...(data.preset || {}),
      },
    };

    return (
      <Container style={{ transform: `translateY(${preset.transformVerticalPosition}%)` }}>
        <div style={{ transform: `rotate(${preset.transformRotate}deg) scale(${preset.transformScale})` }}>
          <GoalTitle titleStyle={data.title} />
          <ProgressContainer>
            <GoalImage imageStyle={data.image} />
            <GoalProgressBar progressBarStyle={data.progressBar} value={preset.value} goalValue={preset.goalValue} />
          </ProgressContainer>
        </div>
      </Container>
    );
  }
}
