const delay = 100; // speed (time different between 1 -> 2)
const rollTime = 5000;

export default {
  delay,
  delayInSec: delay / 1000,
  rollTime,
  rollTimeInSec: rollTime / 1000,
  rollFlashingTime: 4400,
  timeBetweenRolls: 2000,
};
