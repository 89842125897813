import React, { PureComponent } from 'react';

import {
  DropDown,
} from 'components/UI';

import { Service_ID } from 'constants/service';

import {
  SearchContainer,
  CustomTextInput,
  CustomButton,
  SearchByTypeWrapper,
  BadgeBasic,
  BadgePremium,
  BadgeTrial,
} from './UI';

const searchByTypes = ['email', ...Object.values(Service_ID)];

export class Search extends PureComponent {
  state = {
    searchByType: 0,
  };

  onChangeEmail = e => this.props.logsInputEmail(e.target.value.trim());

  onGetInfoClick = () => this.props.logsUserGetInfo(this.props.email, searchByTypes[this.state.searchByType]);

  onSelectSearchByType = index => this.setState({ searchByType: index });

  render() {
    const {
      email,
      token,
      payment,
      logsUserImpersonate,
      logsActivateTrial,
    } = this.props;
    const { searchByType } = this.state;
    return (
      <SearchContainer>
        <CustomTextInput value={email} onChange={this.onChangeEmail} placeholder="Search by" />
        <SearchByTypeWrapper>
          <DropDown width="110px" items={searchByTypes} value={searchByType} onSelect={this.onSelectSearchByType} />
        </SearchByTypeWrapper>
        <CustomButton
          onClick={this.onGetInfoClick}
        >
          Get info
        </CustomButton>
        {token && <CustomButton onClick={logsUserImpersonate}>Impersonate</CustomButton>}
        {email && <CustomButton onClick={logsActivateTrial}>Activate trial</CustomButton>}
        {(payment && payment.special === 'trial' && <BadgeTrial>Trial</BadgeTrial>)}
        {(payment && payment.active && !(payment.special === 'trial') && <BadgePremium>Premium</BadgePremium>)}
        {(payment && payment.basic && !(payment.special === 'trial') && !payment.active && <BadgeBasic>Basic</BadgeBasic>)}
      </SearchContainer>
    );
  }
}
