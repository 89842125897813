// @flow

import type { TipAlertData } from 'types/Widget';
import { defaultFont } from './fonts';

export const TipAlertDataDefault: TipAlertData = {
  amount: {
    enabled: true,

    color: 0,
    fontFamily: defaultFont,
    fontSize: 16,
    fontWeight: 600,
    fontWeightBold: false,
    fontStyle: 'normal', // normal | italic
    textDecorationLineThrough: false,
    textDecorationUnderline: false,
    textTransform: 'none', // none | capitalize | uppercase | lowercase | full-width | full-size-kana | inherit | initial | unset
    textAlign: 'left',

    lineHeight: 100, // percentage

    animationEnabled: false,
    animation: 0,
    animationDuration: 2,

    backgroundColor: 1,
    backgroundEnabled: false,

    horizontalPosition: 1,
    verticalPosition: 2,

    transformHorizontally: 0,
    transformVertically: 0,
    transformRotate: 0,

    textShadowEnabled: true,
    textShadowX: 0,
    textShadowY: 0,
    textShadowBlur: 0,
    textShadowColor: 0,
  },
  tipper: {
    enabled: true,

    color: 0,
    fontFamily: defaultFont,
    fontSize: 16,
    fontWeight: 600,
    fontWeightBold: false,
    fontStyle: 'normal', // normal | italic
    textDecorationLineThrough: false,
    textDecorationUnderline: false,
    textTransform: 'none', // none | capitalize | uppercase | lowercase | full-width | full-size-kana | inherit | initial | unset
    textAlign: 'left',

    lineHeight: 100, // percentage

    animationEnabled: false,
    animation: 0,
    animationDuration: 2,

    backgroundColor: 1,
    backgroundEnabled: false,

    horizontalPosition: 1,
    verticalPosition: 0,

    transformHorizontally: 0,
    transformVertically: 0,
    transformRotate: 0,

    textShadowEnabled: true,
    textShadowX: 0,
    textShadowY: 0,
    textShadowBlur: 0,
    textShadowColor: 0,
  },
  message: {
    customMessage: '',

    color: 0,
    fontFamily: defaultFont,
    fontSize: 16,
    fontWeight: 600,
    fontWeightBold: false,
    fontStyle: 'normal', // normal | italic
    textDecorationLineThrough: false,
    textDecorationUnderline: false,
    textTransform: 'none', // none | capitalize | uppercase | lowercase | full-width | full-size-kana | inherit | initial | unset

    lineHeight: 100, // percentage
    textAlign: 'center',

    animationEnabled: false,
    animation: 0,
    animationDuration: 2,

    backgroundColor: 1,
    backgroundEnabled: false,

    horizontalPosition: 1,
    verticalPosition: 1,

    transformHorizontally: 0,
    transformVertically: 0,
    transformRotate: 0,

    textShadowEnabled: true,
    textShadowX: 0,
    textShadowY: 0,
    textShadowBlur: 0,
    textShadowColor: 0,
  },
  image: {
    enabled: true, /* 0 - 9999: true, -1: false */
    opacity: 1,
    image: 0,

    poolRandom: [],
    onPoolRandom: false,

    animationEnabled: false,
    animation: 0,
    animationDuration: 2,
  },
  sound: {
    enabled: false, /* 0 - 9999: true, -1: false */
    volume: 100,
    sound: 0, /* 0 - 9999 */
  },
  preset: {
    enabled: true,
    from: 0,
    to: 0,
    animationDurationMode: 0,
    animationDuration: 5,
    animation: 0,
    splitAlertsByOneToken: false,
    transformScale: 1,
  },
};
