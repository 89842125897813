import React, { Component } from 'react';

import { Circle } from 'components';

import {
  Container,
  ButtonContainer,
  Button,
  ConnectedAS,
  CircleContainer,
} from './style';


export class ConnectionSuccess extends Component {
  render() {
    const {
      id,
      onBack,
      onDone,
    } = this.props;
    return (
      <Container>
        <CircleContainer>
          <Circle size="24" colorСircle="#5AFFC4" colorCheck="#2B4152" />
        </CircleContainer>
        <ConnectedAS>
          Connected as
          <p>{id}</p>
        </ConnectedAS>
        <ButtonContainer>
          <Button type="button" onClick={onBack}>Back</Button>
          <Button type="button" onClick={onDone} orange>Done</Button>
        </ButtonContainer>
      </Container>
    );
  }
}
