import React from 'react';
import styled, { css } from 'styled-components';

const Label = styled.div`
  display: flex;
  ${({ theme, themeFont }) => theme.font[themeFont]};
  padding-right: 16px;
`;

const Children = styled.div`
  display: flex;
`;

const Container = styled.div`
  display: flex;

  min-height: 36px;

  margin-bottom: 12px;

  align-items: ${({ flexStart }) => (flexStart ? 'flex-start' : 'center')};

  ${({ header }) => (
    header
      ? css`
      ${Label} {
        flex: 1;
      }
      ${Children} {
        justify-content: flex-end;
      }`
      : css`
      ${Label} {
        flex: 1;
        opacity: 0.85;
      }
      ${Children} {
        flex: 3;
      }`
  )};

`;

export const FormItem = ({
  label,
  children,
  themeFont,
  header,
  flexStart,
  style,
}) => (
  <Container
    header={header}
    flexStart={flexStart}
    style={style}
  >
    <Label themeFont={themeFont}>
      {label}
    </Label>
    <Children>
      {children}
    </Children>
  </Container>
);
