import React, { Component } from 'react';

import {
  BackgroundHeader,
  Header,
  TextColumn,
  TextHeader,
  ButtonContainer,
  ButtonConnectFree,
  RegLink,
  YouTubePromoContainer,
} from './style';


export class HeaderContent extends Component {
  render() {
    return (
      <BackgroundHeader>
        <Header>
          <TextColumn>
            <TextHeader>
              TipAlerts are tools for streamers
              <p>
                Create a unique show with animated alerts, goals,
                top tippers stats and roll the dice game.
                Reward those viewers who support you and get new ones.
              </p>
            </TextHeader>
            <ButtonContainer>
              <RegLink to="/signup">
                <ButtonConnectFree>Try for free</ButtonConnectFree>
              </RegLink>
            </ButtonContainer>
          </TextColumn>
          <YouTubePromoContainer>
            <iframe
              title="Video TipAlerts channel"
              width="948"
              height="533"
              src="https://www.youtube.com/embed/aj6HEK11SX4"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </YouTubePromoContainer>
        </Header>
      </BackgroundHeader>
    );
  }
}
