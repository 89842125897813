/* eslint-disable */
import React from 'react';
import styled, { keyframes, css } from 'styled-components';

import { getColor } from 'utils';
import { animationConfig, widgetFonts } from 'constants';

const getTextDecoration = (lineThrough, underline) => {
  let str = '';
  if (lineThrough) {
    str += 'line-through';
    if (underline) {
      str += ' ';
    }
  }
  if (underline) {
    str += 'underline';
  }
  return str;
};

const shadow = data => `${data.textShadowX}px ${data.textShadowY}px ${data.textShadowBlur}px ${getColor(data.textShadowColor)}`;

const setStyle = ({ customStyle }) => (
  /* background-color: ${customStyle.backgroundEnabled ? getColor(customStyle.backgroundColor) : 'transparent'}; */
  ` color: ${getColor(customStyle.color)};
    font-family: ${widgetFonts[customStyle.fontFamily]};
    text-shadow: ${customStyle.textShadowEnabled && shadow(customStyle)};
    font-weight: ${customStyle.fontWeightBold ? 'bold' : customStyle.fontWeight};
    font-style: ${customStyle.fontStyle};
    text-decoration: ${getTextDecoration(customStyle.textDecorationLineThrough, customStyle.textDecorationUnderline)};
    text-transform: ${customStyle.textTransform};
    `
);


const check = colorText => keyframes`
  0% {
    background-color: transparent;
  }
  33% {
    background-color: ${colorText};
  }
  66% {
    background-color: ${colorText};
  }
  100% {
    background-color: transparent;
  }
`;

const fadeInOut = colorText => keyframes`
  0% {
    background-color: transparent;
  }
  10% {
    background-color: ${colorText};
  }
  100% {
    background-color: transparent;
  }
`;

const gradient = (deg, backgroundColor) =>
css`
  background: linear-gradient(
    ${deg}deg,
    rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, ${backgroundColor.a*0.7}) 0%,
    rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, ${backgroundColor.a}) 55px,
    rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, ${backgroundColor.a}) 100%);
`;

const Container = styled.div`
  ${({ customStyle }) => (customStyle.backgroundEnabled && gradient(180, getColor(customStyle.backgroundColor)))};

  border-radius: 0 0 6px 6px;
  margin: 0px 16px 0px;
  padding: 12px 0px 8px;

  text-align: left;

  ${({ screenPosition }) => ((screenPosition === 'top-right'
  && css`
    transform: scale(-1, 1);
  `) || (screenPosition === 'bottom-left'
  && css`
    transform: scale(1, -1);
    border-radius: 6px 6px 0 0;
    padding: 12px 0px 16px;
    ${({ customStyle }) => (customStyle.backgroundEnabled && gradient(360, getColor(customStyle.backgroundColor)))};
  `) || (screenPosition === 'bottom-right'
  && css`
    transform: scale(-1, -1);
    border-radius: 6px 6px 0 0;
    padding: 12px 0px 16px;
    ${({ customStyle }) => (customStyle.backgroundEnabled && gradient(360, getColor(customStyle.backgroundColor)))};
  `))}
`;

const PrizeView = styled.div`
  padding: 0px 12px;

  &.prize-animate-0, &.prize-animate-1 {
    background-color: transparent;
    animation:
     ${({ customStyle }) => fadeInOut(customStyle.accentColor)}
     ${({ animateTime }) => animateTime}s
     ${animationConfig.delayInSec}s
     ease-out
     forwards;
  }

  &.prize-check {
    background-color: transparent;
    animation: ${({ customStyle }) => check(customStyle.accentColor)} 0.6s 0s ease-in infinite;
  }

  &.prize-chosen {
    background-color: ${({ customStyle }) => customStyle.accentColor};
  }

  background-color: transparent;

  transition: background-color 3s ease-out;

  ${setStyle};
  line-height: 30px;
`;

const NoPrizes = styled.div`
  background-color: transparent;
  line-height: 30px;
  padding: 0px 12px;

  ${setStyle};
`;

class Prize extends React.Component {
  state = {
    animation: '', // prize-animate-0 | prize-animate-1 | prize-check | prize-chosen
  };

  checkTimout = null;
  chosenTimout = null;

  static getDerivedStateFromProps(props, state) {
    if (props.stop) return { animation: '' };
    if (props.chosen && state.animation !== 'prize-chosen') {
      return { ...state, animation: 'prize-check' };
    }
    if (props.start && !props.chosen) {
      if (state.animation === 'prize-animate-0') return { ...state, animation: 'prize-animate-1' };
      return { ...state, animation: 'prize-animate-0' };
    }
    return null;
  }

  componentDidUpdate({ stop }) {
    if (this.props.stop && stop !== this.props.stop) clearTimeout(this.chosenTimout);
  }

  componentWillUnmount() {
    clearTimeout(this.checkTimout);
    clearTimeout(this.chosenTimout);
    if (this.props.chosen) {
      this.props.onChosenUnmount();
    }
  }

  onAnimationStart = () => {
    if (this.props.chosen) {
      this.checkTimout = setTimeout(() => {
        this.chosenTimout = this.setState({ animation: 'prize-chosen' });
        setTimeout(() => {
          this.props.onAnimationEnd();
        }, animationConfig.rollFlashingTime / 4);
      }, animationConfig.rollFlashingTime);
    }
  };

  onAnimationEnd = () => this.setState({ animation: '' });

  render() {
    const {
      length,
      children,
      customStyle,
    } = this.props;
    const { animation } = this.state;
    const animateTime = animationConfig.delayInSec * (length / 1.5);


    return (
      <PrizeView
        key={animation}
        className={animation}

        animateTime={animateTime}
        onAnimationEnd={this.onAnimationEnd}
        onAnimationStart={this.onAnimationStart}

        customStyle={customStyle}
      >
        {children === '' ? <br /> : children}
      </PrizeView>
    );
  }
}

export class PrizeList extends React.Component {
  static defaultProps = {
    chosen: null, // for stop iteration with delay
    prizes: [], // firebase return prizes like array ¯\_(ツ)_/¯
  };

  state = {
    iterationCount: null,
    chosen: null, // for update
  };

  interval = null;

  componentDidMount() {
    if (this.props.roll && this.props.prizes.length) {
      this.startRoll();
    }
  }

  componentDidUpdate({ prizes: prizesPrev = [], roll: rollPrev }) {
    const { prizes = [], roll } = this.props;
    if (!roll && roll !== rollPrev) {
      return this.resetAnimation();
    }
    if (prizes && roll && roll !== rollPrev) {
      this.startRoll();
    }
  }

  componentWillUnmount () {
    clearInterval(this.interval);
  }

  startRoll = () => {
    this.resetAnimation();
    this.props.onAnimationStart();
    // roll
    this.interval = setInterval(() => {
      const { chosen: _chosen, prizes = [] } = this.props;
      const { iterationCount: _iterationCount } = this.state;
      const next = _iterationCount || _iterationCount === 0 ? _iterationCount + 1 : 0;
      const iterationCount = next > prizes.length - 1 ? 0 : next;
      this.setState({ iterationCount });

      // check on change length of prizes in animation time
      // if prize index not in range it will be the last
      let chosen = _chosen;
      if ((chosen || chosen === 0) && prizes.length - 1 < chosen) {
        chosen = prizes.length - 1;
      }

      if (parseInt(chosen) === iterationCount) {
        clearInterval(this.interval);
        this.setState({ chosen: parseInt(chosen), iterationCount: null });
      }
    }, animationConfig.delay);
  };

  resetAnimation = () => {
    clearInterval(this.interval);
    this.setState({ iterationCount: null, chosen: null });
  };

  onChosenUnmount = () => {
    const { chosen: _chosen, prizes = [] } = this.props;
    let chosen = _chosen;
    if ((chosen || chosen === 0) && prizes.length - 1 < chosen) {
      chosen = prizes.length - 1;
      this.setState({ chosen: parseInt(chosen) });
    }
  };

  onAnimationEnd = () => {
    this.props.onAnimationEnd();
  };

  render() {
    const {
      prizes = [],
      roll,

      screenPosition,
      customStyle,
    } = this.props;
    const { iterationCount, chosen } = this.state;

    const lineHeight = Math.round(customStyle.fontSize * 1.35);

    return (
      <Container customStyle={customStyle} screenPosition={screenPosition}>
        {prizes.length ? prizes.map((prize, i) => (
          <Prize
            key={i}
            length={prizes.length}
            start={iterationCount === i}
            chosen={chosen === i}
            onAnimationEnd={this.onAnimationEnd}
            onChosenUnmount={this.onChosenUnmount}
            stop={!roll}

            customStyle={customStyle}
          >
            {prize}
          </Prize>)
        ) : <NoPrizes customStyle={customStyle}>No prizes yet</NoPrizes>}
      </Container>
    );
  }
}
