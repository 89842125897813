import React, { Component } from 'react';
import {
  Button,
  Modal,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { authPaymentEnableSubscription } from 'store/reducers/auth';

const mapDispatchToProps = d => bindActionCreators({ authPaymentEnableSubscription }, d);

const mapStateToProps = ({ auth }) => ({
  paymentContactModal: auth.paymentContactModal,
  paymentContactModalProgress: auth.paymentContactModalProgress,
});

class AutopayModalBase extends Component {
  state = {
    showModal: false,
  };

  componentDidMount() {
    if (location.search.indexOf('mdAutopay') !== -1) { // eslint-disable-line
      this.setState({ showModal: true });
    }
  }

  hideModal = () => {
    location.href = '/settings'; // eslint-disable-line
  };

  enableAutopay = () => {
    this.props.authPaymentEnableSubscription();
    setTimeout(this.hideModal, 1500);
  };

  render() {
    return (
      <Modal
        show={this.state.showModal}
        onHide={this.hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Would you want to enable monthly subscription?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          With subscription enabled, we will charge you automatically
          when your current payment period expires.
          <br />
          You can deactivate subscription anytime in your account Settings page.
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            onClick={this.enableAutopay}
          >
            Enable
          </Button>
          <Button
            bsStyle="default"
            onClick={this.hideModal}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export const AutopayModal = connect(mapStateToProps, mapDispatchToProps)(AutopayModalBase);
