import React from 'react';

import {
  Section,
  FormItem,
  ShadowBlock,
  NumberCountInput,
} from 'components/UI';

import {
  Col,
  Row,
  Label,
} from './style';

export default class BarAppearance extends React.Component {
  render() {
    const {
      data,
      widgetUpdate,
      borderTop,
    } = this.props;
    const label = 'Bar appearance';
    const emptyData = { color: data.barBackgroundColor };
    const filledData = { color: data.barForegroundColor };
    const borderData = {
      color: data.barBorderColor,
      borderWidth: data.barBorderWidth,
      borderBlurShadow: data.barBorderBlur,
    };
    return (
      <Section borderTop={borderTop}>
        <FormItem label={label} themeFont="header" header />
        <Row>
          <Col>
            <ShadowBlock
              data={emptyData}
              onChange={({ color: barBackgroundColor }) => widgetUpdate({ barBackgroundColor })}
              title="Empty"
            />
          </Col>
          <Col>
            <ShadowBlock
              data={filledData}
              onChange={({ color: barForegroundColor }) => widgetUpdate({ barForegroundColor })}
              title="Filled"
            />
          </Col>
          <Col>
            <ShadowBlock
              data={borderData}
              onChange={({ color: barBorderColor, borderWidth: barBorderWidth, borderBlurShadow: barBorderBlur }) => widgetUpdate({ barBorderColor, barBorderWidth, barBorderBlur })}
              title="Border"
              options
            />
          </Col>
          <Col>
            <Label>
              Corners
            </Label>
            <NumberCountInput
              value={data.barBorderRadius}
              onChange={barBorderRadius => widgetUpdate({ barBorderRadius })}
              min={0}
              max={99}
              width="87px"
            />
          </Col>

        </Row>
      </Section>
    );
  }
}
