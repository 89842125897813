import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';

const popUpOpen = keyframes`
  from{
    transform: translateY(10px);
    opacity: 0;
  }
  to{
    transform: translateY(0px);
    opacity: 1;
  }
`;

const StyledModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  border-radius: 6px;
  padding: 16px 16px 12px 16px;

  background-color: #9AD8FF;
  color: #192229;

  font-weight: normal;
  font-size: 14px;
  line-height: 18px;


  position: absolute;
  top: 40px;
  right: ${({ visibleLog }) => visibleLog ? '118px' : '65px'};

  animation-name: ${popUpOpen};
  animation-duration: 0.2s;
  animation-timing-function: ease-out;

  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15), 0px 8px 12px rgba(0, 0, 0, 0.15);

  &:after {
    content: " ";
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #9AD8FF transparent;
  }
`;

const ButtonClosed = styled.button`
  font-weight: bold;
  width: fit-content;
  padding: 9px 8px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin-top: 8px;
  border: none;
  outline: none;

  &:hover {
    background: rgba(0, 0, 0, 0.12);
  }
  &:active {
    background: rgba(0, 0, 0, 0.14);
  }

  transition: background 0.2s ease-in-out;
`;


class TwitterModalBase extends Component {
  state = {
    showModal: false,
  };

  modal = React.createRef();

  componentDidMount() {
    const isHide = localStorage.getItem('hideTwitterPopUp');
    if (!isHide) {
      setTimeout(
        this.showModal,
        3000,
      );
    }
  }

  showModal = () => {
    this.setState(
      { showModal: true },
    );
  };

  hideModal = (e) => {
    e.stopPropagation();
    this.setState(
      { showModal: false },
    );
    localStorage.setItem('hideTwitterPopUp', true);
  };

  render() {
    const {
      user,
      hide,
      visibleLog,
    } = this.props;
    const {
      showModal,
    } = this.state;
    if (!(user && !hide && showModal)) return null;
    return (
      <StyledModal ref={this.modal} visibleLog={visibleLog}>
        Check out our Twitter for latest news and updates!
        <ButtonClosed
          onClick={this.hideModal}
        >
          Close
        </ButtonClosed>
      </StyledModal>
    );
  }
}

export const TwitterModal = TwitterModalBase;
