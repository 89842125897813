// @flow

import type { DiceData } from 'types/Widget';
import { defaultFont } from './fonts';

export const DiceDataDefault: DiceData = {
  widget: {
    amount: 1,
    rolls: 1,
    screenPosition: 'top-left', /* + */
  },

  prizeList: {
    hide: false,

    prizes: [],

    accentColor: '#ffb65a',

    color: '#F25AFF',
    fontFamily: defaultFont,
    fontSize: 16,
    fontWeight: 400,
    fontWeightBold: false,
    fontStyle: 'normal', // normal | italic
    textDecorationLineThrough: false,
    textDecorationUnderline: false,
    textTransform: 'none', // none | capitalize | uppercase | lowercase | full-width | full-size-kana | inherit | initial | unset
    textAlign: 'left',

    lineHeight: 100, // percentage

    backgroundColor: '#FFFFFFCC',
    backgroundEnabled: true,

    textShadowEnabled: true,
    textShadowX: 0,
    textShadowY: 0,
    textShadowBlur: 0,
    textShadowColor: 0,

  },

  notifications: {
    hide: false,

    prizeDuration: 30,

    accentColor: '#ffb65a',

    color: '#F25AFF',
    fontFamily: defaultFont,
    fontSize: 16,
    fontWeight: 400,
    fontWeightBold: false,
    fontStyle: 'normal', // normal | italic
    textDecorationLineThrough: false,
    textDecorationUnderline: false,
    textTransform: 'none', // none | capitalize | uppercase | lowercase | full-width | full-size-kana | inherit | initial | unset
    textAlign: 'left',

    lineHeight: 100, // percentage

    backgroundColor: '#FFFFFFCC',
    backgroundEnabled: true,

    textShadowEnabled: true,
    textShadowX: 0,
    textShadowY: 0,
    textShadowBlur: 0,
    textShadowColor: 0,
  },
  sounds: {
    soundAnnounce: false,
    soundRoll: false,
    soundPrize: false,
    volume: 100,
  },
};

export const dicePositions = {
  'top-left': {
    title: 'Top-left',
    reversePosition: false,
  },
  'top-right': {
    title: 'Top-right',
    reversePosition: 'X',
  },
  'bottom-left': {
    title: 'Bottom-left',
    reversePosition: 'Y',
  },
  'bottom-right': {
    title: 'Bottom-right',
    reversePosition: true,
  },
};

/* старое
  appearance: {
    backgroundColor: {
      hexa: '#ffffffCC',
      rgb: {
        r: 255,
        g: 255,
        b: 255,
        a: 0.8,
      },
    },
    accentColor: '#ffb65a',
  }, */
