// @flow
import React, { Component } from 'react';

import { connect } from 'react-redux';

import { TabsContainer } from 'components/UI';
import { TipAlertDataDefault } from 'constants';

import type { TipAlertData } from 'types/Widget';

import {
  AmountTab,
  PresetTab,
  TipperTab,
  MessageTab,
  ImageTab,
  SoundTab,
} from './Tabs';

type Props = {
  tipsData: TipAlertData,
  selectedVariation: string,
};

export default connect(
  state => ({
    tipsData: state.widgets.tips || {},
    selectedVariation: state.widgetsOptions.tips.selectedPreset,
    supporter: state.auth.supporter,
  }),
)(class Options extends Component<Props> {
  static defaultProps = {
    tipsData: {},
  };

  render() {
    const {
      tipsData,
      selectedVariation,
      supporter,
    } = this.props;
    const variationData = tipsData[selectedVariation] || {};
    const isBasic = supporter && supporter.basic && !(supporter.special === 'trial') && !supporter.active;
    const tabHOC = (Tab, otherProps) => <Tab {...otherProps} selectedVariation={selectedVariation} />;
    return (
      <TabsContainer
        content={[
          tabHOC(PresetTab),
          tabHOC(AmountTab, {
            data: {
              ...TipAlertDataDefault.amount,
              ...(variationData.amount || {}),
            },
          }),
          tabHOC(TipperTab, {
            data: {
              ...TipAlertDataDefault.tipper,
              ...(variationData.tipper || {}),
            },
          }),
          tabHOC(MessageTab, {
            data: {
              ...TipAlertDataDefault.message,
              ...(variationData.message || {}),
            },
            isBasic,
          }),
          tabHOC(ImageTab, {
            data: {
              ...TipAlertDataDefault.image,
              ...(variationData.image || {}),
            },
          }),
          tabHOC(SoundTab, {
            data: {
              ...TipAlertDataDefault.sound,
              ...(variationData.sound || {}),
            },
            isBasic,
          }),
        ]}
      />
    );
  }
});
