import React from 'react';
import styled from 'styled-components';

const RangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

const Range = styled.input`
  width: 250px !important;
  margin-left: 20px;
`;

const Value = styled.span`
  width: 20px;
`;

type Props = {
  label?: string,
  min?: number,
  max?: number,
  step?: number,
  hideValue?: boolean,
  value: string,
  onChange: Function
};

export const Slider = ({
  label, value, hideValue, onChange, min, max, step,
}: Props) => (
  <div>
    <span>{label}</span>
    <RangeContainer>
      {!hideValue && <Value>{value}</Value>}
      <Range
        type="range"
        onChange={onChange}
        value={value}
        min={typeof min !== 'undefined' ? min : 10}
        max={typeof max !== 'undefined' ? max : 40}
        step={typeof step !== 'undefined' ? step : 2}
      />
    </RangeContainer>
  </div>
);

Slider.defaultProps = {
  label: 'Size',
};
