import React, { PureComponent } from 'react';

import { CheckBox } from 'components/UI';
import { Service_ID } from 'constants';

import {
  FiltersButton,
  TagsContainer,
  TagButton,
} from './UI';

const tagsNamesServices = {
  PROCESS_SPAWN: 'Process spawn',
  PROCESS_CLOSED: 'Process closed',
  PROCESS_INFO: 'Process info',
  PROCESS_ERROR: 'Process error',
  PROCESS_RMQ_CONNECTION_SUCCESS: 'Process RMQ connection success',
  PROCESS_RMQ_CONNECTION_FAULT: 'Process RMQ connection fault',

  CHAT_CONNECTED: 'Chat connected',
  CHAT_DISCONNECTED: 'Chat disconnected',
  CHAT_MESSAGE: 'Chat message',
  CHAT_TIP: 'Chat tip',
};

const tagsNamesGeneral = {
  SOCKET_CHANGE_PORT: 'Socket change port',
  SOCKET_CHAT_RECONNECT: 'Socket chat reconnect',
  SOCKET_INFO: 'Socket info',
  SOCKET_DISCONNECT: 'Socket disconnect',
  SOCKET_CONNECTED: 'Socket connected',
  API_INFO: 'API info',
};

const servicesTags = Object.keys(tagsNamesServices);
const generalTags = Object.keys(tagsNamesGeneral);
const services = Object.keys(Service_ID);

export class Filters extends PureComponent {
  state = {
    showFilters: false,
  };

  toggleShowFilters = () => this.setState(({ showFilters }) => ({ showFilters: !showFilters }));

  render() {
    const { showFilters } = this.state;
    const {
      fetchAuto,
      logsToggleFetchAuto,
      chosenServices,
      chosenTags,
      onTagClick,
      onServiceClick,
    } = this.props;
    return (
      <>
        <div style={{ display: 'flex', marginLeft: '4px' }}>
          <CheckBox
            checked={fetchAuto}
            onChange={logsToggleFetchAuto}
          >
            Fetch auto
          </CheckBox>
          <FiltersButton onClick={this.toggleShowFilters}>
            {showFilters ? 'Hide' : 'Show'}
            &nbsp;filters
          </FiltersButton>
        </div>
        {showFilters && (
          <React.Fragment>
            <TagsContainer>
              <div>Services: </div>
              {services.map(service => (
                <TagButton key={service} chosen={chosenServices[service]} onClick={() => onServiceClick(service)}>
                  {Service_ID[service]}
                </TagButton>
              ))}
            </TagsContainer>
            <TagsContainer>
              <div>Tags, connected with services: </div>
              {servicesTags.map(tag => (
                <TagButton key={tag} chosen={chosenTags[tag]} onClick={() => onTagClick(tag)}>
                  {tagsNamesServices[tag]}
                </TagButton>
              ))}
            </TagsContainer>
            <TagsContainer>
              <div>General tags: </div>
              {generalTags.map(tag => (
                <TagButton key={tag} chosen={chosenTags[tag]} onClick={() => onTagClick(tag)}>
                  {tagsNamesGeneral[tag]}
                </TagButton>
              ))}
            </TagsContainer>
          </React.Fragment>
        )}
      </>
    );
  }
}
