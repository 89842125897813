// @flow

/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

import {
  NumberCountInput,
  FormItem,
  Section,
} from 'components/UI';
import { DiceDataDefault, widgets } from 'constants';

import DiceRadio from './DiceRadio';
import sprite from 'assets/images/sprite.svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActionWidgetsComponentsUpdate, widgetReset } from 'store/reducers/widgets';


const Container = styled.div`

`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;


const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;


const Label = styled.div`
  min-width: 310px;
  color: ${({ theme }) => theme.white54};;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

const HelpButton = styled.svg`
  & > use {
    transition: fill 0.3s ease-in-out;
    fill: rgba(255,255,255,0.54);
  }
`;

const HelpContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: calc(100% + 4px);
  cursor: pointer;

  height: 36px;
  width: 36px;

  border-radius: 4px;
  background-color: transparent;

  transition: background-color 0.3s ease-in-out;

  &:hover ${HelpButton} > use {
    fill: rgba(255,255,255,1);
  }
`;

const Tooltip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: max-content;
  max-width: 280px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: calc(100% + 7px);
  left: 50%;
  transform: translateX(-50%);

  min-height: 24px;

  border-radius: 4px;
  padding: 2px 8px;

  color: #FFFFFF;
  background: #11171B;

  transition: opacity 0.3s ease-in-out;

  cursor: auto;
  pointer-events: none;

    font-size: 14px;
    text-align: left;
    line-height: 20px;

  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #11171B transparent transparent transparent;
  }

  ${HelpContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;


class WidgetTab extends React.Component {
  render() {
    const {
      data,
      widgetsComponentsUpdate,
    } = this.props;
    return (
      <Container>
        <Section>
          <FormItem label="Game settings" themeFont="header" header />
          <Content>
            <Row>
              <Label margin>
                Tokens amount to roll the dice
              </Label>
              <NumberCountInput
                value={data.amount}
                onChange={amount => widgetsComponentsUpdate({ amount })}
                min={1}
                max={999}
                width="72px"
              />
            </Row>
            <Row>
              <Label margin>
                Maximum rolls from a single tip
              </Label>
              <NumberCountInput
                value={data.rolls}
                onChange={rolls => widgetsComponentsUpdate({ rolls })}
                min={1}
                max={10}
                width="72px"
              />
              <HelpContainer>
                <HelpButton
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <use xlinkHref={`${sprite}#question-mark`} />
                </HelpButton>
                <Tooltip>Example: if you set this to 3 and you charge 33 tokens per roll, a user could tip 66 for 2 rolls or 99 for 3 rolls.</Tooltip>
              </HelpContainer>
            </Row>
          </Content>
        </Section>
        <Section borderTop>
          <FormItem label="Position on screen" themeFont="header" header />
          <DiceRadio checked={data.screenPosition} onChange={screenPosition => widgetsComponentsUpdate({ screenPosition })} />
        </Section>
      </Container>
    );
  }
}

WidgetTab.title = 'Widget';

export default connect(
  null,
  (dispatch, ownProps) => bindActionCreators({
    widgetsComponentsUpdate: getActionWidgetsComponentsUpdate(widgets.dice.id, ownProps.selectedPreset, 'widget'),
  }, dispatch),
)(WidgetTab);
