/* eslint-disable */
import React, { Component, PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { servicesURLs, Service_ID, servicesCodes, servicesColors } from 'constants';
import deepEqual from 'deep-equal';
import { connect } from 'react-redux';

// const UserItem = styled.div`
//   cursor: pointer;
//   padding: 2px 4px;
//   &:hover {
//     background-color: rgba(255, 255, 255, 0.1);
//   }
// `;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({ isNewVersion }) => isNewVersion && css`background-color: #1d272e42;`}

  cursor: pointer;
  padding: 6px 4px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  border-bottom: 1px solid ${({ theme }) => theme.greyTable};
`;

const Header = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #fff;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;


  font-size: 16px;
  font-weight: 400;
  color: #fff;
`;

const Error = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: red;
`;

const Mail = styled.span`
  background-color: ${({ color }) => color};
  padding: 0px 3px;
  border-radius: 3px;
`;

const Service = styled.span`
  color: ${({ color }) => color};
`;

const Indicator = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin: 0 5px;
`;

const Widgets = styled.a`
  margin-right: 10px;
  color: ${({ active }) => active !== '0' && 'orange'};
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
`;

const AccountTypeFilter = styled.div`
  input {
    margin-right: 2px;
    cursor: pointer;
  }
  label {
    margin-right: 6px;
    cursor: pointer;
  }
`;

const widgetsUrl = (widget, active, title, reactKey) => (
  <Widgets
    active={active}
    target="_blank"
    rel="noopener noreferrer"
    href={`https://tipalerts.com/w/${widget === 'race' ? 'tree' : widget}/${reactKey}`}
  >
    {title}
  </Widgets>
);

class UsersListItem extends Component {
  shouldComponentUpdate(nextProps) {
    return !deepEqual(nextProps, this.props);
  }
  render () {
    const {
      onClick,
      uid,
      email,
      profiles,
      onlineProcesses,
      tips,
      goals,
      top,
      dice,
      isNewVersion,
      error,
      color,
    } = this.props;
    return (
      <Container onClick={() => onClick(uid, email)} isNewVersion={isNewVersion}>
        <Header>
          <Mail color={color}>{`${email}`}</Mail>
        </Header>
        {profiles.map((profile) => {
          if (!profile.streamAccessToken) return null;
          return (
            <React.Fragment key={profile.streamAccessToken}>
              <Header>
                {widgetsUrl('tips', tips, 'A', profile.streamAccessToken)}
                {widgetsUrl('goals', goals, 'G', profile.streamAccessToken)}
                {widgetsUrl('top', top, 'TT', profile.streamAccessToken)}
                {widgetsUrl('dice', dice, 'RTD', profile.streamAccessToken)}
              </Header>
              {Object.keys(servicesURLs).map(key => {
                return (
                  profile[`${key}Id`]
                  && (
                    <Info key={`${profile.streamAccessToken}_${key}`}>
                      <Service
                        color={servicesColors[key]}
                        active={profile[`${key}Id`]}
                      >
                        {servicesCodes[key]}
                        :&nbsp;
                      </Service>
                      <a
                        href={`${servicesURLs[key]}/${key === Service_ID.myfreecams ? '#' : ''}${profile[`${key}Id`]}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {`[${profile[`${key}Id`]}]`}
                      </a>
                      <Indicator color={parseInt(onlineProcesses[key]) ? '#00FF00' : '#FF0000'} />
                    </Info>
                  ));
              })}
            </React.Fragment>
          );
        })}
        {error && <Error onClick={() => onClick(uid, email)}>{`Error: ${error}`}</Error>}
      </Container>
    );
  }
}

const getUsersList = (users, onClick) => {
  users.sort((a, b) => {
    const nameA=a.email.toLowerCase(), nameB=b.email.toLowerCase()
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
    });
  return (
    users.map(({ online, profiles, user: _user, special, active, basic: _basic, uid: _uid, email: _email, error: _error }) => {
      const userOnline = online || {};
      const user = _user || {};
      const onlineConnections = userOnline.connections || {};
      const onlineProcesses = userOnline.processes || {};

      const tips = onlineConnections.tips || '0';
      const goals = onlineConnections.goals || '0';
      const top = onlineConnections.top || '0';
      const dice = onlineConnections.dice || '0';
      const race = onlineConnections.race || '0';

      // TODO: REMOVE ALL AFTER ||
      const trial = special === 'trial' || (user && user.special === 'trial');
      const premium = active && special !== 'trial' || (user && user.active && !(user.special === 'trial'));
      const basic = _basic && special !== 'trial' && !active || (user && user.basic && !(user.special === 'trial') && !user.active);

      let color;
      if (trial) {
        color = '#26a8ffcc';
      } else if (basic) {
        color = '#ffff0066';
      } else if (premium) {
        color = '#298c29cc';
      }

      const uid = _uid || user.uid;
      const email = _email || user.email;
      const error = _error || user.error;
      return (
        <UsersListItem
          key={uid}
          onClick={onClick}
          uid={uid}
          email={email}
          profiles={profiles}
          onlineProcesses={onlineProcesses}
          tips={tips}
          goals={goals}
          top={top}
          dice={dice}
          race={race}
          error={error}
          color={color}
          isNewVersion={onlineConnections.new_version === '1'}
        />
      );
    })
  );
}

class ActiveUsersBase extends PureComponent {
  state = {
    accountTypeFilter: 'all', // all || trial || basic || premium || nothing
  };
  filter = (users) => {
    const { accountTypeFilter } = this.state;
    if (accountTypeFilter === 'all') return users;
    return users.filter(({ special, active, basic }) => {
      if (accountTypeFilter === 'trial') {
        return special === 'trial';
      }
      if (accountTypeFilter === 'basic') {
        return !active && basic && special !== 'trial';
      }
      if (accountTypeFilter === 'premium') {
        return active && basic && special !== 'trial';
      }
      if (accountTypeFilter === 'nothing') {
        return !active && !basic;
      }
    });
  };
  onAccountTypeFilterChange = (e) => {
    this.setState({ accountTypeFilter: e.target.value });
  };
  render() {
    const { users = [], onClick } = this.props;
    const { accountTypeFilter } = this.state;
    const filteredUsers = this.filter(users);
    const usersList = getUsersList(filteredUsers, onClick);
    return (
      <div>
        <ListHeader>
          Users:
          {' '}
          {accountTypeFilter === 'all' ? (
            users.length
          ) : `${filteredUsers.length} (${users.length})`}
          <AccountTypeFilter>
            <input type="radio" name="accountType" id="aTAll" value="all" checked={accountTypeFilter === 'all'} onChange={this.onAccountTypeFilterChange} />
            <label htmlFor="aTAll">All</label>
            <input type="radio" name="accountType" id="aTTrial" value="trial" checked={accountTypeFilter === 'trial'} onChange={this.onAccountTypeFilterChange} />
            <label htmlFor="aTTrial">T</label>
            <input type="radio" name="accountType" id="aTBasic" value="basic" checked={accountTypeFilter === 'basic'} onChange={this.onAccountTypeFilterChange} />
            <label htmlFor="aTBasic">B</label>
            <input type="radio" name="accountType" id="aTPremium" value="premium" checked={accountTypeFilter === 'premium'} onChange={this.onAccountTypeFilterChange} />
            <label htmlFor="aTPremium">P</label>
            <input type="radio" name="accountType" id="aTNothing" value="nothing" checked={accountTypeFilter === 'nothing'} onChange={this.onAccountTypeFilterChange} />
            <label htmlFor="aTNothing">N</label>
          </AccountTypeFilter>
        </ListHeader>
        {usersList}
      </div>
    );
  }
}

const mapStateToProps = state => ({ users: state.logs.activeUsers });

export const ActiveUsers = connect(mapStateToProps)(ActiveUsersBase);
