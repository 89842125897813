import React from 'react';


import { ImageGallery } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActionWidgetsComponentsUpdate, widgetsUploadImage } from 'store/reducers/widgets';
import { widgets } from 'constants';
import { deleteFile } from 'store/reducers/auth';

const ImageTab = ({
  user,
  data,
  widgetsComponentsUpdate,
  widgetsUploadImage,
  deleteFile,
  t,
}) => (
  <ImageGallery
    title={t.alertImage}
    type="image"
    user={user}
    selected={data.image}
    imageCanBeDeselected
    onImageSelect={imageNumber => widgetsComponentsUpdate({ image: imageNumber, enabled: true })}
    onImageUpload={widgetsUploadImage}
    onFileDelete={deleteFile}
    data={data}
    widgetUpdate={widgetsComponentsUpdate}
    t={t}
  />
);

ImageTab.title = 'Image';

export default connect(
  state => ({
    user: state.auth.user,
    t: state.lang.t,
  }),
  (dispatch, ownProps) => bindActionCreators({
    widgetsComponentsUpdate: getActionWidgetsComponentsUpdate(widgets.tips.id, ownProps.selectedVariation, 'image'),
    widgetsUploadImage,
    deleteFile,
  }, dispatch),
)(ImageTab);
