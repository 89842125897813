// @flow

/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

import {
  FormItem,
  Section,
  NumberCountInput,
  CheckBox,
  ShadowBlock,
} from 'components/UI';

import {
  TextAppearance,
} from 'components/UIkit';

import { DiceDataDefault, widgets } from 'constants';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActionWidgetsComponentsUpdate, widgetReset } from 'store/reducers/widgets';


const Container = styled.div`

`;

const ColMain = styled.div`
  margin-right: 16px;
`;

const RowMain = styled.div`
display: flex;
align-items: ${({ baseline }) => (baseline ? 'baseline' : 'center')};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;


const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;


const Label = styled.div`
  min-width: 310px;
  color: ${({ theme }) => theme.white54};;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;



class NotificationsTab extends React.Component {

  onMainBackgroundData = ({ checkbox: mainBackgroundColorEnabled, color: mainBackgroundColor }) => {
    const { widgetsComponentsUpdate } = this.props;
    widgetsComponentsUpdate({ mainBackgroundColorEnabled, mainBackgroundColor });
  };
  onAccentData = ({ color: accentColor }) => {
    const { widgetsComponentsUpdate } = this.props;
    widgetsComponentsUpdate({ accentColor });
  };

  render() {
    const {
      data,
      widgetsComponentsUpdate,
    } = this.props;

    const mainBackgroundData = {
      color: data.mainBackgroundColor,
      checkbox: data.mainBackgroundColorEnabled,
    };
    const accentData = {
      color: data.accentColor,
    };

    return (
      <Container>
        <Section>
          <FormItem label="Display time" themeFont="header" header/>
          <Content>
            <Row>
              <Label margin>
                Display prize notification for, seconds
              </Label>
              <NumberCountInput
                value={data.prizeDuration}
                onChange={prizeDuration => widgetsComponentsUpdate({ prizeDuration })}
                min={1}
                max={999}
                width="72px"
              />
            </Row>
          </Content>
        </Section>

        <Section borderTop>
          <FormItem label="Notifications appearance" themeFont="header" header />
          <RowMain>
            <ColMain>
              <ShadowBlock
                data={accentData}
                onChange={this.onAccentData}
                title="Accent"
                disabledRandomButton
              />
            </ColMain>
          </RowMain>
        </Section>

        <TextAppearance
          data={data}
          widgetUpdate={widgetsComponentsUpdate}
          mainLabel="Notifications"
          noCheckBox
          borderTop
        />

      </Container>
    );
  }
}

NotificationsTab.title = 'Notifications';

export default connect(
  null,
  (dispatch, ownProps) => bindActionCreators({
    widgetsComponentsUpdate: getActionWidgetsComponentsUpdate(widgets.dice.id, ownProps.selectedPreset, 'notifications'),
  }, dispatch),
)(NotificationsTab);
