/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'components/UI';
import { RenderTopTippers } from 'components/WidgetsRender';

const RenderPreview = styled.div`
  border-radius: 4px;
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;

  z-index: 1;
  position: relative;

  .RenderTips_Tip {
    animation-iteration-count: infinite;
  }

  :before{
    z-index:-1;
    width: 100%;
    height: 100%;
    position:absolute;
    left:0;
    top:0;
    content: '';
    background: url('/images/checker-bw.svg');
    background-color: hsl(205, 33%, 17%); /* ${({ theme }) => theme.slateThree}; */
    background-blend-mode: multiply;
    opacity: 0.16;
  }
`;

const Title = styled.div`
  ${({ theme }) => theme.font.title18_700};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
`;

export default connect(
  state => ({
    config: state.widgets.top || {},
    topUsers: state.widgets.topUsers,
    supporter: state.auth.supporter,
    t: state.lang.t,
  }),
)(
  class extends React.Component {
    state = {
      previewPause: false,
    };

    render() {
      const {
        config,
        topUsers,
        supporter,
        t,
      } = this.props;
      const { previewPause } = this.state;

      const configDefault = (config && config.default) || {};
      const topList = Object.keys(topUsers || {})
        .map(name => ({ name, amount: topUsers[name] })).sort((a, b) => b.amount - a.amount);
      return (
        <React.Fragment>
          <Row>
            <Title>{t.preview}</Title>
          </Row>
          <RenderPreview>
            <RenderTopTippers
              config={configDefault}
              topList={topList}
              paused={previewPause}
              supporter={supporter}
              t={t}
              isPreview
            />
          </RenderPreview>
          {/*<Button onClick={() => this.setState({ previewPause: !previewPause })}>*/}
          {/*  {!previewPause ? 'Pause' : 'Play'}*/}
          {/*</Button>*/}
        </React.Fragment>
      );
    }
  },
);
