/* eslint-disable */
import {
  takeLatest,
  take,
  fork,
  call,
  put,
  actionChannel,
  select,
  cancel,
} from 'redux-saga/effects';
import { eventChannel, delay, buffers } from 'redux-saga';
import io from 'socket.io-client';
import { animationDuration, randInt } from 'utils';
// import { bugsnagClient } from 'utils/bugsnag';
import { widgetsOptionsRaceSetTime } from 'store/reducers/widgetsOptions';
import { renderRaceOptimisticActiveToInactive } from 'store/reducers/render';
import {
  widgetRaceActiveToInactive,
} from 'store/reducers/widgets';
import {
  TipAlertDataDefault,
  widgets,
  getAPIHost,
} from 'constants';
// import { randInt } from 'utils/common';
import { firebaseGetWidget, firebaseGetPaymentDataByToken } from '../../utils/firebase';

import {
  RENDER_INIT,
  RENDER_INIT_ERROR,
  RENDER_INIT_SUCCESS_CONNECT,
  // RENDER_SET_PORT,
  renderWidgetPush,
  renderWidgetItemRemove,
  // renderSetPort,
  renderDiceData,
  renderInit as renderInitActionCreator,
} from '../reducers/render';

function connect({ streamAccessToken, widgetId, port }) {
  return firebaseGetPaymentDataByToken(streamAccessToken).then((data) => {
    let reloaded = false;
    try {
      reloaded = localStorage.getItem('reloaded');
    } catch(e) {
      // manycam dont support localStorage (?) and throw error at this step
    }
    const hostWithPort = getAPIHost(port);
    const socket = io(`${hostWithPort}/?token=${streamAccessToken}&type=${widgetId}&reloaded=${reloaded}`, {
      path: '/tips',
    });
    return new Promise((resolve) => {
      socket.on('connect', () => {
        resolve(socket);
      });
    });
  }).catch(() => {
    // if (bugsnagClient) bugsnagClient.notify(`[render] Token ${streamAccessToken} don't have e-mail in realtime-db/widgets/${streamAccessToken}!`);
    console.log(`[render] Token ${streamAccessToken} don't have e-mail in realtime-db/widgets/${streamAccessToken}!`);
    return Promise.reject(new Error('no email'));
  });
}

let chatConnected = true;

function subscribe(socket) {
  return eventChannel((emit) => {
    socket.on('tipalert', (message) => {
      emit({ type: 'SOCKET_TIPALERT', message });
    });
    socket.on('disconnect', () => {
      console.log('disconnect');
    });
    socket.on('reload-for-update', (msg) => {
      localStorage.setItem('reloaded', msg);
      window.location.reload(true);
    });
    socket.on('chat-connected', () => {
      chatConnected = true;
      console.log('CHAT-CONNECTED');
    });
    socket.on('roll-the-dice', (message) => {
      emit(renderDiceData(message));
      // emit({ type: 'SOCKET_EMIT', payload: { event: 'roll-the-dice-next' } });
    });
    socket.on('chat-change-port', (port) => {
      // emit(renderDiceData(message));
      // emit({ type: 'SOCKET_EMIT', payload: { event: 'CHAT_RECONNECT' } });
      console.log('chat change port');
      socket.emit('redirected-disconnect');
      emit({ type: 'SOCKET_CHANGE_PORT', port });
      // socket.close();
      // cancel(flow);
      // socket.io.uri = `${wsHost}:${4332}/?${socket.query}`; // eslint-disable-line
      // emit({ type: 'SOCKET_EMIT', payload: { event: 'roll-the-dice-next' } });
    });
    socket.on('chat-disconnected', () => {
      chatConnected = false;
      console.log('chat-disconnected');
      emit({ type: 'SOCKET_EMIT', payload: { event: 'CHAT_RECONNECT' } });
    });
    return () => {};
  });
}

function* read(socket) {
  const channel = yield call(subscribe, socket);
  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

let chatReconnectAllowed = true;
function* socketChatReconnect(socket) {
  chatReconnectAllowed = false;
  socket.emit('CHAT_RECONNECT');
  yield delay(20000 + (randInt(0, 10) * 1000));
  chatReconnectAllowed = true;
  if (!chatConnected) {
    console.log('try another one chat-reconnect');
    yield fork(socketChatReconnect, socket);
  }
}

function* socketDispatchEmit(socket, event, data) {// eslint-disable-line
  switch (event) { // eslint-disable-line
    case 'CHAT_RECONNECT':
      console.log('chat-reconnect');
      if (chatReconnectAllowed) {
        yield fork(socketChatReconnect, socket);
      }
      break;
    // case 'roll-the-dice-next':
    //   yield delay(10000);
    //   yield put(renderDiceData({}));
    //   socket.emit(event);
    //   break;
  }
}

function* write(socket) {
  while (true) {
    const { payload: { event, data } } = yield take('SOCKET_EMIT');
    yield fork(socketDispatchEmit, socket, event, data);
  }
}

function* handleIO(socket) {
  yield fork(read, socket);
  yield fork(write, socket);
}

function* flow(streamAccessToken, widgetId, port) {
  // while (true) {
  // const { streamAccessToken, widgetId } = yield take(RENDER_INIT);
  // yield take(RENDER_SET_PORT);
  // if (widgetId === 'tips') {
  try {
    const socket = yield call(connect, { streamAccessToken, widgetId, port });
    yield fork(handleIO, socket);
    yield put({ type: RENDER_INIT_SUCCESS_CONNECT });
  } catch (e) {
    yield put({ type: RENDER_INIT_ERROR, error: e.message });
    yield delay(5000);
    yield put({
      type: RENDER_INIT,
      widgetId,
      streamAccessToken,
    });
  }
  // }
  // }
}

function* removeAlert(alert, data, supporter) {
  let dataForAmount = (data && data.default && data.default.preset) || {};
  if (supporter.active && data) {
    Object.keys(data).forEach((key) => {
      if (data[key].preset && (data[key].preset.enabled || data[key].preset.enabled === undefined)) {
        const amountVariation = data[key].preset;
        const { amount } = alert;
        if (amount >= amountVariation.from && amount <= amountVariation.to) {
          dataForAmount = amountVariation;
        }
      }
    });
  }
  yield delay((animationDuration({
    ...TipAlertDataDefault.preset,
    ...dataForAmount,
    ...alert,
  }) * 1000) + 2000);
  yield put(renderWidgetItemRemove(alert._id));
}

function* socketTipAlert() {
  const chan = yield actionChannel('SOCKET_TIPALERT', buffers.expanding());
  while (true) {
    const { message: alert } = yield take(chan);
    const { render: { data }, auth: { supporter } } = yield select(store => store);
    const amount = parseInt(alert.amount, 10);
    let dataForAmount = data.default;
    if (supporter.active) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const variation = data[key];

          if (key !== 'default' && variation && variation.preset) {
            if (
              (variation.preset.from || variation.preset.from === 0)
              && variation.preset.to
              && (amount >= variation.preset.from)
              && (amount <= variation.preset.to)
            ) {
              dataForAmount = variation;
              break;
            }
          }
        }
      }
    }
    if (dataForAmount && dataForAmount.preset && dataForAmount.preset.splitAlertsByOneToken) {
      for (let i = 0; i < amount; i += 1) {
        const alertId = `${alert._id}-${i}`;
        yield put(renderWidgetPush({ item: { ...alert, _id: alertId }, id: alertId }));
        yield fork(removeAlert, { ...alert, _id: alertId }, data, supporter);
        yield delay(700);
      }
    } else {
      yield put(renderWidgetPush({ item: { ...alert }, id: alert._id }));
      yield fork(removeAlert, { ...alert }, data, supporter);
    }
    // yield delay(1000);
  }
}

// function* socketRollTheDice(socket) {
//   const channel = yield call(subscribe, socket);
//   while (true) {
//     const action = yield take(channel);
//     yield put(action);
//   }
// }

// function* getServerPort(streamAccessToken) {
//   try {
//     const res = yield fetch(`${wsHost}:${wsPort}/port?token=${streamAccessToken}`);
//     const { port } = yield res.json();
//     yield put(renderSetPort(port));
//     return port;
//   } catch (e) {
//     return false;
//   }
// }

const customFramesStep = 1000 / 10; // for throttle
let lastFrameTimeMs = 0;
let stopTicker = false;

function ticker() {
  return eventChannel((emitter) => {
    stopTicker = false;
    const step = (timestamp) => {
      if (stopTicker) return;
      if (timestamp >= lastFrameTimeMs + customFramesStep) {
        lastFrameTimeMs = timestamp;
        emitter(Date.now());
      }
      window.requestAnimationFrame(step);
    };
    window.requestAnimationFrame(step);

    return () => {
      stopTicker = true;
    };
  });
}

const destroyedRaces = {};
function* destroyRequestDebounce(socket, name) {
  if (!destroyedRaces[name]) {
    destroyedRaces[name] = true;
    yield put(renderRaceOptimisticActiveToInactive(name));
    socket.emit('race-active-to-inactive', name);
    yield delay(10000);
    delete destroyedRaces[name];
  }
}

function* timerToDestroy(streamAccessToken, widgetId, socket) {
  const chan = yield call(ticker);
  console.log('TIMER START! ');
  // let active = yield select(({ render }) => render.default.race.active);
  // while (stateSlice !== expectedValue) {
  //   yield take('');
  //   stateSlice = yield select(({ render }) => render.default.race.active);
  // }
  try {
    while (true) {
      const date = yield take(chan);
      const { active, racesSeconds } = yield select(({ render, widgetsOptions }) => ({ active: render.race.default.active, racesSeconds: widgetsOptions.race || {} }));
      const payload = {};
      for (const key in active) {
        if (active.hasOwnProperty(key)) {
          const { expireAt, name } = active[key];
          const secondsToDestroy = Math.floor((expireAt - date) / 1000);
          if (secondsToDestroy !== racesSeconds[name]) {
            const isZero = secondsToDestroy < 0;
            if (isZero) yield fork(destroyRequestDebounce, socket, name);
            payload[name] = isZero ? 0 : secondsToDestroy;
          }
        }
      }
      if (Object.keys(payload).length > 0) yield put(widgetsOptionsRaceSetTime(payload));
    }
  } catch (e) {
    console.log(e);
  } finally {
    console.log('TIMER TERMINATED!!');
    chan.close();
  }
}

function* renderInit({ widgetId, streamAccessToken }) {
  yield firebaseGetWidget(streamAccessToken, widgetId);
  // let port = null;
  // while (!port) {
  //   port = yield call(getServerPort, streamAccessToken);
  //   if (!port) yield delay(3000);
  // }
  // firebaseRenderUpdateActivity(streamAccessToken);
  // setInterval(() => {
  //   firebaseRenderUpdateActivity(streamAccessToken);
  // }, 60000);
  if (widgetId === widgets.tips.id) yield fork(socketTipAlert);
  // if (widgetId === WIDGET_ID.dice) yield fork(socketRollTheDice);
  let flowTask = yield fork(flow, streamAccessToken, widgetId);

  while (true) {
    const { port } = yield take('SOCKET_CHANGE_PORT');
    yield cancel(flowTask);
    flowTask = yield fork(flow, streamAccessToken, widgetId, port);
  }
}

export function* render() {
  yield takeLatest(RENDER_INIT, renderInit);
  // yield takeEvery('SOCKET_TIPALERT', socketTipAlert);
}
