import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'components/UI';
import { RenderTips } from 'components/WidgetsRender';

const RenderPreview = styled.div`
  border-radius: 4px;
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;

  z-index: 1;
  position: relative;

  .RenderTips_Tip {
    animation-iteration-count: infinite;
  }

  :before{
    z-index:-1;
    width: 100%;
    height: 100%;
    position:absolute;
    left:0;
    top:0;
    content: '';
    background: url('/images/checker-bw.svg');
    background-color: hsl(205, 33%, 17%); /* ${({ theme }) => theme.slateThree}; */
    background-blend-mode: multiply;
    opacity: 0.16;
  }
`;

const Title = styled.div`
  ${({ theme }) => theme.font.title18_700};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
`;

export default connect(
  state => ({
    tipsData: state.widgets.tips || {},
    selectedPreset: state.widgetsOptions.tips.selectedPreset,
    supporter: state.auth.supporter,
    t: state.lang.t,
  }),
)(
  class extends React.Component {
    state = {
      previewPause: false,
    };

    render() {
      const {
        tipsData,
        supporter,
        selectedPreset,
        t,
      } = this.props;
      const { previewPause } = this.state;

      const selectedData = tipsData[selectedPreset] || {};

      return (
        <React.Fragment>
          <Row>
            <Title>{t.preview}</Title>
          </Row>
          <RenderPreview>
            <RenderTips
              data={{ ...selectedData, tips: { amount: 100, from: 'Alex' } }}
              paused={previewPause}
              supporter={supporter}
              t={t}
              isPreview
            />
          </RenderPreview>
          <Button onClick={() => this.setState({ previewPause: !previewPause })}>
            {!previewPause ? 'Pause' : 'Play'}
          </Button>
        </React.Fragment>
      );
    }
  },
);
