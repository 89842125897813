/* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from 'react-bootstrap';
import styled from 'styled-components';
import isEqual from 'react-fast-compare';

import {
  ConnectService,
  Warning,
} from 'components/widget';
import {
  WidgetURL,
  TestAlert,
  AboutGameRace,
} from 'components';
import { PremiumOnly } from 'components/UI';
import {
  authGenerateStreamAccessToken,
  authDisableNewIntegration,
  authDisableNewEditor,
  authUserGoToNewVersion,
} from 'store/reducers/auth';
import {
  widgetsTestAlert,
} from 'store/reducers/widgets';
import { widgets, Service_ID } from 'constants';

import * as tips from './tips';
import * as goals from './goals';
import * as top from './top';
import * as dice from './dice';

const PreviewContainer = styled.div`
  margin-top: 20px;
  margin-right: 30px;
  width: 458px;
  min-width: 458px;
  flex-basis: auto;
`;

const WidgetOptionsContainer = styled.div`
  flex: 1;
  margin-top: 20px;
`;

const PageContainer = styled.div`
  display: flex;
`;

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const WidgetURLStyled = styled(WidgetURL)`
        flex: 1;
        margin-right: 30px;
      `;
const TestAlertStyled = styled(TestAlert)`
        display: flex;
        align-items: center;
      `;

const ContainerNewEditor = styled.div`
  height: 36px;
  width: 100%
  display: grid;
  grid-template-columns: 36px 1fr 36px;
  grid-template-rows: 1fr;

  grid-template-areas: ". content cross";

  background: url('/images/banner-newEditor.png') no-repeat;
  background-size: cover;
;
`;

const ContentNewEdit = styled.div`
  grid-area: content;
  justify-self: center;
  align-self: center;
`;

const Cross = styled.div`
  grid-area: cross;
  justify-self: end;
  align-self: center;
`;

const LinkWithClick = styled.span`
  @import url('https://fonts.googleapis.com/css?family=Inter:400,600,700&display=swap');
  color: #212121;
  cursor: pointer;
  font: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  &:hover {
    color: #323232;
  }
`;

const Icon = styled.img.attrs({
  src: '/images/ic-arrow.svg',
})`
  width: 24px;
  height: 24px;
  margin-left: 6px;
  cursor: pointer;
`;
const IconCross = styled.img.attrs({
  src: '/images/ic-cross.svg',
})`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ButtonCross = styled.div`
  margin: 6px;
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.28);
  }
`;

const NewEditor = (props) => {
  const {
    authUserGoToNewVersion,
    authDisableNewEditor,
    profile,
  } = props;
  if (profile.disableNewEditor) return null;
  return (
    <ContainerNewEditor>
      <ContentNewEdit>
        <LinkWithClick onClick={authUserGoToNewVersion}>
          Try updated TipAlerts for new experience
          <Icon />
        </LinkWithClick>
      </ContentNewEdit>
      <Cross>
        <ButtonCross onClick={() => authDisableNewEditor(true)}>
          <IconCross />
        </ButtonCross>
      </Cross>
    </ContainerNewEditor>
  )
}

const getStreamStatesPresets = service => ({
  'not-found': `Your stream page on ${service} not found!`,
  'auth-required': `Your stream page on ${service} require authorization. Make it public in account settings.`,
  offline: `Your stream on ${service} is offline`,
});

const serviceIdArr = Object.keys(Service_ID);

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    authDisableNewIntegration,
    authDisableNewEditor,
    authGenerateStreamAccessToken,
    widgetsTestAlert,
    authUserGoToNewVersion,
  },
  dispatch,
);

export default connect(
  state => ({
    auth: state.auth,
    user: state.auth.user,
    supporter: state.auth.supporter,
    lastActivity: state.auth.lastActivity,
    streamStates: state.widgets.streamStates,
    t: state.lang.t,
  }),
  mapDispatchToProps,
)(class extends React.Component {
    shouldComponentUpdate(nextProps) {
      return !isEqual(nextProps, this.props);
    }

    onHelpClick = (service, status) => () => {
      window.$crisp.push(['do', 'chat:open']);
      window.$crisp.push(["do", "message:thread:start", [`helpme: ${service}/${status}`]]);
    };

    setDocumentTitle = (widgetId) => {
      const widgetIdWithTree = widgetId === 'tree' ? 'race' : widgetId
      document.title = widgets[widgetIdWithTree] && widgets[widgetIdWithTree].docTitle ||  'TipAlerts';
    };

    render() {
      const {
        authDisableNewIntegration,
        authDisableNewEditor,
        authUserGoToNewVersion,
        user,
        supporter,
        lastActivity,
        match: { params: { widgetId } },
        authGenerateStreamAccessToken,
        widgetsTestAlert,
        streamStates,
        t,
      } = this.props;
      this.setDocumentTitle(widgetId);
      if (!user.profiles) return null;

      // const {
      //   alfaVersionAccess,
      // } = user;

      const profile = user.profiles[user.currentProfile];
      const connectedServices = serviceIdArr.filter((service) => profile[`${Service_ID[service]}Id`]);
      return (
        <div>
          {/* alfaVersionAccess && (
            <NewEditor
              authUserGoToNewVersion={authUserGoToNewVersion}
              authDisableNewEditor={authDisableNewEditor}
              profile={profile}
            />
          ) */}
          <NewEditor
            authUserGoToNewVersion={authUserGoToNewVersion}
            authDisableNewEditor={authDisableNewEditor}
            profile={profile}
          />
          <ConnectService authDisableNewIntegration={authDisableNewIntegration} profile={profile} />
          <Grid style={{ marginBottom: '20px' }}>
            {connectedServices.map(service => (
              streamStates && streamStates[service] && streamStates[service] !== 'ok'
              && (
                <Warning
                  key={service}
                  title={getStreamStatesPresets(service)[streamStates[service]]}
                  lastActivity={lastActivity}
                  onHelpClick={this.onHelpClick(service, streamStates[service])}
                />
              )))}
            {!supporter.active && <Switch>
              <Route
                exact
                path={`/widget/${widgets.goals.id}`}
                render={() => <PremiumOnly widgetTitle={widgets[widgetId].title} />}
              />
              <Route
                exact
                path={`/widget/${widgets.top.id}`}
                render={() => <PremiumOnly widgetTitle={widgets[widgetId].title} />}
              />
              <Route
                exact
                path={`/widget/${widgets.dice.id}`}
                render={() => <PremiumOnly widgetTitle={widgets[widgetId].title} />}
              />
            </Switch>}
            {connectedServices.length ? (
              <ToolbarContainer>
                <WidgetURLStyled
                  authGenerateStreamAccessToken={authGenerateStreamAccessToken}
                  streamAccessToken={profile.streamAccessToken}
                  lastActivity={lastActivity}
                  widgetId={widgetId}
                  t={t}
                />
                {widgets.tips.id === widgetId && <TestAlertStyled onSend={widgetsTestAlert} />}
              </ToolbarContainer>
            ) : null}
            <div>
              <Switch>
                <Route exact path={`/widget/${widgets.tips.id}`} component={tips.AmountVariations} />
                <Route exact path={`/widget/${widgets.goals.id}`} component={goals.AmountVariations} />
              </Switch>
              <PageContainer>
                <PreviewContainer>
                  <Switch>
                    <Route exact path={`/widget/${widgets.tips.id}`} component={tips.Preview} />
                    <Route exact path={`/widget/${widgets.goals.id}`} component={goals.Preview} />
                    <Route exact path={`/widget/${widgets.top.id}`} component={top.Preview} />
                    <Route exact path={`/widget/${widgets.dice.id}`} component={dice.Preview} />
                  </Switch>
                </PreviewContainer>
                <WidgetOptionsContainer>
                  <Switch>
                    <Route exact path={`/widget/${widgets.tips.id}`} component={tips.Options} />
                    <Route exact path={`/widget/${widgets.goals.id}`} component={goals.Options} />
                    <Route exact path={`/widget/${widgets.top.id}`} component={top.Options} />
                    <Route exact path={`/widget/${widgets.dice.id}`} component={dice.Options} />
                  </Switch>
                </WidgetOptionsContainer>
              </PageContainer>
            </div>
          </Grid>
        </div>
      );
    }
  },
);
