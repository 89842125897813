import { REHYDRATE, PURGE } from 'redux-persist/lib/constants';
import { removeUndefinedFields } from 'utils';
import { REQUEST_STATUS } from 'constants';

const initialStore = {
  user: null,
  fields: {
    email: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_AUTH_NAME || '' : '',
    password: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_AUTH_PASSWORD || '' : '',
    passwordRepeat: '',
  },
  inProgress: false,
  authError: null,
  resetPasswordSuccess: false,
  lastActivity: null,
  hideDonationModal: false,
  supporter: {
    firstTime: true,
  },
  paymentInProgress: false,
  paymentPatreonURL: null,
  paymentShowFSModal: false,
  paymentFSFailed: false,
  paymentContactModal: false,
  paymentContactModalProgress: false,

  provider: '',
  getProviderInProgress: false,

  trialInProgress: false,
  trialDelay: false,
  servicesErrors: {},
  servicesLoadings: {},
  sendInviteStatus: REQUEST_STATUS.CLEAR,
  savedInviteCode: '',
};

export const AUTH_SET_FIELD_VALUE = 'AUTH_SET_FIELD_VALUE';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_SIGNUP_FAIL = 'AUTH_SIGNUP_FAIL';
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNIN_SUCCESS = 'AUTH_SIGNIN_SUCCESS';
export const AUTH_SIGNIN_FAIL = 'AUTH_SIGNIN_FAIL';
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT';
export const AUTH_DISABLE_NEW_INTEGRATION = 'AUTH_DISABLE_NEW_INTEGRATION';
export const AUTH_DISABLE_NEW_EDITOR = 'AUTH_DISABLE_NEW_EDITOR';
export const AUTH_DELETE_SERVICE_ID = 'AUTH_DELETE_SERVICE_ID';
export const AUTH_SETCHATURBATEID_SUCCESS = 'AUTH_SETCHATURBATEID_SUCCESS';
export const AUTH_SETCHATURBATEID_FAIL = 'AUTH_SETCHATURBATEID_FAIL';
export const AUTH_SEND_SERVICE_ID = 'AUTH_SEND_SERVICE_ID';
export const AUTH_SET_SERVICE_ID_ERROR = 'AUTH_SET_SERVICE_ID_ERROR';
export const AUTH_SET_SERVICE_ID_LOADING = 'AUTH_SET_SERVICE_ID_LOADING';
export const AUTH_FIREBASE_SIGNIN = 'AUTH_FIREBASE_SIGNIN';
export const AUTH_GENERATE_STREAMACCESSTOKEN = 'AUTH_GENERATE_STREAMACCESSTOKEN';
export const AUTH_SET_PASSWORD = 'AUTH_SET_PASSWORD';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_RESET_PASSWORD_ERROR = 'AUTH_RESET_PASSWORD_ERROR';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_UPDATE_LAST_ACTIVITY = 'AUTH_UPDATE_LAST_ACTIVITY';
export const AUTH_DONATION_MODAL_HIDE = 'AUTH_DONATION_MODAL_HIDE';
export const AUTH_SET_DONATION_STATUS = 'AUTH_SET_DONATION_STATUS';
export const AUTH_SET_USER_FIELDS = 'AUTH_SET_USER_FIELDS';
export const AUTH_GET_PAYMENT_LINK = 'AUTH_GET_PAYMENT_LINK';
export const AUTH_GET_PAYMENT_PROVIDER = 'AUTH_GET_PAYMENT_PROVIDER';
export const AUTH_SET_PAYMENT_PROVIDER = 'AUTH_SET_PAYMENT_PROVIDER';
export const AUTH_SET_RENEW_SUBSCRIPTION = 'AUTH_SET_RENEW_SUBSCRIPTION';
export const AUTH_PAYMENT_PATREON = 'AUTH_PAYMENT_PATREON';
export const AUTH_PAYMENT_PATREON_CLEAR = 'AUTH_PAYMENT_PATREON_CLEAR';
export const AUTH_PAYMENT_PROGRESS_CLEAR = 'AUTH_PAYMENT_PROGRESS_CLEAR';
export const AUTH_PAYMENT_FS_MODAL_SHOW = 'AUTH_PAYMENT_FS_MODAL_SHOW';
export const AUTH_PAYMENT_FS_DISABLE_SUBSCRIPTION = 'AUTH_PAYMENT_FS_DISABLE_SUBSCRIPTION';
export const AUTH_PAYMENT_ENABLE_SUBSCRIPTION = 'AUTH_PAYMENT_ENABLE_SUBSCRIPTION';
export const AUTH_TRIAL_PROGRESS_CLEAR = 'AUTH_TRIAL_PROGRESS_CLEAR';
export const AUTH_TRIAL_PROGRESS_TO_CANCEL = 'AUTH_TRIAL_PROGRESS_TO_CANCEL';
export const AUTH_TRIAL_DELAY_IS_OVER = 'AUTH_TRIAL_DELAY_IS_OVER';
export const WIDGETS_DELETE_IMAGE = 'WIDGETS_DELETE_IMAGE';
export const WIDGETS_DELETE_FILE = 'WIDGETS_DELETE_FILE';
export const AUTH_USER_SETTINGS_UPDATE = 'AUTH_USER_SETTINGS_UPDATE';
export const AUTH_PAYMENT_CONTACT_MODAL_SHOW = 'AUTH_PAYMENT_CONTACT_MODAL_SHOW';
export const AUTH_PAYMENT_CONTACT_SAVE = 'AUTH_PAYMENT_CONTACT_SAVE';
export const AUTH_SUBSCRIPTION_EXPIRE = 'AUTH_SUBSCRIPTION_EXPIRE';
export const AUTH_SKIP_CHECK_ON_SIGNUP = 'AUTH_SKIP_CHECK_ON_SIGNUP';
export const AUTH_SEND_INVITE = 'AUTH_SEND_INVITE';
export const AUTH_SEND_INVITE_SUCCESS = 'AUTH_SEND_INVITE_SUCCESS';
export const AUTH_SEND_INVITE_FAIL = 'AUTH_SEND_INVITE_FAIL';
export const AUTH_SET_INVITE_CODE = 'AUTH_SET_INVITE_CODE';
export const AUTH_USER_GO_TO_NEW_VERSION = 'AUTH_USER_GO_TO_NEW_VERSION';
export const AUTH_CHANGE_TOKENS_RATE = 'AUTH_CHANGE_TOKENS_RATE';


export const authSetFieldValue = (field, value) => ({
  type: AUTH_SET_FIELD_VALUE,
  field,
  value,
});
export const authSignUp = () => ({ type: AUTH_SIGNUP });
export const authSignUpFail = error => ({ type: AUTH_SIGNUP_FAIL, error });
export const authSignUpSuccess = user => ({ type: AUTH_SIGNUP_SUCCESS, user });
export const authSignIn = () => ({ type: AUTH_SIGNIN });
export const authSignInSuccess = user => ({ type: AUTH_SIGNIN_SUCCESS, user });
export const authFirebaseSignIn = user => ({
  type: AUTH_FIREBASE_SIGNIN,
  user,
});
export const authSignInFail = error => ({ type: AUTH_SIGNIN_FAIL, error });
export const authSignOut = () => ({ type: AUTH_SIGNOUT });
export const authDisableNewIntegration = disableNewIntegration => ({
  type: AUTH_DISABLE_NEW_INTEGRATION,
  disableNewIntegration,
});

export const authDisableNewEditor = disableNewEditor => ({
  type: AUTH_DISABLE_NEW_EDITOR,
  disableNewEditor,
});

export const authDeleteServiceId = service => ({
  type: AUTH_DELETE_SERVICE_ID,
  service,
});

export const authSetChaturbateIdFail = error => ({
  type: AUTH_SETCHATURBATEID_FAIL,
  error,
});
export const authSetChaturbateIdSuccess = user => ({
  type: AUTH_SETCHATURBATEID_SUCCESS,
  user,
});

export const authSendServiceId = (id, service) => ({
  type: AUTH_SEND_SERVICE_ID,
  id,
  service,
});
export const authSetServiceIdLoading = (loading, service) => ({
  type: AUTH_SET_SERVICE_ID_LOADING,
  loading,
  service,
});
export const authSetServiceIdError = (error, service) => ({
  type: AUTH_SET_SERVICE_ID_ERROR,
  error,
  service,
});

export const authGenerateStreamAccessToken = () => ({
  type: AUTH_GENERATE_STREAMACCESSTOKEN,
});
export const authSetPassword = (oldPassword, password) => ({
  type: AUTH_SET_PASSWORD,
  oldPassword,
  password,
});
export const authResetPassword = () => ({ type: AUTH_RESET_PASSWORD });
export const authResetPasswordError = error => ({
  type: AUTH_RESET_PASSWORD_ERROR,
  error,
});
export const authResetPasswordSuccess = () => ({
  type: AUTH_RESET_PASSWORD_SUCCESS,
});
export const authUpdateLastActivity = date => ({
  type: AUTH_UPDATE_LAST_ACTIVITY,
  date,
});
export const authDonationModalHide = () => ({ type: AUTH_DONATION_MODAL_HIDE });

export const authSetDonationStatus = status => ({
  type: AUTH_SET_DONATION_STATUS,
  status,
});

export const authSetUserFields = user => ({
  type: AUTH_SET_USER_FIELDS,
  user,
});

export const authGetPaymentLink = (accountType, goStraight, duration = '1m') => ({
  type: AUTH_GET_PAYMENT_LINK,
  accountType,
  goStraight,
  duration,
});

export const authGetPaymentProvider = () => ({ type: AUTH_GET_PAYMENT_PROVIDER });
export const authSetPaymentProvider = provider => ({ type: AUTH_SET_PAYMENT_PROVIDER, provider });

export const authSetRenewSubscription = renewSubscription => ({
  type: AUTH_SET_RENEW_SUBSCRIPTION,
  renewSubscription,
});

export const authPaymentPatreon = url => ({ type: AUTH_PAYMENT_PATREON, url });
export const authPaymentPatreonClear = () => ({ type: AUTH_PAYMENT_PATREON_CLEAR });
export const authTrialProgressClear = () => ({ type: AUTH_TRIAL_PROGRESS_CLEAR });
export const authTrialProgressToCancel = () => ({ type: AUTH_TRIAL_PROGRESS_TO_CANCEL });
export const authTrialDelayIsOver = () => ({ type: AUTH_TRIAL_DELAY_IS_OVER });
export const authPaymentProgressClear = () => ({ type: AUTH_PAYMENT_PROGRESS_CLEAR });
export const authPaymentFSModalShow = (show, failed = false) => ({ type: AUTH_PAYMENT_FS_MODAL_SHOW, show, failed });
export const authPaymentDisableSubscription = pp => ({ type: AUTH_PAYMENT_FS_DISABLE_SUBSCRIPTION, pp });
export const authPaymentEnableSubscription = () => ({ type: AUTH_PAYMENT_ENABLE_SUBSCRIPTION });
export const authPaymentContactModalShow = show => ({ type: AUTH_PAYMENT_CONTACT_MODAL_SHOW, show });
export const authPaymentContactSave = (firstName, lastName) => ({ type: AUTH_PAYMENT_CONTACT_SAVE, firstName, lastName });
export const authSubscriptionExpire = (expired = true) => ({ type: AUTH_SUBSCRIPTION_EXPIRE, expired });

export const deleteFile = (fileId, file, fileType) => ({
  type: ((fileType === 'image') ? WIDGETS_DELETE_IMAGE : WIDGETS_DELETE_FILE),
  fileType,
  fileId,
  file,
});

export const authUserSettingsUpdate = widgetId => payload => ({
  type: AUTH_USER_SETTINGS_UPDATE,
  widgetId,
  payload,
});

export const authUserGoToNewVersion = () => ({
  type: AUTH_USER_GO_TO_NEW_VERSION,
});
export const authSkipCheckOnSignUp = (skip = true) => ({ type: AUTH_SKIP_CHECK_ON_SIGNUP, skip });

export const authSendInvite = code => ({ type: AUTH_SEND_INVITE, code });
export const authSendInviteFail = () => ({ type: AUTH_SEND_INVITE_FAIL });
export const authSendInviteSuccess = () => ({ type: AUTH_SEND_INVITE_SUCCESS });
export const authSetInviteCode = code => ({ type: AUTH_SET_INVITE_CODE, code });
export const authChangeTokensRate = (service, rate) => ({ type: AUTH_CHANGE_TOKENS_RATE, service, rate });

export default (state = { ...initialStore }, action = {}) => {
  switch (action.type) {
    case PURGE:
      return { ...initialStore };
    case REHYDRATE:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        ...action.payload.auth,
        authError: null,
        resetPasswordSuccess: false,
      };
    case AUTH_SET_FIELD_VALUE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.field]: action.value,
        },
      };
    case AUTH_SIGNUP:
      return {
        ...state,
        inProgress: true,
        authError: null,
      };
    case AUTH_SIGNUP_FAIL:
      return {
        ...state,
        inProgress: false,
        authError: action.error.message,
      };
    case AUTH_SIGNUP_SUCCESS:
      return {
        ...state,
        inProgress: false,
        user: { ...state.user, ...action.user },
        authError: null,
        fields: { ...initialStore.fields },
      };
    case AUTH_SIGNIN:
      return {
        ...state,
        inProgress: true,
        authError: null,
      };
    case AUTH_SIGNIN_SUCCESS:
      return {
        ...state,
        inProgress: false,
        authError: null,
        fields: { ...initialStore.fields },
      };
    case AUTH_SIGNIN_FAIL:
      return {
        ...state,
        inProgress: false,
        authError: action.error.message,
      };
    case AUTH_SIGNOUT:
      return {
        ...state,
        user: null,
        supporter: { ...initialStore.supporter },
      };
    case AUTH_SETCHATURBATEID_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case AUTH_FIREBASE_SIGNIN:
      return {
        ...state,
        user: { ...state.user, ...action.user },
      };
    case AUTH_RESET_PASSWORD:
      return {
        ...state,
        authError: null,
      };
    case AUTH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        authError: null,
        resetPasswordSuccess: true,
      };
    case AUTH_RESET_PASSWORD_ERROR:
      return {
        ...state,
        authError: action.error.message,
      };
    case AUTH_UPDATE_LAST_ACTIVITY:
      return {
        ...state,
        lastActivity: action.date,
      };
    case AUTH_DONATION_MODAL_HIDE:
      return {
        ...state,
        hideDonationModal: true,
      };
    case AUTH_SET_DONATION_STATUS:
      return {
        ...state,
        supporter: action.status ? action.status : initialStore.supporter,
      };
    case AUTH_SET_USER_FIELDS:
      return {
        ...state,
        user: { ...state.user, ...action.user },
      };
    case AUTH_GET_PAYMENT_LINK:
      return {
        ...state,
        paymentInProgress: true,
        trialInProgress: action.accountType === 'trial',
        trialDelay: action.accountType === 'trial',
      };
    case AUTH_GET_PAYMENT_PROVIDER:
      return {
        ...state,
        getProviderInProgress: true,
      };
    case AUTH_SET_PAYMENT_PROVIDER:
      return {
        ...state,
        getProviderInProgress: false,
        provider: action.provider,
      };
    case AUTH_TRIAL_PROGRESS_CLEAR:
    case AUTH_TRIAL_PROGRESS_TO_CANCEL: // cancel in saga
      return {
        ...state,
        trialInProgress: false,
        trialDelay: false,
      };
    case AUTH_TRIAL_DELAY_IS_OVER:
      return {
        ...state,
        trialDelay: false,
      };
    case AUTH_PAYMENT_PATREON:
      return {
        ...state,
        paymentPatreonURL: action.url,
      };
    case AUTH_PAYMENT_PROGRESS_CLEAR:
      return {
        ...state,
        paymentInProgress: false,
      };
    case AUTH_PAYMENT_FS_MODAL_SHOW:
      return {
        ...state,
        paymentShowFSModal: action.show,
        paymentFSFailed: action.failed,
      };
    case AUTH_PAYMENT_PATREON_CLEAR:
      return {
        ...state,
        paymentPatreonURL: null,
        paymentInProgress: false,
        trialInProgress: false,
        trialDelay: false,
      };
    case WIDGETS_DELETE_IMAGE: {
      const _state = { ...state, user: { ...state.user, files: { ...state.user.files } } };
      delete _state.user.files[action.file];
      return _state;
    }
    case WIDGETS_DELETE_FILE: {
      const _state = { ...state, user: { ...state.user, uploads: { ...state.user.uploads, sound: { ...state.user.uploads.sound } } } };
      delete _state.user.uploads.sound[action.file];
      return _state;
    }
    case AUTH_SET_SERVICE_ID_ERROR: {
      return {
        ...state,
        servicesErrors: {
          ...state.servicesErrors,
          [action.service]: action.error,
        },
        servicesLoadings: {
          ...state.servicesLoadings,
          [action.service]: false,
        },
      };
    }
    case AUTH_SET_SERVICE_ID_LOADING: {
      return {
        ...state,
        servicesErrors: {
          ...state.servicesErrors,
          [action.service]: '',
        },
        servicesLoadings: {
          ...state.servicesLoadings,
          [action.service]: action.loading,
        },
      };
    }
    case AUTH_USER_SETTINGS_UPDATE: {
      const optionsWidget = (state.user && state.user.settings && state.user.settings[action.widgetId]) || {};
      return {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...(state.user.settings || {}),
            [action.widgetId]: {
              ...optionsWidget,
              ...removeUndefinedFields(action.payload),
            },
          },
        },
      };
    }
    case AUTH_PAYMENT_CONTACT_MODAL_SHOW:
      return {
        ...state,
        paymentContactModal: action.show,
        paymentContactModalProgress: false,
      };
    case AUTH_PAYMENT_CONTACT_SAVE:
      return {
        ...state,
        paymentContactModalProgress: true,
      };
    case AUTH_SUBSCRIPTION_EXPIRE:
      return {
        ...state,
        subscriptionExpired: action.expired,
      };
    case AUTH_SKIP_CHECK_ON_SIGNUP:
      return {
        ...state,
        skipCheckOnSignUp: action.skip,
      };
    case AUTH_SEND_INVITE:
      return {
        ...state,
        sendInviteStatus: REQUEST_STATUS.LOADING,
      };
    case AUTH_SEND_INVITE_FAIL:
      return {
        ...state,
        sendInviteStatus: REQUEST_STATUS.FAIL,
      };
    case AUTH_SEND_INVITE_SUCCESS:
      return {
        ...state,
        sendInviteStatus: REQUEST_STATUS.SUCCESS,
      };
    case AUTH_SET_INVITE_CODE:
      return {
        ...state,
        savedInviteCode: action.code,
      };
    default:
      return state;
  }
};
