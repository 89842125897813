import { css } from 'styled-components';

export const theme = {
  main: {
    orangeLanding: '#ffb65a',
    red: '#FF5A5A', // hsl(0, 100%, 68%)
    redHover: '#FF2929', // hsl(0, 100%, 58%)
    lightGrey: '#E9E9E9',
    orangeLanding05: 'rgba(255, 182, 90, 0.5)',
    orangeButtonActive: '#FFA029',
    orangeButtonHover: '#FFCD8F',
    linkHover: '#FFC378',
    linkActive: '#E3932E',
    darkGrey: '#333333',
    greyTable: '#364755',
    backgroundTabHOC: '#324A5C',
    pastelOrange: '#ff984d',
    slate: '#536a7a',
    slate50: 'rgba(83, 106, 122, 0.5)',
    dark: '#2e3c47',
    white: '#ffffff',
    whiteHover: '#E6E6E6', // hsl(0, 00%, 90%)
    bananaYellow: '#e8ff41',
    babyBlue: '#9ad8ff',
    lightPeach: '#ffbcbc',
    slateTwo: '#495d6b',
    darkGreyBlue: '#31404d',
    slateThree: '#394b59',
    white05: 'rgba(255, 255, 255, 0.05)',
    white10: 'rgba(255, 255, 255, 0.1)',
    white17: 'rgba(255, 255, 255, 0.1)',
    white35: 'rgba(255, 255, 255, 0.35)',
    white40: 'rgba(255, 255, 255, 0.4)',
    white50: 'rgba(255, 255, 255, 0.5)',
    white54: 'rgba(255, 255, 255, 0.54)',
    white60: 'rgba(255, 255, 255, 0.6)',
    white80: 'rgba(255, 255, 255, 0.8)',
    darkGreyBlueTwo: '#31414c',
    slateFour: '#485c6b',
    darkTwo: '#1d272e',
    blueGrey: '#5a7385',
    slateFive: '#3d4e59',
    slateSix: '#3a4c59',
    slateSeven: '#4c6170',
    black: '#000',
    lightGold: '#ffd84c',
    lightGold60: '#ffd84c99',
    blueGreyTwo: '#5c7383',
    black05: 'rgba(0, 0, 0, 0.05)',
    black20: 'rgba(0, 0, 0, 0.2)',
    black50: 'rgba(0, 0, 0, 0.5)',
    black80: 'rgba(0, 0, 0, 0.80)',
    disabled: '#ADADAD',
    font: {
      accent: css`
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.2px;
        line-height: 1.05;
      `,
      usual: css`
        font-size: 14px;
        font-weight: 600;
        line-height: 1.05;
      `,
      heading: css`
        font-size: 16px;
        font-weight: 700;
        line-height: 1.05;
      `,
      title18_700: css`
        font-size: 18px;
        font-weight: 700;
        line-height: 1.15;
      `,
      /*
        normal: css`
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.1px;
        line-height: 1.23;
      `,
      */
      normalBold: css`
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.1px;
        line-height: 1.23;
      `,
      /* new fonts */
      normal: css`
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5; /* 24px */
      `,
      header: css`
        font-weight: 500;
        font-size: 20px;
        line-height: 1.2; /* 24px */
      `,
      headerCheckbox: css`
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
      `,
      formItemCol: css`
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      `,
    },
  },
};
