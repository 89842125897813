import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
  TextAppearance,
  TransformBlock,
  AnimationBlock,
  PositionBlock,
  InputAreaBlock,
} from 'components/UIkit';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActionWidgetsComponentsUpdate } from 'store/reducers/widgets';
import { widgetsOptionsSelectTestPreview } from 'store/reducers/widgetsOptions';
import { widgets } from 'constants';

const Col2 = styled.div`
  flex: 2;
  margin-right: ${({ marginRight }) => marginRight}px;
`;

const Col1 = styled.div`
  flex: 1;
  margin-right: ${({ marginRight }) => marginRight}px;
  ${({ flexEnd }) => flexEnd && css`
    justify-content: flex-end;
    display: flex;
  `};
`;

const Row = styled.div`
  display: flex;
  align-items: ${({ baseline }) => (baseline ? 'baseline' : 'center')};
`;

const Text = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  >span {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;

  width: 490px;
  justify-content: center;
  text-align: center;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;

  display: flex;

  justify-content: center;
  align-items: center;

  margin-top: 30%;
  margin-bottom: 30%;


`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  margin-top: 26px;
`;

const PricingPageLink = styled(Link)`
  color: ${({ theme }) => theme.orangeLanding};

  border: none;
  outline: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
   border: none;
   outline: none;
  }

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  padding: 8px 16px;
  border-radius: 4px;

  background: hsla(33, 100%, 68%, 0.17); /* hsla(33, 100%, 68%, 0.17); rgba(255, 182, 90, 0.17)*/

  &:hover{
    background-color: hsla(33, 100%, 78%, 0.17);
  }
  &:active{
    background-color: hsla(33, 100%, 58%, 0.17);
  }

  &:visited{
    color: ${({ theme }) => theme.orangeLanding};
  }
`;

const TestButton = styled.button`
  color: ${({ theme }) => theme.orangeLanding};

  border: none;
  outline: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
  border: none;
  outline: none;
  }

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  padding: 8px 16px;
  border-radius: 4px;

  background: hsla(33, 100%, 68%, 0.17); /* hsla(33, 100%, 68%, 0.17); rgba(255, 182, 90, 0.17)*/

  &:hover{
    background-color: hsla(33, 100%, 78%, 0.17);
  }
  &:active{
    background-color: hsla(33, 100%, 58%, 0.17);
  }

  margin-right: 12px;
`;

class MessageTab extends Component {
  render() {
    const {
      data,
      widgetsComponentsUpdate,
      isBasic,
      selectedTestPreview,
      widgetsOptionsSelectTestPreview,
    } = this.props;

    return (
      <div>
        {(isBasic && !selectedTestPreview) && (
        <Content>
          <TextContent>
            <Text>
              Upgrade your subscription to premium to use custom messages
              <br />
              <span>You can also test it in a preview-only mode.</span>
            </Text>
            <ButtonContent>
              <TestButton onClick={() => widgetsOptionsSelectTestPreview()}>Test in Preview</TestButton>
              <PricingPageLink to="/price"> Upgrade </PricingPageLink>
            </ButtonContent>
          </TextContent>
        </Content>)}
        {(!isBasic || selectedTestPreview) && (
        <div>
          <InputAreaBlock
            data={data}
            widgetUpdate={widgetsComponentsUpdate}
            previewOnly={selectedTestPreview}
          />
          <TextAppearance
            data={data}
            widgetUpdate={widgetsComponentsUpdate}

            customMessageOptions
            borderTop
            mainLabel="Message"
          />
          <Row baseline>
            <Col1 marginRight="16">
              <PositionBlock
                data={data}
                widgetUpdate={widgetsComponentsUpdate}
              />
            </Col1>
            <Col2>
              <TransformBlock
                data={data}
                widgetUpdate={widgetsComponentsUpdate}
              />
            </Col2>
          </Row>
          <AnimationBlock
            data={data}
            widgetUpdate={widgetsComponentsUpdate}
            borderTop
          />
        </div>)}
      </div>
    );
  }
}

MessageTab.title = 'Custom message';

export default connect(
  state => ({
    selectedTestPreview: state.widgetsOptions.tips.selectedTestPreview,
  }),
  (dispatch, ownProps) => bindActionCreators({
    widgetsComponentsUpdate: getActionWidgetsComponentsUpdate(widgets.tips.id, ownProps.selectedVariation, 'message'),
    widgetsOptionsSelectTestPreview,
  }, dispatch),
)(MessageTab);
