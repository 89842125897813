import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  margin-bottom: 36px;

  ${({ borderTop }) => (borderTop
    && css`
    border-top: 1px solid hsla(205, 35%, 23%, 1);
    `)};
`;

export const Section = ({
  children,
  borderTop,
}) => (
  <Container borderTop={borderTop}>
    {children}
  </Container>
);
