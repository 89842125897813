// @flow
/* eslint-disable */
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { widgetsOptionsSelectPreset } from 'store/reducers/widgetsOptions';
import styled from 'styled-components';

import { TabsContainer } from 'components/UI';
import { GoalsDefaultData, widgets } from 'constants';

import type { TipAlertData } from 'types/Widget';

import {
  TitleTab,
  ProgressBarTab,
  ImageTab,
  PresetTab,
} from './Tabs';


const NoVariationsContainer = styled.div`
  text-align: center;
`;
const GoalsNoVariations = () => (
  <NoVariationsContainer>Click &quot;Create new&quot; button to create goal</NoVariationsContainer>
);

type Props = {
  goalsData: {},
  selectedPreset: string,
  widgetsOptionsSelectPreset: any => void,
};

export default connect(
  state => ({
    goalsData: state.widgets.goals || {},
    selectedPreset: state.widgetsOptions.goals.selectedPreset,
  }),
  dispatch => bindActionCreators({
    widgetsOptionsSelectPreset,
  }, dispatch),
)(class Options extends Component<Props> {
  static defaultProps = {
    goalsData: {},
  };

  state = {
    lengthGoalsData: {},
  };

  componentDidMount() {
    const { goalsData, selectedPreset, widgetsOptionsSelectPreset } = this.props;
    const { lengthGoalsData } = this.state
    if (!selectedPreset) {
      const presets = Object.keys(goalsData)
        .filter(key => goalsData[key] && goalsData[key].preset && goalsData[key].preset.goalValue !== undefined)
        .sort((a, b) => goalsData[a].preset.goalValue - goalsData[b].preset.goalValue);
      widgetsOptionsSelectPreset(widgets.goals.id, presets[0]);
    }

    const lenghtData = Object.keys(goalsData).length;
    if (lengthGoalsData !== lenghtData) {
      this.setState({ lengthGoalsData: lenghtData });
    };
  }

  componentDidUpdate() {
    const { goalsData, widgetsOptionsSelectPreset } = this.props;
    const { lengthGoalsData } = this.state;
    const lenghtData = Object.keys(goalsData).length;
    if (lengthGoalsData !== lenghtData) {
      const presets = Object.keys(goalsData)
        .filter(key => goalsData[key] && goalsData[key].preset && goalsData[key].preset.goalValue !== undefined)
        .sort((a, b) => goalsData[a].preset.goalValue - goalsData[b].preset.goalValue);
      widgetsOptionsSelectPreset(widgets.goals.id, presets[0]);
      this.setState({ lengthGoalsData: lenghtData });
    };
  }


  render() {
    const {
      goalsData,
      selectedPreset,
    } = this.props;

    const presets = Object.keys(goalsData).reduce((acc, key) => {
      if (goalsData[key] && goalsData[key].preset && goalsData[key].preset.value !== undefined) return { ...acc, [key]: goalsData[key].preset};
      return acc;
    }, {});

    const selectedData = goalsData[selectedPreset] || {};
    const tabHOC = (Tab, props) => <Tab {...props} selectedPreset={selectedPreset} />;

    return Object.keys(presets).length && selectedPreset ? (
      <TabsContainer
        content={[
          tabHOC(PresetTab, {
            data: {
              ...GoalsDefaultData.preset,
              ...(selectedData.preset || {}),
            },
            presets,
          }),
          tabHOC(TitleTab, {
            data: {
              ...GoalsDefaultData.title,
              ...(selectedData.title || {}),
            },
          }),
          tabHOC(ProgressBarTab, {
            data: {
              ...GoalsDefaultData.progressBar,
              ...(selectedData.progressBar || {}),
            },
          }),
          tabHOC(ImageTab, {
            data: {
              ...GoalsDefaultData.image,
              ...(selectedData.image || {}),
            },
          }),
        ]}
      />
    ) : (
      <GoalsNoVariations />
    );
  };
});
