/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { widgetUpdate } from 'store/reducers/widgets';
import { widgetsOptionsSelectPreset } from 'store/reducers/widgetsOptions';

import { GoalsDefaultData, widgets } from 'constants';

import { TabsBar } from 'components/UI';
import uuid from 'uuid/v4';

export default connect(
  state => ({
    goalsData: state.widgets.goals || {},
    selectedPreset: state.widgetsOptions.goals.selectedPreset,
  }),
  dispatch => bindActionCreators(
    {
      widgetUpdate,
      widgetsOptionsSelectPreset,
    },
    dispatch,
  ),
)(class AmountVariations extends React.Component {
  shouldComponentUpdate({ goalsData: nextGoalsData, selectedPreset: nextSelectedPreset }) {
    const { goalsData, selectedPreset } = this.props;
    const actual = Object.keys(goalsData).sort().map((key) => goalsData[key] && goalsData[key].preset);
    const next = Object.keys(nextGoalsData).sort().map((key) => nextGoalsData[key] && nextGoalsData[key].preset);
    return JSON.stringify(actual) !== JSON.stringify(next) || selectedPreset !== nextSelectedPreset;
  }

  onCreateClick = () => {
    const { widgetUpdate, widgetsOptionsSelectPreset } = this.props;

    const key = uuid();

    widgetUpdate(widgets.goals.id, { [key]: { preset: { ...GoalsDefaultData.preset } } });
    widgetsOptionsSelectPreset(widgets.goals.id, key);
  };

  onSelect = (variationIndex: number, items: string[]) => {
    const { widgetsOptionsSelectPreset } = this.props;
    const { key } = items[variationIndex];
    widgetsOptionsSelectPreset(widgets.goals.id, key);
  };

  render() {
    const {
      goalsData,
      selectedPreset,
    } = this.props;
    const sortedVariations = Object.keys(goalsData)
      .reduce((acc, key) => {
        if (goalsData[key] && goalsData[key].preset && goalsData[key].preset.goalValue !== undefined) {
          return [...acc, { key, goalValue: goalsData[key].preset.goalValue }];
        }
        return acc;
      }, [])
      .sort((a, b) => a.goalValue - b.goalValue);

    const active = sortedVariations.findIndex(({ key }) => key === selectedPreset);

    return (
      <TabsBar
        title={widgets.goals.title}
        actionButtonTitle="Create"
        items={sortedVariations}
        itemsTitleKey="goalValue"
        active={active}
        onChangeTab={this.onSelect}
        onActionButtonClick={this.onCreateClick}
      />
    );
  }
});
