const isStageStand = process.env.REACT_APP_NODE_ENV === 'stage';
const isDevStand = process.env.REACT_APP_NODE_ENV === 'dev';


export const getAPIHost = (port, cluster = 1) => {
  if (isStageStand) {
    return `https://ws${port || 4333}.s${cluster}.tipalerts.com`;
  }
  if (isDevStand) {
    return `https://ws${port || 4333}.d${cluster}.tipalerts.com`;
  }
  return `https://ws${port || 4333}.p${cluster}.tipalerts.com`;
};

export const API_HOST = getAPIHost();

export const taCloudfunctions = 'https://us-central1-tipalerts-94db4.cloudfunctions.net';
// export const taCloudfunctions = 'https://fs.alerts.to/tipalerts-94db4/us-central1';


export const web2Link = process.env.REACT_APP_WEB2_LINK || 'http://localhost:3001';
