import styled, { css } from 'styled-components';

import { theme } from 'constants/theme';

export const Container = styled.div`
  width: 100%;
  background-color: #334A5B;

  display: flex;
  justify-content: center;
`;

export const ContainerContent = styled.div`
  padding-top: 32px;
  padding-bottom: 56px;
`;

export const Title = styled.span`
  font-size: 56px;
  font-weight: 800;
  line-height: 110%;
  font-size: 56px;
`;

export const AboutTitles = styled.span`
  font-size: 22px;
  line-height: 32px;
`;

export const AboutTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const contact = css`
  font-size: 18px;
  line-height: 22px;`;

export const Contact = styled.span`${contact}`;

export const EmailLink = styled.a`
  ${contact}
  color: #FFB65A;
  border: none;
  outline: none;
  
  &:hover,
  &:active,
  &:focus {
   border: none;
   outline: none;
   color: ${theme.main.orangeLanding};
  }

  &:visited{
    color: ${theme.main.orangeLanding};
  }

  &:hover{
    color: ${theme.main.linkHover};
  }
  &:active{
    color: ${theme.main.linkActive};
  }
`;
