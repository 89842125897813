import styled from 'styled-components';

import { theme } from 'constants/theme';
import Constants from '../const/const';

export const BackgroundUsesPackage = styled.div`
  background: ${theme.main.slateThree};
  display: flex;
  justify-content: center;
`;

export const UsesPackage = styled.div`
  width: ${Constants.WidthContent};
  display: flex;
  flex-flow: column wrap;
  align-content: center;
`;

export const LogoPackage = styled.div`
  padding-top: 36px;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

export const OBS = styled.div`
  margin: 16px;
  width: 90px;
  height: 90px;
  background-image: url('/images/OBS-logo.svg');
`;

export const XSplit = styled(OBS)`
  background-image: url('/images/XSplit-logo.svg');
`;

export const Wirecast = styled(OBS)`
  background-image: url('/images/Wirecast-logo.svg');
`;

export const TextLogo = styled.div`
  margin-top: 6px;
  margin-bottom: 70px;

  font-weight: bold;
  line-height: 24px;
  font-size: 24px;

  text-align: center;
  > p {
    margin-top: 4px;

    font-weight: normal;
    line-height: 24px;
    font-size: 16px;
  }
`;

export const SpanOrange = styled.span`
  color: ${theme.main.orangeLanding};
`;
