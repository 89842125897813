import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import deepEqual from 'deep-equal';
import styled from 'styled-components';

import './react-datepicker.css';

import { ActiveUsers } from 'components/Log';
import {
  logsGetItems,
  logsClear,
  logsFindMore,
  logsFindMoreByDate,
  logsToggleFetchAuto,
  logsInputUid,
  logsAuth,
  logsInputEmail,
  logsUserGetInfo,
  logsUserImpersonate,
  logsActivatePremium,
  logsActivateTrial,
  logsGetServerStartTime,
  logsRestartServer,
  logsGetActiveUsers,
  logsKillProcess,
  logsTestAlert,
} from 'store/reducers/logs';

import { Service_ID } from 'constants';

import { LogsList } from './LogsList';
import { UserInfo } from './UserInfo';
import { StartTime } from './StartTime';
import { LogButtons } from './LogButtons';
import { Filters } from './Filters';
import { Search } from './Search';
import { SendTestAlert } from './TestAlert';
import { FBLinks } from './FBLinks';

import '../../../node_modules/prismjs/themes/prism-dark.css';

const Container = styled.div`
  display: flex;
  height:calc(100vh - 34px);
  overflow: hidden;
  overflow-y: hidden;
  background-color: rgb(50, 74, 92);
`;

const ContainerButtonTest = styled.div`
  position: relative;
`;

const LeftPart = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const LeftTopControls = styled.div`
  padding-bottom: 4px;
`;
const LeftBottomLogs = styled.div`
  flex: 1;
  display: flex;
  min-height: 50%;
  overflow: auto;
`;

const RightPart = styled.div`
  width: 260px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const tagsNamesServices = {
  PROCESS_SPAWN: 'Process spawn',
  PROCESS_CLOSED: 'Process closed',
  PROCESS_INFO: 'Process info',
  PROCESS_ERROR: 'Process error',
  PROCESS_RMQ_CONNECTION_SUCCESS: 'Process RMQ connection success',
  PROCESS_RMQ_CONNECTION_FAULT: 'Process RMQ connection fault',

  CHAT_CONNECTED: 'Chat connected',
  CHAT_DISCONNECTED: 'Chat disconnected',
  CHAT_MESSAGE: 'Chat message',
  CHAT_TIP: 'Chat tip',
};

const tagsNamesGeneral = {
  SOCKET_CHANGE_PORT: 'Socket change port',
  SOCKET_CHAT_RECONNECT: 'Socket chat reconnect',
  SOCKET_INFO: 'Socket info',
  SOCKET_DISCONNECT: 'Socket disconnect',
  SOCKET_CONNECTED: 'Socket connected',
  API_INFO: 'API info',
};

const servicesTags = Object.keys(tagsNamesServices);
const generalTags = Object.keys(tagsNamesGeneral);
const tags = [...servicesTags, ...generalTags];
const services = Object.keys(Service_ID);

class LogsBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      hideUserInfo: false,
      chosenTags: {},
      chosenServices: {},
      searchByType: 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    this.setState({
      startDate: date,
    });
  }

  componentDidMount() {
    const {
      logsAuth,
      logsGetServerStartTime,
      logsGetActiveUsers,
      location,
      logsInputEmail,
      logsUserGetInfo,
    } = this.props;
    logsAuth();
    setTimeout(() => {
      logsGetServerStartTime();
      logsGetActiveUsers();
      if (location && location.search) {
        const searchParams = new URLSearchParams(location.search);
        const searchEmail = searchParams.get('email');
        logsInputEmail(searchEmail);
        logsUserGetInfo();
      }
    }, 2000);
    this.activeUsersInterval = setInterval(() => logsGetActiveUsers(), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.activeUsersInterval);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !deepEqual(nextState, this.state) || !deepEqual(this.props.logs, nextProps.logs);
  }

  onUidInput = e => this.props.logsInputUid(e.target.value.trim());

  onTagClick = (tag) => {
    const { chosenTags } = this.state;
    this.setState({ chosenTags: { ...chosenTags, [tag]: !chosenTags[tag] } });
  };

  onServiceClick = (service) => {
    const { chosenServices } = this.state;
    this.setState({ chosenServices: { ...chosenServices, [service]: !chosenServices[service] } });
  };

  onFindClick = () => {
    const { chosenTags, chosenServices } = this.state;
    this.props.logsGetItems(tags.filter(tag => chosenTags[tag]), services.filter(service => chosenServices[service]));
  };

  onFindMoreClick = (latest = true) => () => {
    const { chosenTags, chosenServices } = this.state;
    this.props.logsFindMore({ latest, tags: tags.filter(tag => chosenTags[tag]), services: services.filter(service => chosenServices[service]) });
  };

  onDateBeforelogsInput = e => () => {
    this.setState({ dateBefore: e.target.dateBefore });
  }

  onDateAfterlogsInput = e => () => {
    this.setState({ dateAfter: e.target.dateAfter });
  }

  onFindMoreByDateClick = (dateBefore, dateAfter) => {
    this.props.logsClear();
    this.props.logsFindMoreByDate({ dateBefore, dateAfter });
  };

  onActiveUserClick = (uid, email) => {
    this.props.logsClear();
    this.props.logsUserGetInfo(email);
  };

  onSelectSearchByType = (searchByType) => {
    this.setState({ searchByType });
  };

  findLogsUp = () => this.onFindMoreClick(true)();

  findLogsDown = () => this.onFindMoreClick(false)();

  sendLogsTestAlert = (value) => {
    const { logs: { userInfo }, logsTestAlert } = this.props;
    const streamAccessToken = (userInfo && userInfo.user && userInfo.user.profiles[0] && userInfo.user.profiles[0].streamAccessToken) || {};
    logsTestAlert(streamAccessToken, value);
  };

  onChangeHideUserInfo = () => this.setState(state => ({ hideUserInfo: !state.hideUserInfo }));

  render() {
    const {
      logsClear,
      logsToggleFetchAuto,
      logsUserImpersonate,
      logsActivateTrial,
      logsGetServerStartTime,
      logsInputEmail,
      logsUserGetInfo,
      logs: {
        uid,
        fetchAuto,
        email,
        userInfo,
        serverStartTime,
        status,
      },
    } = this.props;
    document.title = 'Logs - TipAlerts';
    const {
      hideUserInfo,
      chosenTags,
      chosenServices,
    } = this.state;

    return (
      <Container>
        <LeftPart>
          <LeftTopControls>
            <StartTime time={serverStartTime} onFetchStartTime={logsGetServerStartTime} />
            <ContainerButtonTest>
              <Search
                email={email}
                token={userInfo && userInfo.token}
                payment={userInfo && userInfo.payment}
                logsUserImpersonate={logsUserImpersonate}
                logsActivateTrial={logsActivateTrial}
                logsInputEmail={logsInputEmail}
                logsUserGetInfo={logsUserGetInfo}
              />
              {(userInfo && userInfo.token) && (
                <SendTestAlert sendLogsTestAlert={this.sendLogsTestAlert} />
              )}
            </ContainerButtonTest>
            {userInfo && (
              <FBLinks
                user={userInfo.user}
                hideUserInfo={this.state.hideUserInfo}
                onChangeHideUserInfo={this.onChangeHideUserInfo}
              />
            )}
            {(userInfo && !hideUserInfo) && <UserInfo info={userInfo} />}
            <LogButtons
              uid={uid}
              onChangeUID={this.onUidInput}
              onFindClick={this.onFindClick}
              onFindClickUp={this.findLogsUp}
              onFindClickDown={this.findLogsDown}
              logsClear={logsClear}
              status={status}
              onDateChange={this.handleChange}
              startDate={this.state.startDate}
              onFindByDateClick={this.onFindMoreByDateClick}
            />
            <Filters
              fetchAuto={fetchAuto}
              logsToggleFetchAuto={logsToggleFetchAuto}
              chosenServices={chosenServices}
              chosenTags={chosenTags}
              onTagClick={this.onTagClick}
              onServiceClick={this.onServiceClick}
            />
          </LeftTopControls>
          <LeftBottomLogs>
            <LogsList />
          </LeftBottomLogs>
        </LeftPart>
        <RightPart>
          <ActiveUsers onClick={this.onActiveUserClick} />
        </RightPart>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  logsGetItems,
  logsClear,
  logsFindMore,
  logsFindMoreByDate,
  logsToggleFetchAuto,
  logsInputUid,
  logsAuth,
  logsInputEmail,
  logsUserGetInfo,
  logsUserImpersonate,
  logsActivatePremium,
  logsActivateTrial,
  logsGetServerStartTime,
  logsRestartServer,
  logsGetActiveUsers,
  logsKillProcess,
  logsTestAlert,
}, dispatch);

export const Logs = connect(
  ({ logs }) => (
    {
      logs: {
        uid: logs.uid,
        fetchAuto: logs.fetchAuto,
        email: logs.email,
        userInfo: logs.userInfo,
        serverStartTime: logs.serverStartTime,
        status: logs.status,
      },
    }),
  mapDispatchToProps,
)(LogsBase);
