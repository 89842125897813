import React, { Component } from 'react';

import {
  BackgroundContainer,
  KeyFeatures,
  Title,
  TextContent,
  GroupContentRow,
  GroupContentColumn,
  LogoPaint,
  LogoDiag,
  LogoDialog,
  LogoVolume,
} from './style';

export class KeyFeaturesContainer extends Component {
  render() {
    return (
      <BackgroundContainer>
        <KeyFeatures>
          <Title>Key features</Title>
          <GroupContentRow>
            <GroupContentColumn>
              <LogoPaint />
              <TextContent>
                Unique outcome
                <p>
                  Editor allows to upload custom images, set up fonts, text size and color, but most
                  importantly it allows to change elements location and animate them the way you
                  want
                </p>
              </TextContent>
            </GroupContentColumn>
            <GroupContentColumn>
              <LogoDiag />
              <TextContent>
                Preset collection
                <p>
                  We got you covered! We prepared different types of alerts and goals for each
                  amount of tokens
                </p>
              </TextContent>
            </GroupContentColumn>
            <GroupContentColumn>
              <LogoDialog />
              <TextContent>
                Personalized message texts
                <p>
                  Individual approach will definitely impress your viewers. Such functionality
                  available in premium account
                </p>
              </TextContent>
            </GroupContentColumn>
            <GroupContentColumn>
              <LogoVolume />
              <TextContent>
                Sound effects
                <p>
                  Choose one of pre-created sound effects in TipAlerts collection or add your own
                  via premium account
                </p>
              </TextContent>
            </GroupContentColumn>
          </GroupContentRow>
        </KeyFeatures>
      </BackgroundContainer>
    );
  }
}
