import React, { Component } from 'react';
import styled from 'styled-components';


const CircleFull = styled.div`
  width: 18px;
  height: 18px;
  background: ${({ colorСircle }) => colorСircle};
  border-radius: 50%;
`;

const CircleMask = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${({ colorCheck }) => colorCheck};
  mask-image: url('/images/check-mark-Price.svg');
  mask-repeat: no-repeat;
  mask-position: center;
`;

type Props = {
    colorСircle: string,
    colorCheck: string,
  };

export class Circle extends Component<Props> {
  render() {
    const {
      colorСircle,
      colorCheck,
    } = this.props;
    return (
      <div>
        <CircleFull colorСircle={colorСircle}>
          <CircleMask colorCheck={colorCheck} />
        </CircleFull>
      </div>
    );
  }
}
