/* eslint-disable */
import React from 'react';
import styled, { keyframes, css } from 'styled-components';

import { getColor } from 'utils';
import { widgetFonts, animationConfig } from 'constants';
import deepEqual from 'deep-equal';

const firstMargin = 9;
const eachMarginTop = 4;
const height = 28;

const eachOffset = ind => ind * (height + eachMarginTop) + firstMargin;

const getTextDecoration = (lineThrough, underline) => {
  let str = '';
  if (lineThrough) {
    str += 'line-through';
    if (underline) {
      str += ' ';
    }
  }
  if (underline) {
    str += 'underline';
  }
  return str;
};

const shadow = data => `${data.textShadowX}px ${data.textShadowY}px ${data.textShadowBlur}px ${getColor(data.textShadowColor)}`;

const setStyle = ({ customStyle }) => (
  ` color: ${getColor(customStyle.color)};
    font-family: ${widgetFonts[customStyle.fontFamily]};
    text-shadow: ${customStyle.textShadowEnabled && shadow(customStyle)};
    font-weight: ${customStyle.fontWeightBold ? 'bold' : customStyle.fontWeight};
    font-style: ${customStyle.fontStyle};
    text-decoration: ${getTextDecoration(customStyle.textDecorationLineThrough, customStyle.textDecorationUnderline)};
    text-transform: ${customStyle.textTransform};
    `
);


// second step of animation is percent of first item came to list of another items
const secondStep = Math.round(100 / (firstMargin + height + eachMarginTop) * firstMargin);

const fadeInFirst = keyframes`
  0% {
    transform: translateY(0);
  }
  ${secondStep}% {
    transform: translateY(${firstMargin}px);
  }
  100% {
    transform: translateY(${firstMargin + eachMarginTop + height}px);
  }
`;

const fadeInAnother = ind => keyframes`
  0% {
    transform: translateY(${eachOffset(ind)}px);
  }
  ${secondStep}% {
    transform: translateY(${eachOffset(ind)}px);
  }
  100% {
    transform: translateY(${eachOffset(ind + 1)}px);
  }
`;

const fadeInAnotherDown = ind => keyframes`
  0% {
    transform: translateY(${eachOffset(ind + 2)}px);
  }
  ${secondStep}% {
    transform: translateY(${eachOffset(ind + 2)}px);
  }
  100% {
    transform: translateY(${eachOffset(ind + 1)}px);
  }
`;

const rollUp = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(${height}px - 100%));
  }
`;

const rollDown = keyframes`
  0% {
    transform: translateY(calc(100% - ${height}px));
  }
  100% {
    transform: translateY(0);
  }
`;

const flashing = color => keyframes`
  0% {
    background-color: transparent;
  }
  33% {
    background-color: ${color};
  }
  66% {
    background-color: ${color};
  }
  100% {
    background-color: transparent;
  }
`;

const Container = styled.div`
  border-radius: 18px;

  ${setStyle};

  background-color: ${({ customStyle, colorBack }) => (colorBack ? `${colorBack}` : (customStyle.backgroundEnabled ? `${customStyle.backgroundColor}` : 'transparent' ))};

  font-size: 14px;
  line-height: ${height}px;
  height: ${height}px;

  padding: ${({ padding }) => (padding ? '0px 12px' : 0)};

  text-align: center;

  right: 16px;
  bottom: 100%;

  position: absolute;

  opacity: 1;
  transition: opacity 0.6s ease-out;

  white-space: nowrap;
  text-overflow: ellipsis;

  transform: translateY(${({ ind }) => (ind ? eachOffset(ind) : 0)}px);

  animation: ${({ ind, prevInd }) => (ind ? (ind > prevInd ? fadeInAnother(ind) : fadeInAnotherDown(ind)) : fadeInFirst)} 0.5s 0s ease-out forwards;
  overflow: hidden;
`;

const RollItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  animation:
   ${({ screenPosition }) => ((screenPosition === 'bottom-left') || (screenPosition === 'bottom-right')) ? rollDown : rollUp}
   ${({ iterationTime }) => iterationTime}s
   0s
   ease-out
   forwards;
`;

const Item = styled.div`
  height: ${height}px;
  font-weight: ${({ textWeight }) => textWeight};
  font-size: 14px;
  line-height: ${height}px;

  padding: 0 12px;
  background-color: ${({ colorBack, stopFlash }) => (stopFlash && colorBack) || 'transparent' };

  &.flashing {
    animation: ${({ colorBack }) => flashing(colorBack)} 0.6s 0s ease-in infinite;
  }
`;

const Position = styled.div`
   ${({ screenPosition }) => ((screenPosition === 'top-right'
  && css`
    transform: scale(-1, 1);
  `) || (screenPosition === 'bottom-left'
  && css`
    transform: scale(1, -1);
  `) || (screenPosition === 'bottom-right'
  && css`
    transform: scale(-1, -1);;
  `))}
`;

export class StrokeComponent extends React.Component {
  static defaultProps = {
    children: '',
    onAnimationEnd: () => {},
  };

  state = {
    flashLastItem: false,
    stopFlash: false,
    mount: true,
    opacity: 1,
  };

  prevIndex = 0;
  delayDefaultExpiry = null;
  delayFlashing = null;
  delayArrayExpiry = null;

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextProps.unmount) {
      return { ...nextState, opacity: 0 };
    }
    return null;
  }

  componentDidMount() {
    const { children, expiry, ind } = this.props;
    this.prevIndex = ind;
    if (!Array.isArray(children) && expiry) {
      this.delayDefaultExpiry = setTimeout(() => {
        this.setState({ opacity: 0 });
      }, expiry);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.delayDefaultExpiry);
    clearTimeout(this.delayFlashing);
    clearTimeout(this.delayArrayExpiry);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.ind !== nextProps.ind) this.prevIndex = this.props.ind;
    return (
      nextProps.ind !== this.props.ind
      || nextProps.screenPosition !== this.props.screenPosition
      || !deepEqual(nextProps.children, this.props.children)
      || nextState.flashLastItem !== this.state.flashLastItem
      || nextState.opacity !== this.state.opacity
      || nextState.mount !== this.state.mount
    );
  }

  onAnimationEnd = () => {
    this.setState({ flashLastItem: true });
    this.delayFlashing = setTimeout(() => {
      this.setState({ flashLastItem: false, stopFlash: true });
      if (this.props.expiry) {
        this.delayArrayExpiry = setTimeout(() => {
          this.setState( { opacity: 0 });
        }, this.props.expiry);
      }
    }, animationConfig.rollFlashingTime);
  };

  onTransitionEnd = () => { // unmount component
    this.setState({ mount: false }, () => {
      const { onUnmount, id } = this.props;
      onUnmount(id);
    });
  };

  render() {
    const {
      colorAccentNotification: color,
      ind,
      children: _children,
      chosen,
      onAnimationEnd,
      screenPosition,
      customStyle,
    } = this.props;
    const {
      flashLastItem,
      stopFlash,
      mount,
      opacity,
    } = this.state;
    if (!mount) return null;
    let children = _children;
    const isArray = Array.isArray(children);
    if (isArray) {
      const iterationTime = animationConfig.delayInSec * (children.length);
      const iterations = Math.floor(animationConfig.rollTimeInSec / iterationTime);
      const lastIteration = children.filter((_, i) => i <= chosen); // if prize index not in range it will be the last
      const animatedArray = (new Array(iterations || 0))
        .fill(children)
        .flat()
        .concat(lastIteration);

      children = (
        <RollItems
          iterationTime={animatedArray.length * animationConfig.delayInSec}
          onAnimationEnd={this.onAnimationEnd}
          screenPosition={screenPosition}
        >
          {animatedArray.map((text, i) => (
            <Item
              key={text + Math.random()}
              stopFlash={stopFlash}
              colorBack={i === animatedArray.length - 1 && color}
              className={flashLastItem && i === animatedArray.length - 1 && 'flashing'}
              customStyle={customStyle}
            >
              {text}
            </Item>
          ))}
        </RollItems>);
    }
    return (
      <Container
        ind={ind}
        prevInd={this.prevIndex}
        className="animate"
        style={{ opacity }}
        onTransitionEnd={this.onTransitionEnd}
        colorBack={!isArray && color}
        padding={!isArray}
        onAnimationEnd={() => onAnimationEnd(ind)}
        customStyle={customStyle}
      >
        <Position screenPosition={screenPosition}>
          {children}
        </Position>
      </Container>);
  }
}
