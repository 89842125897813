import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';

import { theme } from 'constants/theme';

export const ChoisesContainer = styled.div`
  width: 750px;
`;

export const ContainerContent = styled.div`
  width: 100%;
  position: relative;

  background: radial-gradient(800.00px at 50% 50%, #446176 0%, #233747 100%);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 60px;
  font-weight: 800;
  line-height: 110%;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 59px;
`;

export const AboutTitle = styled.span`
  font-size: 22px;
  line-height: 32px;
  max-width: 690px;
  text-align: center;
`;

export const AboutTitleContainer = styled(TitleContainer)`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubscriptionPlanContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  margin-top: 24px;
`;

export const SeeDetailLink = styled(HashLink)`

  color: #FFB65A;
  margin: 48px 0 34px 0;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;

  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    border: none;
  }

  &:visited{
    color: ${theme.main.orangeLanding};
  }

  &:hover{
    color: ${theme.main.linkHover};
  }
  &:active{
    color: ${theme.main.linkActive};
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SubscriptionPeriodsContainer = styled.div`
  display: flex;
  margin-top: 34px;
  align-items: baseline;
  justify-content: stretch;
  &>p {
    margin-right: 17px;
  }
`;

export const TaxNote = styled.div`
  margin-bottom: 30px;
`;
