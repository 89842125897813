import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;

  width: 100%;
`;

export const Beta = styled.img`
  position: absolute;
  top: -20px;
  ${({ right }) => (right ? css`right: -20px;` : css`left: -20px;`)};

  z-index: 2;
`;

export const Text = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 500;
  text-shadow: 0px 0px 10px #394B59;
  font-size: ${({ landing }) => (landing ? 18 : 16)}px;
  padding: ${({ landing }) => (landing ? '14px 40px' : '14px 38px')};
  border-radius: 8px;

  background: ${({ theme }) => theme.white05};
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 16px, rgba(255,255,255,.06) 16px, rgba(255,255,255,.06) 32px);

  >span {
    font-weight: 600;
  }
`;
