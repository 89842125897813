import styled, { css } from 'styled-components';
/* import { Link } from 'react-router-dom'; */
// import { theme } from 'constants/theme';

export const Container = styled.div`
  height:calc(100vh - 34px);
  background: radial-gradient(360.00px at 50% 50%, #324D60 0%, #253A49 100%);

  display: flex;
  justify-content: center;
`;

export const DashboardContainer = styled.div`
  position: relative;
  margin-top: 30px;
`;

export const Dashboard = styled.div`
  width: 688px;
  height: 612px;

  background: #324A5C;
  border-radius: 6px;

`;

export const Logo = styled.img`
  max-height: 45px;
  vertical-align: middle;
  max-width: 200px;
`;

export const ImageGradient = styled.div`
  width: 100%;
  padding: 0 30px;
  line-height: 72px;

  border-radius: 6px 6px 0px 0px;

  ${({ service }) => (service === 'chaturbate') && css`
    background: radial-gradient(345.88px at 50% 50%, #FED46B 0%, #F99A1F 100%);
  `}

  ${({ service }) => (service === 'bongacams') && css`
    background: radial-gradient(136.00px at 50% 50%, #DC3C5E 0%, #C52245 100%);
  `}

  ${({ service }) => (service === 'myfreecams') && css`
    background: radial-gradient(136.00px at 50% 0%, #35AF57 0%, #339A35 100%);
  `}

  ${({ service }) => (service === 'stripchat') && css`
    background: #A2252D;
  `}

  ${({ service }) => (service === 'cam4') && css`
    background: #1c1c1c;
  `}

  ${({ service }) => (service === 'camsoda') && css`
    background: #00B0FC;
  `}

  ${({ service }) => (service === 'manyvids') && css`
    background: #EE417F;
  `}

  ${({ service }) => (service === 'cams') && css`
    background: #161833;
  `}

  ${({ service }) => (service === 'amateur') && css`
    background: #bd182b;
  `}
`;

export const Title = styled.span`
  font-weight: 900;
  font-size: 36px;
  line-height: 105%;
`;

export const Content = styled.div`
  display: flex;
  height: calc(100% - 126px);
  flex-direction: column;
  align-items: flex-start;

  margin: 27px 32px;
`;

const Text = css`
  font-size: 16px;
  line-height: 140%;
`;

export const MainText = styled.span`
  ${Text};
  margin: 12px 0 27px 0;
`;

export const InputText = styled.span`
  ${Text};
  margin-bottom: 9px;
  >span{
    font-weight: 600;
  }
`;

export const IdInput = styled.input`
  width: 100%;
  padding: 0 10px;
  background: #2E3F4C;
  border-radius: 4px;
  border: 1px solid transparent;
  outline: none;
  height: 36px;

  font-size: 14px;
  line-height: 20px;
  color: #FFF;

  /* тут спросить */
  &:placeholder {
    color: rgba(255, 255, 255, 0.34);
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.orangeLanding};
  }

  &:hover, &:active {
    border-color: ${({ theme }) => theme.orangeButtonHover};
  }
`;

export const ExampleContainer = styled.div`
  margin-top: 8px;

  width: 100%;

  display: flex;
  justify-content: space-between;
`;

export const ExampleText = styled.span`
  font-size: 14px;
  line-height: 115%;
  color: #C0D1DE;
  >span{
    font-weight: 600;
  }
`;

const link = css`
  color: ${({ theme }) => theme.orangeLanding};

  font-size: 16px;
  line-height: 115%;
  font-weight: 500;

  border: none;
  outline: none;

  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
   border: none;
   color: ${({ theme }) => theme.linkActive};
   outline: none;
  }
  `;

export const OuterLink = styled.a`${link}`;

export const Warning = styled.span`
  ${Text};
  color: #FF9B9B;
`;

export const WarningContainer = styled.div`
  padding-top: 30px;
  height: 120px;
`;

export const Beta = styled.div`
  position: absolute;
  top: -20px;
  ${({ right }) => (right ? css`right: -20px;` : css`left: -20px;`)};


  width: 60px;
  height: 60px;
  background-size: cover;
  background-image: url('/images/beta-badge.png');

  z-index: 2;
`;

export const BetaTestText = styled.div`
  width: 100%;
  text-align: center;
  font-weight: 500;
  text-shadow: 0px 0px 10px #394B59;

  padding: 14px 38px;
  border-radius: 8px;

  background: ${({ theme }) => theme.white05};
  background-image: repeating-linear-gradient(-45deg, transparent, transparent 16px, rgba(255,255,255,.06) 16px, rgba(255,255,255,.06) 32px);

  >span {
    color: ${({ theme }) => theme.linkActive};
  }
`;

export const ContainerBetaTest = styled.div`
  width: 100%;
  height: 92px;
  position: relative;
`;

export const ButtonContainer = styled.div`
  margin-top: 18px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 12px;

  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.button`
  outline: none;
  border: none;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  color: ${({ orange, theme }) => (orange ? theme.black : theme.white)};

  margin-left: 6px;
  padding: 8px 16px;
  border-radius: 4px;

  ${({ orange }) => (orange
    ? css`
      background-color:  ${({ theme }) => theme.orangeLanding};
      color: ${({ theme }) => theme.black};
      &:hover{
        background-color:  ${({ theme }) => theme.orangeButtonHover};
      }
      &:active{
        background-color:  ${({ theme }) => theme.orangeButtonActive};
      }
    ` : css`
      background-color: #44657E;
      color: ${({ theme }) => theme.white};
      &:hover {
        background-color: ${({ theme }) => theme.white10};
      }
      &:active {
        background-color:  ${({ theme }) => theme.black05};
      }
    `)};
`;
