import styled from 'styled-components';

// for adding new service color
const servicesStyles = {
  chaturbate: {
    foreground: 'linear-gradient(226.57deg, #FED46B 0%, #F99A1F 100%)',
    hoverForeground: 'linear-gradient(226.57deg, #FEDC85 0%, #FAA538 100%)',
    background: '#AC6E16',
    hoverBackground: '#cb811a',
  },
  bongacams: {
    foreground: 'linear-gradient(212.21deg, #DC3C5E 0%, #C52245 100%)',
    hoverForeground: 'linear-gradient(212.21deg, #e05271 0%, #da254c 100%)',
    background: '#8E1A33',
    hoverBackground: '#ac203e',
  },
  myfreecams: {
    foreground: 'linear-gradient(212.21deg, #008000 0%, #339A35 100%)',
    hoverForeground: 'linear-gradient(212.21deg, #009900 0%, #39ac3b 100%)',
    background: '#1B691B',
    hoverBackground: '#1f7a1f',
  },
  stripchat: {
    foreground: '#A2252D',
    hoverForeground: '#bb2a34',
    background: '#851920',
    hoverBackground: '#961d25',
  },
  cam4: {
    foreground: '#2D2D2D',
    hoverForeground: '#404040',
    background: '#232323',
    hoverBackground: '#333333',
  },
  camsoda: {
    foreground: '#00B0FC',
    hoverForeground: '#1abaff',
    background: '#0088C2',
    hoverBackground: '#0096d6',
  },
  amateur: {
    foreground: '#bd182b',
    hoverForeground: '#de2a3f',
    background: '#a90014',
    hoverBackground: '#c20017',
  },
  manyvids: {
    foreground: '#EE417F',
    hoverForeground: '#f0568f',
    background: '#C21E59',
    hoverBackground: '#d42163',
  },
  cams: {
    foreground: '#161833',
    hoverForeground: '#1d2044',
    background: '#0F1029',
    hoverBackground: '#151638',
  },
};

// can't come up with something better than that, for fast adding/editing new one
// if fields doesn't match, it will get from default
const typesStyles = {
  landing: {
    containerHeight: '240px',
    containerCursor: 'auto',
    containerHover: '',
    containerActive: '',
    containerHoverLogoContainer: () => '',
    containerHoverCard: () => '',
    logoContainerBottomBorderRadius: '12px',
    titleMarginBottom: '5px',
    idFontSize: '18px',
    containerGradientAlignItems: 'center',
    containerGradientJustifyContent: 'center',
    containerGradientPadding: '16px 24px',
    clickTextFontSize: '16px',
    idImageRightDisplay: 'none',
  },
  connectService: {
    containerHeight: '100px',
    titleFontSize: '20px',
    titleMarginBottom: '5px',
    idFontSize: '18px',
    containerGradientPadding: '16px 24px',
    containerGradientHeight: '88px',
    clickTextFontSize: '16px',
    idImageLeftDisplay: 'inline-block',
    idImageRightDisplay: 'none',
  },
  settings: {
  },
  default: {
    containerHeight: '100px',
    containerCursor: 'pointer',
    containerHover: 'transform: translateY(-5px);',
    containerActive: 'transform: translateY(-3px);',
    containerHoverLogoContainer: color => `background: ${color};`,
    containerHoverCard: color => `background: ${color};`,
    logoContainerBottomBorderRadius: '0',
    titleFontSize: '16px',
    titleMarginBottom: '2px',
    idFontSize: '14px',
    containerGradientAlignItems: 'flex-start',
    containerGradientJustifyContent: 'space-between',
    containerGradientPadding: '10px 20px',
    containerGradientHeight: '64px',
    clickTextFontSize: '14px',
    idImageLeftDisplay: 'none',
    idImageRightDisplay: 'inline-block',
  },
};

export const LogoContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: none;
`;

export const Card = styled.div`
  flex: 1;

  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 12px;
`;

export const Title = styled.div`
  font-weight: 700;
  line-height: 120%;
  text-align: left;
`;

export const ID = styled.div`
  color: white;

  font-weight: 500;
  line-height: 120%;
  text-align: left;

  >span {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ContainerGradient = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
`;

export const ClickText = styled.span`
  line-height: 120%;
  color: ${({ theme }) => theme.white50};
`;

export const IDimageLeft = styled.img`
  margin: ${({ margin }) => margin || 0};
`;

export const IDimageRight = styled.img`
  margin: ${({ margin }) => margin || 0};
`;

const setContainerStyles = ({ type, service }) => {
  const serviceStyle = servicesStyles[service] || {};
  const styles = { ...typesStyles.default, ...(typesStyles[type] || {}) };
  return `
  height: ${styles.containerHeight};
  cursor: ${styles.containerCursor};
  &:hover {
    ${styles.containerHover}
  }
  &:active {
    ${styles.active}
  }
  &:hover ${LogoContainer} {
    ${styles.containerHoverLogoContainer(serviceStyle.hoverForeground)}
  }
  &:hover ${Card} {
    ${styles.containerHoverCard(serviceStyle.hoverBackground)}
  }
  ${LogoContainer} {
    border-bottom-left-radius: ${styles.logoContainerBottomBorderRadius};
    border-bottom-right-radius: ${styles.logoContainerBottomBorderRadius};
    background: ${servicesStyles[service].foreground};
  }
  ${Card} {
    background-color: ${serviceStyle.background};
  }
  ${Title} {
    font-size: ${styles.titleFontSize};
    margin-bottom: ${styles.titleMarginBottom};
  }
  ${ID} {
    font-size: ${styles.idFontSize};
  }
  ${ContainerGradient} {
    align-items: ${styles.containerGradientAlignItems};
    justify-content: ${styles.containerGradientJustifyContent};
    
    padding: ${styles.containerGradientPadding};
    height: ${styles.containerGradientHeight};
  }
  ${ClickText} {
    font-size: ${styles.clickTextFontSize};
  }
  ${IDimageLeft} {
    display: ${styles.idImageLeftDisplay};
  }
  ${IDimageRight} {
    display: ${styles.idImageRightDisplay};
  }`;
};

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 273px;
  margin: 8px;
  transition: transform 0.3s ease-in-out;
  ${setContainerStyles}
`;

export const New = styled.span`
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 1.5px;
  color: white;

  position: absolute;
  top: 14px;
  left: 24px;
`;

export const Logo = styled.img`
  image-rendering: crisp-edges;
  max-width: 75%;
`;

export const Beta = styled.img`
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 2;
`;

export const InfoContainer = styled.div`
  text-align: left;
`;
