import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import bugsnagReact from '@bugsnag/plugin-react';

import 'array-flat-polyfill';

import { bugsnagClient } from 'utils/bugsnag';

import './index.css';
import App from './App';

let ErrorBoundary = ({ children }) => children;

if (bugsnagClient) {
  bugsnagClient.use(bugsnagReact, React);
  ErrorBoundary = bugsnagClient.getPlugin('react');
}

ReactDOM.render(<ErrorBoundary><App /></ErrorBoundary>, document.getElementById('root'));
