import styled from 'styled-components';

import { theme } from 'constants/theme';

export const Container = styled.div`
  width: 100%;
  background-color: #172937;
  display: flex;
  justify-content: center;
  padding: 45px 0px;
`;

export const Text = styled.span`
  font-size: 18px;
  line-height: 22px;
`;

export const HelpLink = styled.a`
  font-size: 18px;
  line-height: 22px;
  color: #FFB65A;

  border: none;
  outline: none;

  &:hover,
  &:active,
  &:focus {
   border: none;
   outline: none;
   color: ${theme.main.orangeLanding};
  }

  &:visited{
    color: ${theme.main.orangeLanding};
  }

  &:hover{
    color: ${theme.main.linkHover};
  }
  &:active{
    color: ${theme.main.linkActive};
  }
`;
