// @flow

import React from 'react';

import { ImageGallery } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActionWidgetsComponentsUpdate, widgetsUploadImage } from 'store/reducers/widgets';
import { widgets } from 'constants';
import { deleteFile } from 'store/reducers/auth';

type Props = {
  data: {
    image: number | string,
  },
  user: {},
  t: {},
  widgetsUploadImage: (any) => void,
  deleteFile: (any) => void,
  widgetUpdate: (any) => void,
};

const ImageTab = ({
  data,
  user,
  widgetsUploadImage,
  deleteFile,
  widgetsComponentsUpdate,
  t,
}: Props) => (
  <ImageGallery
    type="image"
    user={user}
    selected={data.image}
    imageCanBeDeselected
    onImageSelect={imageNumber => widgetsComponentsUpdate({ image: imageNumber })}
    onImageUpload={widgetsUploadImage}
    onFileDelete={deleteFile}
    data={data}
    widgetUpdate={widgetsComponentsUpdate}
    t={t}
  />
);

ImageTab.title = 'Image';

export default connect(
  state => ({
    user: state.auth.user,
    t: state.lang.t,
  }),
  (dispatch, ownProps) => bindActionCreators({
    widgetsComponentsUpdate: getActionWidgetsComponentsUpdate(widgets.goals.id, ownProps.selectedPreset, 'image'),
    widgetsUploadImage,
    deleteFile,
  }, dispatch),
)(ImageTab);
