import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default ({
  t,
  paymentPatreonURL,
  email,
  onPayClick,
}) => (
  <Modal
    show={Boolean(paymentPatreonURL)}
    onHide={onPayClick}
  >
    <Modal.Header closeButton>
      <Modal.Title>{t.payment}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Unfortuantely, at now in your country we support payments only via Patreon.</p>
      <p>On Patreon you can pay via credit card (VISA/MasterCard) or PayPal.</p>
      <div>To pay at Patreon you need:</div>
      <div>
        - go to&nbsp;
        <a
          href={paymentPatreonURL}
          onClick={onPayClick}
          target="_blank"
          rel="noopener noreferrer"
        >
          {paymentPatreonURL}
        </a>
      </div>
      <div>- click Continue button</div>
      <div>- fill simple registration form (verification not required)</div>
      <div style={{ marginLeft: '10px', color: '#860707' }}>
        Please sure that you use your e-mail address:&nbsp;
        {email}
      </div>
      <div>- pay with credit card or PayPal</div>
      <p style={{ marginTop: '10px' }}>Done! Now you can use all premium features on TipAlerts.</p>
      <div>
        If you have any troubles please contact our support&nbsp;
        <a href="mailto:support@tipalerts.com">support@tipalerts.com</a>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <a
        href={paymentPatreonURL}
        onClick={onPayClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button bsStyle="primary">Pay on Patreon</Button>
      </a>
    </Modal.Footer>
  </Modal>
);
