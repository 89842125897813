import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { GoalView } from 'components/WidgetsRender/RenderGoals/GoalView';

const RenderPreview = styled.div`
  border-radius: 4px;
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;

  z-index: 1;
  position: relative;

  .RenderTips_Tip {
    animation-iteration-count: infinite;
  }

  :before{
    z-index:-1;
    width: 100%;
    height: 100%;
    position:absolute;
    left:0;
    top:0;
    content: '';
    background: url('/images/checker-bw.svg');
    background-color: hsl(205, 33%, 17%); /* ${({ theme }) => theme.slateThree}; */
    background-blend-mode: multiply;
    opacity: 0.16;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Title = styled.div`
  ${({ theme }) => theme.font.title18_700};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
`;

export default connect(
  state => ({
    goalsData: state.widgets.goals || {},
    selectedPreset: state.widgetsOptions.goals.selectedPreset,
    supporter: state.auth.supporter,
    t: state.lang.t,
  }),
)(
  class extends React.Component {
    render() {
      const {
        goalsData,
        selectedPreset,
        supporter,
        t,
      } = this.props;
      if (!selectedPreset) return null;

      const selectedData = goalsData[selectedPreset] || {};

      return (
        <React.Fragment>
          <Row>
            <Title>{t.preview}</Title>
          </Row>
          <RenderPreview>
            <Container>
              {supporter.active ? <GoalView data={selectedData} /> : null}
            </Container>
          </RenderPreview>
        </React.Fragment>
      );
    }
  },
);
