import React from 'react';
import styled, { css } from 'styled-components';
import { images, scheme } from './config';

const Container = styled.div`
  flex-direction: row;
  display: flex;
  margin-bottom: 15px;
`;

const Title = styled.span`
  font-size: 14px;
  line-height: 14.8px;
  min-width: 90px;
`;

const BlockContainer = styled.div`
  width: 106px;
  height: 106px;
  margin-left: 28px;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const Button = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 4px;
  ${({
    vertical, horizontal, index, theme,
  }) => (scheme[index].VerticalAlign === vertical && scheme[index].HorizontalAlign === horizontal
    ? css`
        background-color: ${theme.slateFive};
        box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.25);
      `
    : css`
        background-color: transparent;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
      `)
};
  border: solid 1px ${({ theme }) => theme.slateFive};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  &:focus {
    outline: none;
  }
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  transform: ${({ transform }) => transform};
`;

type Props = {
  title: string,
  verticalAlign: number,
  horizontalAlign: number
}

export function AlignmentBlock({
  title, verticalAlign, horizontalAlign, onChange, type,
}: Props) {
  return (
    <Container>
      <Title>{title}</Title>
      <BlockContainer>
        {scheme.map((el, i) => (
          <Button
            vertical={verticalAlign}
            horizontal={horizontalAlign}
            index={i}
            key={i}
            onClick={() => onChange({
              [`${type}VerticalAlign`]: el.VerticalAlign,
              [`${type}HorizontalAlign`]: el.HorizontalAlign,
            })}
          >
            <Image transform={el.transform} src={i === 4 ? images.circle : images.arrow} />
          </Button>
        ))}
      </BlockContainer>
    </Container>
  );
}
