import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { replace } from 'connected-react-router';
import Spinner from 'react-spinkit';

import { Button } from 'components/UI';
import { authSetFieldValue, authSignUp } from 'store/reducers/auth';

import { InputWithValidation } from '../../components';
import { signUpValidation } from '../../utils';
import { StyledBlock } from './SignIn';

import './SignUp.css';

const Centered = styled.div`
  text-align: center;
  margin: 10px 0;
`;

const Error = styled.div`
  color: red;
  width: 400px;
  text-align: center;
`;

class SignUpBase extends Component {
  componentDidMount() {
    if (this.props.auth.user) {
      this.props.routerReplace('/');
    }
  }

  render() {
    const {
      auth: { fields, authError, inProgress },
      authSetFieldValue,
      authSignUp,
      isIntegrated,
      t,
    } = this.props;
    return (
      <div className="SignUp">
        <h3>{t.signUp}</h3>
        {!isIntegrated && (
          <Error>
            {authError}
          </Error>
        )}
        <StyledBlock>
          <InputWithValidation
            type="text"
            icon="user"
            placeholder={t.eMail}
            value={fields.email}
            onChange={e => authSetFieldValue('email', e.target.value)}
            isValid={signUpValidation.email(fields)}
          />
          <InputWithValidation
            type="password"
            placeholder={t.password}
            icon="lock"
            value={fields.password}
            onChange={e => authSetFieldValue('password', e.target.value)}
            isValid={signUpValidation.password(fields)}
            validationErrorText={t.passwordValidationError}
          />
          <InputWithValidation
            type="password"
            placeholder={t.repeatPassword}
            icon="lock"
            value={fields.passwordRepeat}
            onChange={e => authSetFieldValue('passwordRepeat', e.target.value)}
            isValid={signUpValidation.passwordRepeat(fields)}
            validationErrorText={t.repeatPasswordValidationError}
          />
          <Centered>
            {t.signUpTermsText}
            <Link to="/terms">{t.signUpTermsTitle}</Link>
          </Centered>
          <Button
            disabled={inProgress || !signUpValidation.all()}
            className="pt-button pt-large pt-intent-primary"
            onClick={authSignUp}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {inProgress ? <Spinner name="wave" color="white" /> : t.signUp}
          </Button>
        </StyledBlock>
        {!isIntegrated && (
          <div>Also you can <Link to="/auth">Sign In</Link></div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({ auth: state.auth, t: state.lang.t });
const mapDispatchToProps = dispatch => bindActionCreators({
  authSetFieldValue,
  authSignUp,
  routerReplace: replace,
}, dispatch);

export const SignUp = connect(mapStateToProps, mapDispatchToProps)(SignUpBase);
