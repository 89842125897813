import React, { Component } from 'react';
import {
  GoalsContentBackground,
  GoalsContent,
  TextContainer,
  EmptyContainer,
  ImageGoals,
} from './style';

export class GoalsContainer extends Component {
  render() {
    return (
      <GoalsContentBackground>
        <GoalsContent>
          <TextContainer>
            {'Goals '}
            <span className="superIndex">Premium</span>
            <p>Focus your viewers attention on the goals to get tokens faster and easier</p>
          </TextContainer>
          <EmptyContainer>
            <ImageGoals />
          </EmptyContainer>
        </GoalsContent>
      </GoalsContentBackground>
    );
  }
}
