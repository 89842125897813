const animationDurationTipAmount = [
  { min: 0, max: 1, durationSec: 1 },
  { min: 2, max: 10, durationSec: 2 },
  { min: 11, max: 50, durationSec: 3 },
  { min: 51, max: 100, durationSec: 4 },
  { min: 101, max: 500, durationSec: 6 },
  { min: 501, max: 1000, durationSec: 10 },
  { min: 1001, max: 99999999, durationSec: 15 },
];

export const animationDuration = ({ animationDurationMode: userChoice, animationDuration: _durationSec, amount: _amount }) => {
  if (!_amount) return 0;
  const amount = parseInt(_amount, 10);
  const durationChoices = Number(userChoice) === 0 ? animationDurationTipAmount.find(item => item.min <= amount && item.max >= amount).durationSec : _durationSec;
  return durationChoices;
};

export const durationLowToHigh = animationDuration => (1.02 - (animationDuration / 5).toFixed(1)) * 5;
export const durationHighToLow = animationDuration => Math.abs((animationDuration) - 5.1).toFixed(1);
