import React, { Component } from 'react';

import {
  Container,
  ContainerContent,
  Title,
  TextContainer,
  Text,
  Question,
} from './style';

export class FAQ extends Component {
  render() {
    return (
      <Container>
        <ContainerContent>
          <Title>FAQ</Title>
          <TextContainer>
            <Question>
              Question text so strongly and metaphysically did I conceive of my situation?
            </Question>
            <Text>
              In the tumultuous business of cutting in and attending
              to a whale, there is much running backwards and forwards
              among the crew. Now hands are wanted here,and then again
              hands are wanted there. There is no staying in any one place;
              for at one and the same time everything has to be done everywhere.
            </Text>
          </TextContainer>
          <TextContainer>
            <Question>
              Question text so strongly and metaphysically did I conceive of my situation?
            </Question>
            <Text>
              In the tumultuous business of cutting in and attending
              to a whale, there is much running backwards and forwards
              among the crew. Now hands are wanted here,and then again
              hands are wanted there. There is no staying in any one place;
              for at one and the same time everything has to be done everywhere.
            </Text>
          </TextContainer>
          <TextContainer>
            <Question>
              Question text so strongly and metaphysically did I conceive of my situation?
            </Question>
            <Text>
              In the tumultuous business of cutting in and attending
              to a whale, there is much running backwards and forwards
              among the crew. Now hands are wanted here,and then again
              hands are wanted there. There is no staying in any one place;
              for at one and the same time everything has to be done everywhere.
            </Text>
          </TextContainer>
        </ContainerContent>
      </Container>
    );
  }
}
