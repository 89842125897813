import React, { Component } from 'react';

import {
  BackgroundContainer,
  Price,
  Title,
  TablePrice,
  TableRow,
  TextContent,
  TextHeader,
  TableColumn1,
  TableColumn23,
  CheckMark,
  CheckMarkOrange,
  SpanOrange,
  TextPrice,
  TextUSD,
  ButtonStart,
  ButtonContainer,
  LogoContainer,
  ButLogTextContainer,
  RegLink,
} from './style';

export class PriceContainer extends Component {
  render() {
    return (
      <BackgroundContainer>
        <Price>
          <Title>
            Pricing
            {/* <p>
              Choose the most convenient pack for you. We provide basic functionalities such as
              image uploading and layout customization for free. If you want to have more tools in
              your pocket and freedom to express your uniqueness then we have a Premium account for
              you.
            </p> */}
          </Title>
          <TablePrice>
            <TableRow>
              <TableColumn1>
                <TextHeader />
              </TableColumn1>
              <TableColumn23>
                <TextHeader>Basic</TextHeader>
              </TableColumn23>
              <TableColumn23>
                <TextHeader>
                  <SpanOrange>Premium</SpanOrange>
                </TextHeader>
              </TableColumn23>
            </TableRow>
            <TableRow moreGrey>
              <TableColumn1>
                <TextContent>
                  Layout settings
                  <p>
                    You can change font, colors and text size. Also, you can play with elements
                    location and change images
                  </p>
                </TextContent>
              </TableColumn1>
              <TableColumn23>
                <CheckMark />
              </TableColumn23>
              <TableColumn23>
                <CheckMark />
              </TableColumn23>
            </TableRow>
            <TableRow>
              <TableColumn1>
                <TextContent>
                  Upload your images
                  <p>JPEG, GIF, PNG are supported</p>
                </TextContent>
              </TableColumn1>
              <TableColumn23>
                <CheckMark />
              </TableColumn23>
              <TableColumn23>
                <CheckMark />
              </TableColumn23>
            </TableRow>
            <TableRow moreGrey>
              <TableColumn1>
                <TextContent>
                  Alerts sounds
                  <p>Ability to upload your own sounds</p>
                </TextContent>
              </TableColumn1>
              <TableColumn23 />
              <TableColumn23>
                <CheckMarkOrange />
              </TableColumn23>
            </TableRow>
            <TableRow>
              <TableColumn1>
                <TextContent>
                  Ability to add personalized message to alerts
                  <p>You can add tipper’s name and amount of tokens</p>
                </TextContent>
              </TableColumn1>
              <TableColumn23 />
              <TableColumn23>
                <CheckMarkOrange />
              </TableColumn23>
            </TableRow>
            <TableRow moreGrey>
              <TableColumn1>
                <TextContent>
                  Different alerts for different amount of tokens
                  <p>Create as many presets as you want</p>
                </TextContent>
              </TableColumn1>
              <TableColumn23 />
              <TableColumn23>
                <CheckMarkOrange />
              </TableColumn23>
            </TableRow>
            <TableRow>
              <TableColumn1>
                <TextContent>Goals</TextContent>
              </TableColumn1>
              <TableColumn23 />
              <TableColumn23>
                <CheckMarkOrange />
              </TableColumn23>
            </TableRow>
            <TableRow moreGrey>
              <TableColumn1>
                <TextContent>Top Tippers</TextContent>
              </TableColumn1>
              <TableColumn23 />
              <TableColumn23>
                <CheckMarkOrange />
              </TableColumn23>
            </TableRow>
            <TableRow>
              <TableColumn1>
                <TextContent>Roll The Dice</TextContent>
              </TableColumn1>
              <TableColumn23 />
              <TableColumn23>
                <CheckMarkOrange />
              </TableColumn23>
            </TableRow>
            <TableRow moreGrey>
              <TableColumn1>
                <TextContent>Ability to hide tipper’s name and the amount of tokens</TextContent>
              </TableColumn1>
              <TableColumn23 />
              <TableColumn23>
                <CheckMarkOrange />
              </TableColumn23>
            </TableRow>
            <TableRow>
              <TableColumn1>
                <TextContent>Priority in support</TextContent>
              </TableColumn1>
              <TableColumn23 />
              <TableColumn23>
                <CheckMarkOrange />
              </TableColumn23>
            </TableRow>
            <TableRow moreGrey>
              <TableColumn1 />
              <TableColumn23>
                <TextPrice>
                  <p>
                    $5/month
                  </p>
                </TextPrice>
              </TableColumn23>
              <TableColumn23>
                <TextPrice>
                  <span>$</span>
                  {'10 '}
                  <TextUSD>per month</TextUSD>
                </TextPrice>
              </TableColumn23>
            </TableRow>
          </TablePrice>
          <ButLogTextContainer>
            <ButtonContainer>
              <RegLink to="/auth">
                <ButtonStart>Start</ButtonStart>
              </RegLink>
            </ButtonContainer>
            <LogoContainer />
            <TextContent TextAlign="center">
              <p>
                You can proceed with payment using Visa, MasterCard cards. Subscription will
                be automatically renewed at the end of each month. You can cancel subscription in
                the “Account” section. All payments are secured by a safe payment gateway. To
                provide high level of security we are using 3D protocol.
              </p>
            </TextContent>
          </ButLogTextContainer>
        </Price>
      </BackgroundContainer>
    );
  }
}
