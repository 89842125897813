/* eslint-disable */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes, css } from 'styled-components';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AmountVariation } from 'components';
import {
  Section,
  FormItem,
  CheckBox,
  NewSlider,
  RadioButton,
  DropDown,
  ButtonIconText,
  ModalTooltip,
} from 'components/UI';


import { animationWidgets } from 'utils';
import { widgetUpdate, getActionWidgetsComponentsUpdate, widgetsTestAlert } from 'store/reducers/widgets';
import { widgetsOptionsSelectPreset } from 'store/reducers/widgetsOptions';
import { TipAlertDataDefault, widgets } from 'constants';

import sprite from 'assets/images/sprite.svg';
import icWarning from 'assets/images/ic_warn.svg';

const Content = styled.div`

`;

const RowCheckBoxHelp = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const HelpButton = styled.svg`
  & > use {
    transition: fill 0.3s ease-in-out;
    fill: rgba(255,255,255,0.54);
  }
`;

const HelpContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: calc(100% + 4px);
  cursor: pointer;

  height: 24px;
  width: 24px;

  border-radius: 4px;
  background-color: transparent;

  transition: background-color 0.3s ease-in-out;

  &:hover ${HelpButton} > use {
    fill: rgba(255,255,255,1);
  }

  margin-left: 4px;
`;

const Tooltip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: max-content;
  max-width: 200px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: calc(100% + 7px);
  top: 50%;
  transform: translateY(-50%);

  min-height: 24px;

  border-radius: 4px;
  padding: 2px 8px;

  color: #FFFFFF;
  background: #11171B;

  transition: opacity 0.3s ease-in-out;

  cursor: auto;
  pointer-events: none;

    font-size: 14px;
    text-align: left;
    line-height: 20px;

  &:after {
    content: " ";
    position: absolute;
    top: 43%;
    right: 100%;
    transform: rotate(90deg);
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #11171B transparent transparent transparent;
  }

  ${HelpContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;



const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GroupButton = styled.div`
  display: flex;
`;

const openModal = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
    height: 0px;
  }
  100% {
    opacity: 1;
    height: 48px;
    overflow: visible;
  }
`;

const closeModal = keyframes`
  0% {
    opacity: 1;
    height: 48px;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    height: 0px;
  }
`;


const ContainerDuration = styled.div`
  overflow: hidden;
  ${({ open }) => open && css`
    animation: ${openModal} 175ms ease-in forwards;
  `};
  ${({ close }) => close && css`
    animation: ${closeModal} 175ms ease-in forwards;
  `};
`;

const listDuration = {
  0: {
    title: 'Depends on tip amount',
  },
  1: {
    title: 'Custom',
  },
};

const PricingPageLink = styled(Link)`

  color: ${({ theme }) => theme.orangeLanding};

  border: none;
  outline: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
   border: none;
   outline: none;
  }

  &:visited{
    color: ${({ theme }) => theme.orangeLanding};
  }

  &:hover{
    color: ${({ theme }) => theme.linkHover};
  }
  &:active{
    color: ${({ theme }) => theme.linkActive};
  }
`;

const BasicWarning = styled.div`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.orangeLanding};
  padding: 12px 20px;
  padding-left: 17px;
  margin: 12px 0;
  color: black;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  align-items: center;
  a {
    color: black;
    border-bottom: 1px solid black;
    transition-delay: 0;
    &:hover {
      border-bottom-color: transparent;
    }
  }
`;

const BasicWarningImage = styled.img`
  width: 22px;
  height: 20px;
  margin-right: 17px;
`;

const ANY_AMOUNT_VALUE = 'default';

// TODO: shouldComponentUpdate for tipsData (need for amount variations)
class PresetTab extends Component {
  state = {
    resetActive: false,
    deleteActive: false,
  };

  modal = React.createRef();

  showdeleteModal = () => {
    this.setState({ deleteActive: true }, () => document.addEventListener('click', this.onClick));
  };

  onClick = (e) => {
    const modalNode = ReactDOM.findDOMNode(this.modal.current);
    const isOutClick = !(modalNode && modalNode.contains(e.target));
    if (isOutClick) this.hidedeleteModal(e);
  };

  hidedeleteModal = (e) => {
    e.stopPropagation();
    this.setState({ deleteActive: false }, () => document.removeEventListener('click', this.onClick));
  };

  onDeleteVariation = (e) => {
    e.stopPropagation();
    const { selectedVariation, widgetUpdate, widgetsOptionsSelectPreset } = this.props;
    this.setState({ deleteActive: false }, () => {
      document.removeEventListener('click', this.onClick);
      widgetsOptionsSelectPreset(widgets.tips.id, 'default');
      widgetUpdate(widgets.tips.id, { [selectedVariation]: null });
    });
  };

  onAmountVariationSave = ({ from, to, prevFrom, prevTo }, setError) => {
    const { widgetUpdate, widgetsOptionsSelectPreset, tipsData } = this.props;

    const isIntersected = Object.keys(tipsData).some((key) => {
      const { from: checkFrom, to: checkTo } = (tipsData[key] && tipsData[key].preset || {});
      if (checkFrom === undefined || checkTo === undefined || (prevFrom === checkFrom && prevTo === checkTo)) return false;
      return (checkFrom <= from && checkTo >= from) || (checkFrom <= to && checkTo >= to);
    });
    if (isIntersected) return setError('New range should not be in the existing range');

    const oldVariation = `${prevFrom}-${prevTo}`;
    const newVariation = `${from}-${to}`;
    const oldData = tipsData[oldVariation] || {};
    widgetUpdate( // either do so two requests (+ widgetsComponentsUpdate)
      widgets.tips.id,
      {
        [oldVariation]: null,
        [newVariation]: {
          ...oldData,
          preset: {
            ...(oldData.preset || {}),
            from,
            to,
          },
        },
      });
    widgetsOptionsSelectPreset(widgets.tips.id, newVariation);
  };

  findLastTo = () => {
    const{
      tipsData,
    } = this.props;
    const keyVariations = Object.keys(tipsData);
    const allTo = keyVariations.map(key =>
      tipsData[key].preset && tipsData[key].preset.to && tipsData[key].preset.to !== undefined
      ? tipsData[key].preset.to : -1
    );
    let lastTo = Math.max.apply(null, allTo);
    if (lastTo <= 0) lastTo = 0;
    else lastTo += 1;
    return lastTo
  }

  copyPresetOptions = () => {
    const{
      tipsData,
      selectedVariation,
      widgetUpdate,
      widgetsOptionsSelectPreset,
    } = this.props;
    let lastTo = this.findLastTo(tipsData);

    const from = lastTo;
    const to = lastTo + 99;

    const presetTabOptions = tipsData[selectedVariation] && tipsData[selectedVariation].preset || {};
    widgetUpdate(widgets.tips.id, {
      [`${from}-${to}`]: {...tipsData[selectedVariation], preset: { ...presetTabOptions, from, to } }
    });
    widgetsOptionsSelectPreset(widgets.tips.id, `${from}-${to}`);
  };

  sendTestAlert = () => {
    const{
      tipsData,
      selectedVariation,
      widgetsTestAlert,
    } = this.props;
    let max = 100;
    let min = 1;
    if (tipsData
      && selectedVariation
      && tipsData[selectedVariation]
      && tipsData[selectedVariation].preset
      && (tipsData[selectedVariation].preset.from || tipsData[selectedVariation].preset.from === 0)
      && tipsData[selectedVariation].preset.to
    ) {
      max = tipsData[selectedVariation].preset.from;
      min = tipsData[selectedVariation].preset.to;
    } else if (selectedVariation === 'default') {
      min = this.findLastTo(tipsData);
      max = min + 99;
    }
    const randomTestAmount = Math.floor(Math.random() * (max - min) + min);
    widgetsTestAlert(randomTestAmount);

  };

  render() {
    const {
      tipsData,
      widgetsComponentsUpdate,
      selectedVariation,
      isPremium,
      supporter,
      t,
    } = this.props;
    const { deleteActive } = this.state;

    const variationData = tipsData[selectedVariation] || {};

    const data = {
      ...TipAlertDataDefault.preset,
      ...(variationData.preset || {}),
    };

    const isNotDefault = selectedVariation !== 'default';

    const open = Number(data.animationDurationMode) === 1;
    const close = Number(data.animationDurationMode) === 0;

    const basic = supporter && supporter.basic && !(supporter.special === 'trial') && !supporter.active;
    return (
      <div>
        <Content>
          <Section>
            <FormItem label="Alert condition" themeFont="header" header>
              <CheckBox
                checked={data.enabled}
                onChange={({ target: { checked } }) => widgetsComponentsUpdate({ enabled: checked })}
                themeFont="headerCheckbox"
              >
                Enable this preset
              </CheckBox>
            </FormItem>
            {!isNotDefault && (
              <span>This preset works for all tips that are not used in other presets.</span>
            )}
            {(basic && !isNotDefault) && (<div>
              <PricingPageLink to="/price"> Premium subscription </PricingPageLink>
               allows you to create alert presets for different tip amounts.</div>
            )}
            {(!isPremium && isNotDefault) && (
            <BasicWarning>
              <BasicWarningImage src={icWarning} />
              <div>
                Switch your subscription to <Link to="/settings">Premium</Link> to use this preset on your stream.
              </div>
            </BasicWarning>)}
            {isNotDefault && <AmountVariation
              key={selectedVariation}
              to={data.to}
              from={data.from}
              onSave={this.onAmountVariationSave}
              t={t}
            />}
          </Section>

          <Section borderTop>
            <FormItem label="Animation" themeFont="header" header borderTop />
            <FormItem label="Duration mode" themeFont="normal" flexStart>
              <RadioButton
                list={listDuration}
                checked={data.animationDurationMode}
                onChange={mode => widgetsComponentsUpdate({ animationDurationMode: mode })}
              />
            </FormItem>
            <ContainerDuration open={open} close={close}>
              <FormItem label="Duration, sec" themeFont="normal">
                <NewSlider
                  value={data.animationDuration}
                  min={1}
                  max={60}
                  step={1}
                  onChange={s => widgetsComponentsUpdate({ animationDuration: s })}
                />
              </FormItem>
            </ContainerDuration>
            <FormItem label="Preset" themeFont="normal">
              <DropDown
                width="65%"
                items={animationWidgets}
                value={Number(data.animation)}
                onSelect={animation => widgetsComponentsUpdate({ animation: Number(animation || 0) })}
              />
            </FormItem>
            <FormItem>
              <RowCheckBoxHelp>
                <CheckBox
                  checked={data.splitAlertsByOneToken}
                  onChange={() => widgetsComponentsUpdate({ splitAlertsByOneToken: !data.splitAlertsByOneToken })}
                  themeFont="normal"
                >
                  Split alerts by one token
                </CheckBox>
                <HelpContainer>
                  <HelpButton
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <use xlinkHref={`${sprite}#question-mark`} />
                  </HelpButton>
                  <Tooltip>If active, animation will be played as many times as tokens tipped.</Tooltip>
                </HelpContainer>
              </RowCheckBoxHelp>
            </FormItem>
          </Section>

          <Section borderTop>
            <FormItem label="Transform" themeFont="header" header borderTop />
            <FormItem label="Scale" themeFont="normal">
              <NewSlider
                value={data.transformScale}
                min={0}
                max={5}
                step={0.1}
                decPlace={1}
                onChange={size => widgetsComponentsUpdate({ transformScale: size })}
              />
            </FormItem>
          </Section>

          <Row>
            <GroupButton>
              <ButtonIconText
                icon="send"
                text="Send test alert"
                onClick={this.sendTestAlert}
                marginRight="6px"
              />
            {isPremium && <ButtonIconText
                icon="duplicate"
                text="Duplicate"
                onClick={this.copyPresetOptions}
              />}
            </GroupButton>
            {selectedVariation === ANY_AMOUNT_VALUE || (
            <ButtonIconText
              icon="trash"
              text="Delete Preset"
              active={deleteActive}
              onClick={this.showdeleteModal}
              >
              <ModalTooltip
                ref={this.modal}
                show={deleteActive}
                title="Delete preset?"
                text="This action cannot be undone."
                leftButton={{
                  label: 'Cancel',
                  onClick: this.hidedeleteModal,
                }}
                rightButton={{
                  label: 'Yes, delete',
                  onClick: this.onDeleteVariation,
                }}
              />
            </ButtonIconText>)}
          </Row>

        </Content>
      </div>
    );
  }
}

PresetTab.title = 'Preset';

export default connect(
  state => ({
    tipsData: state.widgets.tips || {},
    isPremium: state.auth.supporter.active,
    supporter: state.auth.supporter,
    selectedVariation: state.widgetsOptions.tips.selectedPreset,
  }),
  (dispatch, ownProps)=> bindActionCreators({
    widgetsOptionsSelectPreset,
    widgetsComponentsUpdate: getActionWidgetsComponentsUpdate(widgets.tips.id, ownProps.selectedVariation, 'preset'),
    widgetUpdate,
    widgetsTestAlert,
  }, dispatch),
)(PresetTab);
