/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { authUserSettingsUpdate } from 'store/reducers/auth';
import { widgets, settingsDefault } from 'constants';
import {
  Container,
  Text,
  TextContainer,
  ImageContainer,
  AboutGameImage,
  Title,
  ButtonTitle,
  ImageArrowDown,
  ImageSnow,
  Content,
  ContainerImageArrowDown,
} from './style';

export default connect(
  state => ({
    settings: state.auth.user.settings || {},
  }),
  dispatch => bindActionCreators({
    settingsUpdate: authUserSettingsUpdate(widgets.race.id),
  }, dispatch),
)(class extends React.Component {
    render() {
      const {
        settings,
        settingsUpdate,
      } = this.props;
      const raceSettings = {
        ...settingsDefault.race,
        ...(settings.race || {}),
      };
      return (
        <Container>
          <ImageSnow />
          <Content disabledRule={raceSettings.disabledRule}>
            <ButtonTitle onClick={() => settingsUpdate({ disabledRule: !raceSettings.disabledRule })}>
              <Title>About game</Title>
              <ContainerImageArrowDown>
                <ImageArrowDown disabledRule={raceSettings.disabledRule}/>
              </ContainerImageArrowDown>
            </ButtonTitle>
            <TextContainer  disabledRule={raceSettings.disabledRule}>
              <Text marginBottom>
                Encourage your viewers to purchase a Christmas Tree and help it grow with their tips. You set how many tokens is required to enter a game (
                <span>Tip to join game, tokens </span>
                field) and how many viewers can participate at the same time (
                <span>Number of players </span>
                field).
              </Text>
              <Text marginBottom>
                Donations received from a viewer who bought a tree make their Christmas tree grow and gain level.
                When the tree reaches level 35 its growth stops but the level will still increase with more tips to come.
                {false && 'Tips past the maximum level will trigger special effects.'}
              </Text>
              <Text marginBottom>
                You can set the amount of tokens needed to get a tree to level 35 (
                <span>Tokens to level 35 </span>
                field).
              </Text>
              <Text>
                If left without tips for a certain amount of time (configured in
                <span> No tips destruction in, min </span>
                field), the tree will decay and eventually disappear.
              </Text>
            </TextContainer>
            <ImageContainer>
              <AboutGameImage disabledRule={raceSettings.disabledRule}/>
            </ImageContainer>
          </Content>
        </Container>
      );
    }
  }
);
