import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import {
  Glyphicon,
  Modal,
} from 'react-bootstrap';
import styled from 'styled-components';

import { Block, TextInput, Button } from 'components/UI';

import './WidgetURL.css';

// const lastActivityIndicator = (lastActivity, t) => {
//   if (!lastActivity) {
//     return (
//       <div>
//         {icon('danger', 'remove')}
//         {t.lastActivitySetup}
//         <p>{t.lastActivityTip}</p>
//       </div>
//     );
//   }
//   const isUserHasActivityInLastMinute =
//     new Date(lastActivity).getTime() + 60000 > new Date().getTime();
//   return (
//     <div style={{ marginTop: '4px', marginBottom: '4px' }}>
//       {isUserHasActivityInLastMinute ? (
//         <div>
//           {icon('success', 'ok')}
//           {t.lastActivityConnected} {lastActivity}
//         </div>
//       ) : (
//         <div>
//           {icon('warning', 'time')}
//           {t.lastActivityEarlier} {lastActivity}
//         </div>
//       )}
//     </div>
//   );
// };

const MessageBlockURL = styled(Block)`
  margin-bottom: 6px;
  display: flex;
  align-items: center;
`;

const LongTextInput = styled(TextInput)`
  flex: 1;
  margin-left: 20px;
  margin-right: 10px;
`;

const URLWarning = styled.div`
  color: ${({ theme }) => theme.lightPeach};
  margin-bottom: 20px;
  ${({ theme }) => theme.font.usual};
`;

const WarningIcon = styled(Glyphicon)`
  margin-left: 2px;
  margin-right: 8px;
  font-size: 11px;
`;

const HelpButton = styled(Button)`
  color: white;
  &:focus, &:hover, &:active {
    color: white;
    border-bottom: none;
  }
`;

type State = {
  refreshModal: boolean,
  refreshTimer: number,
};
type Props = {
  authGenerateStreamAccessToken: () => void,
  streamAccessToken: string,
  widgetId: string,
  t: object,
};

export class WidgetURL extends PureComponent<Props, State> {
  state = {
    refreshModal: false,
    refreshTimer: 0,
  };

  buttonInterval = null;

  componentWillUnmount() {
    clearInterval(this.buttonInterval);
  }


  resetButtonTimeout = () => {
    this.setState({ refreshTimer: 20 });
    this.buttonInterval = setInterval(() => {
      this.setState(({ refreshTimer }) => {
        if (refreshTimer > 0) {
          return { refreshTimer: refreshTimer - 1 };
        }
        clearInterval(this.buttonInterval);
        return { refreshTimer: 0 };
      });
    }, 1000);
  };

  showRefreshModal = () => {
    this.setState({ refreshModal: true });
    this.resetButtonTimeout();
  };

  hideRefreshModal = () => {
    this.setState({ refreshModal: false });
    clearInterval(this.buttonInterval);
  };

  hideRefreshModalAndGenerateToken = () => {
    this.hideRefreshModal();
    this.props.authGenerateStreamAccessToken();
  };

  render() {
    const {
      streamAccessToken,
      widgetId,
      // lastActivity,
      t,
      className,
    } = this.props;
    const {
      refreshTimer,
    } = this.state;
    if (!streamAccessToken) return null;
    return (
      <div className={className}>
        <MessageBlockURL>
          {t.widgetURL}
          <LongTextInput
            value={`${window.location.origin}/w/${widgetId}/${streamAccessToken}`}
            readOnly
            onClick={e => e.target.select()}
            innerRef={r => (this.inputRef = r)}
          />
          <Button onClick={this.showRefreshModal}>
            {t.refresh}
          </Button>
          <Button
            onClick={() => {
              ReactDOM.findDOMNode(this.inputRef).select();
              document.execCommand('copy');
              ReactDOM.findDOMNode(this.inputRef).blur();
            }}
          >
            {t.copy}
          </Button>
          <HelpButton
            as="a"
            href="http://help.tipalerts.com/article/basic-configuration/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Glyphicon glyph="question-sign" />
          </HelpButton>
          {/* {lastActivityIndicator(lastActivity, t)} */}
          <Modal show={this.state.refreshModal} onHide={this.hideRefreshModal}>
            <Modal.Header closeButton>
              <Modal.Title>{t.warning}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t.warningText1}
              {' '}
              <a
                href="http://help.tipalerts.com/article/basic-configuration/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'rgb(4, 156, 251)', fontWeight: 500 }}
              >
                {t.warningText2}
              </a>
              &nbsp; <strong>for all widgets</strong>.
              <p>{t.warningYouReallyWant}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{
                  width: '108px',
                  textAlign: 'center',
                }}
                bsStyle="danger"
                onClick={this.hideRefreshModalAndGenerateToken}
                disabled={refreshTimer !== 0}
              >
                {t.refresh}
                &nbsp;
                {refreshTimer !== 0 ? `(${refreshTimer})` : ''}
              </Button>
              <Button bsStyle="primary" onClick={this.hideRefreshModal}>
                {t.cancel}
              </Button>
            </Modal.Footer>
          </Modal>
        </MessageBlockURL>
        <URLWarning>
          <WarningIcon glyph="alert" />
          {t.widgetURLWarning}
        </URLWarning>
      </div>
    );
  }
}
