import React, { Component } from 'react';

import { Table } from './Table';
import {
  AboutTitles,
  AboutTitleContainer,
  Container,
  ContainerContent,
  Title,
  Contact,
  EmailLink,
} from './style';


export class SubscriptionsComparison extends Component {
  render() {
    return (
      <Container id="subscriptions_comparison">
        <ContainerContent>
          <Title>Subscriptions comparison</Title>
          <AboutTitleContainer>
            <AboutTitles>
              Which features are included in the subscription plans?
            </AboutTitles>
          </AboutTitleContainer>
          <Table />
          <Contact>
            If you have any questions regarding features contact us&nbsp;
            <EmailLink href="mailto:support@tipalerts.com">support@tipalerts.com</EmailLink>
          </Contact>
        </ContainerContent>
      </Container>
    );
  }
}
