import styled from 'styled-components';
/* import { Link } from 'react-router-dom'; */
// import { theme } from 'constants/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  background: radial-gradient(360.00px at 50% 50%, #324D60 0%, #253A49 100%);
  height:calc(100vh - 34px);
  overflow: auto;
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  width: calc(100% + 16px);
  margin-left: -8px;
  margin-bottom: 60px;
`;

export const Content = styled.div`
  display: flex;
  margin-top: 40px;
  width: 1140px;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  margin-bottom: 100px;
`;
/*
const link = css`
  font-weight: 500;
  color: #FF9510;

  font-size: 18px;
  line-height: 115%;
  margin: 0px 8px;

  border: none;
  outline: none;

  &:hover {
    border: none;
    color: ${({ theme }) => theme.orangeButtonHover};
    outline: none;
  }
  &:active,
  &:focus {
   border: none;
   color: 1px solid ${({ theme }) => theme.orangeButtonActive};
   outline: none;
  }

  `;

export const InnerLink = styled(Link)`${link}`; */

export const SetupLink = styled.div`
  width: 100%;
  margin: 53px 0px 14px 0px;
  display: flex;
  justify-content: flex-start;

  font-weight: 500;
  color: #FF9510;

  font-size: 18px;
  line-height: 115%;
`;

export const Title = styled.span`
  font-weight: 900;
  font-size: 48px;
  line-height: 115%;
  text-align: left;

  margin-bottom: 45px;
`;

export const QuitButton = styled.button`
  border-radius: 24px;
  border: none;
  outline: none;
  background: ${({ theme }) => theme.white05} url('/images/X.svg') no-repeat ;
  background-position: 20px center;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  padding: 14px 24px 14px 46px;

  &:hover {
    background-color: ${({ theme }) => theme.white10};
  }
  &:active {
    background-color:  ${({ theme }) => theme.black05};
  }
`;

export const QuitButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
