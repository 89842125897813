import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { widgets } from 'constants';

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
`;

export const Upgrade = connect(state => ({
  supporter: state.auth.supporter,
}))(
  class extends React.Component {
    render() {
      const {
        supporter,
      } = this.props;
      return (
        <Container>
          {(supporter.active || supporter.basic) && <Redirect to={`/widget/${widgets.tips.id}`} />}
          <h4>Free account is not available more.</h4>
          <br />
          Please upgrade your account on the&nbsp;
          <Link to="/settings">Settings</Link>
          &nbsp;page
          <div>
            Read more information about this change on the&nbsp;
            <Link to="/sorry-but-it-actually-important">Upgrade Notice</Link>
            &nbsp;page
          </div>
          <br />
          If you have any troubles with upgrade - please contact us via chat or
          e-mail&nbsp;
          <a href="mailto:support@tipalerts.com">support@tipalerts.com</a>
          <br />
          Also you can request trial access.
        </Container>
      );
    }
  },
);
