import styled, { css } from 'styled-components';

import { theme } from 'constants/theme';

export const Container = styled.div`
  width: 100%;
  background-color: #1B2D3A;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Title = styled.span`
  font-weight: 800;
  font-size: 56px;
  line-height: 110%;

  margin-top: 52px;
  margin-bottom: 16px;
`;

export const AboutTitle = styled.span`
  width: 557px;

  font-size: 22px;
  line-height: 32px;
`;

export const ContainerStep = styled.div`
  width: 1140px;

  margin: 40px 0px;

  display: flex;
  flex-direction: row;
  justify-content: center;

`;

export const Step = styled.div`
  display: flex;

  margin: 0px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: flex-start;
`;

export const Number = styled.span`
  font-weight: 500;
  font-size: 56px;
  line-height: 100%;
  color: #FFB65A;
`;

export const TextStep = styled.span`
  flex: 1;
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

export const LinkOrange = styled.a`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #FFB65A;
  
  border: none;
  outline: none;
  
  &:hover,
  &:active,
  &:focus {
   border: none;
   outline: none;
   color: ${theme.main.orangeLanding};
  }

  &:visited{
    color: ${theme.main.orangeLanding};
  }

  &:hover{
    color: ${theme.main.linkHover};
  }
  &:active{
    color: ${theme.main.linkActive};
  }
`;

export const ButtonOrangeLink = styled.a`
  padding: 16px 38px;

  font-weight: bold;
  font-size: 16px;
  color: #1B2D3A;

  border-radius: 100px;
  border: none;

  background-color: ${theme.main.orangeLanding};

  &:focus {
    outline: none;
    border: none;
    color: black;
  }

  &:hover {
    outline: none;
    border: none;
    color: black;
    background-color: ${theme.main.orangeButtonHover};
  }

  &:active {
    outline: none;
    border: none;
    color: black;
    background-color: ${theme.main.orangeButtonActive};
  }
`;

export const TextItalic = styled.span`
  width: 750px;

  margin: 30px 0px;

  font-style: italic;
  font-weight: normal;
  font-size: 22px;
  line-height: 32px;
`;

const contact = css`
  font-size: 18px;
  line-height: 22px;

  margin-bottom: 45px;`;

export const Contact = styled.span`
  ${contact}
`;

export const EmailLink = styled.a`
  ${contact}
  color: #FFB65A;
  border: none;
  outline: none;
  
  &:hover,
  &:active,
  &:focus {
   border: none;
   outline: none;
   color: ${theme.main.orangeLanding};
  }

  &:visited{
    color: ${theme.main.orangeLanding};
  }

  &:hover{
    color: ${theme.main.linkHover};
  }
  &:active{
    color: ${theme.main.linkActive};
  }
`;

export const LogoContainer = styled.div`
 margin-bottom: 70px;
`;

export const Logo = styled.div`
  width: 104px;
  height: 22px;
  background-image: url('/images/visa_ms.svg');
`;
