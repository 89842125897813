import React, { Component } from 'react';
import { Service_ID, servicesNames } from 'constants';

import {
  Container,
  Content,
  Image,
  Text,
  InnerLinkButton,
  ContainerInnerLink,
  RemoveNotification,
} from './style';

const newServices = Object.keys(Service_ID)
  .filter(key => Service_ID[key] !== Service_ID.chaturbate);

// const servicesNamesArr = Object.keys(servicesNames).map(key => servicesNames[key]);
const newServicesNames = newServices.map(service => servicesNames[service]);

export default class ConnectService extends Component {
  render() {
    const {
      profile = {},
      authDisableNewIntegration,
    } = this.props;
    const { chaturbateId, disableNewIntegration } = profile;
    const isAnyNewService = newServices.some(service => profile[`${service}Id`]);

    // нет ни одного сервиса
    if (!chaturbateId && !isAnyNewService) {
      const str1 = 'Connect stream and start using TipAlerts widgets.';
      return (
        <Container>
          <Content>
            <Image />
            <Text>
              Your stream is not connected
              <br />
              <span>{str1}</span>
            </Text>
            <ContainerInnerLink>
              <InnerLinkButton to="/connect-service">Connect stream</InnerLinkButton>
            </ContainerInnerLink>
          </Content>
        </Container>
      );
    }

    // есть chaturbate, нет ни одного сервиса из новой интеграции и disableNewIntegration false
    if (chaturbateId && !isAnyNewService && !disableNewIntegration) {
      const str2 = `${newServicesNames.slice(0, -1).join(', ')} and ${newServicesNames[newServicesNames.length - 1]} integrations are available. Connect your stream now!`;
      return (
        <Container>
          <Content>
            <Text>{str2}</Text>
            <ContainerInnerLink>
              <InnerLinkButton to="/connect-service">Connect stream</InnerLinkButton>
              <RemoveNotification onClick={() => authDisableNewIntegration(true)} />
            </ContainerInnerLink>
          </Content>
        </Container>
      );
    }

    // все остальные случаи
    return null;
  }
}
