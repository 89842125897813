/* eslint-disable */
import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  margin-right: ${({ marginRight }) => marginRight && '6px'};
  width: 50%;
  height: 36px;

  ${({ position }) => (position === 'left')
    && css`
      margin-right: 1px;
    `
    };

    ${({ position }) => (position === 'center')
      && css`
        margin-right: 1px;
      `};

  position: relative;
`;

const Button = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 4px;


  ${({ position }) => (position === 'left')
    ? css`
      border-radius: 4px 0px 0px 4px;
    `
    : (position === 'right') && css`
      border-radius: 0px 4px 4px 0px;
    `
    };

    ${({ position }) => (position === 'center')
      && css`
        border-radius: 0px 0px 0px 0px;
      `};

  background: url('/images/icons/ic-${({ icon }) => icon}.svg') no-repeat;
  background-position: center;

  border: none;
  outline: none;
  background-color: hsla(206, 29%, 36%, 1);
  &:hover {
    background-color: ${({ active }) => active
    ? 'hsl(33, 100%, 73%) !important'
    : 'hsla(206, 29%, 46%, 1)'};
  };
  &:focus {
    outline: none;
  };

  ${({ active }) => active
    && css`
      background-color: hsl(33, 100%, 68%) !important;
      `};
`;

const Tooltip = styled.div`
display: flex;
align-items: center;
justify-content: center;

width: max-content;
max-width: 280px;
visibility: hidden;
opacity: 0;
position: absolute;
bottom: calc(100% + 7px);
left: 50%;
transform: translateX(-50%);

min-height: 24px;

border-radius: 4px;
padding: 2px 8px;

color: #FFFFFF;
background: #11171B;

transition: opacity 0.3s ease-in-out;

cursor: auto;
pointer-events: none;

  font-size: 14px;
  text-align: left;
  line-height: 20px;

&:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #11171B transparent transparent transparent;
}

${Container}:hover & {
  visibility: visible;
  opacity: 1;
}

z-index: 2;
`;



const Image = styled.div`
  background-color: ${({ theme, active }) => active ? theme.darkGrey : theme.white };

  width: 100%;
  height: 100%;

  mask-image:  url('/images/icons/ic-${({ icon }) => icon}.svg');
  mask-repeat: no-repeat;
  mask-position: center;
`;

export default class ButtonFont extends React.Component {
  onClick = () => {
    const { onClick, active, radioButton } = this.props;
    if (radioButton && active) return;
    onClick(active);
  };

  render() {
    const {
      active,
      icon,
      position,
      marginRight,
      label,
    } = this.props;
    return (
      <Container
        position={position}
        marginRight={marginRight}
      >
        <Button
          onClick={this.onClick}
          active={active}
          position={position}
        >
          <Image active={active} icon={icon} />
        </Button>
        <Tooltip>{label}</Tooltip>
      </Container>
    );
  }
};
