import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './auth';
import widgets from './widgets';
import widgetsOptions from './widgetsOptions';
import render from './render';
import lang from './lang';
import { logs } from './logs';

export const makeCombinedReducers = history => combineReducers({
  router: connectRouter(history),
  auth,
  widgets,
  widgetsOptions,
  render,
  lang,
  logs,
});
