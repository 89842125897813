import React, { PureComponent } from 'react';
import moment from 'moment';

import { CustomButton, StartTimeContainer } from './UI';

export class StartTime extends PureComponent {
  render() {
    const { time, onFetchStartTime } = this.props;
    const startTime = time ? moment(time) : null;
    return (
      <StartTimeContainer>
        <span>
          Server started:
          {' '}
          {startTime && startTime.fromNow()}
          {startTime && `(${startTime.format('DD.MM.YYYY HH:mm:ss')})`}
          {' '}
        </span>
        <CustomButton onClick={onFetchStartTime}>Fetch start time</CustomButton>
      </StartTimeContainer>
    );
  }
}
