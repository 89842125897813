import React from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';


import {
  NewColorSelector,
  CheckBox,
} from 'components/UI';
import ModalTooltip from './ModalTooltip';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 60px;
  width: 143px;

  align-items: flex-start;
  justify-content: flex-start;
`;

const Label = styled.div`
  display: flex;
  height: 20px;
  margin-bottom: 4px;
`;

const Row = styled.div`
  display: flex;

  ${({ disabled }) => disabled && css`
    opacity: 0.54;
    pointer-events: none;
  `};
`;

const OptionsButton = styled.button`
  width: 24px;
  height: 24px;
  background: url('/images/icons/ic-settings.svg') no-repeat;
  background-position: center;

  margin: 6px;

  border: none;
  outline: none;

  position: relative;
`;

type Props = {
  data: {
    checkbox: boolean,
    color: number,

    x: number,
    y: number,
    blur: number,

    borderWidth: number,
    borderBlurShadow: number,
  },
  onChange: (any) => void,
  title: string,
}
// TODO: это не shadow block (потому как меняет цвета и для background)
export default class ShadowBlock extends React.Component<Props> {
  state = {
    optionActive: false,
  };

  /* ModalTooltip */
  modal = React.createRef();

  showOptionModal = () => {
    this.setState({ optionActive: true }, () => document.addEventListener('click', this.onClick));
  };

  onClick = (e) => {
    const modalNode = ReactDOM.findDOMNode(this.modal.current);
    const isOutClick = !(modalNode && modalNode.contains(e.target));
    if (isOutClick) this.hideOptionModal(e);
  };

  hideOptionModal = (e) => {
    e.stopPropagation();
    this.setState({ optionActive: false }, () => document.removeEventListener('click', this.onClick));
  };

  render() {
    const {
      data,
      onChange,
      title,
      options,
      allColorModels,
      disabledRandomButton,
    } = this.props;
    const { optionActive } = this.state;
    const disabled = data.checkbox === false;
    return (
      <Container>
        <Label>
          {data.checkbox !== undefined
            ? (
              <CheckBox
                checked={data.checkbox}
                onChange={() => onChange({ checkbox: !data.checkbox })}
                themeFont="formItemCol"
              >
                {title}
              </CheckBox>
            )
            : `${title}`}
        </Label>
        <Row disabled={disabled}>
          <NewColorSelector
            onSelect={selectColor => onChange({ color: selectColor })}
            value={data.color}
            allColorModels={allColorModels}
            disabledRandomButton={disabledRandomButton}
            disabled={disabled}
          />
          {options && (
            <OptionsButton active={optionActive} onClick={this.showOptionModal}>
              <ModalTooltip
                ref={this.modal}
                show={optionActive}
                data={data}
                onChange={onChange}
                title={title}
                quitButton={{
                  onClick: this.hideOptionModal,
                }}
              />
            </OptionsButton>)}
        </Row>
      </Container>
    );
  }
}
