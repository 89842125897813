import { all } from 'redux-saga/effects';

import { auth } from './auth';
import { widgets } from './widgets';
import { render } from './render';
import { logs } from './logs';
// import { widgetsOptions } from './widgetsOptions';

export default function* () {
  return yield all([
    auth(),
    widgets(),
    render(),
    logs(),
    // widgetsOptions(),
  ]);
}
