import React, { Component } from 'react';
import {
  AlertsContentBackground,
  AlertsContent,
  TextContainer,
  ImageContainer,
  ImageAlerts,
} from './style';

export class AlertsContainer extends Component {
  render() {
    return (
      <AlertsContentBackground>
        <AlertsContent>
          <ImageContainer>
            <ImageAlerts />
          </ImageContainer>
          <TextContainer>
            Alerts
            <p>Express your gratitude towards viewers and and improve their loyalty</p>
          </TextContainer>
        </AlertsContent>
      </AlertsContentBackground>
    );
  }
}
