import styled from 'styled-components';
import { Button, TextInput } from 'components/UI';

export const CustomButton = styled(Button)`
  background-color: hsla(206, 29%, 36%, 1);
  &:hover {
    background-color: hsla(206, 29%, 46%, 1);
  }
  &:active {
    background-color: hsla(206, 29%, 36%, 1);
  }
`;

export const FiltersButton = styled(CustomButton)`
  margin-left: 4px;
`;

export const CustomTextInput = styled(TextInput)`
  margin-right: 4px;
  background-color: #2E3F4C;
  ::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
`;

export const ContainerDate = styled.div`
  display: flex;
  >div{
    color: white;
  }
  >Button {
    flex-shrink: 0;
    flex-grow: 0;
    margin-left: 4px;
  }
  button.react-datepicker__close-icon::after {
    background-color: #334b5f;
  }
  input {
    background-color: hsla(206,29%,36%,1);
    border: 1px solid hsla(206,29%,36%,1);
    border-radius: 4px;
    height: 34px;
    width: 162px;
    padding-left: 4px;
  }
`;

export const ButtonLogContainer = styled.div`
  display: flex;
  margin: 5px;
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 20px;
`;

export const TagButton = styled.div`
  background-color: ${({ chosen }) => (chosen ? '#6F829133' : '#6F8291')};
  height: 34px;
  padding: 0 8px;
  border: 2px solid #6F8291;
  border-radius: 4px;
  margin: 4px 0 0 4px;

  line-height: 30px;
  color: white;
  cursor: pointer;

  &:active {
    background-color: #6F82911A;
  }
`;

export const SearchContainer = styled.div`
  margin-left: 5px;
  display: flex;
  align-items: center;
`;

export const SearchByTypeWrapper = styled.span`
  display: inline-block;
  margin-right: 4px;
`;

export const BadgePremium = styled.span`
  border-radius: 2px;
  padding: 2px 4px;
  background-color: #298c29;
  margin-left: 4px;
`;

export const BadgeTrial = styled(BadgePremium)`
  background-color: #26a8ff;
`;

export const BadgeBasic = styled(BadgePremium)`
  color: black;
  background-color: #ffff00;
`;

export const ContainerTestAlerts = styled.div`
  display: flex;

  position: absolute;
  top: -44px;
  right: 0;
`;

export const StartTimeContainer = styled.div`
  margin-bottom: 6px;
`;
