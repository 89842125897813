// @flow

/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

import {
  FormItem,
  Section,
  CheckBox,
  ShadowBlock,
} from 'components/UI';

import {
  TextAppearance,
} from 'components/UIkit';

import { DiceDataDefault, widgets } from 'constants';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActionWidgetsComponentsUpdate, widgetReset } from 'store/reducers/widgets';


const Container = styled.div`

`;

const ColMain = styled.div`
  margin-right: 16px;
`;

const RowMain = styled.div`
display: flex;
align-items: ${({ baseline }) => (baseline ? 'baseline' : 'center')};
`;

const TextLabel = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;
  color: ${({ theme }) => theme.white54};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ContentPrizes = styled(Content)`
  margin-bottom: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const PrizeRow = styled(Row)`
  margin-bottom: 6px;
`;

const Label = styled.div`
  min-width: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  margin-right: ${({ margin }) => (margin ? '15px' : '0')};
`;

const HelpButton = styled.svg`
  & > use {
    transition: fill 0.3s ease-in-out;
    fill: rgba(255,255,255,0.54);
  }
`;

const HelpContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: calc(100% + 4px);
  cursor: pointer;
  
  height: 36px;
  width: 36px;
  
  border-radius: 4px;
  background-color: transparent;
  
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #617685;
  }

  &:hover ${HelpButton} > use {
    fill: rgba(255,255,255,1);
  }
`;

const Cross = styled.div`
  position: relative;
  
  width: 14px;
  height: 14px;
  opacity: 0.54;
  
  cursor: pointer;
  
  transition: opacity 0.3s ease-in-out;
  
  &:before, &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: '';
    height: 18px;
    width: 2px;
    background-color: #fff;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
 
`;

const CrossContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  /* calc(100% + 4px); */
  cursor: pointer;
  
  height: 36px;
  width: 36px;
  
  border-radius: 4px;
  background-color: transparent;
  
  transition: background-color 0.3s ease-in-out;

  &:hover ${Cross} {
    opacity: 1;
  }
  
  &:active ${Cross} {
    opacity: 0.8;
  }
  
`;


const DeleteTooltip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: max-content;
  max-width: 280px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: calc(100% + 7px);
  left: 50%;
  transform: translateX(-50%);
  
  min-height: 24px;
  
  border-radius: 4px;
  padding: 2px 8px;

  color: #FFFFFF;
  background: #11171B;
  
  transition: opacity 0.3s ease-in-out;
  
  cursor: auto;
  pointer-events: none;
  
    font-size: 14px;
    text-align: left;
    line-height: 20px;
  
  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #11171B transparent transparent transparent;
  }
  
  ${CrossContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

const PrizeInput = styled.input`
  width: 100%;
  padding: 0 10px;
  background: #2E3F4C;
  border-radius: 4px;
  border: 1px solid transparent;
  outline: none;
  height: 36px;
  
  font-size: 14px;
  line-height: 20px;
  color: #FFF;
  
 
  &:placeholder {
    color: rgba(255, 255, 255, 0.34);
  }

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.orangeLanding};;
  }

  &:hover {
    border-color: ${({ theme }) => theme.orangeButtonHover};
  }
  &:active {
    border-color:  ${({ theme }) => theme.orangeButtonActive};
  }
`;

const AddPrize = styled.div`
  padding: 8px 27px;
  background-color: #FFB65A;
  border-radius: 4px;
  
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  
  ${({ disabled }) => (
    disabled
  ? 'background-color: #ADADAD;'
      : 'background-color: #FFB65A; cursor: pointer; &:hover { opacity: 0.8; } &:active { opacity: 0.9; }'
)}

  color: #1D272E;

`;

const prizesLimit = 12;

class PrizeListTab extends React.Component {
  inputsRefs = {};

  componentDidUpdate({ data: { prizes: prevPrizes = [] } }) {
    const { data: { prizes = [] } } = this.props;
    const lastKey = prizes.length - 1;
    if (prevPrizes.length < prizes.length && lastKey >= 0 && prizes[lastKey] === '' && this.inputsRefs[lastKey]) { // focus last on add click
      this.inputsRefs[lastKey].focus();
      this.inputsRefs[lastKey].scrollIntoView({ block: 'center' });
    }
  }

  deletePrize = key => () => {
    const { data: { prizes }, widgetsComponentsUpdate } = this.props;
    if (!prizes || !prizes.length) return;
    const newPrizes = [...prizes.slice(0, key), ...prizes.slice(key + 1)];
    if (this.inputsRefs[key - 1]) {
      this.inputsRefs[key - 1].focus();
      this.inputsRefs[key - 1].scrollIntoView({ block: 'center' });
    }
    widgetsComponentsUpdate({ prizes: newPrizes });
  };

  addPrize = () => {
    const { data: { prizes }, widgetsComponentsUpdate } = this.props;
    if (!prizes) return widgetsComponentsUpdate({ prizes: [''] });
    if (prizes.length >= prizesLimit) return;
    widgetsComponentsUpdate({ prizes: [...prizes,  ''] });
  };

  onChange = key => e => {
    const { data: { prizes }, widgetsComponentsUpdate } = this.props;
    const newPrizes = [...(prizes || [])];
    newPrizes[key] = e.target.value;
    widgetsComponentsUpdate({ prizes: newPrizes });
  };

  keyPress = (e, key) => {
    if (e.keyCode === 13) {
      this.addPrize();
    }
    if (!e.target.value && e.keyCode === 8) {
      this.deletePrize(key)();
    }
  };

  onMainBackgroundData = ({ checkbox: mainBackgroundColorEnabled, color: mainBackgroundColor }) => {
    const { widgetsComponentsUpdate } = this.props;
    widgetsComponentsUpdate({ mainBackgroundColorEnabled, mainBackgroundColor });
  };
  onAccentData = ({ color: accentColor }) => {
    const { widgetsComponentsUpdate } = this.props;
    widgetsComponentsUpdate({ accentColor });
  };

  render() {
    const {
      data,
      widgetsComponentsUpdate,
    } = this.props;
    const disabledAddButton = data.prizes.length >= prizesLimit;

    const mainBackgroundData = {
      color: data.mainBackgroundColor,
      checkbox: data.mainBackgroundColorEnabled,
    };
    const accentData = {
      color: data.accentColor,
    };

    return (
      <Container>
        <Section>
          <FormItem label="List appearance" themeFont="header" header>
            <CheckBox
              checked={!data.hide}
              onChange={() => widgetsComponentsUpdate({ hide: !data.hide })}
              themeFont="headerCheckbox"
            >
              Display prizes list
            </CheckBox>
          </FormItem>
          <RowMain>
            <ColMain>
              <ShadowBlock
                data={accentData}
                onChange={this.onAccentData}
                title="Accent"
                disabledRandomButton
              />
            </ColMain>
          </RowMain>
        </Section>

        <TextAppearance
          data={data}
          widgetUpdate={widgetsComponentsUpdate}
          mainLabel="List"
          borderTop
        />

        <Section borderTop>
          <FormItem label="Prizes" themeFont="header" header />
          <TextLabel>You can add up to 12 prizes. All prizes have the same chances to be rolled.</TextLabel>
          {!!data.prizes.length && <ContentPrizes>
          {data.prizes.map((prize, key) => (
            <PrizeRow key={key}>
              <Label>
                {key + 1}
              </Label>
              <PrizeInput
                ref={(ref) => this.inputsRefs[key] = ref}
                onKeyDown={(e) => this.keyPress(e, key)}
                type="text"
                value={prize}
                onChange={this.onChange(key)}
                placeholder="Prize title"
              />
              <CrossContainer onClick={this.deletePrize(key)}>
                <Cross />
                <DeleteTooltip>Delete prize</DeleteTooltip>
              </CrossContainer>
            </PrizeRow>
          ))}
        </ContentPrizes>}
        <AddPrize disabled={disabledAddButton} onClick={disabledAddButton ? () => {} : this.addPrize}>Add prize</AddPrize>
        </Section>

      </Container>
    );
  }
}

PrizeListTab.title = 'Prize list';

export default connect(
  null,
  (dispatch, ownProps) => bindActionCreators({
    widgetsComponentsUpdate: getActionWidgetsComponentsUpdate(widgets.dice.id, ownProps.selectedPreset, 'prizeList'),
  }, dispatch),
)(PrizeListTab);
