export const validatePasswordRepeat = ({ password }) => value => value && value === password;
export const validatePassword = () => password => password.length >= 6;
export const validateEmail = () => email => email.indexOf('@') !== -1;
const enhanceValidation = (validationsOriginal, state, validationId, validation) => (...args) => {
  const validations = validationsOriginal;
  const method = validation.apply(this, args);
  Object.assign(state, args[0]);
  return (...args1) => {
    const result = method.apply(this, args1);
    validations[validationId] = result;
    return result;
  };
};

export const combineValidations = (validationsMap) => {
  const state = {};
  const validations = {};
  const enhancedValidations = {};
  for (const validationId in validationsMap) {
    if (!validationsMap.hasOwnProperty(validationId)) continue;
    const validation = validationsMap[validationId];
    validations[validationId] = undefined;
    enhancedValidations[validationId] = enhanceValidation(
      validations,
      state,
      validationId,
      validation,
    );
  }
  enhancedValidations.all = () => {
    for (const validationId in validations) {
      if (!validations[validationId]) continue;
      if (!state[validationId]) {
        return false;
      }
      if (!enhancedValidations[validationId](state)(state[validationId])) {
        return false;
      }
    }
    return true;
  };
  return enhancedValidations;
};

export const signUpValidation = combineValidations({
  email: validateEmail,
  password: validatePassword,
  passwordRepeat: validatePasswordRepeat,
});
