// @flow
/* eslint-disable */
import React from 'react';
import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.font.normal};

  padding-top: 1px;
`;

const RadioTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  cursor: ${({checked}) => (checked ? 'default' : 'pointer')};
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 6px;

  width: 12px;
  height: 12px;
  border-radius: 50%;

  background-color: ${({ checked, theme }) => checked ? theme.orangeLanding : 'hsl(207, 30%, 15%)'};

  transition: 0.3s ease-in-out;
  transition-property: opacity, background-color;

  &:after {
    content: '';
    opacity: ${({ checked }) => checked ? 1 : 0};

    width: 6px;
    height: 6px;
    border-radius: 50%;

    background-color: #111;
  }

  ${RadioTitle}:hover & {
    background-color: ${({ checked }) => !checked && 'hsl(207, 30%, 20%)'};
  }
`;

const Label = styled.div`
  ${({ theme }) => theme.font.normal};
  transition: color 0.3s ease-in-out;
  color: ${({ checked, theme }) => checked ? theme.orangeLanding : theme.white};
  white-space: no-wrap;
`;


export default class RadioButton extends React.Component {
  render() {
    const {
      onChange,
      list,
      checked,
    } = this.props;
    return (
      <Container>
        {Object.keys(list).map((key) => {
          const isChecked = Number(key) === Number(checked);
          const { title } = list[key];
          return (
            <RadioTitle key={title} onClick={() => onChange(key)} checked={isChecked}>
              <Circle checked={isChecked} />
              <Label checked={isChecked}>{title}</Label>
            </RadioTitle>
          )
        })}
      </Container>
    );
  }
}
