import { keyframes } from 'styled-components';

export const Gradient = keyframes`
  0% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  } 
`;

export const fadeOutUp = keyframes`
0% {
  opacity: 0;
}
10% {
  transform: rotate3d(0, 0, 1, 15deg) translate3d(0, 150%, 0);
  opacity: 0;
}

20% {
  transform: rotate3d(0, 0, 1, 15deg) translate3d(0, 100%, 0);
  opacity: 0.2;
}

40% {
   transform: rotate3d(0, 0, 1, -10deg) translate3d(0, 40%, 0);
   opacity: 1;
}

60% {
  transform: rotate3d(0, 0, 1, 5deg) translate3d(0, 0%, 0); 
  opacity: 1;   
}

80% {
  transform: rotate3d(0, 0, 1, -5deg) translate3d(0, -40%, 0);
  opacity: 0.7;
}
100% {
  opacity: 0;
  transform: translate3d(0, -90%, 0);
}
`;

export const fadeOutUp2 = keyframes`
0% {
  opacity: 0;
}
10% {
  transform: rotate3d(0, 0, 1, 10deg) translate3d(10%, 160%, 0);
  opacity: 0;
}

20% {
  transform: rotate3d(0, 0, 1, 10deg) translate3d(0, 110%, 0);
  opacity: 0.2;
}

40% {
   transform: rotate3d(0, 0, 1, -15deg) translate3d(-30%, 40%, 0);
   opacity: 1;
}

60% {
  transform: rotate3d(0, 0, 1, 15deg) translate3d(50%, 2%, 0); 
  opacity: 1;   
}

80% {
  transform: rotate3d(0, 0, 1, -5deg) translate3d(0, -40%, 0);
  opacity: 0.7;
}
100% {
  opacity: 0;
  transform: translate3d(0, -90%, 0);
}
`;

export const fadeOutUp3 = keyframes`
0% {
  opacity: 0;
}
10% {
  transform: rotate3d(0, 0, 1, -5deg) translate3d(-210%, 300%, 0);
  opacity: 0;
}

20% {  
  opacity: 0.1;
}

30% {
  opacity: 0.3;
}
35% {  
  opacity: 0.6;
}
40% {
   transform: rotate3d(0, 0, 1, 5deg) translate3d(-210%, 100%, 0);
   opacity: 1;
}

60% {
  transform: rotate3d(0, 0, 1, -5deg) translate3d(-180%, 0%, 0); 
  opacity: 1;   
}

80% {
  transform: rotate3d(0, 0, 1, 5deg) translate3d(-210%, -40%, 0);
  opacity: 0.7;
}
100% {
  opacity: 0;
  transform: translate3d(-200%, -90%, 0);
}
`;

export const pulse = keyframes`
    0% {     
      transform: scale3d(1, 1, 1);
    }  
    50% {
      transform: scale3d(1.25, 1.25, 1.25);
    }  
    100% {      
      transform: scale3d(1, 1, 1);
    }
 `;

export const pulseSimple = keyframes`
70% {     
  transform: scale3d(1, 1, 1);
}  
85% {
  transform: scale3d(1.05, 1.05, 1.05);
}  
100% {      
  transform: scale3d(1, 1, 1);
}
`;

export const rubberBand = keyframes`
    0% {
      transform: scale3d(1, 1, 1);
    }  
    30% {      
      transform: scale3d(1.25, 0.75, 1);
    }  
    40% {      
      transform: scale3d(0.75, 1.25, 1);
    }  
    50% {      
      transform: scale3d(1.15, 0.85, 1);
    }  
    65% {      
      transform: scale3d(0.95, 1.05, 1);
    }  
    75% {     
      transform: scale3d(1.05, 0.95, 1);
    }  
    100% {      
      transform: scale3d(1, 1, 1);
    }
  `;

export const swing = keyframes`
    20% {
      transform: rotate3d(0, 0, 1, 15deg);
    }  
    40% {
      transform: rotate3d(0, 0, 1, -10deg);
    }  
    60% {      
      transform: rotate3d(0, 0, 1, 5deg);
    }  
    80% {      
      transform: rotate3d(0, 0, 1, -5deg);
    }  
    100% {      
      transform: rotate3d(0, 0, 1, 0deg);
    }
  `;
