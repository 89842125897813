import React from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  TextAppearance,
  TransformBlock,
  AnimationBlock,
  PositionBlock,
} from 'components/UIkit';
import { getActionWidgetsComponentsUpdate } from 'store/reducers/widgets';
import { widgets } from 'constants';

const Col2 = styled.div`
  flex: 2;
  margin-right: ${({ marginRight }) => marginRight}px;
`;

const Col1 = styled.div`
  flex: 1;
  margin-right: ${({ marginRight }) => marginRight}px;
  ${({ flexEnd }) => flexEnd && css`
    justify-content: flex-end;
    display: flex;
  `};
`;

const Row = styled.div`
  display: flex;
  align-items: ${({ baseline }) => (baseline ? 'baseline' : 'center')};
`;


const AmountTab = ({
  data,
  widgetsComponentsUpdate,
}) => (
  <div>
    <TextAppearance
      data={data}
      widgetUpdate={widgetsComponentsUpdate}
      checkBoxText="Display tip amount"
      mainLabel="Tip amount"
    />
    <Row baseline>
      <Col1 marginRight="16">
        <PositionBlock
          data={data}
          widgetUpdate={widgetsComponentsUpdate}
        />
      </Col1>
      <Col2>
        <TransformBlock
          data={data}
          widgetUpdate={widgetsComponentsUpdate}
        />
      </Col2>
    </Row>
    <AnimationBlock
      data={data}
      widgetUpdate={widgetsComponentsUpdate}
      borderTop
    />
  </div>
);

AmountTab.title = 'Tip amount';

export default connect(
  null,
  (dispatch, ownProps) => bindActionCreators({
    widgetsComponentsUpdate: getActionWidgetsComponentsUpdate(widgets.tips.id, ownProps.selectedVariation, 'amount'),
  }, dispatch),
)(AmountTab);
