import React from 'react';
import styled from 'styled-components';

import { TextInput, Button, Block } from 'components/UI';

import './SignIn.css';

export const StyledBlock = styled(Block)`
  flex-direction: column;
  display: flex;
  & > input {
    margin-bottom: 8px;
  }
  & > button {
    margin-bottom: 0;
  }
`;

export const SignIn = ({
  auth: { fields },
  authSetFieldValue,
  authSignIn,
  t,
}) => (
  <div className="SignIn">
    <h3 className="SignIn__Item">{t.signIn}</h3>
    <StyledBlock>
      <TextInput
        type="text"
        placeholder={t.eMail}
        value={fields.email}
        onChange={e => authSetFieldValue('email', e.target.value)}
      />
      <TextInput
        type="password"
        placeholder={t.password}
        value={fields.password}
        onChange={e => authSetFieldValue('password', e.target.value)}
      />
      <Button
        onClick={authSignIn}
      >
        {t.signIn}
      </Button>
    </StyledBlock>
  </div>
);
