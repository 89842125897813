import React from 'react';

import { widgetFonts, widgetFontWeightsScheme } from 'constants';

import {
  Section,
  FormItem,
  FormItemCol,
  CheckBox,
  DropDown,
  NewColorSelector,
  NewSlider,
  ButtonFont,
  Tooltip,
  ShadowBlock,
} from 'components/UI';

import {
  RowFont,
  Col7,
  Col5,
  Col3,
  Col2,
  ColFont,
  RowSlider,
  Col,
  Row,
  ImageTT,
  ImageLS,
} from './style';

const widgetFontWeights = Object.keys(widgetFontWeightsScheme);

type Props = {
  data: {
    enabled: boolean,

    color: string,
    fontFamily: number,
    fontSize: number,
    fontWeight: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900,
    fontWeightBold: boolean, // if fontWeight 700
    fontStyle: 'normal' | 'italic',
    textDecorationLineThrough: boolean,
    textDecorationUnderline: boolean,
    textTransform: 'none' | 'uppercase' | 'lowercase', // 'capitalize' | 'full-width' | 'full-size-kana' | 'inherit' | 'initial' | 'unset',
    textAlign: 'left' | 'center' | 'right',

    backgroundEnabled: boolean,
    backgroundColor: number,

    textShadowEnabled: boolean,
    textShadowX: number,
    textShadowY: number,
    textShadowBlur: number,
    textShadowColor: number,
  },
}

export default class TextAppearance extends React.Component<Props> {
  updateWeight = (weight) => {
    const { widgetUpdate } = this.props;
    const fontWeight = Number(weight);
    if (fontWeight === 700) widgetUpdate({ fontWeightBold: true });
    else widgetUpdate({ fontWeight, fontWeightBold: false });
  };

  onBoldClick = (active) => {
    const { widgetUpdate, data } = this.props;
    const updateObj = { fontWeightBold: !active };
    if (Number(data.fontWeight) === 700) updateObj.fontWeight = 400;
    widgetUpdate(updateObj);
  };

  onShadowChange = ({
    checkbox: textShadowEnabled,
    x: textShadowX,
    y: textShadowY,
    blur: textShadowBlur,
    color: textShadowColor,
  }) => {
    const { widgetUpdate } = this.props;
    widgetUpdate({
      textShadowEnabled,
      textShadowX,
      textShadowY,
      textShadowBlur,
      textShadowColor,
    });
  };

  onBackgroundChange = ({ checkbox: backgroundEnabled, color: backgroundColor }) => {
    const { widgetUpdate } = this.props;
    widgetUpdate({ backgroundEnabled, backgroundColor });
  };

  render() {
    const {
      data,
      widgetUpdate,
      checkBoxText,

      customMessageOptions,
      borderTop,
      mainLabel,

      noFontSize,
      noRandomAll,
    } = this.props;
    const label = `${mainLabel} text appearance`;
    const isItalic = data.fontStyle === 'italic';
    const shadowData = {
      checkbox: data.textShadowEnabled,
      color: data.textShadowColor,

      x: data.textShadowX,
      y: data.textShadowY,
      blur: data.textShadowBlur,
    };
    const backgroundData = {
      checkbox: data.backgroundEnabled,
      color: data.backgroundColor,
    };
    return (
      <Section borderTop={borderTop}>
        <FormItem label={label} themeFont="header" header>
          {checkBoxText !== undefined && (
          <CheckBox
            checked={data.enabled}
            onChange={() => widgetUpdate({ enabled: !data.enabled })}
            themeFont="headerCheckbox"
          >
            {checkBoxText}
          </CheckBox>)}
        </FormItem>
        <RowFont>
          <Col7>
            <FormItemCol label="Font" themeFont="formItemCol">
              <DropDown
                width="100%"
                id="fonts"
                items={widgetFonts}
                value={Number(data.fontFamily)}
                onSelect={selectFont => widgetUpdate({ fontFamily: Number(selectFont) })}
              />
            </FormItemCol>
          </Col7>
          <Col5>
            <FormItemCol label="Weight" themeFont="formItemCol">
              <DropDown
                width="100%"
                id="weights"
                items={widgetFontWeights}
                itemsScheme={widgetFontWeightsScheme}
                titleKey="title"
                value={(data.fontWeightBold && '700') || data.fontWeight.toString()}
                onSelect={this.updateWeight}
              />
            </FormItemCol>
          </Col5>
        </RowFont>
        <RowFont>
          <Col7>
            <FormItemCol label="Style" themeFont="formItemCol">
              <ColFont>
                <ButtonFont
                  icon="font-bold"
                  marginRight
                  active={data.fontWeightBold}
                  onClick={this.onBoldClick}
                  label="Bold"
                />
                <ButtonFont
                  icon="font-italic"
                  active={isItalic}
                  onClick={active => widgetUpdate({ fontStyle: active ? 'normal' : 'italic' })}
                  label="Italic"
                />
              </ColFont>
              <ColFont center>
                <ButtonFont
                  icon="strike-text"
                  position="left"
                  active={data.textDecorationLineThrough}
                  onClick={active => widgetUpdate({ textDecorationLineThrough: !active })}
                  label="Strike"
                />
                <ButtonFont
                  icon="underline"
                  position="right"
                  active={data.textDecorationUnderline}
                  onClick={active => widgetUpdate({ textDecorationUnderline: !active })}
                  label="Underline"
                />
              </ColFont>
              <ColFont>
                <ButtonFont
                  icon="uppercase"
                  position="left"
                  active={data.textTransform === 'uppercase'}
                  onClick={active => widgetUpdate({ textTransform: active ? 'none' : 'uppercase' })}
                  label="Uppercase"
                />
                <ButtonFont
                  icon="lowercase"
                  position="right"
                  active={data.textTransform === 'lowercase'}
                  onClick={active => widgetUpdate({ textTransform: active ? 'none' : 'lowercase' })}
                  label="Lowercase"
                />
              </ColFont>
            </FormItemCol>
          </Col7>
          <Col5>
            <Col3>
              {customMessageOptions && (
              <FormItemCol label="Alignment" themeFont="formItemCol">
                <ButtonFont
                  icon="text-align-left"
                  position="left"
                  active={data.textAlign === 'left'}
                  onClick={() => widgetUpdate({ textAlign: 'left' })}
                  radioButton
                  label="Align left"
                >
                  <Tooltip>Align left</Tooltip>
                </ButtonFont>
                <ButtonFont
                  icon="text-align-center"
                  position="center"
                  active={data.textAlign === 'center'}
                  onClick={() => widgetUpdate({ textAlign: 'center' })}
                  radioButton
                  label="Align center"
                >
                  <Tooltip>Align center</Tooltip>
                </ButtonFont>
                <ButtonFont
                  icon="text-align-right"
                  position="right"
                  active={data.textAlign === 'right'}
                  onClick={() => widgetUpdate({ textAlign: 'right' })}
                  radioButton
                  label="Align right"
                >
                  <Tooltip>Align right</Tooltip>
                </ButtonFont>
              </FormItemCol>)}
            </Col3>
            <Col2>
              <FormItemCol label="Color">
                <NewColorSelector
                  onSelect={selectColor => widgetUpdate({ color: selectColor })}
                  value={data.color}
                  disabledRandomButton={noRandomAll}
                />
              </FormItemCol>
            </Col2>
          </Col5>
        </RowFont>
        {!noFontSize && (
        <RowSlider customMessageOptions={customMessageOptions}>
          <ImageTT />
          <NewSlider
            value={data.fontSize || 14}
            min={1}
            max={144}
            step={1}
            onChange={size => widgetUpdate({ fontSize: Number(size) })}
          />
        </RowSlider>)}
        {customMessageOptions && (
          <RowSlider>
            <ImageLS />
            <NewSlider
              value={data.lineHeight}
              min={1}
              max={200}
              step={1}
              onChange={height => widgetUpdate({ lineHeight: Number(height) })}
            />
          </RowSlider>)}
        <Row>
          <Col>
            <ShadowBlock
              data={shadowData}
              onChange={this.onShadowChange}
              title="Shadow"
              options
              disabledRandomButton={noRandomAll}
            />
          </Col>
          <ShadowBlock
            data={backgroundData}
            onChange={this.onBackgroundChange}
            title="Background"
            disabledRandomButton={mainLabel === 'List' || mainLabel === 'Notifications' || noRandomAll}
          />
        </Row>
      </Section>
    );
  }
}
