import React from 'react';

import {
  Section,
  FormItem,
  NewSlider,
} from 'components/UI';

import {
  Row,
  Label,
} from './style';

export default class TransformGoalText extends React.Component {
  render() {
    const {
      data,
      widgetUpdate,
      borderTop,
    } = this.props;
    const label = 'Transform';
    return (
      <Section borderTop={borderTop}>
        <FormItem label={label} themeFont="header" header />
        <Row>
          <Label>Rotate text</Label>
          <NewSlider
            value={data.transformRotate}
            min={-180}
            max={180}
            step={5}
            onChange={angle => widgetUpdate({ transformRotate: Number(angle) })}
            centerPoint
            magnitPoint={7}
          />
        </Row>
      </Section>
    );
  }
}
