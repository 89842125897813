import styled from 'styled-components';

export const SupportText = styled.div`
  /* !!! KeyFeatures Install: 'padding-top: 60px;'*/
  /* padding-top: 60px; */
  padding: 28px 0px;

  font-style: normal;
  font-weight: 900;
  line-height: 48px;
  font-size: 36px;
  text-align: center;
  > p {
    padding-top: 2px;

    font-weight: normal;
    line-height: 24px;
    font-size: 16px;
  }
`;
