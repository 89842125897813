import React from 'react';
import styled, { css } from 'styled-components';


const Container = styled.div`
  width: ${({ width }) => width || '200px'};
  &:focus {
    outline: none;
  };
  position: relative;
`;
const DropDownButton = styled.button`
  width: 100%;
  height: 36px;
  border-radius: 4px;
  /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1); */
  ${({ theme, backgroundColor }) => css`
    color: ${theme.white};
    background-color: ${backgroundColor || '#416077'};
    /* border: solid 1px ${theme.slateFive}; */
    border: none;
  `};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 1.05;
  letter-spacing: 0.1px;
  outline: none;
  padding: 7px 7px 10px 12px;
  ${({ border }) => (border ? 'border: 1px solid #3A4E5C;' : '')}
  &:focus {
    outline: none;
  }

  ${({ item }) => item && css`
    font-family: ${item};
  `};

  &:hover{
    background-color: hsla(206, 29%, 46%, 1);
  }
`;

const DropDownMenu = styled.div`
${({ theme, backgroundColor }) => css`
    background-color: ${backgroundColor || '#416077'};
    /* border: solid 1px ${theme.slateFive}; */
  `};

  ${({ opened }) => (opened ? 'opacity: 1; visibility: visible;' : 'opacity: 0; visibility: hidden;')}
  transition: opacity 0.2s ease-in-out;
  border-radius: 4px;
  max-height: 210px;
  position: absolute;
  width: 100%;
  z-index: 1000;
  ${({ topDDmenu }) => topDDmenu && 'top: -210px'};


  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 6px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background: ${({ theme }) => theme.white05};

    padding: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.white17};
    border-radius: 3px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.white54};
  }
`;

const MenuItem = styled.button`
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 12px 10px 12px;
  outline: none;
  background-color: transparent;
  border-width: 0;
  ${({ selected }) => selected && css`
    background-color: hsla(206, 29%, 46%, 1);
  `}
  &:hover {
    background-color: hsla(206, 29%, 46%, 1);
  }
  &:focus {
    outline: none;
  };

  ${({ item }) => item && css`
    font-family: ${item};
  `};
`;

type Props = {
  items: array,
  value: number,
  onSelect: Function
}
type State = {
  opened: boolean,
}

export class DropDown extends React.PureComponent<Props, State> {
  state = {
    opened: false,
  }

  render() {
    const { opened } = this.state;
    const {
      items, value, onSelect, itemsScheme, titleKey, id, border, width, backgroundColor, topDDmenu,
    } = this.props;
    const isNotScheme = typeof value === 'number' && !itemsScheme && !titleKey;
    return (
      <Container
        width={width}
        tabIndex="0"
        onBlur={() => !this.focused && this.setState({ opened: false })}
        onMouseEnter={() => this.focused = true}
        onMouseLeave={() => this.focused = false}
        innerRef={ref => this.dropDown = ref}
      >
        <DropDownButton
          backgroundColor={backgroundColor}
          onClick={() => this.setState({ opened: !opened }, () => this.dropDown && this.dropDown.focus())}
          item={id === 'fonts' ? items[value] : ''}
          border={border}
        >
          {isNotScheme ? items[value] : itemsScheme[value] && itemsScheme[value][titleKey]}
          <span>&#9662;</span>
        </DropDownButton>
        <DropDownMenu backgroundColor={backgroundColor} opened={opened} topDDmenu={topDDmenu}>
          {items.map((item, i) => (
            <MenuItem
              key={item}
              selected={isNotScheme ? i === value : item === value}
              onClick={() => this.setState({ opened: false }, () => onSelect(isNotScheme ? i : item))}
              item={id === 'fonts' ? item : ''}
            >
              {isNotScheme ? item : itemsScheme[item][titleKey]}
            </MenuItem>
          ))}
        </DropDownMenu>
      </Container>
    );
  }
}
