/* eslint-disable */
import React from 'react';
import styled, { css } from 'styled-components';
import deepEqual from "deep-equal";

import { getColor } from 'utils';
import { DiceDataDefault, widgetFonts, animationConfig } from 'constants';

import { PrizeList, StrokeComponent } from './index';
import { bugsnagClient } from 'utils/bugsnag';


const getTextDecoration = (lineThrough, underline) => {
  let str = '';
  if (lineThrough) {
    str += 'line-through';
    if (underline) {
      str += ' ';
    }
  }
  if (underline) {
    str += 'underline';
  }
  return str;
};

const shadow = data => `${data.textShadowX}px ${data.textShadowY}px ${data.textShadowBlur}px ${getColor(data.textShadowColor)}`;

const setStyle = ({ customStyle }) => (
  ` color: ${getColor(customStyle.color)};
    font-family: ${widgetFonts[customStyle.fontFamily]};

    background-color: ${customStyle.backgroundEnabled ? getColor(customStyle.backgroundColor) : 'transparent'};
    text-shadow: ${customStyle.textShadowEnabled && shadow(customStyle)};
    font-weight: ${customStyle.fontWeightBold ? 'bold' : customStyle.fontWeight};
    font-style: ${customStyle.fontStyle};
    text-decoration: ${getTextDecoration(customStyle.textDecorationLineThrough, customStyle.textDecorationUnderline)};
    text-transform: ${customStyle.textTransform};
    `
);
//** font-size: ${customStyle.fontSize}px; */

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  padding-top: 2px;


  ${({ preview }) => (preview ? 'zoom: 0.4;' : '')}
  ${({ screenPosition }) => ((screenPosition === 'top-right'
  && css`
    transform: scale(-1, 1);
  `) || (screenPosition === 'bottom-left'
  && css`
    transform: scale(1, -1);
  `) || (screenPosition === 'bottom-right'
  && css`
    transform: scale(-1, -1);;
  `))}
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const PrizeListContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: max-content;
  visibility: ${({ hide }) => hide ? 'hidden' : 'visible'};
`;

const ContainerTitlePrizeList = styled.div`
  display: flex;
  flex-direction: column;

  transform-origin: left top 0;
  transform: scale(${({ scaleTransform }) => scaleTransform});
`;

const Title = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.black};
  border-radius: 50px;

  padding: 0px 16px 0px 70px;
  margin: 13px 0px 0px 16px;

  ${({ screenPosition }) => ((screenPosition === 'top-right'
  && css`
    transform: scale(-1, 1);
    padding: 0px 70px 0px 16px;
    text-align: right;
  `) || (screenPosition === 'bottom-left'
  && css`
    transform: scale(1, -1);
  `) || (screenPosition === 'bottom-right'
  && css`
    transform: scale(-1, -1);
    padding: 0px 70px 0px 16px;
    text-align: right;
  `))}

  display: flex;
  ${setStyle};
`;

const TextTitle = styled.span`
  /* красивый текст background */
  padding: 0.216em;
  border-radius: 0.216em;
`;

const Dice = styled.svg`
  height:${({ mini }) => (mini ? '46px' : '50px')};
  width: ${({ mini }) => (mini ? '42px' : '50px')};
  transform: rotate(${({ mini }) => (mini ? '-112deg' : '9.5deg')});

  position: absolute;
  left: ${({ mini }) => (mini ? '5px' : '30px')};
  z-index: 2;

  ${({ screenPosition }) => (
    ((screenPosition === 'bottom-left') || (screenPosition === 'bottom-right')) && css`
    transform: rotate(${({ mini }) => (mini ? '112deg' : '-9.5deg')}) scale(1, -1);
  `)}
  `;

const Announcement = styled.div`
  width: 100%;
  display: flex;
  flex: 1;

  transform-origin: right top 0;
  transform: scale(${({ scaleTransform }) => scaleTransform});
`;

const DiceComponent = (data) => {
  return (
    <Dice
      mini={data.mini}
      screenPosition={data.screenPosition}
      viewBox="0 0 192 211"
    >
      <path d="M116.093 210.36C88.1868 205.896 54.1397 199.758 35.7205 195.295C19.9783 191.479 9.4884 177.997 6.13901 164.605C3.90699 138.938 1.11628 113.829 0 87.6035C0 66.9581 3.34885 52.4505 11.721 41.2908C21.7675 30.6891 36.2792 15.6236 43.535 8.36976C53.5815 0.557984 67.5351 1.22757e-05 80.9305 0C87.07 -5.62637e-06 137.861 6.13783 161.861 8.92775C177.489 11.7177 192 20.0874 192 34.037L188.651 110.481C187.587 123.955 185.514 137.049 181.953 150.098C181.953 150.098 166.101 189.317 161.861 198.642C158.512 206.008 133.954 213.217 116.093 210.36Z" fill="white"/>
      <path d="M114 199.5C89.0005 195.5 58.5 190 41.9995 186C27.8972 182.581 18.5 170.5 15.4995 158.5C13.5 135.5 11 113 10 89.5C10 71 13 58 20.5 48C29.5 38.5 42.5 25 49 18.5C58 11.5 70.5 11 82.5 11C88 11 133.5 16.5 155 19C169 21.5 182 29 182 41.5L179 110C178.046 122.074 176.19 133.807 173 145.5C173 145.5 158.798 180.644 155 189C152 195.6 130 202.06 114 199.5Z" fill={data.colorDice} />
      <path d="M36.4997 69.4997C9.00012 67.9996 10.5 94.9996 10.5 94.9996L15.5 158C15.5 158 18.4997 177.5 40.9997 186C61.4997 190 111 199 111 199C111 199 140.5 205 145 179.5C145 153.527 146 138 146 113C146 109 141.5 83.4926 110.5 78.9997C81.665 74.8207 59.4997 72 36.4997 69.4997Z" fill="black" fillOpacity="0.2"/>
      <path d="M48.999 18.5C48.999 18.5 24.0002 44 20.0001 49C18 57.5 29.653 62.3492 37.499 63.5C67.499 67.9 99.499 71.5 125.999 74C139.999 74.5 151.5 72.2675 155.499 65.5C161.999 54.5 173.499 34 173.499 34C176.999 24 166.999 22 156.999 19.5C156.999 19.5 118.999 14.5 74.499 11C65.9989 11.5 53.999 14 48.999 18.5Z" fill="white" fillOpacity="0.35"/>
      <ellipse cx="66.5" cy="35.5" rx="13" ry="5.5" fill={data.dotColor}/>
      <ellipse cx="78" cy="23" rx="12.5" ry="5" fill={data.dotColor}/>
      <ellipse cx="54.5" cy="49.5" rx="13" ry="5.5" fill={data.dotColor}/>
      <ellipse cx="132.5" cy="43.5" rx="13" ry="5.5" fill={data.dotColor}/>
      <ellipse cx="141.5" cy="29.5" rx="13" ry="5.5" fill={data.dotColor}/>
      <ellipse cx="123.5" cy="58" rx="14" ry="6" fill={data.dotColor}/>
      <ellipse cx="42.1428" cy="100.559" rx="13.3699" ry="10.9666" transform="rotate(32.5927 42.1428 100.559)" fill={data.dotColor}/>
      <ellipse cx="111.672" cy="110.442" rx="14.4184" ry="11.8266" transform="rotate(32.5927 111.672 110.442)" fill={data.dotColor}/>
      <ellipse cx="45.4701" cy="161.117" rx="13.291" ry="10.9018" transform="rotate(32.5927 45.4701 161.117)" fill={data.dotColor}/>
      <ellipse cx="111.57" cy="172.344" rx="13.291" ry="10.9018" transform="rotate(32.5927 111.57 172.344)" fill={data.dotColor}/>
      <ellipse cx="159.921" cy="97.8571" rx="3.34804" ry="13.8705" transform="rotate(6.54775 159.921 97.8571)" fill={data.dotColor}/>
      <ellipse cx="158.408" cy="158.162" rx="3.34804" ry="13.8705" transform="rotate(6.54775 158.408 158.162)" fill={data.dotColor}/>
      <ellipse cx="175.408" cy="67.1618" rx="3.34804" ry="13.8705" transform="rotate(6.54775 175.408 67.1618)" fill={data.dotColor}/>
      <ellipse cx="173.268" cy="125.022" rx="2.94897" ry="12.2171" transform="rotate(6.54775 173.268 125.022)" fill={data.dotColor}/>
      <ellipse cx="166.915" cy="111.875" rx="3.07843" ry="13.6123" transform="rotate(6.54775 166.915 111.875)" fill={data.dotColor}/>
      <path d="M161.001 67.0002C156.122 76.352 152.999 100.5 152 112.5C149.73 139.754 150 169 150 181.5C150 197 155.501 188.5 155.501 188.5L173.001 145.5C173.001 145.5 177 132.5 179.001 113C180 85.5004 182.001 41.5002 182.001 41.5002C182.001 41.5002 181.5 30.0004 177.501 36.5002C172 46.0002 167 55.5002 161.001 67.0002Z" fill="black" fillOpacity="0.2"/>
    </Dice>
  )
};

const defaultState = {
  chosen: null, // for update list
  rollList: false,
  notifications: [],
};


export class RenderRollTheDice extends React.Component {
  state = { ...defaultState };

  chosen = null;
   // for notifications
  rollNumber = null; // for count
  animated = false;
  delays = [];

  playPromiseSoundAnnounce = null;
  playPromiseSoundRoll = null;
  playPromiseSoundPrize = null;

  refSoundAnnounce = React.createRef();
  refSoundRoll = React.createRef();
  refSoundPrize = React.createRef();

  componentDidMount() {
    this.refSoundAnnounce.current && this.refSoundAnnounce.current.pause();
    this.refSoundRoll.current && this.refSoundRoll.current.pause();
    this.refSoundPrize.current && this.refSoundPrize.current.pause();
  }

  componentDidUpdate({
    data: prevData,
    config: prevConfig,
  }, prevState) {
    const {
      data,
      config,
      renderShiftDiceData,
      settings,
      isPreview,
    } = this.props;

    const prevPrizes = prevConfig && prevConfig.prizeList && prevConfig.prizeList.prizes || [];
    const prizes = config && config.prizeList && config.prizeList.prizes || [];

    const prevScreenPosition = prevConfig && prevConfig.widget && prevConfig.widget.screenPosition;
    const screenPosition = config && config.widget && config.widget.screenPosition;

    const _volume = config && config.sounds && config.sounds.volume || DiceDataDefault.sounds.volume;
    const volume = (_volume / 100).toFixed(2);

    this.refSoundAnnounce.current && (this.refSoundAnnounce.current.volume=volume);
    this.refSoundRoll.current && (this.refSoundRoll.current.volume=volume);
    this.refSoundPrize.current && (this.refSoundPrize.current.volume=volume);

    if (isPreview) {
      const mute = settings.mute;
      this.refSoundAnnounce.current.muted = mute;
      this.refSoundRoll.current.muted = mute;
      this.refSoundPrize.current.muted = mute;
    };

    if (prevPrizes.length && !prizes.length) {
      return this.setToDefault(renderShiftDiceData);
    }
    if (
      (!deepEqual(prevPrizes, prizes) || prevScreenPosition !== screenPosition)
      && this.chosenPrizeDelay
      && this.chosen !== null
      && this.animated
      && this.state.chosen === null
    ) {
      return this.startListAnimation(true);
    }
    if (
      !deepEqual(prevPrizes, prizes)
      && this.state.chosen !== null
      && prevState.chosen === this.state.chosen
      && this.chosen === null
    ) {
      const notifications = [...this.state.notifications]
        .map(notification => (notification.id === data[0].user + '_prizes_' + this.rollNumber ? { ...notification, content: prizes } : notification));
      return this.setState({ notifications });
    }
    if (
      prevData.length !== data.length ||
      prevPrizes.length !== prizes.length
    ) {
      return this.start();
    }
  }

  componentWillUnmount() {
    this.delayClear();
  }

  /** helpers */

  checkOnPrizes = (cb) => {
    const { configuration, renderShiftDiceData } = this.props;
    if (!configuration || !configuration.prizes || !configuration.prizes.length) {
      return this.setToDefault(renderShiftDiceData);
    }
    if (cb) cb();
  };

  delayHandler = (handler, time, check) => {
    const delay = setTimeout(check ? () => this.checkOnPrizes(handler) : handler, time);
    this.delays.push(delay);
    return delay;
  };

  delayClear = () => {
    this.delays.forEach((delay) => clearTimeout(delay));
    this.delays = [];
  };

  setToDefault = (cb = () => {}) => {
    const { notifications } = this.state;
    this.rollNumber = null;
    this.chosen = null;
    this.chosenPrizeDelay = null;
    this.animated = false;
    this.delayClear();
    this.setState({
      ...defaultState,
      notifications: notifications.map((item) => ({ ...item, unmount: true })),
    }, cb);
  };

  /** animation methods */

  start = () => {
    const { data, config } = this.props;
    const winner = data[0];
     // need for debounce animation when update prize list
     if (!winner || this.animated) return;
    this.setToDefault();
    this.animated = true;
    this.delayHandler(() => { // before first notification
      /* audio */
      this.refSoundPrize.current.pause();
      this.refSoundAnnounce.current.currentTime = 0;
      const audioPlaySoundAnnounce = typeof (config && config.sounds && config.sounds.soundAnnounce) === 'undefined' ? DiceDataDefault.sounds.soundAnnounce : config.sounds.soundAnnounce;
      this.playPromiseSoundAnnounce = audioPlaySoundAnnounce && this.refSoundAnnounce.current.play();

      this.setState({
        notifications: [
          {
            id: 'start_' + winner.user,
            content: `${winner.user} tipped to roll the dice!${winner.currentRolls >= 2 ? ` x${winner.currentRolls}` : ''}`,
            // expiry: 4000,
          },
          // ...this.state.notifications,
        ],
      });

      this.delayHandler(() => { // before start first roll
        /* audio https://developers.google.com/web/updates/2017/06/play-request-was-interrupted*/
        if (this.playPromiseSoundAnnounce) {
          this.playPromiseSoundAnnounce.then(_ => {
            this.refSoundAnnounce.current.pause();
          })
          .catch(error => {
            if (bugsnagClient) bugsnagClient.notify('SoundAnnounce', error);
          });
        }
        this.refSoundRoll.current.currentTime = 0;
        const prizes = config && config.prizeList && config.prizeList.prizes || [];
        const audioPlaySoundRoll = typeof (config && config.sounds && config.sounds.soundRoll) === 'undefined' ? DiceDataDefault.sounds.soundRoll : config.sounds.soundRoll;
        this.playPromiseSoundRoll = (audioPlaySoundRoll && prizes.length !== 0) && this.refSoundRoll.current.play();

        this.choosePrizeAndStart();
      }, 3000, false);
    }, 2000, false);
  };

  chosenPrizeDelay = null;
  startListAnimation = (repeat) => {
    clearTimeout(this.chosenPrizeDelay);
    this.chosenPrizeDelay = null;
    this.chosen = null;

    const { notifications } = this.state;
    const { config: { prizeList: { prizes = [] } } } = this.props;

    /** RANDOM choose here */
    const chosen = Math.floor(Math.random() * prizes.length);
    this.chosen = chosen > (prizes.length - 1) ? prizes.length - 1 : chosen;
    // this.chosen = prizes.length - 1;


    this.setState(
      {
        rollList: false, // start (need for trigger animations)
        chosen: null,
        notifications: repeat ? [...notifications].slice(1) : notifications,
      },
      () => this.setState(
        {
          rollList: true,
        },
        () => {

          const oneRollTime = (prizes.length * animationConfig.delay);
          const numberOfFullRolls = Math.floor(animationConfig.rollTime / oneRollTime);
          this.chosenPrizeDelay = this.delayHandler(() => {
            this.setState({ chosen: this.chosen }, () => {
              clearTimeout(this.chosenPrizeDelay);
              this.chosenPrizeDelay = null;
              this.chosen = null;
            }); // set chosen prize to trigger to stop the roll on the prize
          }, oneRollTime * numberOfFullRolls); // animation specific
        },
      ),
    );
  };

  // executed for each roll
  choosePrizeAndStart = () => {
    const {
      data = [],
      renderShiftDiceData,
      config,
    } = this.props;
    const prizes = config && config.prizeList && config.prizeList.prizes || [];
    this.rollNumber = (this.rollNumber || this.rollNumber === 0) ? this.rollNumber + 1 : 0;
    if (!data.length || !prizes.length) return this.setToDefault(renderShiftDiceData);
    if (data[0].currentRolls <= this.rollNumber) { // ends rolls for user
      if (data[0].currentRolls) {
        this.finish();
        const notifications = [...this.state.notifications]
          .map(notification => (notification.id === 'start_' + data[0].user ? { ...notification, unmount: true } : notification));
        return this.setState({ rollList: false, chosen: null, notifications });
      }
      return this.setToDefault(renderShiftDiceData);
    }
    return this.startListAnimation();
  };

  onPrizeListAnimationStart = () => {
    const { config, data } = this.props;
    const prizes = config && config.prizeList && config.prizeList.prizes || [];
    const accentColorPrizeList = config && config.prizeList && config.prizeList.accentColor || {};
    const accentColorNotifications = config && config.notifications && config.notifications.accentColor || {};
    this.setState({
      notifications: [
        {
          id: data[0].user + '_prizes_' + this.rollNumber,
          content: prizes,
          chosen: this.chosen,
          color: getColor(accentColorPrizeList || DiceDataDefault.prizeList.accentColor),
          colorAccentNotification: getColor(accentColorNotifications || DiceDataDefault.notifications.accentColor),
        }, ...this.state.notifications],
    });
  };

  onPrizeListAnimationEnd = () => {
    const { config, data } = this.props;
    const accentColorPrizeList = config && config.prizeList && config.prizeList.accentColor || {};
    const notifications = [...this.state.notifications]
      .map(notification => (notification.id === data[0].user + '_prizes_' + this.rollNumber ? { ...notification, unmount: true } : notification));
      if (!config || !config.prizeList.prizes || !config.prizeList.prizes.length) return this.setToDefault();

    // if prize index not in range it will be the last
    const prize = config.prizeList.prizes[this.state.chosen] || config.prizeList.prizes[config.prizeList.prizes.length - 1];
    this.setState({
      notifications: [
        {
          id: data[0].user + '_winner_' + this.rollNumber,
          content: `${data[0].user}'s prize: ${prize}`,
          color: getColor(accentColorPrizeList || DiceDataDefault.prizeList.accentColor),
        }, ...notifications],
    });

    /* audio */

    if (this.playPromiseSoundRoll) {
      this.playPromiseSoundRoll.then(_ => {
        this.refSoundRoll.current.pause();
      })
      .catch(error => {
        if (bugsnagClient) bugsnagClient.notify('SoundRoll', error);
      });
    }

    this.refSoundPrize.current.currentTime = 0;
    const audioPlaySoundPrize = typeof (config && config.sounds && config.sounds.soundPrize) === 'undefined' ? DiceDataDefault.sounds.soundPrize : config.sounds.soundPrize;
    this.playPromiseSoundPrize = audioPlaySoundPrize && this.refSoundPrize.current.play();

    this.delayHandler(() => {
      this.choosePrizeAndStart();
    }, animationConfig.timeBetweenRolls);
  };

  onNotifUnmount = (id) => {
    const { notifications } = this.state;
    this.setState({ notifications: notifications.filter(((notif) => id !== notif.id)) });
  };

  finish = () => {
    const { config, renderShiftDiceData } = this.props;
    const prizeDuration = config && config.notifications && config.notifications.prizeDuration || 30
    this.delayHandler(() => {
      this.setToDefault(renderShiftDiceData)
    }
    , prizeDuration * 1000);
  };


  render() {
    const {
      config,
      isPreview,
      premium,
    } = this.props;
    if (!isPreview && !premium) return null;
    const diceDataDefault = config || {};
    const renderData = {
      widget: {
        ...DiceDataDefault.widget,
        ...(diceDataDefault.widget || {}),
      },
      prizeList: {
        ...DiceDataDefault.prizeList,
        ...(diceDataDefault.prizeList || {}),
      },
      notifications: {
        ...DiceDataDefault.notifications,
        ...(diceDataDefault.notifications || {}),
      },
      sounds: {
        ...DiceDataDefault.sounds,
        ...(diceDataDefault.sounds || {}),
      },
    };

    const notificationConfig = renderData.notifications;
    const prizeListConfig = renderData.prizeList;
    const widgetConfig = renderData.widget;


    const { rollList, chosen, notifications } = this.state;

    const lineHeight = Math.round(prizeListConfig.fontSize * 1.36);
    const scaleTransform = lineHeight * 0.05;

    const lineHeightRight = Math.round(notificationConfig.fontSize * 1.36);
    const scaleTransformRihtgContainer = lineHeightRight * 0.05;


    return (
      <Container screenPosition={widgetConfig.screenPosition} preview={isPreview}>
        <ContainerTitlePrizeList scaleTransform={scaleTransform}>
          <TitleContainer>
            <DiceComponent mini colorDice={prizeListConfig.backgroundColor} dotColor={prizeListConfig.color} screenPosition={widgetConfig.screenPosition} />
            <DiceComponent colorDice={prizeListConfig.accentColor} dotColor={prizeListConfig.color} screenPosition={widgetConfig.screenPosition} />
            <Title
              screenPosition={widgetConfig.screenPosition}
              customStyle={prizeListConfig}
            >
              <TextTitle customStyle={prizeListConfig}>
                {`Tip ${widgetConfig.amount} token${widgetConfig.amount.toString() !== '1' ? 's' : ''} to roll the dice! (max x${widgetConfig.rolls})`}
              </TextTitle>
            </Title>
          </TitleContainer>
          <PrizeListContainer hide={prizeListConfig.hide}>
            <PrizeList
              prizes={prizeListConfig.prizes}
              showDuration={notificationConfig.prizeDuration}
              screenPosition={widgetConfig.screenPosition}

              roll={rollList}
              chosen={chosen}
              onAnimationStart={this.onPrizeListAnimationStart}
              onAnimationEnd={this.onPrizeListAnimationEnd}

              customStyle={prizeListConfig}
            />
          </PrizeListContainer>
        </ContainerTitlePrizeList>
        <Announcement scaleTransform={scaleTransformRihtgContainer}>
          {notifications.map(({ content, id, ...rest }, ind) => (
            <StrokeComponent
              key={id}
              ind={ind}
              id={id}
              onUnmount={this.onNotifUnmount}
              customStyle={notificationConfig}
              screenPosition={widgetConfig.screenPosition}
              {...rest}
            >
              {content}
            </StrokeComponent>))}
        </Announcement>
        <audio ref={this.refSoundAnnounce} src={`/sounds/message_2.mp3`} />
        <audio ref={this.refSoundRoll} src={`/sounds/baraban.mp3`} />
        <audio ref={this.refSoundPrize} src={`/sounds/message_1.mp3`} />
      </Container>
    );
  }
}
