import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  background: #FFB65A;

  display: flex;
  justify-content: center;

  margin-bottom: 12px;
`;

export const Content = styled.div`
  display: flex;
  width: 1140px;
  height: 72px;

  justify-content: flex-start;
  align-items: center;
`;

export const Image = styled.div`
  width: 44px;
  height: 52px;
  background: url('/images/camera-off.svg') no-repeat;

  margin-right: 20px;
`;

export const Text = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  >span{
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  };

  color: ${({ theme }) => theme.darkTwo};
`;

const LinkButton = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.darkTwo} !important;

  padding: 8px 12px;

  background-color: #FFD9A8;
  border-radius: 4px;

  outline:none !important;
  border: none !important;

  &:hover{
    background-color: #FFF0DB;
  }
  &:active{
    background-color: #FFC375;
  }

`;

export const InnerLinkButton = styled(Link)`${LinkButton}`;

export const ContainerInnerLink = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`;

export const RemoveNotification = styled.button`
  margin-left: 18px;

  width: 14px;
  height: 14px;

  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.darkTwo};
  mask-image: url('/images/X.svg');
  mask-repeat: no-repeat;
  mask-position: center;

  &:hover{
    background-color: #31424E;
  }
  &:active{
    background-color: #0A0D10;
  }
`;
