/* eslint-disable */

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/analytics";
import { v4 as uuid } from "uuid";
import deepEqual from "deep-equal";
import md5 from "md5";

import {
  authSignOut,
  authFirebaseSignIn,
  authUpdateLastActivity,
  authSetDonationStatus,
  authSetUserFields,
} from "store/reducers/auth";
import { widgetsLoad, widgetsActiveUpdate } from "store/reducers/widgets";
import {
  renderWidgetData,
  renderWidgetPush,
  renderWidgetItemRemove,
  renderAddUsers,
} from "store/reducers/render";
import { animationDuration } from "utils";
import { bugsnagClient } from "utils/bugsnag";
import { TipAlertDataDefault, widgets, raceDataDefault } from "constants";
import store, { persistor } from "../store";

const config = {
  appId: "1:993682070794:web:c8826dc51a4023b1faeef3",
  apiKey: "AIzaSyDWb6sxjGyLcGuPHsDjdG1CfvycB_25VF4",
  authDomain: "tipalerts-94db4.firebaseapp.com",
  databaseURL: "https://tipalerts-94db4.firebaseio.com",
  projectId: "tipalerts-94db4",
  storageBucket: "tipalerts-94db4.appspot.com",
  messagingSenderId: "993682070794",
  measurementId: "G-7HJPN25XML"
};
firebase.initializeApp(config);

const fbFullUpdates = [
  widgets.tips.id,
  widgets.goals.id,
  { fb: `${widgets.top.id}-users`, state: `${widgets.top.id}Users`},
  widgets.top.id,
  widgets.dice.id,
];

let userJustSignUp = false;

const updateWidgetsInState = (type) => (snapshot) => {
  const fbWidget = snapshot.val();
  const stateWidget = store.getState().widgets[type];
  if (!deepEqual(fbWidget, stateWidget)) {
    store.dispatch(widgetsLoad({ [type]: fbWidget }));
  }
};

const firebaseSubscribe = (streamAccessToken, email) => {
  fbFullUpdates.forEach((item = widgets.tips.id) => {
    const fbName = item.fb || item;
    const stateName = item.state || item;
    firebase
      .database()
      .ref(`widgets/${streamAccessToken}/${fbName}`)
      .on("value", updateWidgetsInState(stateName));
  });
  firebase
    .database()
    .ref(`widgets/${streamAccessToken}/race`)
    .on("value", (snapshot) => {
      const fbWidget = snapshot.val() || {};
      const defaultFBWidget = fbWidget.default || {};
      const stateWidget = store.getState().widgets.race;
      if (!deepEqual(fbWidget, stateWidget)) {
        store.dispatch(widgetsLoad({
          race: {
            default: {
              active: {
                ...raceDataDefault.active,
                ...(defaultFBWidget.active || {}),
              },
              inactive: {
                ...raceDataDefault.inactive,
                ...(defaultFBWidget.inactive || {}),
              },
              preset: {
                ...raceDataDefault.preset,
                ...(defaultFBWidget.preset || {}),
              },
              appearance: {
                ...raceDataDefault.appearance,
                ...(defaultFBWidget.appearance || {}),
              },
            },
          },
        }));
      }
    });
  firebase
    .database()
    .ref(`widgets/${streamAccessToken}/streamState`)
    .on("value", s => {
      const streamStates = s.val() || {};
      store.dispatch(widgetsLoad({ streamStates }));
    });
  firebase
    .database()
    .ref(`lastActivity/${streamAccessToken}`)
    .on("value", s => {
      const val = s.val();
      if (val) {
        store.dispatch(authUpdateLastActivity(val));
      }
    });
  firebase
    .database()
    .ref(`payments/${md5(email)}`)
    .on("value", s => {
      store.dispatch(authSetDonationStatus(s.val()));
    });
};

const firebaseUnsubscribeByAccessToken = streamAccessToken => {
  firebase
    .database()
    .ref(`widgets/${streamAccessToken}/${widgets.tips.id}`)
    .off("value");
  firebase
    .database()
    .ref(`lastActivity/${streamAccessToken}`)
    .off("value");
};

if (window.location.pathname.search(/\/w\//gi) === -1) {
  let SUBSCRIBED = false;
  firebase.auth().onAuthStateChanged(user => {
    try {
      if (!user) {
        store.dispatch(authSignOut());
      } else {
        const user = firebase.auth().currentUser;
        const userFields = {
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified,
          uid: user.uid,
        };
        if (user.metadata && user.metadata.creationTime) {
          userFields.creationTime = user.metadata.creationTime;
        }
        if (window.$crisp) {
          $crisp.push(["set", "user:email", user.email]);
          $crisp.push(["set", "user:nickname", user.email]);
        }
        ga("set", "userId", user.uid); //eslint-disable-line
        ga("set", "ta-userId", user.uid); //eslint-disable-line
        if (bugsnagClient) {
          bugsnagClient.user = {
            ...(bugsnagClient.user || {}),
            ...userFields
          };
        }
        store.dispatch(authFirebaseSignIn(userFields));
        let prevUserKeys = [];
        firebase
          .database()
          .ref(`users/${user.uid}`)
          .on("value", (snapshot,) => {
            const userProfile = snapshot.val();
            if (!userProfile) {
              if (userJustSignUp) {
                console.log('User just sign up, profile can be empty..skip check..');
                return;
              }
              return firebaseGenerateStreamAccessToken().then(() => {
                alert("Sorry, we have some troubles, please login again!");
                firebaseSignOut()
                  .then(() => persistor.purge())
                  .then(() => (window.location.pathname = "/auth"));
              });
            } else {
              userJustSignUp = false;
            }
            const {
              auth: {
                user: stateUser,
              }
            } = store.getState();
            if (!stateUser) {
              return;
            }
            const { currentProfile, profiles } = stateUser;
            let streamAccessToken;
            if (currentProfile) {
              streamAccessToken = profiles[currentProfile].streamAccessToken;
            } else {
              const profilesList = Object.values(profiles || {});
              if (profilesList.length)
                streamAccessToken = profilesList[0].streamAccessToken;
            }
            // const { streamAccessToken } = currentProfile ? profiles[currentProfile] : Object.values(profiles || {})[0];
            const { streamAccessToken: firebaseToken } = userProfile.currentProfile
              ? userProfile.profiles[userProfile.currentProfile]
              : Object.values(userProfile.profiles || {})[0];
            // if (userProfile.streamAccessToken) {
            //   const emailRef = firebase
            //     .database()
            //     .ref(`widgets/${userProfile.streamAccessToken}/email`);
            //   emailRef.once('value', (s) => {
            //     const email = s.val();
            //     if (!email || email !== user.email) {
            //       emailRef.set(user.email);
            //     }
            //   });
            // }
            if (streamAccessToken !== firebaseToken || !SUBSCRIBED) {
              SUBSCRIBED = true;
              firebaseSubscribe(firebaseToken, user.email);
            }
            if (bugsnagClient) bugsnagClient.user.streamAccessToken = firebaseToken;
            // this is required to delete keys in store that deleted in the firebase
            try {
              const deletedFiels = {};
              let mergeRequired = false;
              prevUserKeys.forEach((key) => {
                if (typeof userProfile[key] === 'undefined') {
                  deletedFiels[key] = undefined;
                  mergeRequired = true;
                }
              });
              let userProfileValueFinal = userProfile;
              if (mergeRequired) {
                userProfileValueFinal = {
                  ...userProfile,
                  ...deletedFiels,
                };
              }
              prevUserKeys = Object.keys(userProfile);
              store.dispatch(authSetUserFields(userProfileValueFinal));
            } catch (e) {
              bugsnagClient.notify(e, { metaData: { profileUpdateError: true } });
            }
          });
      }
    } catch(e) {
      if (bugsnagClient) bugsnagClient.notify(e);
      console.log(e);
      store.dispatch(authSignOut());
    }
  });
}

export const firebaseSignUp = (email, password) => {
  userJustSignUp = true;
  return firebase.auth().createUserWithEmailAndPassword(email, password);
}

export const firebaseSignIn = (email, password) =>
  firebase.auth().signInWithEmailAndPassword(email, password);

export const firebaseUpdateProfile = (profile, fields) => {
  const user = firebase.auth().currentUser;
  return firebase
    .database()
    .ref(`users/${user.uid}/profiles`)
    .child(profile)
    .update(fields);
};

export const firebaseUpdateCurrentProfile = (fields, result) => {
  const { currentProfile } = store.getState().auth.user;
  return firebaseUpdateProfile(currentProfile, fields);
};

export const firebaseDeleteServiceId = (fields) => {
  const { currentProfile, uid } = store.getState().auth.user;
  return firebase
    .database()
    .ref(`users/${uid}/profiles/${currentProfile}/${fields}`)
    .remove()
};

export const firebaseGenerateStreamAccessToken = (targetProfileId, _user) => {
  const { currentProfile, profiles, uid, email } = _user || store.getState().auth.user;
  // if currentProfile is not defined user is just registered
  if (!currentProfile) {
    const streamAccessToken = uuid();
    const profileId = firebase
      .database()
      .ref(`users/${uid}/profiles`)
      .push({ streamAccessToken }).key;
    firebase
      .database()
      .ref(`users/${uid}/currentProfile`)
      .set(profileId);
    return firebase
      .database()
      .ref(`widgets/${streamAccessToken}`)
      .update({
        user: uid,
        email
      });
  }
  // targetProfileId used when user adds new profile and we need just generate token
  const profileId = targetProfileId ? targetProfileId : currentProfile;
  const { streamAccessToken } = profiles[profileId];
  const newStreamAccessToken = uuid();
  firebaseUpdateProfile(profileId, { streamAccessToken: newStreamAccessToken });
  if (!streamAccessToken) {
    return firebase
      .database()
      .ref(`widgets/${newStreamAccessToken}`)
      .update({
        user: uid,
        email
      });
  }
  // move widget options to new token
  return firebase
    .database()
    .ref(`widgets/${streamAccessToken}`)
    .once("value", s => {
      firebase
        .database()
        .ref(`widgets/${newStreamAccessToken}`)
        .set(s.val())
        .then(() => {
          firebase
            .database()
            .ref(`widgets/${newStreamAccessToken}`)
            .update({
              user: uid,
              email
            });
        });
      firebaseUnsubscribeByAccessToken(streamAccessToken);
      firebase
        .database()
        .ref(`widgets/${streamAccessToken}`)
        .remove();
    });
};

export const firebaseWidgetUpdate = (widgetId, payload, streamAccessToken) =>
  firebase
    .database()
    .ref("widgets")
    .child(streamAccessToken)
    .child(widgetId)
    .update(payload);

export const firebaseWidgetComponentUpdate = ({ widgetId, componentName, payload, streamAccessToken, variation }) =>
  firebase
    .database()
    .ref("widgets")
    .child(streamAccessToken)
    .child(widgetId)
    .child(variation)
    .child(componentName)
    .update(payload);

export const firebaseWidgetComponentMoveTo = (fromRef, toRef, updateOptions) => {
  const db = firebase.database();
    db.ref(fromRef)
    .once('value', (s) => {
      const data = s.val();
      if (data) {
        db.ref(fromRef).set(null).then(() => {
          db
            .ref(toRef)
            .set({ ...data, ...(updateOptions || {}) })
        });
      }
    }).catch(e => console.log(e));
};

const calculateLvlPrice = (tokensAtRace, currentLvl, maxLvl = 35) => {
  const firstLevelPrice = 1 / maxLvl * ((tokensAtRace - maxLvl) * 2 / maxLvl);
  return Math.round(currentLvl / maxLvl * ((tokensAtRace - maxLvl) * 2 / maxLvl) + 1 - firstLevelPrice / 2);
};

export const firebaseBuyRace = (_name, streamAccessToken) => {
  const name = encodeURIComponent(_name).replace(/\./g, '%2E');
  return firebase
    .database()
    .ref(`widgets/${streamAccessToken}/race/default`)
    .transaction((data) => {
      if (data && Object.keys(data.active || {}).length >= Number((data.preset && data.preset.playersNumber) || raceDataDefault.preset.playersNumber)) {
        return data;
      }
      const startedAt = Date.now();
      if (!data) {
        data = {
          active: {
            [name]: {
              amount: raceDataDefault.preset.basePrice,
              amountAtLevel: 0,
              levelPrice: calculateLvlPrice(raceDataDefault.preset.tokensAtRace, raceDataDefault.preset.startLevel, raceDataDefault.preset.maxGrowLevel),
              level: raceDataDefault.preset.startLevel,
              name,
              startedAt,
              updatedAt: startedAt,
              expireAt: startedAt + raceDataDefault.preset.timeToDestroy * 1000,
            },
          },
        };
      } else if (!data.active) {
        const {
          basePrice,
          tokensAtRace,
          startLevel,
          maxGrowLevel,
          timeToDestroy,
        } = {
          ...raceDataDefault.preset,
          ...(data.preset || {}),
        };
        data.active = {
          [name]: {
            amount: basePrice,
            amountAtLevel: 0,
            levelPrice: calculateLvlPrice(tokensAtRace, startLevel, maxGrowLevel),
            level: startLevel,
            name,
            startedAt,
            updatedAt: startedAt,
            expireAt: startedAt + timeToDestroy * 1000,
          },
        };
      } else if (!data.active[name]) {
        const {
          basePrice,
          tokensAtRace,
          startLevel,
          maxGrowLevel,
          timeToDestroy,
        } = {
          ...raceDataDefault.preset,
          ...(data.preset || {}),
        };
        data.active[name] = {
          amount: basePrice,
          amountAtLevel: 0,
          levelPrice: calculateLvlPrice(tokensAtRace, startLevel, maxGrowLevel),
          level: startLevel,
          name,
          startedAt,
          updatedAt: startedAt,
          expireAt: startedAt + timeToDestroy * 1000,
        };
      }
      if (data && data.inactive && data.inactive[name]) {
        data.inactive[name] = null;
      }
      return data;
    });
};

export const firebaseIncreaseRaceAmount = (name, _tips, streamAccessToken) => {
  const tips = Number(_tips || 0);
  return firebase
    .database()
    .ref(`widgets/${streamAccessToken}/race/default`)
    .transaction((data) => {
      if (data) {
        const updatedAt = Date.now();
        const {
          basePrice,
          tokensAtRace,
          startLevel,
          maxGrowLevel,
          timeToDestroy,
        } = {
          ...raceDataDefault.preset,
          ...(data.preset || {}),
        };
        const expireAt = updatedAt + timeToDestroy * 1000;
        if (data.inactive && data.inactive[name]) {
          const {
            amount,
            level: _level,
            amountAtLevel,
            levelPrice: _levelPrice,
          } = data.inactive[name];
          let levelPrice = _levelPrice;
          let level = +(_level || startLevel);
          if (!levelPrice) levelPrice = calculateLvlPrice(tokensAtRace, level, maxGrowLevel);
          let amountAndAmountAtLevel = +(amountAtLevel || 0) + tips;
          let restAmount = amountAndAmountAtLevel - levelPrice;
          while(restAmount >= 0) {
            level += 1;
            amountAndAmountAtLevel = restAmount;
            if (level === maxGrowLevel) {
              let summ = 0;
              for(let l = 1; l < maxGrowLevel; l++) {
                summ += calculateLvlPrice(tokensAtRace, l, maxGrowLevel);
              }
              levelPrice = tokensAtRace - summ;
              if (levelPrice <= 0) levelPrice = 1;
            } else {
              levelPrice = calculateLvlPrice(tokensAtRace, level, maxGrowLevel);
            }
            restAmount = amountAndAmountAtLevel - levelPrice;
          }
          const newActiveData = {
            ...data.inactive[name],
            amount: +(amount || basePrice) + tips,
            amountAtLevel: amountAndAmountAtLevel,
            levelPrice,
            level,
            name,
            updatedAt,
            expireAt,
          };
          if (!data.active) data.active = { [name]: newActiveData };
          else data.active[name] = newActiveData;
          data.inactive[name] = null;
        } else if (data.active && data.active[name]) {
          const {
            amount,
            level: _level,
            amountAtLevel,
            levelPrice: _levelPrice,
          } = data.active[name];
          let levelPrice = _levelPrice;
          let level = +(_level || startLevel);
          if (!levelPrice) levelPrice = calculateLvlPrice(tokensAtRace, level, maxGrowLevel);
          let amountAndAmountAtLevel = +(amountAtLevel || 0) + tips;
          let restAmount = amountAndAmountAtLevel - levelPrice;
          while(restAmount >= 0) {
            level += 1;
            amountAndAmountAtLevel = restAmount;
            if (level === maxGrowLevel) {
              let summ = 0;
              for(let l = 1; l < maxGrowLevel; l++) {
                summ += calculateLvlPrice(tokensAtRace, l, maxGrowLevel);
              }
              levelPrice = tokensAtRace - summ;
              if (levelPrice <= 0) levelPrice = 1;
            } else {
              levelPrice = calculateLvlPrice(tokensAtRace, level, maxGrowLevel);
            }
            restAmount = amountAndAmountAtLevel - levelPrice;
          }
          data.active[name] = {
            ...data.active[name],
            amount: +(amount || basePrice) + tips,
            amountAtLevel: amountAndAmountAtLevel,
            levelPrice,
            level,
            name,
            updatedAt,
            expireAt,
          };
        }
      }
      return data;
    })
};

export const firebaseIncreaseRaceTime = (name, _time, streamAccessToken) => {
  const time = Number(_time || 0);
  return firebase
    .database()
    .ref(`widgets/${streamAccessToken}/race/default`)
    .transaction((data) => {
      if (data && data.inactive && data.inactive[name]) {
        const newActiveData = {
          ...data.inactive[name],
          name,
          expireAt: Date.now() + (time * 1000),
        };
        if (!data.active) data.active = { [name]: newActiveData };
        else data.active[name] = newActiveData;
        data.inactive[name] = null;
      } else if (data && data.active && data.active[name]) {
        data.active[name] = {
          ...data.active[name],
          name,
          expireAt: data.active[name].expireAt + (time * 1000),
        }
      }
      return data;
    })
};

export const firebaseWidgetReset = (widgetId, streamAccessToken) =>
  firebase
    .database()
    .ref(`widgets/${streamAccessToken}/${widgetId}`)
    .set(null);

let lastTip = new Date();

const addWidgetEvent = payload => {
  return;
  const {
    render: { items, data },
    auth: { supporter }
  } = store.getState();
  if (Object.keys(items || {}).length >= 3) {
    setTimeout(() => addWidgetEvent(payload), 2000);
  } else {
    lastTip = new Date();
    store.dispatch(renderWidgetPush(payload));
    let dataForAmount = (data && data.default && data.default.preset || {});
    if (supporter.active && data) {
      for (const key in data) {
        if (!data.hasOwnProperty(key) || !data[key].preset || (data[key].preset.enabled !== undefined && data[key].preset.enabled !== true)) continue;
        const amountVariation = data[key].preset;
        const { amount } = payload.item;
        if (amount >= amountVariation.from && amount <= amountVariation.to) {
          dataForAmount = amountVariation;
        }
      }
    }
    setTimeout(
      () => {
        store.dispatch(renderWidgetItemRemove(payload.id));
      },
      (animationDuration({
        ...TipAlertDataDefault,
        ...dataForAmount,
        ...payload.item
      }) * 1000) + 2000
    );
  }
};
let isConnectionInit = false;
let isConnectionLoosed = false;
export const firebaseGetWidget = (streamAccessToken, widgetId) => {
  const connectionStatusRef = firebase.database().ref('.info/connected');
  connectionStatusRef.on('value', s => {
    const isConnected = s.val();
    if (isConnectionLoosed && isConnected) {
      isConnectionLoosed = false;
      console.log('Rendered widget connection re-established.')
      if (bugsnagClient) {
        bugsnagClient.notify('Rendered widget connection re-established.');
      }
    }
    if (isConnectionInit) {
      if (!isConnected) {
        if (window.location.pathname.indexOf('/w/') !== -1) {
          isConnectionLoosed = true;
          console.log('Rendered widget loose connection!');
          if (bugsnagClient) {
            if (!bugsnagClient.user) {
              bugsnagClient.user = {};
            }
            bugsnagClient.user.streamAccessToken = streamAccessToken;
            if (!bugsnagClient.metaData) {
              bugsnagClient.metaData = {};
            }
            bugsnagClient.metaData.widgetId = widgetId;
            bugsnagClient.notify('Rendered widget loose connection!');
          }
        }
      }
    } else {
      if (isConnected) {
        isConnectionInit = true;
      }
    }
  });
  firebase
    .database()
    .ref(`widgets/${streamAccessToken}/email`)
    .once("value", s => {
      const email = s.val();
      if (!email) return;
      if (bugsnagClient) {
        bugsnagClient.user.email = email;
      }
      firebase
        .database()
        .ref(`payments/${md5(email)}`)
        .on("value", snap => {
          store.dispatch(authSetDonationStatus(snap.val()));
        });
    });
  firebase
    .database()
    .ref(`widgets/${streamAccessToken}/${widgetId}`)
    .on("value", s => {
      const widgetData = s.val();
      return store.dispatch(renderWidgetData(widgetData));
    });
  if (widgetId === widgets.top.id) {
    firebase
      .database()
      .ref(`widgets/${streamAccessToken}/${widgetId}-users`)
      .on("value", s => {
        store.dispatch(renderAddUsers(`${widgetId}Users`, s.val()));
      });
  }
};

export const firebaseSignOut = () => {
  $crisp.push(["do", "session:reset"]);
  return firebase.auth().signOut();
}

export const firebaseSetPassword = ({ email, oldPassword, password }) =>
  new Promise((resolve, reject) => {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      oldPassword
    );
    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        firebase
          .auth()
          .currentUser.updatePassword(password)
          .then(resolve)
          .catch(reject);
      })
      .catch(reject);
  });

export const firebaseResetPassword = email =>
  firebase.auth().sendPasswordResetEmail(email);

export const firebaseSubscribeToActiveWidgets = () => {
  const retrieveData = s => {
    const accessToken = s.key;
    const date = s.val();
    firebase
      .database()
      .ref(`widgets/${accessToken}/user`)
      .once("value", s => {
        const userId = s.val();
        if (userId) {
          firebase
            .database()
            .ref(`users/${userId}`)
            .once("value", s => {
              store.dispatch(
                widgetsActiveUpdate(userId, { lastActivity: date, ...s.val() })
              );
            });
        }
      });
  };
  firebase
    .database()
    .ref("lastActivity")
    .on("child_added", retrieveData);
  firebase
    .database()
    .ref("lastActivity")
    .on("child_changed", retrieveData);
};

export const firebaseUploadUserImage = (userId, file) => {
  const fileUuid = uuid();
  const storageRef = firebase.storage().ref();
  const imageRef = storageRef.child(`image-${fileUuid}`);
  return imageRef
    .put(file)
    .then(() => {
      firebase
        .database()
        .ref(`users/${userId}/files`)
        .push({ id: fileUuid, date: new Date().toUTCString() });
    })
    .catch(e => alert(e));
};


export const firebaseUploadUserFile = (userId, file, fileType) => {
  const fileUuid = uuid();
  const storageRef = firebase.storage().ref();
  const imageRef = storageRef.child(`${fileType}-${fileUuid}`);
  return imageRef
    .put(file)
    .then(() => {
      firebase
        .database()
        .ref(`users/${userId}/uploads/${fileType}`)
        .push({
          id: fileUuid,
          date: new Date().toUTCString(),
          fileType,
          title: file.name
        });
    })
    .catch(e => alert(e));
};

export const firebaseDeleteUserFile = (userId, fileid, file, fileType) => {
  /**
   * delete file disabled because it also used in new version
   */
  // const storageRef = firebase.storage().ref();
  // const deleteRef = storageRef.child(`${fileType}-${fileid}`);
  // deleteRef.delete()
  firebase
    .database()
    .ref( (fileType === 'image')
      ? `users/${userId}/files/`
      : `users/${userId}/uploads/sound`)
    .child(`${file}`)
    .remove()
}

export const firebaseUserSettingsUpdate = (userId, widgetId, payload) => {
  firebase
    .database()
    .ref("users")
    .child(userId)
    .child("settings")
    .child(widgetId)
    .update(payload);
}

export const firebaseChangeTokensRate = (userId, service, rate) => {
  return firebase.database().ref(`users/${userId}/serviceRate/${service}`).set(rate);
};

export const firebaseWidgetsAddItem = (accessToken, widgetId, item) =>
  firebase
    .database()
    .ref(`widgets/${accessToken}/${widgetId}-items`)
    .push(item);

export const firebaseSetRenewSubscription = (userId, renewSubscription) =>
  firebase
    .database()
    .ref(`users/${userId}/renewSubscription`)
    .set(renewSubscription);

export const firebaseSignWithToken = token => {
  return firebase.auth().signOut().then(()  => firebase.auth().signInWithCustomToken(token));
}

  export const authLogs = () => {
  let int;
  return new Promise((resolve, reject) => {
    if (firebase.auth().currentUser) {
      firebase.auth().currentUser.getIdToken().then(resolve).catch(reject);
    } else {
      int = setInterval(() => {
        if (firebase.auth().currentUser) {
          firebase.auth().currentUser.getIdToken().then(resolve).catch(reject);
          clearInterval(int);
        }
      }, 1000);
    }
  });
};

export const fbGetIdToken = () => firebase.auth().currentUser.getIdToken(true);

export const firebaseGetPaymentDataByToken = streamAccessToken => new Promise((resolve, reject) => {
  firebase
  .database()
  .ref(`widgets/${streamAccessToken}/email`)
  .once("value", s => {
    const email = s.val();
    if (!email) {
      return reject();
    }
    firebase
      .database()
      .ref(`payments/${md5(email)}`)
      .once("value", snap => {
        resolve(snap.val());
      });
  });
});

export const firebaseSendEmailVerification = () => firebase.auth().currentUser.sendEmailVerification({ url: 'https://tipalerts.com/price' });
