import { randInt } from 'utils';

export const animationPartOfWidget = [
  'classic',
  'bounce',
  'flash',
  'pulse',
  'rubberBand',
  'shake',
  'swing',
  'tada',
  'wobble',
  'jello',
  'heartBeat',
  'random',
];

export const getAnimation = (animation) => {
  if (animation === 11 || animation === 'random' || typeof animation === 'undefined') {
    return animationPartOfWidget[randInt(1, animationPartOfWidget.length)];
  }
  if (typeof animation === 'number') {
    return animationPartOfWidget[animation];
  }
  return animation;
};

export const animationWidgets = [
  'bottom-top',
  'top-bottom',
  'left-right',
  'right-left',
  'fade-in-out',
  'full-screen',
  'fadeInUp',
  'bounceIn',
  'bounceInLeft',
  'bounceInRight',
  'bounceInDown',
  'bounceInUp',
  'zoomIn',
  'jackInTheBox',
  'zoomInDown',
  'zoomInUp',
  'random',
  'none',
];

export const getAnimationWidgets = (animation) => {
  if (animation === 16 || animation === 'random' || typeof animation === 'undefined') {
    return animationWidgets[randInt(1, animationWidgets.length)];
  }
  return animationWidgets[animation];
};
