import React, { useEffect } from 'react';

export const NotFound = () => {
  useEffect(() => {
    // в AuthContainer проверка сделана так что если пользователь заходит на tipalerts.com/w/ то отрабатывает роутинг
    // и показывается белый экран с надписью Not Found
    // чтобы при каждой загрузке не делать лишнюю проверку сделал её здесь
    if (window.location.pathname.indexOf('/w/') !== -1) {
      window.location = window.location.origin;
    }
  });
  return (
    <div>Not Found</div>
  );
};
