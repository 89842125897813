import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

import arrowLeft from 'assets/images/arrowLeft.svg';
import icCreate from 'assets/images/ic-create.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.dark};
`;

const TabItem = styled.button`
  outline: none;
  min-width: 64px;
  height: 36px;
  padding: 2px 12px 0px 12px;
  border: 1px solid #FFB65A;
  color: #FFB65A;
  background-color: transparent;
  flex-shrink: 0;
  border-right-width: 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  ${({ active }) => active
    && css`
        color: hsl(205, 19%, 25%);
    `};

  ${({ active, enabled }) => (active && enabled)
    && css`
        background-color: #FFB65A;
    `};

  ${({ active, enabled }) => ((active && !enabled)
    && css`
        background-color: hsl(0, 0%, 77%);
        border: 1px solid hsl(0, 0%, 77%);
    `)}


  ${({ active, enabled }) => !active && enabled && css`
    &:hover {
      background-color: rgba(255, 182, 90, 0.15);
    }
  `}

  ${({ active, enabled }) => !active && !enabled && css`
    &:hover {
      background-color: hsla(0, 0%, 77%, 0.15);
    }
  `}

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-left: 17px;
  }
  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-width: 1px;
  }

  ${({ enabled, active }) => (!enabled && !active
  && css`
      border: 1px solid hsl(0, 0%, 77%);
      color: hsl(0, 0%, 77%);
    `
  )}
`;

const Title = styled.h3`
  margin-right: 20px;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 26px;
  font-weight: bold;
  line-height: 34px;
`;

const ActionButton = styled.button`
  height: 34px;
  outline: none;
  padding: 7px 12px;
  margin-left: 20px;
  border-radius: 4px;
  border: none;
  background-color: #6F8291;
  color: ${({ theme }) => theme.white};
  font-weight: 600;

  & img {
    margin-top: -1px;
  }

  ${({ disabled }) => !disabled && css`
    &:hover {
      background-color: #7E92A0;
    }
    &:active{
      background-color: #334553;
    }
  `}

  ${({ disabled }) => disabled && css`
    opacity: 0.54;
  `}
`;

const TabsContainer = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  ${({ justifyContentCenter }) => justifyContentCenter && css`
    justify-content: center;
  `}
`;

const TabScrollButton = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: rgba(255, 182, 90, 0.15);
  border: none;
  position: relative;
  outline: none;
  background-image: url(${arrowLeft});
  background-position: center;
  background-repeat: no-repeat;
  background-position-x: 9px;
  margin-right: 10px;
  ${({ toLeft }) => !toLeft && css`
    transform: rotate(180deg);
    margin-right: 0;
    margin-left: 10px;
  `}
  &:hover {
    background-color: rgba(255, 182, 90, 0.2);
  }
  &:active {
    background-color: rgba(255, 182, 90, 0.1);
  }
  &:after {
    pointer-events: none;
    position: absolute;
    content: '';
    width: 20px;
    height: 36px;
    ${({ toLeft }) => css`
      left: ${(toLeft ? '37px' : '-30px')};
      background: linear-gradient(to ${toLeft ? 'left' : 'right'}, rgba(0,0,0,0) 0%, rgb(67, 92, 109) 100%);
      ${!toLeft && css`
        transform: rotate(180deg) translateX(-68px);
      `}
    `};
    top: -4px;
  }
  &:focus {
    outline: none;
  }
`;

const InvisiblePad = styled.div`
  width: 15px;
  flex-shrink: 0;
  opacity: 0;
`;

const TokensAmount = styled.div`
  text-align: center;
  margin-bottom: 4px;
  margin-left: -16px;
`;

type Props = {
  title: string,
  actionButtonTitle: string,
  items: array,
  itemsTitleKey: string,
  active: number,
  onChangeTab: () => void,
  onActionButtonClick: () => void,
};

type State = {
  scrollButtonPressed: 'left' | 'right' | false,
  showScrollButtons: boolean,
};

export class TabsBar extends PureComponent<Props, State> {
  state = {
    scrollButtonPressed: false,
    showScrollButtons: false,
  };

  lastScrollWidth = -1;

  scrollDetectorInterval;

  componentDidMount() {
    this.scrollDetectorInterval = setInterval(() => this.detectScrollWidthChange(), 200);
  }

  componentWillUnmount() {
    clearInterval(this.scrollDetectorInterval);
  }

  detectScrollWidthChange = () => {
    if (this.scrollRef) {
      if (this.scrollRef.scrollWidth !== this.lastScrollWidth) {
        this.onScrollWidthChanged(this.scrollRef.scrollWidth, this.lastScrollWidth);
        this.lastScrollWidth = this.scrollRef.scrollWidth;
      }
    }
  };

  onScrollWidthChanged = (scrollWidth: number) => {
    const newShowScrollButtons = this.scrollRef.clientWidth < scrollWidth;
    const { showScrollButtons } = this.state;
    if (showScrollButtons !== newShowScrollButtons) {
      this.setState({ showScrollButtons: newShowScrollButtons });
    }
  };

  onButtonScrollKeyDown = (button: 'left' | 'right') => (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      const { scrollButtonPressed } = this.state;
      if (scrollButtonPressed === button && this.lastEventShiftKey === e.shiftKey) return;
      this.resetDownInterval();
      this.lastEventShiftKey = e.shiftKey;
      const speed = e.shiftKey ? 20 : 5;
      this.scrollButtonDown(button, speed)();
    }
  };

  setScrollButtonPressed = (button: 'left' | 'right') => {
    this.setState({ scrollButtonPressed: button });
  };

  setScrollButtonUnpressed = () => this.setState({ scrollButtonPressed: false });

  lastEventShiftKey = false;

  downInterval;

  speedIncreaseByPressTime = 0;

  calcScrollSpeedAccelerated = (baseSpeed = 5) => {
    const { items = [] } = this.props;
    const accelerationStartedAt = 10;
    const acceleration = (
      items.length < accelerationStartedAt ? 1 : items.length / accelerationStartedAt
    );
    return baseSpeed * acceleration;
  };

  tabsScrollRight = (speed: number = 5) => {
    this.scrollRef.scrollLeft += this.calcScrollSpeedAccelerated(speed);
  };

  tabsScrollLeft = (speed: number = 5) => {
    this.scrollRef.scrollLeft -= this.calcScrollSpeedAccelerated(speed);
  };

  scrollButtonDown = (button: 'left' | 'right', speed: number = 8) => () => {
    this.setScrollButtonPressed(button);
    this.downInterval = setInterval(() => {
      const { scrollButtonPressed } = this.state;
      if (!scrollButtonPressed) {
        return this.resetDownInterval();
      }
      if (scrollButtonPressed === 'right') {
        this.tabsScrollRight(speed + Math.floor(this.speedIncreaseByPressTime));
      } else if (scrollButtonPressed === 'left') {
        this.tabsScrollLeft(speed + Math.floor(this.speedIncreaseByPressTime));
      }
      const { scrollLeft, clientWidth, scrollWidth } = this.scrollRef;
      const startDecreasingAt = 0.2;
      const leftDecreasingPoint = startDecreasingAt;
      const rightDecreasingPoint = 1 - startDecreasingAt;
      const scrollPositionPercentage = (scrollLeft + (button === 'right' ? clientWidth : 0)) / scrollWidth;
      if (
        (button === 'left' && scrollPositionPercentage < leftDecreasingPoint)
        || (button === 'right' && scrollPositionPercentage > rightDecreasingPoint)
      ) {
        this.speedIncreaseByPressTime = this.speedIncreaseByPressTime <= 0 ? 0 : this.speedIncreaseByPressTime - 1;
      } else {
        this.speedIncreaseByPressTime += 0.5;
      }
      return null;
    }, 50);
  };

  resetDownInterval = () => {
    clearInterval(this.downInterval);
    this.downInterval = null;
    this.speedIncreaseByPressTime = 0;
  };

  onTabsWheel = (e: WheelEvent) => {
    e.preventDefault();
    this.scrollRef.scrollLeft += e.deltaX;
  }

  render() {
    const {
      title,
      actionButtonTitle,
      items = [],
      itemsTitleKey,
      active = 0,
      onChangeTab,
      onActionButtonClick,
      supporter,
    } = this.props;
    const { showScrollButtons } = this.state;
    const basic = supporter && supporter.basic && !(supporter.special === 'trial') && !supporter.active;
    return (
      <div>
        <TokensAmount>Tokens amount</TokensAmount>
        <Container>
          <Title>{title}</Title>
          {showScrollButtons && (
          <TabScrollButton
            toLeft
            // onClick={this.tabsScrollLeft}
            onMouseDown={this.scrollButtonDown('left')}
            onMouseUp={this.setScrollButtonUnpressed}
            onMouseLeave={this.setScrollButtonUnpressed}
            onKeyDown={this.onButtonScrollKeyDown('left')}
            onKeyUp={this.setScrollButtonUnpressed}
          />)}
          <TabsContainer
            ref={r => (this.scrollRef = r)}
            onWheel={this.onTabsWheel}
            justifyContentCenter={!showScrollButtons}
          >
            {items.map((item, key) => (
              <TabItem
                key={key}
                active={key === active}
                onClick={() => onChangeTab(key, items)}
                enabled={items[key].enabled !== undefined ? items[key].enabled : true}
              >
                {itemsTitleKey ? item[itemsTitleKey] : item}
              </TabItem>
            ))}
            <InvisiblePad />
          </TabsContainer>
          {showScrollButtons && (
          <TabScrollButton
            // onClick={this.tabsScrollRight}
            onMouseDown={this.scrollButtonDown('right')}
            onMouseUp={this.setScrollButtonUnpressed}
            onMouseLeave={this.setScrollButtonUnpressed}
            onKeyDown={this.onButtonScrollKeyDown('right')}
            onKeyUp={this.setScrollButtonUnpressed}
          />)}
          <ActionButton onClick={() => onActionButtonClick(items)} disabled={basic}>
            <img src={icCreate} />
            {actionButtonTitle}
          </ActionButton>
        </Container>
      </div>
    );
  }
}
