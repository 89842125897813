import moment from 'moment';

export const parseChaturbateId = (value) => {
  let chaturbateId = value;
  const testStr = value.match(/chaturbate.com\/(\w+)/);
  if (testStr) {
    [, chaturbateId] = testStr;
  } else {
    chaturbateId = chaturbateId.replace(/\W/gi, '');
  }
  return chaturbateId;
};

/**
 * Start and end included in result
 * @param {*} start
 * @param {*} end
 */
export const randInt = (start, end) => Math.ceil(Math.random() * (end - (start + 1))) + (start - 1);

export const parseTillDateString = date => moment(Date.parse(date));

export const removeUndefinedFields = payload => Object.keys(payload).reduce((acc, key) => {
  if (payload[key] === undefined) return acc;
  return { ...acc, [key]: payload[key] };
}, {});
