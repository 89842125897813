import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';

import {
  authSignOut,
  authUserGoToNewVersion,
} from 'store/reducers/auth';
import {
  Settings,
  Render,
  Stats,
  Donations,
  Contacts,
  Terms,
  NotFound,
  Auth,
  Upgrade,
  Price,
  ReSub,
  ConnectService,
  ServiceIdEdit,
  Logs,
  Widget,
  Verify,
  SignUp,
} from 'scenes';
import { widgets } from 'constants';
import { PaymentContactModal } from 'components/PaymentContactModal';
import { AutopayModal } from 'components/AutopayModal';

export default withRouter(connect(
  store => ({
    subscriptionExpired: store.auth.subscriptionExpired,
    subscriptionActive: store.auth.supporter
      && (store.auth.supporter.basic || store.auth.supporter.active),
    skipCheckOnSignUp: store.auth.skipCheckOnSignUp,
    user: store.auth.user,
  }),
  dispatch => bindActionCreators({
    authSignOut,
    authUserGoToNewVersion,
  }, dispatch),
)(({
  authSignOut,
  authUserGoToNewVersion,
  subscriptionExpired,
  subscriptionActive,
  skipCheckOnSignUp,
  location,
  // user,
}) => (
  <React.Fragment>
    <Switch>
      <Route exact path="/" component={() => <Redirect to={`/widget/${widgets.tips.id}`} />} />
      {(Boolean(subscriptionExpired) && location.pathname.indexOf('widget') !== -1) && (
        <Redirect to="/price" />
      )}
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/connect-service" component={ConnectService} />
      <Route path="/connect-service/:service" component={ServiceIdEdit} />
      <Route exact path="/stats" component={Stats} />
      <Route path="/logs" component={Logs} />
      <Route
        path="/widget/:widgetId"
        render={({ history, location, match }) => {
          if (skipCheckOnSignUp) {
            return null;
          }
          if (subscriptionActive) {
            return <Widget match={match} history={history} location={location} />;
          }
          return <Price match={match} history={history} location={location} />;
        }}
      />
      <Route path="/w/:widgetId/:accessToken" component={Render} />
      <Route path="/donations" component={Donations} />
      <Route path="/contacts" component={Contacts} />
      <Route path="/terms" component={Terms} />
      <Route path="/upgrade" component={Upgrade} />
      <Route path="/price" component={Price} />
      <Route path="/resub" component={ReSub} />
      <Route path="/auth" component={Auth} />
      <Route path="/verify" component={Verify} />
      <Route path="/signup" component={SignUp} />
      <Route
        path="/signout"
        component={() => {
          authSignOut();
          return <Redirect to="/" />;
        }}
      />
      <Route
        path="/new"
        component={() => {
          // const alfaAccess = (user && user.alfaVersionAccess) || false;
          // if (alfaAccess) {
          authUserGoToNewVersion();
          // } else {
          //   return <Redirect to="/" />;
          // }
          return null;
        }}
      />
      <Route component={NotFound} />
    </Switch>
    <PaymentContactModal />
    <AutopayModal />
  </React.Fragment>
)));
