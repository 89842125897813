import React, { Component } from 'react';

import {
  Container,
  Text,
  HelpLink,
} from './style';

export class HelpCenter extends Component {
  render() {
    return (
      <Container>
        <Text>
          Couldn’t find answer for your question? Visit&nbsp;
          <HelpLink
            href="http://help.tipalerts.com/support/home"
            target="_blank"
            rel="noopener noreferrer"
          >
            TipAlerts Help Center
          </HelpLink>
        </Text>
      </Container>
    );
  }
}
