import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircleFull = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background: ${({ colorСircle }) => colorСircle};
  border-radius: 50%;

`;

const CircleMask = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transform: scale(${({ scaleSize }) => scaleSize});
  background: ${({ colorCheck }) => colorCheck};
  mask-image: url('/images/check-mark-Price.svg');
  mask-repeat: no-repeat;
  mask-position: center;
`;

type Props = {
    colorСircle: string,
    colorCheck: string,
  };

export class Circle extends Component<Props> {
  render() {
    const {
      colorСircle,
      colorCheck,
      size,
    } = this.props;
    const scaleSize = 0.058 * size;
    return (
      <Container>
        <CircleFull size={size} colorСircle={colorСircle}>
          <CircleMask scaleSize={scaleSize} colorCheck={colorCheck} />
        </CircleFull>
      </Container>
    );
  }
}
