import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

`;

const Label = styled.label`
  ${({ theme, themeFont }) => theme.font[themeFont]};

  position: relative;
  padding-left: ${({ size }) => size}px;
  margin: 0;
  cursor: pointer;

  display: flex;
  align-items: center;

  &:before{
    content: "";
    position: absolute;
    left: 0;
    ${({ size }) => css`
      height: ${size}px;
      width: ${size}px;
    `}

    background-color: #2E3F4C;
    border-radius: 50%;

    transition-property: background-color, border-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }

  &:after {
    content: "";

    position: absolute;
    left: calc(50% - 4px);
    top: calc(50% - 4px);
    transition: opacity 0.2s ease-in-out;

    height: 8px;
    width: 8px;

    background-color: #333333;
    border-radius: 50%;

    transform: rotate(-45deg);
    transform-origin: center;
  }

`;

const Input = styled.input`
  display: none;

  & + ${Label}:after {
    opacity: 0;
  }

  &:checked + ${Label}:after {
    opacity: 1;
    cursor: default;
  }

  & + ${Label}:before {
    background-color: #00000059;
    border: 1px solid ${({ theme }) => theme.white54};
  }

  &:checked + ${Label}:before {
    background-color: ${({ theme }) => theme.orangeLanding};
    border: 1px solid transparent;
    cursor: default;
  }

  &:hover + ${Label}:before {
    ${({ checked }) => !checked && css`
      background-color: rgba(0,0,0,.17);
      border: 1px solid  ${({ theme }) => theme.white};
    `}
  }
`;

type Props = {
  checked: boolean,
  onChange: Function,
  children: Node,
  id: string,
  size: Number,
}

export const CheckBoxOne = ({
  checked,
  onChange = () => {},
  onClick,
  children,
  id,
  fontWeight,
  themeFont,
  size,
}: Props) => {
  const randomId = `${Math.random()}`;
  return (
    <Container>
      <Input id={id || randomId} type="checkbox" checked={checked} onChange={onChange} onClick={onClick} />
      <Label
        fontWeight={fontWeight}
        themeFont={themeFont}
        htmlFor={id || randomId}
        checked={checked}
        size={size}
      >
        {children}
      </Label>
    </Container>
  );
};
