import React, { Component } from 'react';

import {
  Container,
  ContainerContent,
  Title,
  AboutTitle,
  ContainerStep,
  Step,
  Number,
  TextStep,
  LinkOrange,
  ButtonOrangeLink,
  TextItalic,
  Contact,
  EmailLink,
  LogoContainer,
  Logo,
} from './style';

export class SubPatreon extends Component {
  render() {
    return (
      <Container id="subpatreon">
        <ContainerContent>
          <Title>How to purchase subscription?</Title>
          <AboutTitle>
            Unfortunately, at the moment we can accept payments only via
            Partreon for your country. On Patreon you can pay with credit
            card or PayPal. To purchase subscription follow these steps:
          </AboutTitle>
        </ContainerContent>
        <ContainerStep>
          <Step>
            <Number>1</Number>
            <TextStep>
              Visit our&nbsp;
              <LinkOrange
                href="https://www.patreon.com/join/tipalerts"
                target="_blank"
                rel="noopener noreferrer"
              >
                Patron checkout page
              </LinkOrange>
              &nbsp;at Patreon.
            </TextStep>
          </Step>
          <Step>
            <Number>2</Number>
            <TextStep>Select Basic or Premium account at Patron checkout</TextStep>
          </Step>
          <Step>
            <Number>3</Number>
            <TextStep>Pay with credit card or PayPal on Patreon</TextStep>
          </Step>
          <Step>
            <Number>4</Number>
            <TextStep>Visit our Patron checkout page at Patreon.</TextStep>
          </Step>
        </ContainerStep>
        <ButtonOrangeLink
          href="https://www.patreon.com/join/tipalerts"
          target="_blank"
          rel="noopener noreferrer"
        >
          Purchase subscription at Patreon
        </ButtonOrangeLink>
        <TextItalic>
          Please make sure that you’re using the same email for
          Pateron and TipAlerts. This is necessary to make your payment work.
        </TextItalic>
        <Contact>
          If you need help with subscription purchasing contact us&nbsp;
          <EmailLink href="mailto:support@tipalerts.com">support@tipalerts.com</EmailLink>
        </Contact>
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </Container>
    );
  }
}
