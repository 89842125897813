import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

const PaymentRow = styled.tr`
`;

const PaymentCell = styled.td`
  padding: 4px;
  width: 120px;
`;

const PaymentCellProduct = styled(PaymentCell)`
  width: 250px;
`;

const PaymentCellPrice = styled(PaymentCell)`
  width: 60px;
`;

const PaymentCellDate = styled(PaymentCell)`
  width: 160px;
`;

const Table = styled.table`
  >tbody, thead{
    display: block;
  }
  >tbody{
    overflow: auto;
    max-height: 250px;
  }
`;

export const PaymentsHistory = ({ payments, t }) => (
  <div style={{ marginBottom: '20px' }}>
    {!payments
      ? <div>{t.noPayments}</div>
      : (
        <Table>
          <thead>
            <tr>
              {false && <PaymentCell>{t.paymentMethod}</PaymentCell>}
              <PaymentCellProduct>{t.product}</PaymentCellProduct>
              <PaymentCellPrice>{t.price}</PaymentCellPrice>
              <PaymentCellDate>{t.date}</PaymentCellDate>
              <PaymentCell>{t.status}</PaymentCell>
            </tr>
          </thead>
          <tbody>
            {
              Object.keys(payments)
                .reverse()
                .filter(paymentId => payments[paymentId] && payments[paymentId].state === 'PAID')
                .map((paymentId) => {
                  const {
                    description,
                    price,
                    dateCreated,
                    dateSuccess,
                    state,
                    invoiceUrl,
                  } = payments[paymentId];
                  return (
                    <PaymentRow key={paymentId}>
                      <PaymentCellProduct>{description}</PaymentCellProduct>
                      <PaymentCellPrice>
                        $
                        {price}
                      </PaymentCellPrice>
                      <PaymentCellDate>{moment(dateSuccess || dateCreated).format('MMM D Y, HH:mm')}</PaymentCellDate>
                      <PaymentCell>{state === 'PAID' ? 'Paid' : 'Not paid'}{invoiceUrl && (<span>&nbsp;(<a href={invoiceUrl} target="_blank" rel="noopener noreferrer">invoice</a>)</span>)}</PaymentCell>
                    </PaymentRow>
                  );
                })
            }
          </tbody>
        </Table>)
      }
  </div>
);
