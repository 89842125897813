import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 0px;
  background: #32424E;
  display: none;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 1140px;

  text-align: center;
`;

export const Title = styled.span`
  font-weight: 900;
  font-size: 56px;
  line-height: 115%;
`;

export const Text = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;

  margin-top: 20px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100% - 100px);
  margin-top: 30px;
`;

export const BetaContainer = styled.div`
  width: 765px;
  margin-top: 45px;
  margin-bottom: 30px;
`;
