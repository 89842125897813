export const widgetFonts = [
  'Roboto',
  'Roboto Slab',
  'Open Sans',
  'Lora',
  'Pacifico',
  'Yanone Kaffeesatz',
  'Lobster',
  'Amatic SC',
  'Marmelad',
  'Pattaya',
  'Rubik Mono One',
  'Acme',
  'Baloo Thambi',
  'Bonbon',
  'Bree Serif',
  'Cantarell',
  'Caveat',
  'Charmonman',
  'Clicker Script',
  'Courgette',
  'Crafty Girls',
  'Dancing Script',
  'Dhurjati',
  'Finger Paint',
  'Fredericka the Great',
  'Gaegu',
  'Great Vibes',
  'Gugi',
  'Hi Melody',
  'Lancelot',
  'League Script',
  'Life Savers',
  'Lily Script One',
  'Love Ya Like A Sister',
  'Milonga',
  'Mogra',
  'Monoton',
  'Mr Bedfort',
  'Niconne',
  'Parisienne',
  'Ranga',
  'Ruthie',
  'Sacramento',
  'Satisfy',
  'Schoolbell',
  'Shadows Into Light',
  'Sofadi One',
  'Sofia',
  'The Girl Next Door',
  'Ultra',
  'VT323',
  'Yeon Sung',
  'ZCOOL KuaiLe',
  'Montserrat',
];

export const widgetFontWeightsScheme = {
  100: { title: 'Extra Light' },
  200: { title: 'Light' },
  300: { title: 'Demi' },
  400: { title: 'Regular' },
  500: { title: 'Medium' },
  600: { title: 'Semibold' },
  700: { title: 'Bold' },
  800: { title: 'Extra Bold' },
};

export const defaultWeight = 400;

export const defaultFont = widgetFonts.length - 1;
