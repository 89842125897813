import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  ${({ disabled }) => disabled && css`
      opacity: 0.54;
      pointer-events: none;
  `};
`;

const Label = styled.label`
  ${({ theme, themeFont }) => theme.font[themeFont]};

  position: relative;
  padding-left: 22px;
  margin: 0;
  cursor: pointer;

  color: ${({ checked, theme }) => checked && theme.orangeLanding};

  display: flex;
  align-items: center;

  &:before{
    content: "";
    position: absolute;
    left: 0;

    height: 12px;
    width: 12px;
    background-color: #2E3F4C;
    border-radius: 2px;

    transition: background-color 0.2s ease-in-out;
  }

  &:after {
    content: "";

    position: absolute;
    left: 2px;
    top: calc(50% - 4px);
    transition: opacity 0.2s ease-in-out;

    height: 5px;
    width: 8px;
    border-left: 2px solid #333333;
    border-bottom: 2px solid #333333;

    transform: rotate(-45deg);
}
`;

const Input = styled.input`
  position: absolute;
  z-index: -9999;
  opacity: 0;
  margin: 0;
  padding: 0;

  & + ${Label}:after {
    opacity: 0;
  }

  &:checked + ${Label}:after {
    opacity: 1;
  }

  & + ${Label}:before {
    background-color: hsl(207, 30%, 15%);
  }

  &:checked + ${Label}:before {
    background-color: ${({ theme }) => theme.orangeLanding};
  }
`;

type Props = {
  checked: boolean,
  onChange: Function,
  children: Node,
  id: string,
}

export const CheckBox = ({
  checked,
  onChange,
  children,
  id,
  fontWeight,
  themeFont,
  disabled,
}: Props) => {
  const randomId = `${Math.random()}`;
  return (
    <Container disabled={disabled}>
      <Input id={id || randomId} type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
      <Label
        fontWeight={fontWeight}
        themeFont={themeFont}
        htmlFor={id || randomId}
        checked={checked}
      >
        {children}
      </Label>
    </Container>
  );
};
