import React, { Component } from 'react';

import { BetaTestText, CardConnect } from 'components';
import { Service_ID } from 'constants';

import {
  Container,
  Title,
  Text,
  CardContainer,
  BetaContainer,
  Content,
} from './style';

export class WebcamServices extends Component {
  render() {
    return (
      <Container>
        <Content>
          <Title>Supported webcam services</Title>
          <Text>Use any of these services along with TipAlerts. Link to your stream or username — all that is required.</Text>
          <CardContainer>
            <CardConnect
              service={Service_ID.chaturbate}
              type="landing"
            />
            <CardConnect
              service={Service_ID.bongacams}
              type="landing"
            />
            <CardConnect
              service={Service_ID.myfreecams}
              type="landing"
            />
            <CardConnect
              service={Service_ID.stripchat}
              type="landing"
            />
            <CardConnect
              service={Service_ID.cam4}
              type="landing"
            />
            <CardConnect
              service={Service_ID.camsoda}
              type="landing"
              newBadge
              beta
            />
            <CardConnect
              service={Service_ID.amateur}
              type="landing"
              newBadge
              beta
            />
            <CardConnect
              service={Service_ID.cams}
              type="landing"
              newBadge
              beta
            />
            <CardConnect
              service={Service_ID.manyvids}
              type="landing"
              newBadge
              beta
            />
          </CardContainer>
          {false && (
            <BetaContainer>
              <BetaTestText landing />
            </BetaContainer>)}
        </Content>
      </Container>
    );
  }
}
