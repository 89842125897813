// @flow
import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Glyphicon } from 'react-bootstrap';
import Dropzone from 'react-dropzone';

import {
  galleryImages, storageImagePath, storageSoundPath, sounds, pathGalleryImages,
} from 'utils';
import { AnimationBlock } from 'components/UIkit';

import {
  Button as ButtonUI,
  CheckBox,
  NewSlider,
} from 'components/UI';

import sprite from 'assets/images/sprite.svg';


import {
  Row,
  Col3,
  Col4,
  Col5,
  Col9,
} from './style';

const filters = {
  library: 'library',
  uploaded: 'uploaded',
  poolRandom: 'pool random',
};

const Dice = styled.div`
  width: 24px;
  height: 24px;

  mask-position: center;
  mask-repeat: no-repeat;

  &:active {
    mask-image: url('/images/dice2.svg') !important;
  }
`;

const RandomButton = styled.button`
  width: 36px;
  height: 36px;

  position: absolute;
  right: 4px;
  bottom: 4px;

  background-color: ${({ active }) => (active ? 'rgba(0,0,0,0.54)' : 'transparent')};
  border-radius: 4px;

  border: 0;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${({ active }) => (active ? 'rgba(0,0,0,0.87)' : 'rgba(0,0,0,0.87)')} !important;
  }
  ${Dice} {
    background-color: ${({ active }) => (active ? 'white' : 'transparent')};
    mask-image: url(${({ active }) => (active ? '/images/dice2.svg' : '/images/dice1.svg')});
  }
`;

const Tooltip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: max-content;
  max-width: 250px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: calc(100% + 4px);
  left: 50%;
  transform: translateX(-50%);

  min-height: 24px;

  border-radius: 2px;
  padding: 2px 8px;

  color: #FFFFFF;
  background: #11171B;

  transition: opacity 0.3s ease-in-out;
  transition-delay: 0s;

  cursor: auto;
  pointer-events: none;

    font-size: 14px;
    text-align: center;
    line-height: 20px;

  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #11171B transparent transparent transparent;
  }

  z-index: 2;
`;

const TooltipRandomButton = styled(Tooltip)`
  ${RandomButton}:hover & {
    visibility: visible;
    opacity: 1;
    transition-delay: 1s;
  }
`;


const RowCheckBoxHelp = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const HelpButton = styled.svg`
  & > use {
    transition: fill 0.3s ease-in-out;
    fill: rgba(255,255,255,0.54);
  }
`;

const HelpContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 80%;
  cursor: pointer;

  height: 24px;
  width: 24px;

  border-radius: 4px;
  background-color: transparent;

  transition: background-color 0.3s ease-in-out;

  &:hover ${HelpButton} > use {
    fill: rgba(255,255,255,1);
  }

  margin-left: 4px;
`;

const TooltipHelp = styled(Tooltip)`
  ${HelpContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;


const CustomButton = styled(ButtonUI)`
  background-color: hsla(206, 29%, 36%, 1);
  &:hover {
    background-color: hsla(206, 29%, 46%, 1);
  }
  &:active {
    background-color: hsla(206, 29%, 36%, 1);
  }
`;

const ButtonsConatainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;


const SliderContainer = styled(ButtonsConatainer)`
  >span{
    margin-right: 12px;
    font-size: 16px;
  }

  height: 36px;
`;

const Button = styled.button`
  margin-right: 8px;
  height: 27px;
  border: 0;
  background-color: transparent;
  ${({ active, theme }) => (active
    ? css`
          color: ${theme.white};
          background-color: #20303B;
          border-radius: 2px;
          cursor: default;
        `
    : css`
          color: ${theme.orangeLanding};
          text-decoration: underline;
          background-color: transparent;
        `)};
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${({ active }) => !active && 'rgba(255,255,255,.17)'};
    border-radius: 2px;
  }
`;

const ButtonSoundSelect = styled.button`
  margin-right: 8px;
  height: 27px;
  border: 0;
  background-color: transparent;
  &:focus {
    outline: none;
  }
`;

const ImageSoundSelect = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.orangeLanding : 'hsla(206, 29%, 36%, 1)')};
  mask-position: center;
  mask-repeat: no-repeat;
  mask-image: url('/images/icons/ic-sound-grey.svg');

  &:hover {
   background-color: ${({ isSelected }) => (isSelected ? '' : 'rgba(255,255,255,.17)')};
  }
`;


const SoundList = styled.div`
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: hsl(206, 30%, 18%);
  ${({ row }) => row
    && css`
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      flex-wrap: wrap;
    `}

    margin-bottom: 24px;


  ::-webkit-scrollbar {
    width: 6px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 3px;
    background: ${({ theme }) => theme.white05};

    padding: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.white17};
    border-radius: 3px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.white54};
  }

  padding: 1px 0px 1px 1px;

  border-radius: 0px 3px 0px 3px;
`;


const X = styled.div`
  width: 14px;
  height: 14px;

  mask-image: url('/images/X.svg');
  mask-repeat: no-repeat;
`;

const DeleteButton = styled.button`
  width: 24px;
  height: 24px;
  padding: 5px;
  border: 0;
  &:focus {
    outline: none;
  }

  background-color: transparent;
  &:hover,
  &:active {
    ${X}{
      background-color: rgba(255,255,255,1) !important;
    }
  }
  &:active{
    background-color: rgba(0,0,0,0.65) !important;
  }

  position: absolute;
  right: 2px;
  top: 2px;
`;

const SoundItem = styled.div`
  padding: 0 0 0 0 !important;
  flex-direction: row;
  height: 34px;
  display: flex;
  width: 100%;
  &:hover {
    background-color: ${({ theme }) => theme.slate};
  }

  position: relative;

  &:hover {
    ${X} {
      background-color: rgba(255,255,255,0.54);
    }
  }
`;

const PlayButton = styled.button`
  border: 0;
  background-color: transparent;
  &:focus {
    outline: none;
  }
`;

const PlayFullItemButton = styled(PlayButton)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ImageButton = styled.button`
  width: 147px;
  height: 147px;

  padding: 0;
  display: flex;

  align-items: center;
  justify-content: center;

  box-sizing: border-box;

  border: solid 1px transparent;

  margin: 1px;
  border-radius: 2px;
  /* background-clip: content-box; */
  background: url('/images/preview-bg.png') repeat;
  background-position: center;
  ${({ selected, theme }) => selected
    && css`
      border: solid 1px ${theme.lightGold};
    `};
  &:focus {
    outline: none;
  }

  &:hover {
    ${({ selected, theme }) => !selected
    && css`
      border: solid 1px ${theme.lightGold60};
    `};
  }
`;

const ContainerImageButton = styled.div`
  position: relative;

  &:hover {
    ${DeleteButton}, ${RandomButton} {
      background-color: rgba(0,0,0,0.54);
    }
    ${Dice} {
      background-color: white;
    }
    ${X} {
      background-color: rgba(255,255,255,0.34);
    }
  }
`;

const CustomImage = styled.div`
  background-image: ${({ image }) => image};
  background-position:  center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 145px;
  height: 145px;

  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

const SoundName = styled.span`
  margin-left: 0px;
`;

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 150px;
`;

const Text = styled.div`
  width: 400px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  font-weight: 600;
  font-size: 20px;
  line-height: 20px;

  >span{
    margin-top: 12px;

    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }
`;

type Props = {
  onImageSelect: any => void,
  onImageUpload: any => void,
  onFileDelete: any => void,
  onSoundUpload: any => void,
  onSoundSelect: any => void,
  imageCanBeDeselected?: boolean,
  user: any,
  selected: number,
  title: string,
  type: 'image' | 'sound',
};
type State = {
  playing: number,
};
export class ImageGallery extends Component<Props, State> {
  state = {
    playing: -1,
    filter: filters.library,
  };

  componentDidUpdate() {
    const { data, type } = this.props;
    const { playing } = this.state;
    if (type === 'sound') {
      if (playing !== -1) {
        const _volume = Number((data.volume / 100).toFixed(2)) || 0;
        this.audioRefs[playing].volume = _volume;
      }
    }
  }


  onFileAccepted = (files) => {
    const { onImageUpload, onSoundUpload, type } = this.props;
    const file = files[0];
    if (file.type && file.type.indexOf(type === 'sound' ? 'audio' : type) !== -1) {
      (type === 'image' ? onImageUpload : onSoundUpload)(file);
    } else {
      alert(`Please select ${type} file!`);
    }
  };

  audioRefs = [];

  hideGalleryModal = () => this.setState({ showGalleryModal: false, playing: -1 }, () => (this.audioRefs = []));

  showCustomImageModal = () => this.setState({ showCustomImageModal: true });

  hideCustomImageModal = () => this.setState({ showCustomImageModal: false, playing: -1 }, () => (this.audioRefs = []));

  showGalleryModal = () => this.setState({ showGalleryModal: true });

  audioPlayOrPause = (audioIndex: number) => {
    const {
      data,
    } = this.props;
    const { playing } = this.state;
    if (audioIndex === playing) {
      this.audioRefs[audioIndex].pause();
    } else {
      if (playing !== -1) {
        this.audioRefs[playing].pause();
      }
      const _volume = Number((data.volume / 100).toFixed(2)) || 0;
      this.audioRefs[audioIndex].volume = _volume;
      this.audioRefs[audioIndex].currentTime = 0;
      this.audioRefs[audioIndex].play();
    }
  };

  renderAudioItem = (key, sound, playing, isSelected = false) => {
    const { onSoundSelect } = this.props;
    const { filter } = this.state;
    const elParentClick = el => el && (el.getAttribute('noParentClick') || elParentClick(el.parentElement));
    return (
      <SoundItem key={key}>
        <PlayFullItemButton onClick={event => !elParentClick(event.target) && this.audioPlayOrPause(key)}>
          <audio
            style={{ display: 'none' }}
            src={sound.path}
            ref={r => (this.audioRefs[key] = r)}
            onPlay={() => this.setState({ playing: key })}
            onPause={() => this.setState({ playing: -1 })}
          />
          <ButtonSoundSelect onClick={() => onSoundSelect(filter === filters.uploaded ? sound.id : key)}>
            <ImageSoundSelect isSelected={isSelected} />
          </ButtonSoundSelect>
          <PlayButton onClick={() => this.audioPlayOrPause(key)}>
            <Glyphicon
              glyph={playing === key ? 'pause' : 'play'}
              styled={{ backgroundColor: 'black' }}
            />
          </PlayButton>
          <SoundName>{sound.title || 'Sound'}</SoundName>
          {filter === filters.uploaded && (
          <DeleteButton
            style={{
              borderRadius: '4px',
              marginRight: '5px',
              top: '5px',
            }}
            noParentClick
            onClick={() => onSoundSelect(this.deleteImage(key))}
          >
            <X />
          </DeleteButton>)}
        </PlayFullItemButton>
      </SoundItem>
    );
  };

  renderMusic = () => {
    const { selected, user } = this.props;
    const { playing, filter } = this.state;
    return (
      <div>
        {filter === filters.library
          ? sounds.map((sound, key) => this.renderAudioItem(key, sound, playing, selected === key))
          : user.uploads
            && user.uploads.sound
            && Object.keys(user.uploads.sound).map(el => this.renderAudioItem(
              el,
              {
                ...user.uploads.sound[el],
                path: storageSoundPath(user.uploads.sound[el].id),
              },
              playing,
              selected === user.uploads.sound[el].id,
            ))}
      </div>
    );
  };

  renderImageItem = (image, key, isCustom = false) => {
    const {
      selected, user, onImageSelect, data, title,
    } = this.props;
    let imageComponent;
    let allButton;
    let imageId;
    let isSelected;
    let activeDice;
    if (isCustom) {
      imageId = user.files[image].id;
      isSelected = selected === imageId;
      activeDice = (title === 'Alert image' && !!data.poolRandom) && data.poolRandom.includes(imageId);
      imageComponent = (
        <CustomImage image={`url(${storageImagePath(user.files[image].id)})`} />
      );
      allButton = (
        <div>
          <DeleteButton key={key} onClick={() => onImageSelect(this.deleteImage(key))}><X /></DeleteButton>
          {title === 'Alert image' && (
          <RandomButton
            active={activeDice}
            onClick={() => (activeDice ? this.removeFromPool(imageId) : this.addToPool(imageId))}
          >
            <Dice />
            <TooltipRandomButton>Show in random pool</TooltipRandomButton>
          </RandomButton>)}
        </div>
      );
    } else {
      imageId = key;
      activeDice = (title === 'Alert image' && !!data.poolRandom) && data.poolRandom.includes(imageId);
      isSelected = selected === imageId;
      imageComponent = (
        <CustomImage image={`url(${pathGalleryImages(imageId)})`} />
      );
      allButton = (
        <div>
          {title === 'Alert image'
          && (
          <RandomButton
            active={activeDice}
            onClick={() => (activeDice ? this.removeFromPool(imageId) : this.addToPool(imageId))}
          >
            <Dice />
            <TooltipRandomButton>Show in random pool</TooltipRandomButton>
          </RandomButton>)}
        </div>
      );
    }
    return (
      <ContainerImageButton>
        <ImageButton key={key} selected={isSelected} onClick={() => onImageSelect(imageId)}>
          {imageComponent}
        </ImageButton>
        {allButton}
      </ContainerImageButton>
    );
  };

  deleteImage = (key) => {
    const {
      selected, user, type, data, onFileDelete,
    } = this.props;
    const userFiles = (type === 'image') ? user.files : user.uploads.sound;
    const fileList = Object.keys(userFiles);
    const keyFile = (type === 'image') ? key : fileList.findIndex(file => userFiles[file].id === selected);
    const file = (type === 'image') ? fileList[key] : key;
    const fileId = userFiles[file].id;
    onFileDelete(fileId, file, type);

    if (type === 'image') {
      const pool = data.poolRandom;
      if (typeof pool !== 'undefined' && pool.includes(fileId)) {
        this.removeFromPool(fileId);
      }
    }

    let nextkey;
    let nextSelected;
    if (selected === fileId && fileList.length !== 1) {
      nextkey = (keyFile === 0) ? keyFile + 1 : keyFile - 1;
      nextSelected = userFiles[fileList[nextkey]].id;
    } else if (selected === fileId && fileList.length === 1) {
      nextSelected = 0;
      this.setState({
        filter: filters.library,
        playing: -1,
      });
    } else if (selected !== fileId) {
      nextSelected = selected;
    }
    return nextSelected;
  };

  renderImages = () => {
    const { filter } = this.state;
    const {
      user,
      data,
      imageCanBeDeselected,
    } = this.props;
    let fileImages;
    let idImages;
    let isCustom;
    let Images;
    if (filter === filters.library) {
      fileImages = (imageCanBeDeselected ? [...galleryImages] : galleryImages);
      isCustom = false;
      Images = fileImages.map((el, i) => this.renderImageItem(el, i, isCustom));
    } else if (filter === filters.uploaded) {
      fileImages = (user.files !== undefined) && Object.keys(user.files);
      isCustom = true;
      Images = (user.files !== undefined) ? fileImages.map((el, i) => this.renderImageItem(el, i, isCustom)) : '';
    } else if (filter === filters.poolRandom) {
      idImages = data.poolRandom;
      const EmptyRandom = (
        <TextContainer>
          <Text>
            Random Pool is empty
            <br />
            <span>
              Images from random pool will be displayed if “Random image” option is active.
              To add image into random pool hover cursor over image and click “Show in random pool” button.
            </span>
          </Text>
        </TextContainer>);
      Images = (Array.isArray(idImages) && idImages.length) ? (idImages.map((id, i) => this.renderImagePoolRandom(id, i))) : EmptyRandom;
    }
    return Images;
  };

  addToPool = (key) => {
    const { filter } = this.state;
    const {
      data,
      widgetUpdate,
    } = this.props;
    let pool = data.poolRandom;
    if (typeof pool === 'undefined') {
      pool = [key];
    } else if ((filter === filters.uploaded || filter === filters.library) && !pool.includes(key)) {
      pool = [...data.poolRandom, key];
    }
    widgetUpdate({ poolRandom: pool });
  };

  removeFromPool = (imageId) => {
    const {
      data,
      widgetUpdate,
    } = this.props;
    const pool = [...data.poolRandom];
    const deleteKey = pool.findIndex(id => id === imageId);
    pool.splice(deleteKey, 1);
    widgetUpdate({
      poolRandom: pool,
      onPoolRandom: (data.poolRandom.length <= 1 ? false : data.onPoolRandom),
    });
  }

  renderImagePoolRandom = (idImages, key) => {
    const {
      data,
    } = this.props;
    let imageComponent;
    const activeDice = !!data.poolRandom && data.poolRandom.includes(idImages);
    const randomButton = (
      <RandomButton
        active={activeDice}
        onClick={(e) => {
          e.stopPropagation();
          return (
            activeDice ? this.removeFromPool(idImages) : this.addToPool(idImages)
          );
        }}
      >
        <Dice />
        <TooltipRandomButton>Show in random pool</TooltipRandomButton>
      </RandomButton>);
    if (typeof idImages === 'string') {
      imageComponent = (
        <div>
          <CustomImage image={`url(${storageImagePath(idImages)})`} />
          <ContainerImageButton>{randomButton}</ContainerImageButton>
        </div>
      );
    } else if (typeof idImages === 'number') {
      imageComponent = (
        <div>
          <CustomImage image={`url(${pathGalleryImages(idImages)})`} />
          <ContainerImageButton>{randomButton}</ContainerImageButton>
        </div>
      );
    }
    return (
      <div>
        <ImageButton key={key}>
          {imageComponent}
        </ImageButton>
      </div>
    );
  };

  render() {
    const {
      data,
      type,
      /* selected, */
      widgetUpdate,
      t,
      title,
    } = this.props;
    const { filter } = this.state;

    return (
      <div>
        {type !== 'sound' && (
          <Row marginBottom={12}>
            <Col5>
              <SliderContainer>
                <span>{t.opacity}</span>
                <NewSlider
                  value={Math.floor(data.opacity * 100)}
                  min={0}
                  max={100}
                  step={1}
                  onChange={opacityImage => widgetUpdate({ opacity: Number(opacityImage / 100) })}
                />
              </SliderContainer>
            </Col5>
            <Col4>
              {title === 'Alert image' && (
              <RowCheckBoxHelp>
                <CheckBox
                  onChange={e => widgetUpdate({ onPoolRandom: e.target.checked })}
                  checked={data.onPoolRandom}
                  themeFont="headerCheckbox"
                  disabled={data.poolRandom.length === 0}
                >
                  Random image
                </CheckBox>
                <HelpContainer>
                  <HelpButton
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <use xlinkHref={`${sprite}#question-mark`} />
                  </HelpButton>
                  <TooltipHelp>Display images only from Random pool in random order.</TooltipHelp>
                </HelpContainer>
              </RowCheckBoxHelp>
              )}
            </Col4>
            <Col3>
              <CheckBox
                checked={data.enabled}
                onChange={e => widgetUpdate({ enabled: e.target.checked })}
                themeFont="headerCheckbox"
              >
                Display image
              </CheckBox>
            </Col3>
          </Row>
        )}
        {type === 'sound' && (
          <Row marginBottom={12}>
            <Col9>
              <SliderContainer>
                <span>Volume</span>
                <NewSlider
                  value={data.volume}
                  min={0}
                  max={100}
                  step={1}
                  onChange={volumeNumber => widgetUpdate({ volume: Number(volumeNumber) })}
                />
              </SliderContainer>
            </Col9>
            <Col3>
              <CheckBox
                checked={data.enabled}
                onChange={e => widgetUpdate({ enabled: e.target.checked })}
                themeFont="headerCheckbox"
              >
                Play sound
              </CheckBox>
            </Col3>
          </Row>
        )}
        <Row marginBottom={6}>
          <Col9>
            <ButtonsConatainer>
              <Button
                onClick={() => this.setState({
                  filter: filters.library,
                  playing: -1,
                })}
                active={filter === filters.library}
              >
                TipAlerts
              </Button>
              <Button
                active={filter === filters.uploaded}
                onClick={() => this.setState({
                  filter: filters.uploaded,
                  playing: -1,
                })}
              >
                {t.galleryUploaded}
              </Button>
              {title === 'Alert image' && (
                <Button
                  active={filter === filters.poolRandom}
                  onClick={() => this.setState({ filter: filters.poolRandom })}
                  style={{ marginRight: '30px' }}
                >
                  {t.poolRandom}
                </Button>
              )}
            </ButtonsConatainer>
          </Col9>
          <Col3>
            <ButtonsConatainer>
              <CustomButton
                onClick={() => {
                  this.dropZone.open();
                  this.setState({
                    filter: filters.uploaded,
                    playing: -1,
                  });
                }}
                style={{ width: '100%', marginRight: '0px' }}
              >
                {`Upload ${type}...`}
              </CustomButton>
              <Dropzone
                multiple={false}
                accept={type === 'sound' ? 'audio/*' : 'image/*'}
                style={{ display: 'none' }}
                maxSize={5 * 1024 * 1024} // 5Mb
                onDropRejected={() => alert('Please upload file not larger then 5Mb')}
                onDropAccepted={this.onFileAccepted}
                ref={ref => (this.dropZone = ref)}
              />
            </ButtonsConatainer>
          </Col3>
        </Row>
        <SoundList row={type === 'image'}>
          {type === 'sound' ? this.renderMusic() : this.renderImages()}
        </SoundList>
        {type !== 'sound' && (
          <AnimationBlock
            data={data}
            widgetUpdate={widgetUpdate}
            borderTop
          />
        )}
      </div>
    );
  }
}
