// @flow

import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  ${({ show }) => (show ? 'opacity: 1; visibility: visible;' : 'opacity: 0; visibility: hidden;')}
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);

  cursor: auto;

  width: 360px;
  background: ${({ theme }) => theme.darkTwo};
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 20px;
  transition: opacity 0.2s ease-in-out;

  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) => theme.darkTwo} transparent transparent transparent;
  }
  z-index: 1000;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #FFFFFF;
  text-align: left;

  margin-bottom: 4px;
`;

const Text = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  text-align: left;

  margin-bottom: 16px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const button = css`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 8px 16px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.white};
  border-radius: 4px;
`;

const LeftButton = styled.div`
  ${button}

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.darkGrey};
  &:hover {
    background-color: ${({ theme }) => theme.whiteHover};
  }
`;

const RightButton = styled.div`
  ${button}

  background-color: ${({ theme }) => theme.red};

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;

  margin-left: 6px;

  &:hover {
    background-color: ${({ theme }) => theme.redHover};
  }
`;

export default class ModalTooltip extends React.Component {
  render() {
    const {
      show,
      title,
      text,
      leftButton,
      rightButton,
    } = this.props;
    return (
      <Container show={show}>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <Buttons>
          <LeftButton onClick={leftButton.onClick}>{leftButton.label}</LeftButton>
          <RightButton onClick={rightButton.onClick}>{rightButton.label}</RightButton>
        </Buttons>
      </Container>
    );
  }
}
