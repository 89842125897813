import { en } from './en';

export const ru = {
  ...en,
  authRequired: 'Требуется авторизация',
  error: 'Ошибка',
  resetPassword: 'Сброс пароля',
  signIn: 'Войти',
  signUp: 'Зарегистрироваться',
  toContinueYouNeed: 'Для того чтобы продолжить необходимо',
  or: 'или',
  and: 'и',
  eMail: 'E-mail',
  password: 'Пароль',
  repeatPassword: 'Повтор пароля',
  passwordValidationError: 'Password should contain at least 6 characters',
  repeatPasswordValidationError: 'Please, repeat password',
  signUpTermsText: 'Нажмиая кнопку Зарегистрироваться вы соглашаетесь с',
  signUpTermsTitle: 'Условиями использования',
  restPasswordSend: 'Мы отправили ссылку для восстановления на электронную почту.',
  contactsTitle: 'Контакты',
  vgTechLLC: 'ООО ВиДжи Тэк',
  ogrn: 'ОГРН',
  innKpp: 'ИНН/КПП',
  address: 'Адрес',
  addressInfo: '630099, Россия, Новосибирск, улица Октябрьская, 33',
  refund: 'Возврат',
  refundIfYouWant: 'Если вы хотите сделать возврат напишите на',
  refundLimitations: 'Обратите внимание что возврат возможен в течении 7 дней с момента покупки и будет сделан на ту же карту которая использовалась для покупки.',
  payments: 'Платежи',
  paymentsInfo: `
        Вы можете оплатить подписку на премиум аккаунт с помощью карт Visa, MasterCard или МИР.
        Подписка будет автоматически продлена в конце оплаченного периода, вы можете отменить продление подписки в настройках.
        Все платежи осуществляются с помощью безопасного платежного шлюза.
        Вам необходимо ввести данные платежной карты на защищенной странице оплаты.
        Мы используем протокол 3D secure для дополнительной авторизации владельца карты.
        Если ваш банк поддерживает эту технологию вы будете перенаправлены на веб-страницу банка для дополнительной авторизации.
        Вы можете получить более подробну информацию и способам авторизации в вашем банке.
  `,
  securityLine1: 'Гарантии безопасности платежей',
  securityLine2: `
  Центр обработки защищает и обрабатывает ваши банковские данные в соответствии
  с максимально безопасным международным стандартом PCI DSS.
  Этот стандарт разработан платежными системами Visa и MasterCard.
  Каждый год сертифицированные аудиторы проверяют соответствие центра обработки всем требованиям и затем выпускают сертификат.`,
  securityLine3: `
  Информация передается с использованием защищенного TLS соединения.
  Обработка и передача осуществляется в закрытых банковских сетях которые имеют наиболее высокий уровень надежности.
  Платежный шлюз не предоставляет данные вашей карты нам и третьим лицам.
  Для дополнительной авторизации владельца карты используется протокол 3D Secure.`,
  securityLine4: `
  Персональная информация предоставляемая вами (имя, адрес, телефон, e-mail, данные кредитной карты) строго конфеденциальны и не подлежат разглашению.
  Данные вашей карты передаются только в зашифрованном виде и не хранятся на нашем сервере.`,
  securityLine5: 'В том случае если у вас есть какие-либо вопросы по проведению платежей - вы можете связаться с нами по адресу support@tipalerts.com',
  termsOfUse: 'Соглашение об использовании',
  termsRu: 'Соглашение об использовании',
  termsEn: 'Соглашение об использовании (Английский перевод)',
  payment: 'Платеж',
  settings: 'Настройки',
  userId: 'Chaturbate ID',
  changePassword: 'Смена пароля',
  oldPassword: 'Старый пароль',
  newPassword: 'Новый пароль',
  repeatNewPassword: 'Повтор нового пароля',
  save: 'Сохранить',
  premiumSubscription: 'Премиум подписка',
  paymentsHistory: 'История платежей',
  paidSuccess: 'Успешно оплачено. Ожидаем подтверждение платежной системы...',
  paidFail: 'Что-то пошло не так. Пожалуйста, напишите на support@tipalerts.com',
  premiumTitle: 'Премиум статус дает дополнительный функционал.',
  purchase: 'Оплата',
  supporterThankYou: 'Спасибо за поддержку!',
  activeTill: 'Активно до',
  renewSubscription: 'Обновлять подписку автоматически',
  animation: 'Анимация',
  animatedAmount: 'Анимированная сумма',
  animatedTipperName: 'Анимированное имя',
  animatedImage: 'Анимированное изображение',
  animatedCustomMessage: 'Анимированное сообщение',
  duration: 'Длительность',
  dependsOnTipAmount: 'в зависимости от суммы',
  sec: 'сек.',
  alignment: 'Выравнивание',
  amountTitle: 'Размер доната',
  tipperTitle: 'Имя донатера',
  customMessageTitle: 'Сообщение',
  color: 'Цвет',
  tipperNameColor: 'Цвет имени',
  amountColor: 'Цвет суммы',
  customMessageColor: 'Цвет сообщения',
  fontOptions: 'Настройки шрифта',
  amountFontSize: 'Размер шрифта суммы',
  tipperNameFontSize: 'Размер шрифта имени',
  customMessageFontSize: 'Размер шрифта сообщения',
  amountFont: 'Шрифт суммы',
  tipperFont: 'Шрифт имени',
  customMessageFont: 'Шрифт сообщения',
  alertImage: 'Изображение уведомления',
  alertSound: 'Звук уведомления',
  alertsWithSoundPremium: 'Уведомления со звуками доступны только для',
  alertsWithSoundPremium2: 'премиум аккаунтов',
  customAlertMessage: 'Добавить сообщение для этого уведомления',
  customMessageKeywords1: 'Вы можете использовать ключевые слова',
  customMessageKeywords2: 'для того чтобы вставить имя донатера и размер доната в сообщение',
  hideAmount: 'Скрыть сумму',
  hideTipperName: 'Скрыть имя',
  customMessagePremium1: 'Сообщение доступно только для',
  customMessagePremium2: 'премиум аккаунтов',
  sendTestAlertMessage: 'Отправить тестовое уведомление',
  tokensAmount: 'Сумма',
  send: 'Отправить',
  anyAmount: 'Любая сумма',
  amountVariationPremium1: 'Эта функция доступна только для',
  premiumAccount: 'премиум аккаунтов',
  premiumTooltip: `На этой странице вы можете увидеть как работают опции для различных сумм,
  но на стриме это будет работать только для пользователей с премиум аккаунтом.`,
  feature: 'Функционал',
  fontCustomizations: 'Настройки шрифтов',
  variablesAnimations: 'Различные анимации',
  customImage: 'Собственные изображения',
  soundAlert: 'Звуковые оповещения',
  customMessageText: 'Собественный текст сообщения',
  alertOptions: 'Настройки уведомлений в зависимости от размера доната',
  prioritySupport: 'Выделенная поддержка 24/7',
  setup: 'Помощь специалиста в настройке',
  price: 'Цена',
  basic: 'Базовый',
  premium: 'Премиум',
  free: 'бесплатно',
  month: 'месяц',
  preview: 'Просмотр',
  widgetURL: 'URL подключения виджета:',
  widgetURLWarning: 'Внимание! Не публикуйте эту ссылку, с помощью нее злоумышленники смогут получить доступ к вашей статистике.',
  warning: 'Внимание',
  warningText1: 'После этого действия вам необходимо',
  warningText2: 'заменить ссылку в настройках плагина OBS BrowserSource',
  warningYouReallyWant: 'Вы действительно хотите сбросить токен доступа?',
  refresh: 'Сброс',
  cancel: 'Отмена',
  lastActivitySetup: 'Вы еще не подключались к виджету.',
  lastActivityTip: 'Вероятно вы еще не добавили ссылку установки в плагин BrowserSource (если вы используете OBS).',
  lastActivityConnected: 'Вы подключены сейчас. Последняя активность:',
  lastActivityEarlier: 'Вы подключались ранее. Последняя активность:',
  getAlerts: 'Отображайте уведомления на вашем стриме!',
  alertsSend: 'Уведомлений отправлено',
  startNow: 'Начать сейчас!',
  displayAlerts: 'Любые уведомления ваших зрителей на стриме!',
  layout: 'Широко настраиваемые шаблоны',
  supportedSoftware: 'Поддерживается всеми популярными программами стриминга',
  pricing: 'Цены',
  paymentsAndRefund: 'Платежи и возвраты',
  random: 'Случайно',
  gallerySelect: 'Выберите или загрузите',
  image: 'изображение',
  sound: 'звук',
  galleryDrop: 'Переместите файл или нажмите сюда',
  galleryRemoveSound: 'Удалить звук',
  galleryUploadCustom: 'Загрузить',
  galleryDeleteCustom: 'Удалить',
  galleryUploaded: 'Загруженные',
  gallerySelectFromGallery: 'Выбрать из галереи',
  gallerySelectFile: 'Выбрать',
  gallerySelected: 'Выбран',
  beforeUse: 'Перед использованием виджета необходимо добавить Chaturbate ID',
  continue: 'Продолжить',
  yourUserId: 'Ваш Chaturbate ID',
  noPayments: 'Платежей пока не было',
  paymentMethod: 'Способ оплаты',
  product: 'Название',
  date: 'Дата',
  status: 'Статус',
  copy: 'Скопировать',
};
