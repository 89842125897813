import styled from 'styled-components';

import { theme } from 'constants/theme';
import Constants from '../const/const';

export const BackgroundContainer = styled.div`
  background-color: ${theme.main.darkTwo};
  display: flex;

  justify-content: center;
  align-content: center;
`;

export const FooterContent = styled.div`
  width: ${Constants.WidthContent};

  display: flex;
  flex-flow: row;
  justify-content: center;
  align-content: center;
`;

export const TextContent = styled.div`
  flex: 8;
  margin: 37px 0px 51px;

  font-weight: normal;
  line-height: 24px;
  font-size: 14px;
`;

export const ButtonContainer = styled.div`
  flex: 2;

  margin: 23px 15px 38px;
`;

export const ButtonPatreon = styled.button`
  width: 217px;
  height: 51px;
  border: none;

  background-image: url('/images/button-patreon.png');

  &:focus,
  &:hover {
    outline: none;
  }
`;

export const OutLinkContainer = styled(TextContent)`
  flex: 1;
  margin: 37px 10px 51px;

  display: flex;
  flex-direction: column;
`;

export const OutLink = styled.a`
  text-align: center;
  color: ${theme.main.orangeLanding} !important;

  > span {
    border-bottom: 1px solid ${theme.main.orangeLanding05};
    &:hover {
      border-bottom: 1px solid ${theme.main.orangeLanding};
    }
  }

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    border-bottom: none;
  }
`;
