export const en = {
  authRequired: 'Authentication required',
  error: 'Error',
  resetPassword: 'Reset password',
  signIn: 'Sign In',
  signUp: 'Sign Up',
  toContinueYouNeed: 'To continue you need',
  or: 'or',
  and: 'and',
  eMail: 'E-mail',
  password: 'Password',
  repeatPassword: 'Repeat Password',
  passwordValidationError: 'Password should contain at least 6 characters',
  repeatPasswordValidationError: 'Please, repeat password',
  signUpTermsText: 'By clicking Sign Up, you agree to our  ',
  signUpTermsTitle: 'Terms',
  restPasswordSend: 'We send reset link to your email.',
  contactsTitle: 'Contacts',
  vgTechLLC: 'VG Tech LLC',
  ogrn: 'OGRN (PSRN)',
  innKpp: 'INN (ITN)/KPP',
  address: 'Address',
  addressInfo: '630099, Russia, Novosibirsk, 33, Oktiabrskaia street',
  refund: 'Refund',
  refundIfYouWant: 'If you want to make a refund please write to',
  refundLimitations: 'Please note that refund can be made in 7 days from subscription date and funds would be returned to the same card that you used to pay subscription.',
  payments: 'Payments',
  paymentsInfo: `
        You can pay premium account subscription by bank cards Visa, MasterCard or МИР (MIR).
        Subscription would be automatically prolonged and billed at the end of subscription period, you can cancel subscription in your account.
        All payments executed through secure payment gateway.
        You need to input your card information on secure gateway page when you would pay.
        We use 3D secure protocol for additional card holder authorization.
        If your bank support this technology you will be redicrected to bank server for additional authorization.
        You can get information about rules and additional authorization methods in your bank.
  `,
  securityLine1: 'Guarantees of online payment security',
  securityLine2: 'Processing Center protects and processes your bank card data according to the maximum international security standard - Payment Card Industry Data Security Standard 3.2 level 1 (abbreviated as PCI DSS). This standard is developed by Visa and MasterCard payment systems. Every year, certified auditors check to see if the processing center meets all requirements and then issues a certificate.',
  securityLine3: 'The transfer of information to the payment gateway occurs using TLS encryption technology. Further transmission of information occurs through closed banking networks that have the highest level of reliability. Payment gateway does not transmit your card information to us and other third parties. For additional cardholder authentication, the 3D Secure protocol is used.',
  securityLine4: 'The personal information provided by you (name, address, telephone, email, credit card number) is confidential and not subject to disclosure. Your credit card details are transmitted only in an encrypted form and are not stored on our server.',
  securityLine5: 'In case you have questions about the payment made, you can contact customer support via e-mail support@tipalerts.com',
  termsOfUse: 'Terms of Use',
  termsRu: 'Terms of use (Russian, primary)',
  termsEn: 'Terms of use (English, translation)',
  payment: 'Payment',
  settings: 'Settings',
  userId: 'Chaturbate id',
  changePassword: 'Change password',
  oldPassword: 'Old password',
  newPassword: 'New password',
  repeatNewPassword: 'Repeat new password',
  save: 'Save',
  premiumSubscription: 'Premium subscription',
  paymentsHistory: 'Payments history',
  paidSuccess: 'Succesfuly paid. Waiting confirmation from payment system...',
  paidFail: 'Something went wrong. Please contact support@tipalerts.com',
  premiumTitle: 'Premium status give you more functionality.',
  month: 'month',
  purchase: 'Purchase',
  supporterThankYou: 'Thank you for support!',
  activeTill: 'Active till',
  renewSubscription: 'Renew subscription automatically',
  animation: 'Animate',
  animatedAmount: 'Tip amount',
  animatedTipperName: 'Tipper Name',
  animatedImage: 'Image',
  animatedCustomMessage: 'Custom message',
  duration: 'Duration',
  dependsOnTipAmount: 'depends on tip amount',
  sec: 'sec.',
  alignment: 'Alignment',
  amountTitle: 'Tip amount',
  tipperTitle: 'Tipper name',
  customMessageTitle: 'Message',
  color: 'Color',
  tipperNameColor: 'Tipper name color',
  amountColor: 'Amount color',
  customMessageColor: 'Custom message color',
  fontOptions: 'Font options',
  amountFontSize: 'Amount font size',
  tipperNameFontSize: 'Tipper name font size',
  customMessageFontSize: 'Custom message font size',
  amountFont: 'Tip amount',
  tipperFont: 'Tipper name',
  customMessageFont: 'Message',
  alertImage: 'Alert image',
  alertSound: 'Alert sound',
  alertsWithSoundPremium: 'Alerts with sound available only with',
  alertsWithSoundPremium2: 'premium account',
  customAlertMessage: 'Add custom message to this alert',
  customMessageKeywords1: 'You can use',
  customMessageKeywords2: 'keywords to insert tipper name and tip amount into the message',
  hideAmount: 'Hide amount',
  hideTipperName: 'Hide tipper name',
  customMessagePremium1: 'Custom message available only with',
  customMessagePremium2: 'premium account',
  sendTestAlertMessage: 'Send test alert message',
  tokensAmount: 'Tokens amount',
  send: 'Send',
  anyAmount: 'Any amount',
  amountVariationPremium1: 'This feature available only with',
  premiumAccount: 'premium account',
  premiumTooltip: `You can preview how configurable amount alerts works at this page,
  but it would not work in live until you got Supporter status.`,
  getAlerts: 'Get alerts for your stream!',
  alertsSend: 'Alerts send',
  startNow: 'Start now!',
  displayAlerts: 'Display any alerts from your viewers on your stream!',
  layout: 'Hightly customizable layout',
  supportedSoftware: 'Supported by all popular broadcasting software',
  feature: 'Feature',
  fontCustomizations: 'Font customizations',
  variablesAnimations: 'Variables animations',
  customImage: 'Custom image',
  soundAlert: 'Sound alert',
  customMessageText: 'Custom message text',
  alertOptions: 'Alert options depends on donation amount',
  prioritySupport: 'Priority support 24/7',
  setup: 'Setup by our specialist',
  price: 'Price',
  basic: 'Basic',
  premium: 'Premium',
  free: 'free',
  description: '',
  preview: 'Preview',
  widgetURL: 'Widget URL:',
  widgetURLWarning: 'Do not publish this URL or Stream Access Token, it cause access to your widgets and stats.',
  warning: 'Warning',
  warningText1: 'After this action you need to',
  warningText2: 'change URL in OBS',
  warningYouReallyWant: 'Do you want to proceed?',
  refresh: 'Refresh',
  cancel: 'Cancel',
  lastActivitySetup: 'You did not setup your broadcasting software to correctly work with TipAlerts.',
  lastActivityTip: 'Probably - you do not added widget URL to BrowserSource plugin (if you use OBS).',
  lastActivityConnected: 'Your broadcasting software connected now. Last activity:',
  lastActivityEarlier: 'Your broadcasting software connected earlier. Last activity:',
  pricing: 'Pricing',
  paymentsAndRefund: 'Payments and refund',
  random: 'Random',
  gallerySelect: 'Select or upload your',
  image: 'image',
  sound: 'sound',
  galleryDrop: 'Drop your file or click here',
  galleryRemoveSound: 'No sound',
  galleryUploadCustom: 'Upload',
  galleryDeleteCustom: 'Delete',
  galleryUploaded: 'Uploaded',
  gallerySelectFromGallery: 'Select from gallery',
  gallerySelectFile: 'Select',
  gallerySelected: 'Selected',
  beforeUse: 'Before use TipAlerts widgets you need to add your Chaturbate id',
  continue: 'Continue',
  yourUserId: 'Your Chaturbate ID',
  noPayments: 'No payments',
  paymentMethod: 'Payment method',
  product: 'Product',
  date: 'Date',
  status: 'Status',
  copy: 'Copy',
  subscriptionManagement: 'You can manage your subscription on ',
  changeChaturbateId: 'To change the Chaturbate ID you need to ',
  shadow: 'Shadow',
  shiftRight: 'Shift right',
  shiftDown: 'Shift down',
  blur: 'Blur',
  widgetSize: 'Widget size',
  animatedText: 'Text animation',
  opacity: 'Opacity',
  disableThisAlerts: 'Disable this preset',
  poolRandom: 'Random pool',
};
