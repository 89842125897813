import React from 'react';
import ReactDOM from 'react-dom';
import { Overlay } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { ColorChooser } from '../../UI';

import './SelectColor.css';

import { widgetColors, getColor } from '../../../utils/index';

const onPickerColorChange = callback => (color) => {
  const alpha = Math.round(color.rgb.a * 255);
  const alphaHex = alpha < 16 ? (0 + alpha.toString(16)) : alpha.toString(16);
  callback(color.hex + alphaHex);
};

type Props = {
  value: number,
  onSelect: Function,
  title: string
}

export class SelectColor extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      previewsNotRandomColor: props.value ? props.value : 1,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== 0) {
      this.setState({ previewsNotRandomColor: nextProps.value });
    }
  }

  onRandomCheckboxChanged = () => {
    const { onSelect, value } = this.props;
    if (value === 0) {
      const { previewsNotRandomColor } = this.state;
      onSelect(previewsNotRandomColor);
    } else {
      onSelect(0);
    }
  };

  render() {
    const {
      title = '',
      value = 0,
      onSelect,
    } = this.props;
    const { opened } = this.state;
    return (
      <div className="SelectColor">
        <ColorChooser
          onChange={this.onRandomCheckboxChanged}
          checked={value === 0}
          value={value}
          onClick={() => this.setState(state => ({ opened: !state.opened }))}
          title={title}
        />
        <Overlay
          show={opened}
          onHide={() => this.setState(() => ({ opened: false }))}
          target={() => ReactDOM.findDOMNode(this.ref)}
          container={this}
          rootClose
        >
          <div className="SelectColor__ColorsView">
            <SketchPicker
              presetColors={widgetColors.slice(0)}
              color={getColor(value)}
              onChange={onPickerColorChange(onSelect)}
              triangle="hide"
              className="SelectColor__SketchPicker"
            />
          </div>
        </Overlay>
      </div>
    );
  }
}
