import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router';

import { AuthContainer } from 'components/AuthContainer'; // carefully with import all components
import store, { history, persistor } from 'store';
import { theme } from 'constants';

import RootRouter from './RootRouter';

import './App.css';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={theme.main}>
              <AuthContainer>
                <RootRouter />
              </AuthContainer>
            </ThemeProvider>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
