/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { authUserSettingsUpdate } from 'store/reducers/auth';

import { Button, CheckBox } from 'components/UI';
import { RenderRollTheDice } from 'components/WidgetsRender';
import { widgets, settingsDefault } from 'constants';

const RenderPreview = styled.div`
  border-radius: 4px;
  width: 100%;
  height: 400px;
  overflow: hidden;
  margin-bottom: 10px;
  /* background-image: url('/images/checker-grey-old.png'); */
  /* border: solid 1px ${({ theme }) => theme.dark}; */
  z-index: 1;
  position: relative;
  :before{
    z-index:-1;
    width: 100%;
    height: 100%;
    position:absolute;
    left:0;
    top:0;
    content: '';
    background: url('/images/checker-bw.svg');
    background-color: hsl(205, 33%, 17%); /* ${({ theme }) => theme.slateThree}; */
    background-blend-mode: multiply;
    opacity: 0.16;
  }
`;


const Title = styled.div`
  ${({ theme }) => theme.font.title18_700};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
`;

const ButtonRoll = styled(Button)`
  height: 100%;
  width: 100%;
  ${({ theme }) => theme.font.accent};
  padding: 0;
  margin: 0;
`;

export default connect(
  state => ({
    diceData: state.widgets.dice || {},
    supporter: state.auth.supporter,
    settings: state.auth.user.settings || {},
    t: state.lang.t,
  }),
  dispatch => bindActionCreators({
    settingsUpdate: authUserSettingsUpdate(widgets.dice.id),
  }, dispatch),
)(
  class extends React.Component {
    state = {
      testDiceWinners: [],
      activeRoll: false,
    };


    diceTestInterval = null;

    sendTestDiceWinners = () => {
      if (this.state.testDiceWinners.length >= 1) return;
      const { data } = this.props;
      const {
        // prizes: _prizes = [],
        amount = 1,
        rolls: _rolls = 1,
      } = data || {};
      // const prizes = Object.keys(_prizes);
      const currentAmount = 500;

      const allRollsOnAmount = Math.floor(currentAmount / amount);
      if (!allRollsOnAmount) return;
      const rolls = Number(_rolls);

      const currentRolls = allRollsOnAmount > rolls ? rolls : allRollsOnAmount;

      this.setState(state => ({
        ...state,
        activeRoll: true,
        testDiceWinners: [
          ...state.testDiceWinners,
          {
            to: 'test',
            token: 'test',
            user: 'Tippername',
            amount,
            currentRolls,
          },
        ],
      }));
    }

    shiftTestDiceWinners = () => {
      const {
        testDiceWinners
      } = this.state
      const _testDiceWinners = testDiceWinners;
      this.setState(state => ({
        ...state,
        activeRoll: false,
        testDiceWinners: _testDiceWinners.slice(1),
      }));
    }

    render() {
      const {
        diceData,
        supporter,
        settings,
        settingsUpdate,
        t,
      } = this.props;
      const { testDiceWinners, activeRoll } = this.state;
      const configDefault = (diceData && diceData.default) || {};
      const diceSettings = {
          ...settingsDefault.dice,
          ...(settings.dice || {}),
        };

      return (
        <React.Fragment>
          <Row>
            <Title>{t.preview}</Title>
            <CheckBox
              checked={!diceSettings.mute}
              onChange={() => settingsUpdate({ mute: !diceSettings.mute })}
              themeFont="normal"
            >
              Preview sound
            </CheckBox>
          </Row>
          <RenderPreview>
            <RenderRollTheDice
              data={testDiceWinners}
              config={configDefault}
              supporter={supporter}
              renderShiftDiceData={() => this.shiftTestDiceWinners()}
              settings={diceSettings}
              t={t}
              isPreview
            />
          </RenderPreview>
          <Row>
            <ButtonRoll
              text="Start roll"
              onClick={() => this.sendTestDiceWinners()}
              disabled={activeRoll}
            >
              {activeRoll ? 'Roll  in progress...' : 'Start roll'}
            </ButtonRoll>
          </Row>
        </React.Fragment>
      );
    }
  },
);
