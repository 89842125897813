import { raceDataDefault } from 'constants/race';

export const RENDER_INIT = 'RENDER_INIT';
export const RENDER_INIT_ERROR = 'RENDER_INIT_ERROR';
export const RENDER_INIT_SUCCESS_CONNECT = 'RENDER_INIT_SUCCESS_CONNECT';
export const RENDER_SET_PORT = 'RENDER_SET_PORT';
export const RENDER_WIDGET_DATA = 'RENDER_WIDGET_DATA';
export const RENDER_WIDGET_PUSH = 'RENDER_WIDGET_PUSH';
export const RENDER_WIDGET_ITEM_REMOVE = 'RENDER_WIDGET_ITEM_REMOVE';
export const RENDER_GOALS_DATA = 'RENDER_GOALS_DATA';
export const RENDER_ADD_USERS = 'RENDER_ADD_USERS';

export const RENDER_DICE_DATA = 'RENDER_DICE_DATA';
export const RENDER_SHIFT_DICE_DATA = 'RENDER_SHIFT_DICE_DATA';

export const RENDER_RACE_DATA = 'RENDER_RACE_DATA';
export const RENDER_RACE_OPTIMISTIC_ACTIVE_TO_INACTIVE = 'RENDER_RACE_OPTIMISTIC_ACTIVE_TO_INACTIVE';

export const renderInit = ({ widgetId, streamAccessToken }) => ({ widgetId, streamAccessToken, type: RENDER_INIT });
export const renderSetPort = port => ({ port, type: RENDER_SET_PORT });
export const renderWidgetData = data => ({ data, type: RENDER_WIDGET_DATA });
export const renderWidgetPush = item => ({ item, type: RENDER_WIDGET_PUSH });
export const renderWidgetItemRemove = itemId => ({ itemId, type: RENDER_WIDGET_ITEM_REMOVE });
export const renderGoalsData = payload => ({ type: RENDER_GOALS_DATA, payload });
export const renderAddUsers = (key, payload) => ({ type: RENDER_ADD_USERS, key, payload });

export const renderDiceData = payload => ({ type: RENDER_DICE_DATA, payload });
export const renderShiftDiceData = () => ({ type: RENDER_SHIFT_DICE_DATA });

export const renderRaceData = payload => ({ type: RENDER_RACE_DATA, payload });
export const renderRaceOptimisticActiveToInactive = name => ({ type: RENDER_RACE_OPTIMISTIC_ACTIVE_TO_INACTIVE, payload: { name } });

const raceInitial = { default: { ...raceDataDefault } };

export default (state = { race: raceInitial }, action) => {
  switch (action.type) {
    case RENDER_INIT:
      return ({
        ...state,
        widgetId: action.widgetId,
        streamAccessToken: action.streamAccessToken,
      });
    case RENDER_INIT_ERROR:
      return ({
        ...state,
        connectionError: action.error,
      });
    case RENDER_INIT_SUCCESS_CONNECT:
      return ({ ...state, connectionError: '' });
    case RENDER_SET_PORT:
      return ({
        ...state,
        port: action.port,
      });
    case RENDER_WIDGET_DATA:
      return ({
        ...state,
        data: { ...action.data },
      });
    case RENDER_RACE_DATA: {
      if (action.payload && action.payload.default) {
        const active = (action.payload.default.active || {});
        const date = Date.now();
        // this need for not showing expired races
        const filteredActive = Object.values(active)
          .filter(({ expireAt }) => expireAt - date > 0)
          .reduce((acc, next) => ({ ...acc, [next.name]: next }), {});
        return {
          ...state,
          race: {
            default: {
              preset: {
                ...raceDataDefault.preset,
                ...(action.payload.default.preset || {}),
              },
              appearance: {
                ...raceDataDefault.appearance,
                ...(action.payload.default.appearance || {}),
              },
              active: {
                ...raceDataDefault.active,
                ...filteredActive,
              },
              inactive: {
                ...raceDataDefault.inactive,
                ...(action.payload.default.inactive || {}),
              },
            },
          },
        };
      }
      return {
        ...state,
        race: {
          default: { ...raceDataDefault },
        },
      };
    }
    case RENDER_RACE_OPTIMISTIC_ACTIVE_TO_INACTIVE: {
      if (state.race.default.active[action.payload.name]) {
        const newActive = { ...state.race.default.active };
        delete newActive[action.payload.name];
        return {
          ...state,
          race: {
            ...state.race,
            default: {
              ...state.race.default,
              active: newActive,
              inactive: {
                ...state.race.default.inactive,
                [action.payload.name]: state.race.default.active[action.payload.name],
              },
            },
          },
        };
      }
      return state;
    }
    case RENDER_WIDGET_PUSH: {
      return ({
        ...state,
        items: {
          ...state.items,
          [action.item.id]: action.item.item,
        },
      });
    }
    case RENDER_WIDGET_ITEM_REMOVE: {
      const _state = { ...state, items: { ...state.items } };
      delete _state.items[action.itemId];
      return _state;
    }
    case RENDER_GOALS_DATA:
      return ({
        ...state,
        goalsData: { ...action.payload },
      });
    case RENDER_ADD_USERS:
      return ({
        ...state,
        [action.key]: { ...action.payload },
      });
    case RENDER_DICE_DATA:
      return ({
        ...state,
        diceData: [...(state.diceData || []), action.payload],
      });
    case RENDER_SHIFT_DICE_DATA:
      return ({
        ...state,
        diceData: (state.diceData || []).slice(1),
      });
    default:
      return ({
        ...state,
      });
  }
};
