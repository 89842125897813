import React from 'react';

import { animationPartOfWidget, durationLowToHigh } from 'utils';

import {
  Section,
  FormItem,
  NewSlider,
  DropDown,
  CheckBox,
} from 'components/UI';


import {
  Row,
  Col1,
  Label,
} from './style';

type Props = {
  data: {
    animationEnabled: boolean,
    animation: number,
    animationDuration: number,
  }
}

export default class AnimationBlock extends React.Component<Props> {
  render() {
    const {
      data,
      widgetUpdate,
      borderTop,
    } = this.props;

    return (
      <Section borderTop={borderTop}>
        <FormItem label="Animation" themeFont="header" header>
          <CheckBox
            checked={data.animationEnabled}
            onChange={() => widgetUpdate({ animationEnabled: !data.animationEnabled })}
            themeFont="headerCheckbox"
          >
            Enable animation
          </CheckBox>
        </FormItem>
        <Row disabled={data.animationEnabled === false}>
          <Col1 marginRight="16">
            <DropDown
              width="100%"
              items={animationPartOfWidget}
              value={Number(data.animation)}
              onSelect={animationSelect => widgetUpdate({ animation: Number(animationSelect) })}
              topDDmenu
            />
          </Col1>
          <Col1>
            <Row>
              <Label>Speed</Label>
              <NewSlider
                value={durationLowToHigh(data.animationDuration)}
                min={0.1}
                max={5}
                step={0.1}
                onChange={time => widgetUpdate({ animationDuration: durationLowToHigh(time) })}
                decPlace={1}
              />
            </Row>
          </Col1>
        </Row>
      </Section>
    );
  }
}
