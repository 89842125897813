import React from 'react';
import { Radio } from 'react-bootstrap';

import './SelectPosition.css';

const positions = {
  vertical: [
    { value: 2, title: 'Top' },
    { value: 1, title: 'Middle' },
    { value: 0, title: 'Bottom' },
  ],
  horizontal: [
    { value: 0, title: 'Left' },
    { value: 1, title: 'Center' },
    { value: 2, title: 'Right' },
  ],
};

export class SelectPosition extends React.Component {
  groupKey = Math.random();

  render() {
    const { groupKey } = this;
    const {
      title, direction = 'vertical', value: selectedValue, onChange,
    } = this.props;
    return (
      <div>
        {title}
        <div>
          {positions[direction].map(({ value, title }) => (
            <Radio
              key={value}
              name={`selectPosition${groupKey}`}
              checked={value === selectedValue}
              onChange={() => onChange(value)}
              inline={direction === 'horizontal'}
            >
              {title}
            </Radio>
          ))}
        </div>
      </div>
    );
  }
}
