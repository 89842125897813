import { REHYDRATE } from 'redux-persist/lib/constants';
import { raceDataDefault } from 'constants';
import { removeUndefinedFields } from 'utils';

export const WIDGETS_LOAD = 'WIDGETS_LOAD';
export const WIDGETS_UPDATE = 'WIDGETS_UPDATE';
export const WIDGETS_COMPONENTS_UPDATE = 'WIDGETS_COMPONENTS_UPDATE';
export const WIDGETS_RESET = 'WIDGETS_RESET';
export const WIDGETS_ACTIVE_SUBSCRIBE = 'WIDGETS_ACTIVE_SUBSCRIBE';
export const WIDGETS_ACTIVE_UPDATE = 'WIDGETS_ACTIVE_UPDATE';
export const WIDGETS_UPLOAD_IMAGE = 'WIDGETS_UPLOAD_IMAGE';
export const WIDGETS_ADD_ITEM = 'WIDGETS_ADD_ITEM';
export const WIDGETS_UPLOAD_FILE = 'WIDGETS_UPLOAD_FILE';
export const WIDGETS_TEST_ALERT = 'WIDGETS_TEST_ALERT';

export const WIDGETS_BUY_RACE = 'WIDGETS_BUY_RACE';
export const WIDGETS_RACE_ACTIVE_TO_INACTIVE = 'WIDGETS_RACE_ACTIVE_TO_INACTIVE';
export const WIDGETS_INCREASE_RACE_AMOUNT = 'WIDGETS_INCREASE_RACE_AMOUNT';
export const WIDGETS_INCREASE_RACE_TIME = 'WIDGETS_INCREASE_RACE_TIME';

export const widgetsLoad = widgets => ({ type: WIDGETS_LOAD, widgets });
export const widgetUpdate = (widgetId, payload) => ({
  type: WIDGETS_UPDATE,
  widgetId,
  payload,
});
export const getActionWidgetsComponentsUpdate = (widgetId, selectedPreset, componentName) => payload => ({
  type: WIDGETS_COMPONENTS_UPDATE,
  widgetId,
  selectedPreset,
  componentName,
  payload,
});
export const widgetRaceActiveToInactive = (username, updateOptions, reverse) => ({
  type: WIDGETS_RACE_ACTIVE_TO_INACTIVE,
  username,
  reverse,
  updateOptions,
});
export const widgetBuyRace = name => ({
  type: WIDGETS_BUY_RACE,
  name,
});
export const widgetIncreaseRaceAmount = (name, amount) => ({
  type: WIDGETS_INCREASE_RACE_AMOUNT,
  name,
  amount,
});
export const widgetIncreaseRaceTime = (name, time) => ({
  type: WIDGETS_INCREASE_RACE_TIME,
  name,
  time,
});
export const widgetReset = widgetId => ({
  type: WIDGETS_RESET,
  widgetId,
});
export const widgetsActiveSubscribe = () => ({
  type: WIDGETS_ACTIVE_SUBSCRIBE,
});
export const widgetsActiveUpdate = (userId, payload) => ({
  type: WIDGETS_ACTIVE_UPDATE,
  userId,
  payload,
});
export const widgetsUploadImage = file => ({
  type: WIDGETS_UPLOAD_IMAGE,
  file,
});

export const widgetsUploadFile = (file, type) => ({
  type: WIDGETS_UPLOAD_FILE,
  fileType: type,
  file,
});

export const widgetsAddItem = (widgetId, item) => ({ type: WIDGETS_ADD_ITEM, widgetId, item });

export const widgetsTestAlert = amount => ({ type: WIDGETS_TEST_ALERT, amount });


const initialState = {
  race: {
    default: { ...raceDataDefault },
  },
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case REHYDRATE: {
      if (!action.payload) return state;
      const { widgets } = action.payload;
      // remove deprecated field saved to local storage
      if (widgets) {
        delete widgets.activeWidgets;
      }
      return {
        ...initialState,
        ...(action.payload.widgets || initialState),
      };
    }
    case WIDGETS_UPDATE:
      return {
        ...state,
        [action.widgetId]: {
          ...state[action.widgetId],
          ...action.payload,
        },
      };
    case WIDGETS_COMPONENTS_UPDATE: {
      const selectedWidget = state[action.widgetId] || {};
      return {
        ...state,
        [action.widgetId]: {
          ...selectedWidget,
          [action.selectedPreset]: {
            ...(selectedWidget[action.selectedPreset] || {}),
            [action.componentName]: {
              ...((selectedWidget[action.selectedPreset] || {})[action.componentName] || {}),
              ...removeUndefinedFields(action.payload),
            },
          },
        },
      };
    }
    case WIDGETS_RESET:
      return {
        ...state,
        [action.widgetId]: null,
      };
    case WIDGETS_LOAD:
      return {
        ...state,
        ...action.widgets,
      };
    case WIDGETS_ACTIVE_UPDATE:
      return {
        ...state,
        activeWidgets: {
          ...state.activeWidgets,
          [action.userId]: {
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
};
