import React, { Component } from 'react';
import betaWhiteImg from 'assets/images/beta_white.svg';
import idManLightImg from 'assets/images/id-man_light.svg';

import { Service_ID, servicesURLs } from 'constants';

import chaturbateLogo from 'assets/images/CB-logo.svg';
import bongacamsLogo from 'assets/images/BC-logo.svg';
import myfreecamsLogo from 'assets/images/MFC-logo.svg';
import stripchatLogo from 'assets/images/SC-logo.svg';
import cam4Logo from 'assets/images/C4-logo.svg';
import camsodaLogo from 'assets/images/camsoda-logo.png';
import manyvidsLogo from 'assets/images/manyvids-logo.png';
import amateurLogo from 'assets/images/amateur-logo.png';
import camsLogo from 'assets/images/cams-com-logo.svg';

import {
  Container,
  Card,
  Title,
  Beta,
  LogoContainer,
  Logo,
  New,
  ID,
  IDimageLeft,
  IDimageRight,
  ContainerGradient,
  InfoContainer,
  ClickText,
} from './style';

const logos = {
  [Service_ID.chaturbate]: chaturbateLogo,
  [Service_ID.bongacams]: bongacamsLogo,
  [Service_ID.myfreecams]: myfreecamsLogo,
  [Service_ID.stripchat]: stripchatLogo,
  [Service_ID.cam4]: cam4Logo,
  [Service_ID.camsoda]: camsodaLogo,
  [Service_ID.cams]: camsLogo,
  [Service_ID.manyvids]: manyvidsLogo,
  [Service_ID.amateur]: amateurLogo,
};

export class CardConnect extends Component {
  handleOnClick = () => {
    const { service, history } = this.props;
    if (history) {
      history.push({
        pathname: `/connect-service/${service}`,
        state: { fromPathname: history.location.pathname },
      });
    }
  };

  render() {
    const {
      id,
      service,
      history,
      type,
      beta,
      newBadge,
    } = this.props;
    const title = servicesURLs[service].replace('https://', '');
    return (
      <Container onClick={this.handleOnClick} type={type} service={service}>
        {beta && <Beta src={betaWhiteImg} alt="beta_white.svg" />}
        <Card>
          {newBadge && <New>NEW</New>}
          <LogoContainer>
            <Logo src={logos[service]} alt={`${title}_img.png`} />
          </LogoContainer>
          <ContainerGradient>
            <InfoContainer>
              <Title>{title}</Title>
              {history && (!id
                ? <ClickText>Click to connect</ClickText>
                : (
                  <ID>
                    <IDimageLeft src={idManLightImg} alt="id-man.svg" margin="0 8px 0 0" />
                    <span>{id}</span>
                  </ID>
                ))}
            </InfoContainer>
            {history && id && <IDimageRight src={idManLightImg} alt="id-man.svg" margin="10px 0 0 0" />}
          </ContainerGradient>
        </Card>
      </Container>
    );
  }
}
