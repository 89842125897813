import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import spinnerImg from 'assets/images/dark_spinner.png';

import { BetaTestText } from 'components';
import { servicesURLs } from 'constants';
import ModalTooltip from './ModalTooltip';

import {
  Container,
  MainText,
  InputText,
  IdInput,
  ExampleContainer,
  ExampleText,
  OuterLink,
  Warning,
  WarningContainer,
  ContainerBetaTest,
  ButtonContainer,
  Button,
  Spinner,
  ButtonDeleteContainer,
  ResetLink,
} from './style';

export class EditId extends Component {
  static defaultProps = {
    id: '',
  };

  state = {
    serviceId: this.props.id,
    prevPropsId: this.props.id,
    resetActive: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.id !== state.prevPropsId) {
      return { ...state, serviceId: props.id, prevPropsId: props.id };
    }
    return null;
  }

  componentDidMount() {
    this.textInput.focus();
  }

  onChange = (e) => {
    const { loading } = this.props;
    if (loading) return;
    const _serviceId = e.target.value.replace(/[ ]/g, '');
    this.setState({ serviceId: _serviceId });
    this.props.onChange(e);
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { onSubmit, loading } = this.props;
    const { serviceId } = this.state;
    if (!serviceId || loading) return;
    onSubmit(serviceId);
  };

  /* ModalTooltip */

  modal = React.createRef();

  showResetModal = () => {
    this.setState({ resetActive: true }, () => document.addEventListener('click', this.onClick));
  };

  onClick = (e) => {
    const modalNode = ReactDOM.findDOMNode(this.modal.current);
    const isOutClick = !(modalNode && modalNode.contains(e.target));
    if (isOutClick) this.hideResetModal(e);
  };

  hideResetModal = (e) => {
    e.stopPropagation();
    this.setState({ resetActive: false }, () => document.removeEventListener('click', this.onClick));
  };

  deleteServiceId = (e, service) => {
    this.props.onDeleteServiceId(service);
    this.hideResetModal(e);
  };


  render() {
    const {
      exampleUserName,
      betaTest,
      warningText,
      service,
      onCancel,
      loading,
      id,
    } = this.props;
    const { serviceId, resetActive } = this.state;
    const serviceLink = servicesURLs[service].replace('https://', '');
    return (
      <Container onSubmit={this.onSubmit}>
        <MainText>
          In order to connect to your stream, TipAlerts requires your username or link to your stream. Paste your username or stream link in a field below.
        </MainText>
        <InputText>
          {'Your\xa0'}
          <span>username</span>
          {'\xa0or\xa0'}
          <span>link to a stream</span>
          {`\xa0at ${serviceLink}`}
        </InputText>
        <IdInput
          value={serviceId}
          onChange={this.onChange}
          ref={(input) => { this.textInput = input; }}
        />
        <ExampleContainer>
          <ExampleText>
            {`Link example: ${serviceLink}/`}
            <span>{exampleUserName}</span>
          </ExampleText>
          {false && <OuterLink>Help</OuterLink>}
        </ExampleContainer>
        <WarningContainer>
          <Warning>{warningText}</Warning>
        </WarningContainer>
        <ContainerBetaTest>
          {betaTest && (<BetaTestText />)}
        </ContainerBetaTest>
        <ButtonContainer>
          <ButtonDeleteContainer>
            {id && (
            <ResetLink active={resetActive} onClick={this.showResetModal}>
              Delete stream profile
              <ModalTooltip
                ref={this.modal}
                show={resetActive}
                title={`Delete stream profile '${id}'?`}
                text="Your widgets will no longer be shown on this stream."
                leftButton={{
                  label: 'Cancel',
                  onClick: this.hideResetModal,
                }}
                rightButton={{
                  label: 'Yes, delete',
                  onClick: (e => this.deleteServiceId(e, service)),
                }}
              />
            </ResetLink>)}
          </ButtonDeleteContainer>
          <Button type="button" onClick={onCancel}>Cancel</Button>
          <Button type="submit" orange isLoading={loading} disabled={!serviceId}>
            {loading ? <Spinner src={spinnerImg} alt="spinner.png" /> : 'Connect'}
          </Button>
        </ButtonContainer>
      </Container>
    );
  }
}
