import React, { Component } from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinkit';
import { HashLink } from 'react-router-hash-link';

import { CheckMarkStroke } from '../PriceComponent';


const Basic = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  ${({ hasMargin }) => hasMargin ? 'margin-left: 30px' : ''};
  flex: 1;

  background: linear-gradient(236.74deg, #4a687e 0%, #385367 100%);
  box-shadow: 0px 4px 4px rgba(39, 59, 73, 0.5);
  border-top: 2px solid ${({ color }) => color};
  position: relative;
  transition: all 0.3s ease-in-out;

  cursor: ${({ loading, disabled }) => ((loading || disabled) ? 'auto' : 'pointer')};

  ${({ loading, disabled }) => ((loading || disabled) ? '' : '&:hover { box-shadow: 0px 12px 36px #182732; }')}
`;

const BasicLink = styled(
  ({
    loading,
    disabled,
    ...rest
  }) => (
    <HashLink {...rest} />
  ),
)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: transparent;
  border: none;

  cursor: ${({ loading, disabled }) => ((loading || disabled) ? 'auto' : 'pointer')};
  outline: none;
  z-index: 0;

  &:visited, &:hover, &:active, &:focus {
    border: none;
    outline: none;
  }
`;

const Content = styled.div`
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding-bottom: 114px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 23px;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 40px;
  line-height: 49px;
`;

const Current = styled.div`
  width: 95px;
  height: 34px;
  color: #112737;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  margin: 4px 0 0 8px;
  
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  background: ${({ background }) => background};
  border-radius: 4px;
`;

const AboutTitle = styled.span`
  margin-top: 3px;
  margin-bottom: 33px;

  font-size: 18px;
  line-height: 22px;
  display: flex;
`;

const Button = styled.div`
  background: ${({ colorBackground }) => colorBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 112px;

  border-radius: 0 0 6px 6px;
  overflow: hidden;
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  padding: 10px 24px 24px 24px;
`;

const Price = styled.span`
  display: flex;
  flex-direction: column;
  color: black;
  text-align: left;

  font-style: italic;
  font-weight: 800;
  font-size: 48px;
  line-height: 59px;
  >p{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    margin: -4px 0 0 0;

  }
`;

const Upgrade = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  color: ${({ color }) => color}
  background-color: #112737;
  border-radius: 4px;
  
  width: 161px;
  height: 63px;
  
  text-align: center
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  
  padding: 10px 20px;
  margin-bottom: 4px;
`;

const Expires = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  text-align: right;
  color: #112737;
`;

type Props = {
  theme: string,
};

export default class ButtonPrice extends Component<Props> {
  onUpgradeClick = (e) => {
    e.stopPropagation();
    this.props.onUpgradeClick();
  };

  onClick = (e) => {
    const {
      disabled,
      loading,
      upgrade,
      expires,
      onUpgradeClick,
      onClick,
      theme,
      link,
    } = this.props;
    if (disabled || loading || link) return e.preventDefault();
    if (upgrade && !expires && onUpgradeClick) onUpgradeClick(e);
    else if (onClick) {
      const mFunc = onClick(theme);
      if (mFunc) mFunc(e);
    }
    return null;
  };

  render() {
    const {
      theme,
      current,
      upgrade,
      upgradeLoading,
      expires,
      loading,
      disabled,
      children,
      link,
      price,
      total,
    } = this.props;
    let color;
    let colorBackground;
    let title;
    let aboutTitle;
    let StrokeComponent;
    let upgradeColor;
    if (theme === 'basic') {
      color = '#5AF5FF';
      colorBackground = 'linear-gradient(252.72deg, #5AFFE1 0%, #5AF5FF 100%)';
      title = 'Basic';
      aboutTitle = '';
      upgradeColor = '#5afcea';
      StrokeComponent = (
        <div>
          <CheckMarkStroke text="Alerts widget" colorСircle={color} />
          <CheckMarkStroke text="Layout customization" colorСircle={color} />
          <CheckMarkStroke text="Upload your own images" colorСircle={color} />
        </div>
      );
    } else if (theme === 'premium') {
      color = '#FFB65A';
      colorBackground = 'linear-gradient(253.15deg, #FF9F5A -0.24%, #FFB65A 100%)';
      title = 'Premium';
      aboutTitle = 'Maximum creative abilities';
      upgradeColor = '#FFA75B';
      StrokeComponent = (
        <div>
          <CheckMarkStroke text="All widgets and features" colorСircle={color} />
          <CheckMarkStroke text="Unlimited presets" colorСircle={color} />
          <CheckMarkStroke text="Upload your own images" colorСircle={color} />
          <CheckMarkStroke text="Sound effects" colorСircle={color} />
          <CheckMarkStroke text="More layout options" colorСircle={color} />
          <CheckMarkStroke text="Priority in support" colorСircle={color} />
        </div>
      );
    }
    return (
      <Basic
        color={color}
        disabled={disabled}
        loading={loading}
        onClick={this.onClick}
        hasMargin={theme === 'premium'}
      >
        <Content>
          <TitleContainer>
            <Title>{title}</Title>
            {current && <Current background={colorBackground}>Current</Current>}
          </TitleContainer>
          <AboutTitle>{aboutTitle}</AboutTitle>
          {StrokeComponent}
        </Content>
        {children}
        <Button colorBackground={colorBackground}>
          {loading
            ? <Spinner name="wave" color="black" />
            : (
              <ButtonContent>
                <Price>
                  {`$${price}`}
                  <p>per month{total !== price ? `, $${total} total` : ''}</p>
                </Price>
                {upgrade && !expires && <Upgrade color={upgradeColor}>{upgradeLoading ? <Spinner name="wave" color={upgradeColor} /> : 'Upgrade'}</Upgrade>}
                {expires && <Expires>{`Expires ${expires}`}</Expires>}
              </ButtonContent>
            )}
        </Button>
        {link && !loading && !disabled && (
          <BasicLink
            to={link}
            smooth
            disabled={disabled}
            loading={loading}
          />
        )}
      </Basic>
    );
  }
}
