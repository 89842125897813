// @flow

import React from 'react';
import styled from 'styled-components';

import { GoalView } from './GoalView';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

type Props = {
  configuration: {
    amountVariations: {},
  },
  data: {},
  isPreview: boolean,
  premium: boolean,
};

export const RenderGoals = (props: Props) => { // not used
  const {
    data,
    isPreview,
    premium,
  } = props;
  if (!isPreview && !premium) return null;
  return (
    <Container>
      {isPreview
        ? <GoalView data={data} />
        : Object.keys(data || {}).map((key) => {
          const userData = data[key];
          if (!userData.preset || !userData.preset.goalValue) {
            return null;
          }
          if (typeof userData.preset.enabled !== 'undefined' && userData.preset.enabled === false) {
            return null;
          }
          return (
            <GoalView key={key} data={userData} />
          );
        })}
    </Container>
  );
};
