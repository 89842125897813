import React from 'react';
import styled from 'styled-components';


import {
  Section,
  FormItem,
  CheckBoxOne,
} from 'components/UI';

import {
  TabCheckBox,
  Edge,
  Edge90,
  TabRow,
} from './style';


const Container = styled.div`

`;

export default class PositionBlock extends React.Component {
  render() {
    const {
      data,
      widgetUpdate,
    } = this.props;
    return (
      <Container>
        <Section borderTop>
          <FormItem label="Position" themeFont="header" header />
          <TabCheckBox>
            <TabRow>
              <CheckBoxOne
                size={16}
                checked={Number(data.horizontalPosition) === 0 && Number(data.verticalPosition) === 2}
                onClick={() => widgetUpdate({
                  horizontalPosition: 0,
                  verticalPosition: 2,
                })}
              />
              <Edge />
              <CheckBoxOne
                size={16}
                checked={Number(data.horizontalPosition) === 1 && Number(data.verticalPosition) === 2}
                onClick={() => widgetUpdate({
                  horizontalPosition: 1,
                  verticalPosition: 2,
                })}
              />
              <Edge />
              <CheckBoxOne
                size={16}
                checked={Number(data.horizontalPosition) === 2 && Number(data.verticalPosition) === 2}
                onClick={() => widgetUpdate({
                  horizontalPosition: 2,
                  verticalPosition: 2,
                })}
              />
            </TabRow>
            <TabRow>
              <Edge90 />
              <Edge90 />
              <Edge90 />
            </TabRow>
            <TabRow>
              <CheckBoxOne
                size={16}
                checked={Number(data.horizontalPosition) === 0 && Number(data.verticalPosition) === 1}
                onClick={() => widgetUpdate({
                  horizontalPosition: 0,
                  verticalPosition: 1,
                })}
              />
              <Edge />
              <CheckBoxOne
                size={16}
                checked={Number(data.horizontalPosition) === 1 && Number(data.verticalPosition) === 1}
                onClick={() => widgetUpdate({
                  horizontalPosition: 1,
                  verticalPosition: 1,
                })}
              />
              <Edge />
              <CheckBoxOne
                size={16}
                checked={Number(data.horizontalPosition) === 2 && Number(data.verticalPosition) === 1}
                onClick={() => widgetUpdate({
                  horizontalPosition: 2,
                  verticalPosition: 1,
                })}
              />
            </TabRow>
            <TabRow>
              <Edge90 />
              <Edge90 />
              <Edge90 />
            </TabRow>
            <TabRow>
              <CheckBoxOne
                size={16}
                checked={Number(data.horizontalPosition) === 0 && Number(data.verticalPosition) === 0}
                onClick={() => widgetUpdate({
                  horizontalPosition: 0,
                  verticalPosition: 0,
                })}
              />
              <Edge />
              <CheckBoxOne
                size={16}
                checked={Number(data.horizontalPosition) === 1 && Number(data.verticalPosition) === 0}
                onClick={() => widgetUpdate({
                  horizontalPosition: 1,
                  verticalPosition: 0,
                })}
              />
              <Edge />
              <CheckBoxOne
                size={16}
                checked={Number(data.horizontalPosition) === 2 && Number(data.verticalPosition) === 0}
                onClick={() => widgetUpdate({
                  horizontalPosition: 2,
                  verticalPosition: 0,
                })}
              />
            </TabRow>
          </TabCheckBox>
        </Section>
      </Container>
    );
  }
}
