// @flow
/* eslint-disable */
import React from 'react';

import styled from 'styled-components';

import { dicePositions } from 'constants';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const RadioButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  border: 1px solid transparent;

  transition: background-color 0.3s ease-in-out;

  padding: 3px 1px 1px 1px;
  background-color: ${({ checked }) => checked ? '#FFB65A' : '#617C8F'};

  border-radius: 4px;

  width: 145px;
  height: 123px;

  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.orangeButtonHover};
  }
  &:active {
    border: 1px solid ${({ theme }) => theme.orangeButtonActive};
  }
  margin-right: 0px 4px;
`;

const RadioTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  margin-right: 6px;
  
  width: 12px;
  height: 12px;
  border-radius: 50%;
  
  background-color: ${({ checked }) => checked ? '#333333' : '#314350'};
  
  transition: 0.3s ease-in-out;
  transition-property: opacity, background-color;
  
  &:after {
    content: '';
    opacity: ${({ checked }) => checked ? 1 : 0}
    
    width: 6px;
    height: 6px;
    border-radius: 50%;
    
    background-color: #FFB65A;
  }
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  transition: color 0.3s ease-in-out;
  color: ${({ checked }) => checked ? '#333333' : '#FFF'};
  white-space: no-wrap;
`;

const MiniPreview = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  
  width: 100%;
  height: 94px;
  border-radius: 4px;
  
  padding: 3px;
  
  background-color: #314350;
  
  ${({ reverse }) => {
    const value =  typeof reverse === 'string' ? reverse : '';
  return (
    reverse ? `-moz-transform:scale${value}(-1); -o-transform:scale${value}(-1); n-webkit-transform:scale${value}(-1); transform:scale${value}(-1);` : ''
  );
}}
`;

const MiniPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  margin-${({ margin }) => margin || 'left'}: 3px;
  
  position: relative;
  
  
`;

const PreviewRow = styled.div`
  width: ${({ width }) => width || 30}px;
  height: 4px;
  background: ${({ color }) => color || '#FFFFFF'};
  border-radius: 2px;
  margin-${({ margin }) => margin || 'bottom'}: 1px;
`;

const PreviewList = styled.div`
  width: 15px;
  height: 29px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.65) 0%, #FFFFFF 17.19%, #FFFFFF 100%);
  border-${({ position }) => position || 'bottom'}-left-radius: 2px;
  border-${({ position }) => position || 'bottom'}-right-radius: 2px;
  
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    
    ${({ position }) => position || 'bottom'}: 6px;

    background: #F9AB36;
  }
`;

const PreviewDiceOrange = styled.div`
  position: absolute;
  width: 5px;
  height: 5px;
  
  left: -3px;

  background-color: #F9AB36;
  border-radius: 1px;
  transform: rotate(30deg);
`;

const PreviewDiceWhite = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  
  top: -1px;
  
  background-color: #F7F7F7;
  border-radius: 1px;
  transform: rotate(-10deg);
`;

const DiceRadioButton = ({ reverse }) => (
  <MiniPreview reverse={reverse}>
    <MiniPreviewContainer>
      <PreviewRow margin width={28} />
      <PreviewList />
      <PreviewDiceOrange />
      <PreviewDiceWhite />
    </MiniPreviewContainer>
    <MiniPreviewContainer alignItems="flex-end">
      <PreviewRow />
      <PreviewRow width={33} color="#F9AB36" />
      <PreviewRow width={21} color="#F9AB36" />
    </MiniPreviewContainer>
  </MiniPreview>
);

export default class DiceRadio extends React.Component {
  render() {
    const {
      checked = 'top-left',
      onChange,
    } = this.props;
    return (
      <Container>
        {Object.keys(dicePositions).map((key) => {
          const isChecked = key === checked;
          const { title, reversePosition } = dicePositions[key];
          return (
            <RadioButton key={title} checked={isChecked} onClick={() => onChange(key)}>
              <RadioTitle>
                <Circle checked={isChecked} />
                <Label checked={isChecked}>{title}</Label>
              </RadioTitle>
              <DiceRadioButton reverse={reversePosition} />
            </RadioButton>
          )
        })}
      </Container>
    );
  }
}
