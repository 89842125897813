import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  authGetPaymentLink,
  authPaymentPatreonClear,
  authTrialProgressToCancel,
  authPaymentFSModalShow,
} from 'store/reducers/auth';

import { PatreonModal, FastSpringModal } from 'components';
import { parseTillDateString } from 'utils';

import {
  ContainerContent,
  Title,
  TitleContainer,
  AboutTitle,
  AboutTitleContainer,
  SubscriptionPlanContainer,
  LinkContainer,
  SeeDetailLink,
  ChoisesContainer,
  SubscriptionPeriodsContainer,
  TaxNote,
} from './style';

import ButtonPrice from './ButtonPrice';
import ButtonTrial from './ButtonTrial';
import { SegmentedControl } from './SegmentedControl';

const upgradePrices = {
  mandarin: till => (5 / 30 * (parseTillDateString(till).diff(moment(), 'days') + 1)).toFixed(2),
  fastspring: till => (5 / 30 * (parseTillDateString(till).diff(moment(), 'days') + 1)).toFixed(2),
  patreon: () => 5,
};

const subscriptionDurations = [
  { id: '1m', title: '1 month', duration: 1 },
  {
    id: '3m',
    title: '3 months',
    duration: 3,
    discount: 10,
  },
  {
    id: '6m',
    title: '6 months',
    duration: 6,
    discount: 20,
  },
];

const basicSubscriptionPrice = 5;
const premiumSubscriptionPrice = 10;

export const Header = connect(
  ({ auth, lang: { t } }) => ({
    email: auth.user.email,
    supporter: auth.supporter,
    uid: auth.user.uid,
    paymentPatreonURL: auth.paymentPatreonURL,
    trialInProgress: auth.trialInProgress,
    trialDelay: auth.trialDelay,
    provider: auth.provider,
    paymentShowFSModal: auth.paymentShowFSModal,
    paymentFSFailed: auth.paymentFSFailed,
    t,
  }),
  dispatch => bindActionCreators({
    authGetPaymentLink,
    authPaymentPatreonClear,
    authTrialProgressToCancel,
    authPaymentFSModalShow,
  }, dispatch),
)(class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingType: '', // basic, premium, upgrade
      selectedDurationIndex: 0,
    };
  }

  onClickPurchase = accountType => () => {
    const {
      uid,
      authGetPaymentLink,
      trialInProgress,
      supporter,
    } = this.props;
    if (this.state.loadingType || trialInProgress) return;
    ga('send', 'event', 'Resub', 'click', accountType, { dimension1: uid });//eslint-disable-line
    if (accountType !== 'trial') this.setState({ loadingType: accountType });
    const { id } = subscriptionDurations[this.state.selectedDurationIndex];
    authGetPaymentLink(accountType, !supporter.firstTime, id);
    setTimeout(() => this.setState({ loadingType: '' }), 30000);
  };

  onClickPayPatreon = () => {
    this.setState({ loadingType: '' });
    // required because when user clicked on link
    // it's destroyed by rerender and not opened
    setTimeout(() => {
      const { authPaymentPatreonClear } = this.props;
      authPaymentPatreonClear();
    });
  };

  cancelTrial = () => {
    this.props.authTrialProgressToCancel();
  };

  componentDidUpdate(oldProps) {
    if (this.state.selectedDurationIndex !== 0) {
      if ((!oldProps.supporter || !oldProps.supporter.basic) && this.props.supporter && this.props.supporter.basic) {
        this.setState({ selectedDurationIndex: 0 }); // eslint-disable-line
      }
    }
  }

  render() {
    const {
      loadingType,
      selectedDurationIndex,
    } = this.state;
    const {
      email,
      paymentPatreonURL,
      supporter: {
        basic,
        active,
        special,
        hasTrial,
        till,
      },
      trialInProgress,
      trialDelay,
      provider,
      paymentShowFSModal,
      authPaymentFSModalShow,
      paymentFSFailed,
      t,
    } = this.props;
    const upgradePrice = (upgradePrices[provider] && upgradePrices[provider](till)) || '';

    const isTrial = special === 'trial';
    const isBasic = basic && !active && !isTrial;
    const isPremium = active && !isTrial;
    const hasAnySubscription = isTrial || isBasic || isPremium;
    // const noAnySubscription = !hasAnySubscription;
    const subscriptionsPeriodsAvailable = provider !== 'patreon';

    const premiumLoading = loadingType === 'premium';
    const upgradeLoading = loadingType === 'upgrade';
    const basicLoading = loadingType === 'basic';

    const showTrial = !(hasTrial || hasAnySubscription);

    let title = 'Try TipAlerts';
    if (trialInProgress) {
      title = 'Activating trial...';
    }
    const selectedDuration = subscriptionDurations[selectedDurationIndex];
    const durationDiscount = selectedDuration.discount ? (100 - selectedDuration.discount) / 100 : 1;
    const basicPrice = basicSubscriptionPrice * durationDiscount;
    const basicTotal = basicPrice * selectedDuration.duration;
    const premiumPrice = premiumSubscriptionPrice * durationDiscount;
    const premiumTotal = premiumPrice * selectedDuration.duration;
    return (
      <ContainerContent id="choose">
        <TitleContainer>
          <Title>Choose your subscription</Title>
        </TitleContainer>
        <AboutTitleContainer>
          <AboutTitle>
            {`TipAlerts offers Basic and Premium subscriptions.${(hasTrial || basic || active || isTrial) ? '' : ' You can also activate a 7-days trial to try all features for free.'}`}
          </AboutTitle>
        </AboutTitleContainer>
        <ChoisesContainer>
          {subscriptionsPeriodsAvailable && (
            <SubscriptionPeriodsContainer>
              <p>Bill for</p>
              <SegmentedControl
                items={subscriptionDurations}
                activeItemId={selectedDuration.id}
                onSelect={id => this.setState({ selectedDurationIndex: subscriptionDurations.findIndex(item => item.id === id) })}
              />
            </SubscriptionPeriodsContainer>
          )}
          <SubscriptionPlanContainer>
            <ButtonPrice
              onClick={this.onClickPurchase}
              current={isBasic}
              theme="basic"
              loading={basicLoading}
              expires={isBasic && parseTillDateString(till).format('MMM DD, YYYY')}
              disabled={premiumLoading || trialInProgress || upgradeLoading}
              link={provider === 'patreon' && '/price#subpatreon'}
              price={basicPrice}
              total={basicTotal}
            />
            <ButtonPrice
              onClick={this.onClickPurchase}
              current={isPremium}
              theme="premium"
              loading={premiumLoading}
              expires={isPremium && parseTillDateString(till).format('MMM DD, YYYY')}
              disabled={basicLoading || trialInProgress || upgradeLoading}
              price={premiumPrice}
              total={premiumTotal}
              upgrade={isBasic && upgradePrice}
              onUpgradeClick={this.onClickPurchase('upgrade')}
              upgradeLoading={upgradeLoading}
              link={provider === 'patreon' && '/price#subpatreon'}
            />
          </SubscriptionPlanContainer>
          {showTrial && (
            <ButtonTrial
              title={title}
              onClick={this.onClickPurchase('trial')}
              onCancel={trialDelay && this.cancelTrial}
              loading={trialInProgress}
              notClickable={isTrial}
            />
          )}
          <LinkContainer>
            <SeeDetailLink smooth to="/price#subscriptions_comparison">{'See detailed comparison >'}</SeeDetailLink>
          </LinkContainer>
          <TaxNote>
            Tax not included and will be calculated depends on your region.
            <p>You can view it on the payment form before purchase.</p>
          </TaxNote>
        </ChoisesContainer>
        <PatreonModal
          t={t}
          paymentPatreonURL={paymentPatreonURL}
          email={email}
          onPayClick={this.onClickPayPatreon}
        />
        <FastSpringModal
          show={paymentShowFSModal}
          onHide={() => authPaymentFSModalShow(false)}
          failed={paymentFSFailed}
        />
      </ContainerContent>
    );
  }
});
