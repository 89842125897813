import { createStore, applyMiddleware, compose as originalCompose } from 'redux';
import { createBrowserHistory as createHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import { routerMiddleware } from 'connected-react-router';

import { makeCombinedReducers } from './reducers';
import sagas from './sagas';

export const history = createHistory();
const reactRouterMiddleware = routerMiddleware(history);

const reducers = makeCombinedReducers(history);

const sagaMiddleware = createSagaMiddleware();

const enhancers = [applyMiddleware(reactRouterMiddleware, sagaMiddleware)];

if (process.env.NODE_ENV === 'development') {
  // enhancers.push(DevTools.instrument());
}

const compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || originalCompose;
const enhancer = compose(...enhancers);

const authSecureFieldsFilter = createBlacklistFilter('auth', ['fields', 'sendInviteStatus', 'inProgress']);
const subscriptionExpiredFilter = createBlacklistFilter('auth', ['subscriptionExpired']);
const skipCheckAfterSignUpFilter = createBlacklistFilter('auth', ['skipCheckOnSignUp']);
const langFilter = createBlacklistFilter('lang', ['t']);
const persistConfig = {
  key: 'persist',
  storage,
  blacklist: ['logs', 'widgetsOptions'],
  transforms: [authSecureFieldsFilter, subscriptionExpiredFilter, skipCheckAfterSignUpFilter, langFilter],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, enhancer);

export const persistor = persistStore(store);
sagaMiddleware.run(sagas);

export default store;
