import styled from 'styled-components';

export const Col = styled.div`
  margin-right: 16px;
`;

export const Row = styled.div`
display: flex;
align-items: ${({ baseline }) => (baseline ? 'baseline' : 'center')};
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  width: 130px;
  margin-right: 12px;
`;
