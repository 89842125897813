import React, { Component } from 'react';

import {
  BackgroundContainer,
  FooterContent,
  ButtonContainer,
  ButtonPatreon,
  OutLinkContainer,
  OutLink,
  TextContent,
} from './style';

export class FooterContainer extends Component {
  render() {
    const copyRightWithDate = `© TipAlerts ${new Date().getFullYear()}`;
    return (
      <BackgroundContainer>
        <FooterContent>
          <TextContent>
            {copyRightWithDate}
          </TextContent>
          <OutLinkContainer>
            <OutLink href="https://twitter.com/real_tipalerts">
              <span>Twitter</span>
            </OutLink>
          </OutLinkContainer>
          <OutLinkContainer>
            <OutLink href="https://youtube.com/tipalerts">
              <span>YouTube</span>
            </OutLink>
          </OutLinkContainer>
          <OutLinkContainer>
            <OutLink href="https://patreon.com/tipalerts">
              <span>Patreon</span>
            </OutLink>
          </OutLinkContainer>
          <ButtonContainer>
            <OutLink href="https://patreon.com/join/tipalerts/checkout?rid=2342021">
              <ButtonPatreon />
            </OutLink>
          </ButtonContainer>
        </FooterContent>
      </BackgroundContainer>
    );
  }
}
