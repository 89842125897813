import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { ImageGallery } from 'components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getActionWidgetsComponentsUpdate, widgetsUploadFile } from 'store/reducers/widgets';
import { widgets } from 'constants';
import { deleteFile } from 'store/reducers/auth';


const Text = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  >span {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;

  width: 490px;
  justify-content: center;
  text-align: center;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;

  display: flex;

  justify-content: center;
  align-items: center;

  margin-top: 30%;
  margin-bottom: 30%;


`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  margin-top: 26px;
`;

const PricingPageLink = styled(Link)`
  color: ${({ theme }) => theme.orangeLanding};

  border: none;
  outline: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
   border: none;
   outline: none;
  }

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  padding: 8px 16px;
  border-radius: 4px;

  background: rgba(255, 182, 90, 0.17);


  &:visited{
    color: ${({ theme }) => theme.orangeLanding};
  }

  &:hover{
    color: ${({ theme }) => theme.linkHover};
  }
  &:active{
    color: ${({ theme }) => theme.linkActive};
  }
`;


const SoundTab = ({
  user,
  data,
  widgetsComponentsUpdate,
  widgetsUploadFile,
  deleteFile,
  t,
  isBasic,
}) => (
  <div style={{ position: 'relative' }}>
    {isBasic && (
      <Content>
        <TextContent>
          <Text>
            Upgrade your subscription to premium to use sounds
          </Text>
          <ButtonContent>
            <PricingPageLink to="/price"> Upgrade </PricingPageLink>
          </ButtonContent>
        </TextContent>
      </Content>)}
    {!isBasic && (<ImageGallery
      title={t.alertSound}
      type="sound"
      user={user}
      selected={data.sound}
      onSoundUpload={file => widgetsUploadFile(file, 'sound')}
      onFileDelete={deleteFile}
      onSoundSelect={soundNumber => widgetsComponentsUpdate({ sound: soundNumber, enabled: true })}
      data={data}
      widgetUpdate={widgetsComponentsUpdate}
      t={t}
    />)}
  </div>
);

SoundTab.title = 'Sound';

export default connect(
  state => ({
    user: state.auth.user,
    t: state.lang.t,
  }),
  (dispatch, ownProps) => bindActionCreators({
    widgetsComponentsUpdate: getActionWidgetsComponentsUpdate(widgets.tips.id, ownProps.selectedVariation, 'sound'),
    widgetsUploadFile,
    deleteFile,
  }, dispatch),
)(SoundTab);
