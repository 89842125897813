import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: #1B2D3A;
  display: flex;
  justify-content: center;
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-top: 35px;
  padding-bottom: 62px;
`;

export const Title = styled.span`
  font-weight: 800;
  font-size: 56px;
  line-height: 110%;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 715px;
  margin: 16px 0px;

  text-align: left;
`;

export const Text = styled.span`
  font-size: 18px;
  line-height: 26px;

  margin-top: 8px;
`;

export const Question = styled.span`
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
`;
