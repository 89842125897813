import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    background-color: ${({ color }) => color};
    align-items: center;
    padding-bottom: 42px;
    >span{
        opacity: 0.5;
    }
`;

const LogoContainer = styled.div`
  margin: 34px 0 18px 0;
  width: 104px;
  height: 22px;
  background-image: url('/images/visa_ms.svg');
`;

export class PaymentsInfo extends Component<Props> {
  render() {
    const {
      color,
    } = this.props;
    return (
      <Container color={color}>
        <LogoContainer />
        <span>
          You can proceed with payment using Visa, MasterCard cards.
          All payments are secured by a safe
          <p>
            payment gateway. To provide high level of security we are using 3D protocol.
          </p>
        </span>
      </Container>
    );
  }
}
