import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Contacts.css';

class DummyContacts extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="Contacts">
        <h3>{t.contactsTitle}</h3>
        <div>
          {t.vgTechLLC}
        </div>
        <div>
          <a href="mailto:support@tipalerts.com">support@tipalerts.com</a>
        </div>
        <div>
          {t.ogrn}
          : 1185476023809
        </div>
        <div>
          {t.innKpp}
          : 5406987958/540601001
        </div>
        <div>
          {t.address}
          :
          {t.addressInfo}
        </div>
      </div>
    );
  }
}

export const Contacts = connect(({ lang }) => ({ t: lang.t }))(DummyContacts);
