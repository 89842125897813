import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { Block, TextInput, Button } from 'components/UI';

const Description = styled.span`
  margin-right: 25px;
`;

const TextInputStyled = styled(TextInput)`
  margin-right: 10px;
  width: 50px;
`;

type Props = {
  onSend: () => void,
};

export class TestAlert extends PureComponent<Props> {
  state = {
    value: 100,
  };

  setValue = ({ target: { value } }) => this.setState({ value: parseInt(value) || 100 });

  render() {
    const { onSend, ...props } = this.props;
    const { value } = this.state;
    return (
      <Block {...props}>
        <Description>Send test tip</Description>
        <TextInputStyled value={value} onChange={this.setValue} />
        <Button onClick={() => onSend(value)}>Send</Button>
      </Block>
    );
  }
}
