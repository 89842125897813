import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';


const MainContainer = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.slateFour};
`;
const TabsCont = styled.div`
  display: flex;
  align-items: center;
`;

const TabName = styled.span`
  ${({ theme }) => theme.font.headerCheckbox};

  font-style: normal;
  font-stretch: normal;

  letter-spacing: normal;
  color: ${({ theme }) => theme.white};
`;
const ContentContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.backgroundTabHOC};
  `};
  border-radius: 5px;
  padding: 12px 24px 24px 24px;
  ${({ isFirstTabSelected }) => isFirstTabSelected && css`
    border-top-left-radius: 0;
  `};

  flex: 1;
`;
const StyledButton = styled.button`
  border-width: 0;

  height: 36px;

  padding: 0px 12px;
  outline: none;
  background-color: transparent;
  position: relative;

  ${({ active, theme }) => active && css`
    border-radius: 5px 5px 0 0;
    background-color: ${theme.backgroundTabHOC};
    cursor: default;
  `}
  &:focus {
    outline: none;
  }
  ${({ active, theme }) => !active && css`
  &:hover {
    border-radius: 5px 5px 0 0;
    background-color: ${theme.slate50};
  }
  `}

`;

const Badge = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: ${({ theme }) => theme.bananaYellow};
  top: -5px;
  right: -5px;
  border-radius: 50%;
`;


type Props = {
  content: array
}

type State = {
  activeTab: number
}

export class TabsContainer extends PureComponent<Props, State> {
  state = {
    activeTab: 0,
  }

  setActiveTab = activeTab => this.setState({ activeTab });

  render() {
    const { activeTab } = this.state;
    const { content } = this.props;
    const isFirstTabSelected = activeTab === 0;
    return (
      <MainContainer>
        <TabsCont>
          {content.map((tab, i) => (
            <StyledButton
              key={tab.type.title}
              active={i === activeTab}
              onClick={() => this.setActiveTab(i)}
            >
              <TabName>{tab.type.title}</TabName>
              {tab.type.badge && <Badge />}
            </StyledButton>
          ))}
        </TabsCont>
        <ContentContainer isFirstTabSelected={isFirstTabSelected}>
          {content[activeTab]}
        </ContentContainer>
      </MainContainer>
    );
  }
}
