import styled from 'styled-components';

export const Image = styled.div`
  width: 24px;
  height: 24px;
  background: url('/images/icons/ic-${({ icon }) => icon}.svg') no-repeat;

  margin-right: 12px;
`;

const Row = styled.div`
  display: flex;
  align-items: ${({ baseline }) => (baseline ? 'baseline' : 'center')};
`;

export const RowSlider = styled(Row)`
  height: 36px;
`;
