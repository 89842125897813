import styled, { css } from 'styled-components';

const StyledButon = styled.button`
  height: 34px;
  padding: 7px 12px 10px 12px;
  border-radius: 4px;
  margin-right: 4px;
  /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  ${({ theme }) => css`
    background-color: ${theme.blueGrey};
    border: solid 1px ${theme.slateFive};
    `}; */
  border: none;
  outline: none;
  background-color: #6F8291;
  &:hover {
    background-color: #7E92A0;
  }
  &:active {
    background-color: #334553;
  }
  &:focus {
    outline: none;
  }

  ${({ disabled }) => disabled && css`
    opacity: 0.54;
    pointer-events: none;
  `};
`;

export const Button = StyledButon;
