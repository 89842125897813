import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-self: baseline;

  height: 36px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`;

export const Col5 = styled.div`
  flex: 5;
  align-self: baseline;
  margin-right: 16px;
`;

export const Col4 = styled.div`
  flex: 4;
  align-self: baseline;
  margin-right: 16px;
`;

export const Col3 = styled.div`
  flex: 3;
  display: flex;
  align-self: baseline;
  justify-content: flex-end;
`;

export const Col9 = styled.div`
  flex: 9;
  align-self: baseline;

  margin-right: 16px;
`;
