import React, { Component } from 'react';
import { connect } from 'react-redux';

import { LogItem } from 'components/Log';
import deepEqual from 'deep-equal';

class LogsListBase extends Component {
  shouldComponentUpdate(nextProps) {
    return !deepEqual(this.props, nextProps);
  }

  renderLogItem = ({
    message,
    date,
    service,
    uuid,
  }) => <LogItem key={uuid === '00000000-0000-0000-0000-000000000000' ? Math.random() : (uuid || Math.random())} message={message} date={date} service={service} />;

  render() {
    return (
      <div
        style={{
          flex: 1,
          overflow: 'auto',
        }}
      >
        {this.props.logs.map(this.renderLogItem)}
      </div>
    );
  }
}

export const LogsList = connect(state => ({ logs: state.logs.items }))(LogsListBase);
