import { randInt } from 'utils';

export const galleryImages = [];
export const sounds = [];

const addSound = path => sounds.push({ path: `/sounds/${path}.mp3`, title: path });

addSound('allright');
addSound('coins');
addSound('message_1');
addSound('message_2');
addSound('message_3');
addSound('message_4');
addSound('message_5');
addSound('message');
addSound('ohyeah');
addSound('point_1');
addSound('point_2');
addSound('point');

export const pathGalleryImages = i => `/images/widgets/img${i}.${i === 0 ? 'png' : 'gif'}`;

for (let i = 0; i <= 32; i += 1) {
  galleryImages.push(pathGalleryImages(i));
}

export const storageFilePath = (id, type) => `https://firebasestorage.googleapis.com/v0/b/tipalerts-94db4.appspot.com/o/${type}-${id}?alt=media`;

export const storageSoundPath = id => storageFilePath(id, 'sound');
export const storageImagePath = (id, randomFlag, poolRandom) => {
  let idImage = id;
  if ((typeof poolRandom !== 'undefined') && randomFlag) {
    idImage = poolRandom[randInt(0, poolRandom.length + 1)];
  }
  return ((typeof idImage === 'number') ? galleryImages[idImage] : storageFilePath(idImage, 'image'));
};
