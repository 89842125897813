/* eslint-disable */
import { takeLatest, takeEvery, select, call } from 'redux-saga/effects';

import { API_HOST } from 'constants';
import {
  WIDGETS_UPDATE,
  WIDGETS_ACTIVE_SUBSCRIBE,
  WIDGETS_UPLOAD_IMAGE,
  WIDGETS_UPLOAD_FILE,
  WIDGETS_ADD_ITEM,
  WIDGETS_TEST_ALERT,
  WIDGETS_RESET,
  WIDGETS_COMPONENTS_UPDATE,
  WIDGETS_RACE_ACTIVE_TO_INACTIVE,
  WIDGETS_BUY_RACE,
  WIDGETS_INCREASE_RACE_AMOUNT,
  WIDGETS_INCREASE_RACE_TIME,
} from '../reducers/widgets';
import {
  firebaseWidgetUpdate,
  firebaseWidgetComponentUpdate,
  firebaseWidgetReset,
  firebaseSubscribeToActiveWidgets,
  firebaseUploadUserImage,
  firebaseUploadUserFile,
  firebaseWidgetsAddItem,
  removeUndefinedFields,
  firebaseWidgetComponentMoveTo,
  firebaseBuyRace,
  firebaseIncreaseRaceAmount,
  firebaseIncreaseRaceTime,
} from '../../utils';

function* updateWidget({ widgetId, payload }) {
  const { currentProfile, profiles } = yield select(({ auth }) => auth.user);
  const { streamAccessToken } = currentProfile
    ? profiles[currentProfile]
    : Object.values(profiles)[0];
  yield firebaseWidgetUpdate(widgetId, removeUndefinedFields(payload), streamAccessToken);
}

function* updateWidgetComponent({ widgetId, componentName, payload }) {
  const { user: { currentProfile, profiles }, options } = yield select(({ auth, widgetsOptions }) => ({ user: auth.user, options: widgetsOptions[widgetId] }));
  const { streamAccessToken } = currentProfile
    ? profiles[currentProfile]
    : Object.values(profiles)[0];
  const variation = (options && options.selectedPreset) || 'default';
  yield firebaseWidgetComponentUpdate({
    widgetId,
    componentName,
    payload: removeUndefinedFields(payload),
    streamAccessToken,
    variation,
  });
}

function* widgetRaceActiveToInactive({ username, updateOptions, reverse }) {
  const { user: { currentProfile, profiles } } = yield select(({ auth, widgetsOptions }) => ({ user: auth.user }));
  const { streamAccessToken } = currentProfile
    ? profiles[currentProfile]
    : Object.values(profiles)[0];
  const activePath = `widgets/${streamAccessToken}/race/default/active/${username}`;
  const inactivePath = `widgets/${streamAccessToken}/race/default/inactive/${username}`;
  if (reverse) yield firebaseWidgetComponentMoveTo(inactivePath, activePath, updateOptions);
  else yield firebaseWidgetComponentMoveTo(activePath, inactivePath, updateOptions);
}

function* widgetBuyRace({ name }) {
  const { user: { currentProfile, profiles } } = yield select(({ auth, widgetsOptions }) => ({ user: auth.user }));
  const { streamAccessToken } = currentProfile
    ? profiles[currentProfile]
    : Object.values(profiles)[0];
  yield firebaseBuyRace(name, streamAccessToken);
}

function* widgetIncreaseRaceAmount({ name, amount }) {
  const { user: { currentProfile, profiles } } = yield select(({ auth, widgetsOptions }) => ({ user: auth.user }));
  const { streamAccessToken } = currentProfile
    ? profiles[currentProfile]
    : Object.values(profiles)[0];
  yield firebaseIncreaseRaceAmount(name, amount, streamAccessToken);
}

function* widgetIncreaseRaceTime({ name, time }) {
  const { user: { currentProfile, profiles } } = yield select(({ auth, widgetsOptions }) => ({ user: auth.user }));
  const { streamAccessToken } = currentProfile
    ? profiles[currentProfile]
    : Object.values(profiles)[0];
  yield firebaseIncreaseRaceTime(name, time, streamAccessToken);
}

function* resetWidget({ widgetId }) {
  const { currentProfile, profiles } = yield select(({ auth }) => auth.user);
  const { streamAccessToken } = currentProfile
    ? profiles[currentProfile]
    : Object.values(profiles)[0];
  yield firebaseWidgetReset(widgetId, streamAccessToken);
}

const subscribed = false;
function* subscribeToActiveWidgets() {
  if (!subscribed) {
    yield firebaseSubscribeToActiveWidgets();
  }
}

function* uploadUserImage({ file }) {
  const { uid } = yield select(({ auth }) => auth.user);
  yield firebaseUploadUserImage(uid, file);
}

function* uploadUserFile({ file, fileType }) {
  const { uid } = yield select(({ auth }) => auth.user);
  yield firebaseUploadUserFile(uid, file, fileType);
}

function* widgetsAddItem({ widgetId, item }) {
  const { currentProfile, profiles } = yield select(({ auth }) => auth.user);
  const { streamAccessToken } = currentProfile
    ? profiles[currentProfile]
    : Object.values(profiles)[0];
  yield firebaseWidgetsAddItem(streamAccessToken, widgetId, item);
}

function* sendTestAlert({ amount }) {
  const { currentProfile, profiles } = yield select(({ auth }) => auth.user);
  const { streamAccessToken } = currentProfile
    ? profiles[currentProfile]
    : Object.values(profiles)[0];
  yield call(fetch, `${API_HOST}/test?token=${streamAccessToken}&amount=${amount}`);
}

export function* widgets() {
  yield takeLatest(WIDGETS_UPDATE, updateWidget);
  yield takeLatest(WIDGETS_COMPONENTS_UPDATE, updateWidgetComponent);
  yield takeLatest(WIDGETS_RESET, resetWidget);
  yield takeLatest(WIDGETS_ACTIVE_SUBSCRIBE, subscribeToActiveWidgets);
  yield takeLatest(WIDGETS_UPLOAD_IMAGE, uploadUserImage);
  yield takeLatest(WIDGETS_ADD_ITEM, widgetsAddItem);
  yield takeLatest(WIDGETS_UPLOAD_FILE, uploadUserFile);
  yield takeLatest(WIDGETS_TEST_ALERT, sendTestAlert);
  yield takeLatest(WIDGETS_RACE_ACTIVE_TO_INACTIVE, widgetRaceActiveToInactive);
  yield takeLatest(WIDGETS_BUY_RACE, widgetBuyRace);
  yield takeLatest(WIDGETS_INCREASE_RACE_AMOUNT, widgetIncreaseRaceAmount);
  yield takeLatest(WIDGETS_INCREASE_RACE_TIME, widgetIncreaseRaceTime);
}
