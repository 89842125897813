import React, { Component } from 'react';

import betaWhiteImg from 'assets/images/beta_white.svg';

import {
  Container,
  Beta,
  Text,
} from './style';

/* MyFreeCams and BongaCams integrations are currently at open
  beta testing. During test period they will be available to any
  TipAlerts user for
*/

export class BetaTestText extends Component {
  render() {
    const {
      landing,
    } = this.props;
    return (
      <Container>
        <Beta src={betaWhiteImg} alt="beta_white.svg" />
        <Text landing={landing}>
          MyFreeCams, BongaCams, Stripchat and Cam4 integrations are currently at open beta testing. During all test period it will be available to any TipAlerts user for
          <span>{'\xa0free'}</span>
          .
        </Text>
      </Container>
    );
  }
}
