import React from 'react';
import styled, { css } from 'styled-components';
import { Glyphicon } from 'react-bootstrap';

import { getColor } from 'utils/index';
import { CheckBox } from '../CheckBox';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-bottom: 15px; */
`;

const PickerContainer = styled.div`
  width: 68px;
  height: 34px;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  ${({ theme }) => css`
    background-color: ${theme.lueGrey};
    border: solid 1px ${theme.slateFive};
  `}
  align-items: center;
  display: flex;
  padding: 1px;
  margin-left: 28px;
`;

const ChosenColor = styled.div`
  width: 31px;
  height: 30px;
  border-radius: 3px 0 0 3px;
  background-color: ${({ value, theme }) => (value ? getColor(value) : theme.slateThree)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const RandomWord = styled.span`
  font-size: 14px;
  line-height: 1.05;
`;

const StyledCheckBox = styled(CheckBox)`
  margin-bottom: 0;
  margin-left: 10px;
`;

// const CheckBox = styled.input`
//   margin: 0 0 0 15px !important;
// `;

const Title = styled(RandomWord)`
  line-height: 14.8px;
  min-width: 150px;
  margin-left: 0;
`;

const PickButton = styled.button`
  align-items: center;
  background-color: transparent;
  justify-content: center;
  display: flex;
  width: 33px;
  height: 30px;
  &:focus{
    outline: none
  };
  border-width: 0;
`;

type Props = {
  title: string,
  value: number,
  onClick: Function,
  onChange: Function,
  checked: boolean
}

export const ColorChooser = (props: Props) => (
  <Container>
    {props.title && <Title>{props.title}</Title>}
    <PickerContainer>
      <ChosenColor value={props.value} onClick={props.onClick}>
        {props.value === 0 && <span>?</span>}
      </ChosenColor>
      <PickButton onClick={props.onClick}>
        <Glyphicon glyph="pencil" />
      </PickButton>
    </PickerContainer>
    <StyledCheckBox onChange={props.onChange} checked={props.checked}>
      <RandomWord>Random</RandomWord>
    </StyledCheckBox>
  </Container>
);
