/* eslint-disable */
import React from 'react';
import styled, { css } from 'styled-components';
import sprite from 'assets/images/sprite.svg';

const InputContainer = styled.div`
  border-radius: 4px;
  background-color: #2E3F4C;
  height: 36px;
  border: none;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  /*
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${({ theme }) => theme.orangeButtonHover};
  }
  &:focus, &:active {
    border: 1px solid ${({ theme }) => theme.orangeButtonActive};
  }
  */
`;

const Input = styled.input`
  background-color: #2E3F4C;
  height: 100%;
  width: ${({ width }) => width || '56px'};
  padding: 0 23px 0 10px;
  border: none;
  color: white;
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button { 
    -webkit-appearance: none;
  }
  &:focus {
    outline: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 16px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0 3px 3px 0px;
  margin: 1px;
  overflow: hidden;
`;

const button = css`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding-bottom: 1px;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  background-color: #576D7D;
  ${({ disabled }) => (disabled ? '' : '&:active { opacity: 0.5; }')}
  transition: opacity 0.1s ease-in-out;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
`;

const Svg = styled.svg`
  & > use {
    fill: ${({ fill }) => fill};
  }
`;

const ButtonUp = styled.div`
  ${button}
  transform: rotate(180deg);
`;

const ButtonDown = styled.div`
  ${button}
`;

export class NumberCountInput extends React.Component {
  state = {
    localValue: this.props.value,
    propsValue: this.props.value,
  };

  static defaultProps = {
    min: 0,
    max: 99,
    style: {},
  };

  componentDidMount() {
  this.input.current.addEventListener('keypress', this.onKeypress);
  }

  componentWillUnmount() {
    this.input.current.removeEventListener('keypress', this.onKeypress);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.onblur && props.value !== state.propsValue) return { localValue: props.value, propsValue: props.value };
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.value !== this.props.value
      || nextState.localValue !== this.state.localValue;
  }

  onKeypress = (e) => {
    if (e.key === 'Enter') {
      this.input.current.blur();
    }
  };

  onChange = (e) => {
    const {
      min,
      max,
      onChange,
      onblur,
    } = this.props;
    const number = Number(e.target.value.replace(/[,.+-]/g, ''));
    if (Number.isNaN(number)) return null;

    if (onblur) return this.setState({ localValue: e.target.value });

    if (number > max || number < min) return null;
    onChange(`${number}`);
  };

  onBlur = () => {
    const {
      min,
      max,
      onChange,
      onblur,
    } = this.props;
    if (onblur) {
      let number = Number(this.state.localValue);
      if (number < min) number = min;
      else if (number > max) number = max;
      this.setState({
        localValue: number,
        propsValue: number,
      }, () => onChange(number));
    }
  };

  input = React.createRef();

  counter = null;

  operators = {
    '+': (a, b) => a + b,
    '-': (a, b) => a - b,
  };

  animate = (_result, operator) => {
    const { onChange, max, min } = this.props;
    const start = performance.now();
    let result = _result;
    this.counter = 1;

    const cb = (time) => {
      if (!this.counter) return;
      const x = (time - start) / 500;
      const y = x ** 2;
      if (y > this.counter) {
        this.counter = Math.floor(y + 1);
        result = this.operators[operator](result, 1);
        onChange(`${result}`);
      }

      if (result < max && result > min) {
        requestAnimationFrame(cb);
      }
    };

    requestAnimationFrame(cb);
  };

  onArrowClick = operator => () => {
    const { max, min, onChange } = this.props;
    const prevNumber = Number(this.input.current.value);
    if (
      (operator === '+' && prevNumber >= max)
      || (operator === '-' && prevNumber <= min)
    ) return;
    const result = this.operators[operator](prevNumber, 1);
    onChange(`${result}`);
    this.animate(result, operator);
  };

  onTouchEnd = () => this.counter = null;

  render() {
    const {
      value: _value,
      min,
      max,
      style,
      width,
      onblur,
      placeholder,
    } = this.props;
    const { localValue } = this.state;
    const value = Number(_value);
    return (
      <InputContainer style={style}>
        <Input
          ref={this.input}
          type="text" // https://github.com/facebook/react/issues/9402
          onChange={this.onChange}
          value={onblur ? localValue : value}
          width={width}
          onBlur={this.onBlur}
          placeholder={placeholder}
        />
        <ButtonContainer>
          <ButtonUp
            onTouchStart={this.onArrowClick('+')}
            onTouchEnd={this.onTouchEnd}
            onMouseDown={this.onArrowClick('+')}
            onMouseUp={this.onTouchEnd}
            onMouseLeave={this.onTouchEnd}
            disabled={max <= value}
          >
            <Svg fill={`${max <= value ? 'rgba(255, 255, 255, 0.34)' : 'white'}`} width="10" height="6" viewBox="0 0 10 6">
              <use xlinkHref={`${sprite}#arrow`} />
            </Svg>
          </ButtonUp>
          <ButtonDown
            onTouchStart={this.onArrowClick('-')}
            onTouchEnd={this.onTouchEnd}
            onMouseDown={this.onArrowClick('-')}
            onMouseUp={this.onTouchEnd}
            onMouseLeave={this.onTouchEnd}
            disabled={min >= value}
          >
            <Svg fill={`${min >= value ? 'rgba(255, 255, 255, 0.34)' : 'white'}`} width="10" height="6" viewBox="0 0 10 6">
              <use xlinkHref={`${sprite}#arrow`} />
            </Svg>
          </ButtonDown>
        </ButtonContainer>
      </InputContainer>
    );
  }
}
