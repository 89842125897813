import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Service_ID, ServicesErrors } from 'constants';
import {
  authSendServiceId,
  authSetServiceIdError,
  authDeleteServiceId,
} from 'store/reducers/auth';

import chaturbateLogo from 'assets/images/CB-logo.svg';
import bongacamsLogo from 'assets/images/BC-logo.svg';
import myfreecamsLogo from 'assets/images/MFC-logo.svg';
import stripchatLogo from 'assets/images/SC-logo.svg';
import cam4Logo from 'assets/images/C4-logo.svg';
import camsodaLogo from 'assets/images/camsoda-logo.png';
import manyvidsLogo from 'assets/images/manyvids-logo.png';
import amateurLogo from 'assets/images/amateur-logo.png';
import camsLogo from 'assets/images/cams-com-logo.svg';

import { EditId, ConnectionSuccess } from './ComponentServiceEdit';

import {
  Container,
  Dashboard,
  DashboardContainer,
  Content,
  ImageGradient,
  Logo,
  Title,
  Beta,
} from './style';

const serviceArr = Object.values(Service_ID);

export const ServiceIdEdit = connect(state => ({
  currentProfile: state.auth.user.currentProfile,
  profiles: state.auth.user.profiles,
  errors: state.auth.servicesErrors,
  loadings: state.auth.servicesLoadings,
}),
d => bindActionCreators({
  authSendServiceId,
  authSetServiceIdError,
  authDeleteServiceId,
}, d))(
  class extends React.Component {
    constructor(props) {
      super(props);
      const { location } = props;
      this.backPath = (location.state && location.state.fromPathname) || '/connect-service';
    }

    componentDidMount() {
      const { authSetServiceIdError, match: { params: { service } } } = this.props;
      if (!serviceArr.some(serviceId => serviceId === service)) return;
      authSetServiceIdError('', service);
    }

    componentWillUnmount() {
      const { authSetServiceIdError, match: { params: { service } } } = this.props;
      if (!serviceArr.some(serviceId => serviceId === service)) return;
      authSetServiceIdError('', service);
    }

    onDeleteServiceId = (service) => {
      const {
        authDeleteServiceId,
        history,
      } = this.props;
      authDeleteServiceId(service);
      history.push(this.backPath);
    };

    onSubmit = (newId) => {
      const {
        authSendServiceId,
        match: { params: { service } },
      } = this.props;
      if (!serviceArr.some(serviceId => serviceId === service)) return;
      authSendServiceId(newId, service);
    };

    onChange = () => {
      const { authSetServiceIdError, match: { params: { service } }, errors } = this.props;
      if (errors[service]) authSetServiceIdError('', service);
    };

    render() {
      const {
        currentProfile,
        profiles,
        match: { url, params: { service } },
        history,
        errors,
        loadings,
      } = this.props;
      const match = serviceArr.some(serviceId => serviceId === service);
      if (!match) return <Redirect to="/connect-service" />;

      const profile = profiles && currentProfile ? profiles[currentProfile] : {};
      const idChaturbate = profile.chaturbateId;
      const idBongacams = profile.bongacamsId;
      const idMyfreecams = profile.myfreecamsId;
      const idStripchat = profile.stripchatId;
      const idCam4 = profile.cam4Id;
      const idCamsoda = profile.camsodaId;
      let id;
      let title;
      let exampleUserName;
      const betaTest = false;
      let logo;
      if (service === Service_ID.chaturbate) {
        id = idChaturbate;
        title = 'Chaturbate';
        exampleUserName = 'username';
        logo = chaturbateLogo;
      } else if (service === Service_ID.bongacams) {
        id = idBongacams;
        title = 'BongaCams';
        exampleUserName = 'MyUsername';
        logo = bongacamsLogo;
      } else if (service === Service_ID.myfreecams) {
        id = idMyfreecams;
        title = 'MyFreeCams';
        exampleUserName = '#MyUsername';
        logo = myfreecamsLogo;
      } else if (service === Service_ID.stripchat) {
        id = idStripchat;
        title = 'StripChat';
        exampleUserName = 'MyUsername';
        logo = stripchatLogo;
      } else if (service === Service_ID.cam4) {
        id = idCam4;
        title = 'Cam4';
        exampleUserName = 'MyUsername';
        logo = cam4Logo;
      } else if (service === Service_ID.camsoda) {
        id = idCamsoda;
        title = 'CamSoda';
        exampleUserName = 'username';
        logo = camsodaLogo;
      } else if (service === Service_ID.amateur) {
        id = profile.amateurId;
        title = 'amateur.tv';
        exampleUserName = 'username';
        logo = amateurLogo;
      } else if (service === Service_ID.cams) {
        id = profile.camsId;
        title = 'Cams.com';
        exampleUserName = 'username';
        logo = camsLogo;
      } else if (service === Service_ID.manyvids) {
        id = profile.manyvidsId;
        title = 'ManyVids Live';
        exampleUserName = 'username';
        logo = manyvidsLogo;
      }
      const allErrors = new ServicesErrors(title);
      const warningText = allErrors.getError(errors[service] || '');
      const loading = loadings[service];
      document.title = 'Service — TipAlerts';
      return (
        <Container>
          <DashboardContainer>
            {false && <Beta right />}
            <Dashboard>
              <ImageGradient service={service}>
                <Logo src={logo} alt={`${service}.png`} />
              </ImageGradient>
              <Content>
                <Title>{`Connect ${title} stream`}</Title>
                <Switch>
                  <Route
                    exact
                    path={`${url}/edit`}
                    render={() => (
                      <EditId
                        id={id}
                        exampleUserName={exampleUserName}
                        betaTest={betaTest}
                        warningText={warningText}
                        service={service}
                        onChange={this.onChange}
                        onSubmit={this.onSubmit}
                        onCancel={() => history.push(this.backPath)}
                        loading={loading}
                        onDeleteServiceId={this.onDeleteServiceId}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`${url}/success`}
                    render={() => (
                      id
                        ? <ConnectionSuccess id={id} onDone={() => history.push(this.backPath)} onBack={() => history.push(`${url}/edit`)} />
                        : <Redirect to={`${url}/edit`} />
                    )}
                  />
                  <Redirect to={`${url}/edit`} />
                </Switch>
              </Content>
            </Dashboard>
          </DashboardContainer>
        </Container>
      );
    }
  },
);
