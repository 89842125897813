export default {
  tips: {
    id: 'tips',
    title: 'Alerts',
    docTitle: 'Alerts - TipAlerts',
    description: 'Give your fans more ways to express love!',
    isPremiumRequired: false,
  },
  goals: {
    id: 'goals',
    title: 'Goals',
    docTitle: 'Goals - TipAlerts',
    description: 'Description',
    isPremiumRequired: true,
  },
  top: {
    id: 'top',
    title: 'Top Tippers',
    docTitle: 'TopTippers - TipAlerts',
    description: 'Description',
    isPremiumRequired: true,
  },
  dice: {
    id: 'dice',
    title: 'Roll The Dice',
    docTitle: 'RollTheDice - TipAlerts',
    description: 'Description',
    isPremiumRequired: true,
  },
};
