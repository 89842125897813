export const Service_ID = {
  chaturbate: 'chaturbate',
  bongacams: 'bongacams',
  myfreecams: 'myfreecams',
  stripchat: 'stripchat',
  cam4: 'cam4',
  camsoda: 'camsoda',
  amateur: 'amateur',
  cams: 'cams',
  manyvids: 'manyvids',
};

export const servicesNames = {
  chaturbate: 'Chaturbate',
  bongacams: 'BongaCams',
  myfreecams: 'MyFreeCams',
  stripchat: 'Stripchat',
  cam4: 'CAM4',
  camsoda: 'CamSoda',
  cams: 'Cams.com',
  manyvids: 'ManyVids',
  amateur: 'amateur.tv',
};

export const servicesCodes = {
  chaturbate: 'CB',
  bongacams: 'BC',
  myfreecams: 'MFC',
  stripchat: 'SC',
  cam4: 'C4',
  camsoda: 'CS',
  cams: 'CAMS',
  manyvids: 'MV',
  amateur: 'AM',
};

export const servicesColors = {
  chaturbate: '#FED46B',
  bongacams: '#DC3C5E',
  myfreecams: '#008000',
  stripchat: '#A2252D',
  cam4: '#2D2D2D',
  camsoda: '#00B0FC',
  amateur: '#DE001A',
  cams: '#171933',
  manyvids: '#FF4080',
};

export const servicesURLs = {
  chaturbate: 'https://chaturbate.com',
  bongacams: 'https://bongacams.com',
  myfreecams: 'https://myfreecams.com',
  stripchat: 'https://stripchat.com',
  cam4: 'https://cam4.com',
  camsoda: 'https://camsoda.com',
  cams: 'https://cams.com',
  manyvids: 'https://live.manyvids.com',
  amateur: 'https://amateur.tv',
};

export class ServicesErrors {
  constructor(title) {
    this.servicesErrors = {
      'Not found by id': `There is no user with specified username at ${title}. Please check if specified username is correct.`,
      'Not found by link': `We were unable to find your stream at ${title}. Please check if specified link is correct.`,
      'Fill id': `There is no user with specified username at ${title}. Please check if specified username is correct.`,
      'Wrong link': `We were unable to find your stream at ${title}. Please check if specified link is correct.`,
      'Wrong id': `There is no user with specified username at ${title}. Please check if specified username is correct.`,
    };
  }

  getError = error => this.servicesErrors[error] || error;
}
