import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  width: 60%;
  margin-top: 2%;
  font-size: 15px;
`;

const StrongBigText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #ffb016;
`;

export class Premium extends React.Component {
  componentDidMount() {
    const s = document.createElement('script');
    s.src = 'https://tipalerts.disqus.com/embed.js';
    s.setAttribute('data-timestamp', +new Date());
    (document.head || document.body).appendChild(s);
  }

  render() {
    return (
      <Container>
        <TextContainer>
          <h4>Hi dear!</h4>
          <p>
            Sorry for interruption.
          </p>
          My name is Vlad, I&apos;m founder of TipAlerts and I very happy that you found this service valuable for you.
          <br />
          Unfortunately recent time we has some issues with avialability and stability of service, perhaps you also faced with it.
          <br />
          It&apos;s caused by fast growed count of users &mdash; so our servers can&apos;t handle it.
          <br />
          Any project have two options &mdash; evolve or die. And today we on the road fork.
          <br />
          <br />
          If we want that TipAlerts will exist &mdash; we should spend more time to make new features, fix issues, support users and a much more.
          <br />
          But it&apos;s not possible with our month budget &mdash; around 1000 USD.
          <br />
          By our estimation required sum for exist and growth is about 5000 USD per month, it&apos;s not too much and we need just one thousand premium users to get this point.
          <br />
          It is just about 10% of all our users and about 25% of our month users.
          <br />
          But with this sum we can make a much great features and ideas &mdash; which asked by you or invented by us.
          <br />
          <br />
          We have talented people &mdash; developers, designer and PM and we can make this project very strong and beautifull.
          <br />
          But those people cannot work without food, bills payment and family expenses, so if we don&apos;t find solution &mdash; we loss our team and project.
          <br />
          <br />
          In view of this facts &mdash; I must introduce some changes, and I hope that you support us with them.
          <br />
          <br />
          <StrongBigText>Started from Febraury 17</StrongBigText>
          &nbsp;we stop providing service for free users, only users with Premium account would have access to TipAlerts functionality.
          <p>
            You can purchase premium account at the&nbsp;
            <Link to="/settings">Settings</Link>
            &nbsp;
            page.
            <br />
            More information about premium account you can find at our&nbsp;
            <a href="http://help.tipalerts.com/article/premium-account/" target="_blank" rel="noopener noreferrer">support center</a>
            .
          </p>
          <StrongBigText>Started from March 1</StrongBigText>
          &nbsp;we will change our tariffs to following:
          <ul>
            <li>basic account (currently free) fee is $5</li>
            <li>premium account (currently $5) fee is $10</li>
          </ul>
          We have plans to return basic free account around start of summer but with some major changes.
          <br />
          If you want to&nbsp;
          <strong>test premium account for free</strong>
          &nbsp;&mdash; please write us to support chat or e-mail&nbsp;
          <a href="mailto:support@tipalerts.com">support@tipalerts.com</a>
          &nbsp;and we give you it for some days.
          <br />
          <br />
          If you want to help us more &mdash; you can donate any additional sum on Patreon, so it would very helpful for service.
          <br />
          <br />
          I&apos;m sorry again for this situation, I believe that you can understand that the main reason of this step is making great service.
          <br />
          I think that we should discuss this point together so we add open Disqus comments at this page, feel free to leave your opinion.
          <br />
          <br />
          Also I want to gratitude people who have premium account from the begining of project &mdash; it really matter!
          <br />
          Thank you Katie!&nbsp;
          <a href="https://chaturbate.com/kdwow" target="_blank" rel="noopener noreferrer">https://chaturbate.com/kdwow</a>
          <br />
          Thank you Liz!&nbsp;
          <a href="https://chaturbate.com/universitysmiles" target="_blank" rel="noopener noreferrer">https://chaturbate.com/universitysmiles</a>
          <br />
          Thank you Alise!&nbsp;
          <a href="https://chaturbate.com/alisebusty" target="_blank" rel="noopener noreferrer">https://chaturbate.com/alisebusty</a>
          <br />
          Thank you Nathalie!&nbsp;
          <a href="https://chaturbate.com/tattoo_ninja_kitty" target="_blank" rel="noopener noreferrer">https://chaturbate.com/tattoo_ninja_kitty</a>
          <br />
          Thank you Clem!&nbsp;
          <a href="https://chaturbate.com/clementine__" target="_blank" rel="noopener noreferrer">https://chaturbate.com/clementine__</a>
          <br />
          Thank you Emma!&nbsp;
          <a href="https://chaturbate.com/emma_lu1" target="_blank" rel="noopener noreferrer">https://chaturbate.com/emma_lu1</a>
          <br />
          And thanks for all who support us for a while (sorry if I miss someone).
          <div id="disqus_thread" />
        </TextContainer>
      </Container>
    );
  }
}
