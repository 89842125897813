import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import iconMail from 'assets/images/ic_mail.svg';
import { firebaseSendEmailVerification } from 'utils/firebase';
import { Button } from 'components/UI';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 70vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
`;

const Text = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  max-width: 525px;
  text-align: center;
`;

class VerifyBase extends PureComponent {
  state = {
    sendButtonAvailableIn: 0,
  };

  initFBUserTimeout = null;

  componentDidMount() {
    if (this.props.location.search.indexOf('send') !== -1) {
      this.resetButtonTimeout();
      this.initFBUserTimeout = setTimeout(this.sendVerification, 3000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.buttonInterval);
    clearTimeout(this.initFBUserTimeout);
  }

  buttonInverval = null;

  resetButtonTimeout = () => {
    this.setState({ sendButtonAvailableIn: 59 });
    this.buttonInterval = setInterval(() => {
      this.setState(({ sendButtonAvailableIn }) => {
        if (sendButtonAvailableIn > 0) {
          return { sendButtonAvailableIn: sendButtonAvailableIn - 1 };
        }
        clearInterval(this.buttonInterval);
        return { sendButtonAvailableIn: 0 };
      });
    }, 1000);
  };

  sendVerification = () => {
    this.resetButtonTimeout();
    if (!this.props.user.emailVerified) {
      firebaseSendEmailVerification().catch(e => alert(e.toString()));
    } else {
      console.log('Email already verified');
    }
  };

  render() {
    const { user: { email, emailVerified } } = this.props;
    const { sendButtonAvailableIn } = this.state;
    if (emailVerified) {
      return (
        <Container>
          <Title>Your e-mail address verified</Title>
          <div>
            Go to the <Link to="/price">Pricing page</Link>
          </div>
        </Container>
      );
    }
    return (
      <Container>
        <img src={iconMail} />
        <Title>Please verify e-mail address</Title>
        <Text>
          Your account has been created, however you need to verify e-mail address.
          We’ve sent you e-mail to the <strong>{email}</strong> with a link for verification.
          <br />
          If you don’t get it please check spam folder.
        </Text>
        <Button style={{ marginTop: '20px' }} disabled={sendButtonAvailableIn !== 0} onClick={this.sendVerification}>Send verification link again {sendButtonAvailableIn !== 0 ? `(${sendButtonAvailableIn})` : ''}</Button>
      </Container>
    );
  }
}

export const Verify = connect(state => ({ user: state.auth.user }))(VerifyBase);
