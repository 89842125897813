/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { widgetUpdate } from 'store/reducers/widgets';
import { widgetsOptionsSelectPreset } from 'store/reducers/widgetsOptions';

import { widgets } from 'constants';

import { TabsBar } from 'components/UI';

export default connect(
  state => ({
    supporter: state.auth.supporter || {},
    tipsData: state.widgets.tips || {},
    selectedPreset: state.widgetsOptions.tips.selectedPreset,
  }),
  dispatch => bindActionCreators(
    {
      widgetUpdate,
      widgetsOptionsSelectPreset,
    },
    dispatch,
  ),
)(class AmountVariations extends React.Component {
  // tipsData too large object
  shouldComponentUpdate({ tipsData: nextTipsData, selectedPreset: nextSelectedPreset }) {
    const { tipsData, selectedPreset } = this.props;
    const actual = Object.keys(tipsData).sort();
    const next = Object.keys(nextTipsData).sort();

    const enabledPreset = tipsData[selectedPreset] && tipsData[selectedPreset].preset && tipsData[selectedPreset].preset.enabled;
    const enabledPresetNext = nextTipsData[selectedPreset] && nextTipsData[selectedPreset].preset && nextTipsData[selectedPreset].preset.enabled;

    return JSON.stringify(actual) !== JSON.stringify(next) || selectedPreset !== nextSelectedPreset || enabledPreset !== enabledPresetNext;
  }

  onCreateClick = (items: string[]) => {
    const { widgetUpdate, widgetsOptionsSelectPreset } = this.props;

    let lastTo = items.reduce((acc, { to }) => {
      if (to > acc) return to;
      return acc;
    }, 0);

    if (lastTo <= 0) lastTo = 0;
    else lastTo += 1;

    const from = lastTo;
    const to = lastTo + 99;

    widgetUpdate(widgets.tips.id, { [`${from}-${to}`]: { preset: { from, to, enabled: true } } });
    widgetsOptionsSelectPreset(widgets.tips.id, `${from}-${to}`);
  };

  onSelect = (variationIndex: number, items: string[]) => {
    const { widgetsOptionsSelectPreset } = this.props;
    const { key } = items[variationIndex];
    widgetsOptionsSelectPreset(widgets.tips.id, key);
  };

  render() {
    const {
      tipsData,
      selectedPreset,
      supporter,
    } = this.props;
    const tipsDataKeys = Object.keys(tipsData);

    // form and sort array for bar with keys { key, title }
    const variations = tipsDataKeys
      .reduce((acc, key) => {
        const { preset } = tipsData[key] || {};
        if (key !== 'default' && preset && preset.from !== undefined && preset.to !== undefined) {
          const title = preset.from === preset.to ? `${preset.from}` : `${preset.from}-${preset.to}`;
          return [...acc, { key, title, from: preset.from, to: preset.to, enabled: preset.enabled, }];
        }
        return acc;
      }, [{
        key: 'default',
        title: 'Any other',
        from: -1,
        to: -1,
        enabled: tipsData.default && tipsData.default.preset && tipsData.default.preset.enabled,
      }])
      .sort((a, b) => a.from - b.from);

    if (variations.length <= 1) {
      const indDefault = variations.findIndex(({ key }) => key === 'default');
      variations[indDefault].title = 'Any';
    }

    const active = variations.findIndex(({ key }) => key === selectedPreset);
    return (
      <TabsBar
        title={widgets.tips.title}
        actionButtonTitle="Create"
        items={variations}
        itemsTitleKey="title"
        active={active}
        onChangeTab={this.onSelect}
        onActionButtonClick={this.onCreateClick}
        supporter={supporter}
      />
    );
  }
});
