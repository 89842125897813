import styled from 'styled-components';

export const Col = styled.div`
  margin-right: 16px;
`;

export const Row = styled.div`
display: flex;
align-items: ${({ baseline }) => (baseline ? 'baseline' : 'center')};
`;

export const Label = styled.div`
  display: flex;
  height: 20px;
  margin-bottom: 4px;
`;
