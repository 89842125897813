// @flow
/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { Overlay } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import styled, { css } from 'styled-components';

import { widgetColors, getColor } from 'utils';

import 'components/Common/SelectColor/SelectColor.css';
import { Tooltip } from 'components/UI';

const Container = styled.div`

`;

const ChosenColor = styled.div`
  width: 54px;
  height: 36px;
  border-radius: 4px 0px 0px 4px;

  background-color: ${({ value }) => value !== 0 ? getColor(value) : 'hsla(206, 29%, 36%, 1)'}; /* color === 'random' || color === 0 || typeof color === 'undefined' */
  cursor: pointer;

  &:hover {
    border: 1px solid ${({ theme }) => theme.orangeButtonHover};
  }
  &:active {
    border: 1px solid ${({ theme }) => theme.orangeButtonActive};
  }
`;

const onPickerColorChange = (callback, allColorModels) => (color) => {
  const alpha = Math.round(color.rgb.a * 255);
  const alphaHex = alpha < 16 ? (0 + alpha.toString(16)) : alpha.toString(16);
  const hexa = color.hex + alphaHex;
  if (allColorModels === true) {
    callback({ ...color, hexa });
  } else {
    callback(color.hex + alphaHex);
  }
};

const RandomButton = styled.button`
  position: relative;

  width: 36px;
  height: 36px;

  outline: none;
  border: none;
  background-color: ${({ active }) => active ? 'hsl(33, 100%, 68%)' : 'hsla(206, 29%, 36%, 1)'};
  border-radius: 0px 4px 4px 0px;

  &:hover {
    background-color: ${({ active, disabled }) => !disabled && (
    active
    ? 'hsl(33, 100%, 73%)'
    : 'hsla(206, 29%, 46%, 1)')};
  }

  margin-left: 1px;

  ${({ disabled }) => disabled && css`
    opacity: 0.54;
    pointer-events: none;
  `};

  transition: opacity 0ms;
`;

const ImageDice = styled.div`
  background-color: ${({ theme, active }) => active ? theme.darkGrey : theme.white };

  width: 24px;
  height: 24px;

  mask-image:  url('/images/icons/ic-dice.svg');
  mask-repeat: no-repeat;
  mask-position: center;
`;

const Text = styled.span`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

export default class NewColorSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewsNotRandomColor: props.value ? props.value : 1,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== 0) {
      this.setState({ previewsNotRandomColor: nextProps.value });
    }
  }

  state = {
    show: false,
  };

  target = null;

  picker = React.createRef();

  onClick = (e) => {
    const isOutClick = ReactDOM.findDOMNode(this.picker.current) && !ReactDOM.findDOMNode(this.picker.current).contains(e.target);
    if (isOutClick) {
      this.setState({ show: false }, () => {
        document.removeEventListener('click', this.onClick, false);
      });
    }
  };

  onShowClick = () => {
    const { show } = this.state;
    this.setState({ show: !show }, () => {
      document.addEventListener('click', this.onClick, false);
    });
  };

  onRandomCheckboxChanged = () => {
    const { onSelect, value } = this.props;
    if (value === 0) {
      const { previewsNotRandomColor } = this.state;
      onSelect(previewsNotRandomColor);
    } else {
      onSelect(0);
    }
  };

  render() {
    const {
      value,
      onSelect,
      allColorModels,
      disabledRandomButton,
      disabled,
    } = this.props;
    const { show } = this.state;
    return (
      <Container className="SelectColor">
          <ChosenColor
            ref={c => this.target = c && ReactDOM.findDOMNode(c)}
            value={allColorModels ? value.hexa : value}
            onClick={this.onShowClick}
          >
            {value === 0 && <Text>?</Text>}
          </ChosenColor>
          <Overlay
            show={show}
            onHide={() => this.setState({ show: false })}
            target={() => this.target}
            container={this}
            ref={this.picker}
            rootClose
          >
            <SketchPicker
              presetColors={widgetColors.slice(0)}
              color={getColor(allColorModels ? value.hexa : value)}
              onChange={onPickerColorChange(onSelect, allColorModels)}
              triangle="hide"
              className="SelectColor__SketchPicker SelectColor__ColorsView SelectColor__BottomPosition"
            />
          </Overlay>
          <RandomButton onClick={this.onRandomCheckboxChanged} active={Number(value) === 0} disabled={!disabled && disabledRandomButton}>
            <ImageDice active={Number(value) === 0}/>
            {!disabledRandomButton && <Tooltip>Toggle random color</Tooltip>}
          </RandomButton>
      </Container>
    );
  }
}
