import React from 'react';
import { Glyphicon } from 'react-bootstrap';
import styled from 'styled-components';

import { TextInput } from 'components/UI';

const StyledTextInput = styled(TextInput)`
  flex: 1;
  margin: 0px 4px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

export const InputWithValidation = ({
  isValid, value, validationErrorText, icon, ...props
}) => (
  <Container>
    <Glyphicon glyph={icon} />
    <StyledTextInput
      value={value}
      {...props}
    />
    {
      !isValid(value) ? <Glyphicon glyph="remove" title={validationErrorText} /> : <Glyphicon glyph="ok" title={validationErrorText} />
    }
  </Container>
);
